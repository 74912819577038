<div class="self-stretch flex flex-col items-center justify-center py-6 px-16 gap-[24px_0px] text-center text-base">
  <img class="w-[134px] relative h-[134px]" alt="group icon" src="assets/images/search/group.svg" />

  <b class="self-stretch relative text-11xl leading-[38px] text-gray-true-800">No results found for "{{ searchString
    }}"</b>
  <div class="self-stretch relative leading-[24px] font-semibold">
    <p class="[margin-block-start:0] [margin-block-end:16px]">
      Sorry! We couldn't locate the product you're searching for.
    </p>
    <p class="m-0">
      Please double-check for any potential spelling errors or try alternative search terms.
    </p>
  </div>
  <div class="self-stretch flex flex-col items-center justify-center gap-[12px_0px] text-xl text-gray-900">
    <div class="self-stretch relative leading-[30px] font-semibold">Search for products</div>
    <div class="h-[41px] flex flex-row items-center justify-start text-left text-sm text-gray-500" id="frameContainer">
      <div
        class="self-stretch md:w-[400px] rounded-tl-8xs rounded-tr-none rounded-br-none rounded-bl-8xs bg-base-white box-border overflow-hidden shrink-0 flex flex-row items-center justify-start border-t-[1px] border-solid border-gray-400 border-b-[1px] border-l-[1px]">
        <div class="flex-1 flex flex-row items-center justify-start">
          <div class="flex-1 relative leading-[20px] font-semibold overflow-hidden text-ellipsis whitespace-nowrap">
            <input [(ngModel)]="searchString" (keyup)="onClearSearch()" type="text"
              class="w-full py-2.5 px-3.5 border-0" placeholder="Search our Shop" />
          </div>
        </div>
      </div>
      <div (click)="onSearchBtnClick()"
        class="self-stretch w-[41px] rounded-tl-none rounded-tr-8xs rounded-br-8xs rounded-bl-none bg-brand-500 overflow-hidden shrink-0 flex flex-row items-center justify-center cursor-pointer">
        <div class="flex-1 flex flex-row items-center justify-center">
          <img class="w-5 relative h-5 overflow-hidden shrink-0" alt="search icon" src="assets/images/home/searchlg.svg" />
        </div>
      </div>
    </div>
  </div>
  <div class="self-stretch relative leading-[24px] font-semibold">OR</div>
  <div class="flex flex-col items-start justify-start text-left text-base-white">
    <div
      class="self-stretch rounded-lg bg-brand-600 shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] overflow-hidden flex flex-row items-center justify-center py-2.5 px-4 gap-[0px_6px] border-[1px] border-solid border-brand-600 cursor-pointer">
      <!-- <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="/public/placeholder.svg" /> -->

      <button [routerLink]="'/'" class="flex flex-row items-center justify-center py-0 px-0.5">
        <div class="relative leading-[24px] font-semibold">Continue Shopping</div>
      </button>
      <!-- <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="/public/placeholder.svg" /> -->
    </div>
  </div>
</div>