import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { OrderDetailService } from './services/order-detail.service';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { GenericService } from '@app/core/services/generics.service';
import { MapInfoWindow, MapMarker, GoogleMapsModule } from '@angular/google-maps';
import { MapDirectionsService } from '@angular/google-maps';
import { AuthService } from '@app/core/services/auth.service';
import { map, Observable } from 'rxjs';

import { Tooltip } from 'flowbite';
import type { TooltipOptions, TooltipInterface } from 'flowbite';
import type { InstanceOptions } from 'flowbite';
import { environment } from '@environments/environment';
import { NotificationService } from '@app/core/services/notification.service';
import { FormsModule } from '@angular/forms';
import { ModalComponent } from '../shared/components/modal/modal.component';
import { OrderItemsComponent } from './components/order-items/order-items.component';
import { DrawerComponent } from '../shared/drawer/drawer.component';
import { NgIf, NgFor, NgClass, AsyncPipe, DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrl: './order-detail.component.scss',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgIf,
    GoogleMapsModule,
    NgFor,
    RouterLink,
    NgClass,
    DrawerComponent,
    OrderItemsComponent,
    ModalComponent,
    FormsModule,
    AsyncPipe,
    DecimalPipe,
  ],
})
export class OrderDetailComponent implements OnInit {
  orderId;
  orderDetails;
  configuration;
  selectedStore;
  isDrawerOpen: boolean = false;
  isOrderItemsOpen: boolean = false;
  intervalTimer;
  options: google.maps.MapOptions = {
    scrollwheel: true,
    streetViewControl: false,
    mapTypeControl: false,
  };
  center: google.maps.LatLngLiteral = { lat: 40, lng: -20 };
  zoom = 15;
  icon = {
    url: 'assets/images/home/app_pin.png', // url
    scaledSize: new google.maps.Size(30, 40), // scaled size
  };
  storeIcon = {
    url: 'assets/images/home/home.svg', // url
    scaledSize: new google.maps.Size(30, 40), // scaled size
  };
  workerIcon = {
    url: 'assets/images/home/delivery-motocycle.svg', // url
    scaledSize: new google.maps.Size(30, 40), // scaled size
  };
  markerOptions: google.maps.MarkerOptions = {
    draggable: false,
    icon: this.icon,
  };
  markerOptionsStore: google.maps.MarkerOptions = {
    draggable: false,
    icon: this.storeIcon,
  };
  markerOptionsWorker: google.maps.MarkerOptions = {
    draggable: false,
    icon: this.workerIcon,
  };
  markerPosition: google.maps.LatLngLiteral;
  markerPositionStore: google.maps.LatLngLiteral;
  markerPositionWorker: google.maps.LatLngLiteral;

  polylineOptions = {};
  user;

  isModalOpen = false;
  isConfirmationOpen = false;
  cancelationReason = '';

  directionsResults$: Observable<google.maps.DirectionsResult | undefined>;
  directionOptions = {
    polylineOptions: { strokeColor: 'orange', strokeWeight: 6 },
    markerOptions: {
      visible: false,
    },
  };

  optionsTooltip: TooltipOptions = {
    placement: 'top',
    triggerType: 'click',
    onHide: () => {
      // console.log('tooltip is shown');
    },
    onShow: () => {
      // console.log('tooltip is hidden');
    },
    onToggle: () => {
      // console.log('tooltip is toggled');
    },
  };

  instanceOptions: InstanceOptions = {
    id: 'tooltipContent',
    override: true,
  };

  tooltip: TooltipInterface;

  toolTipMessage;

  constructor(
    private orderDetailService: OrderDetailService,
    private route: ActivatedRoute,
    private genericService: GenericService,
    private authService: AuthService,
    private mapDirectionsService: MapDirectionsService,
    private notification: NotificationService
  ) {
    this.authService.currentUser.subscribe((data) => {
      this.user = data;
    });
    this.route.params.subscribe((param) => {
      this.orderId = param['id'];
      localStorage.removeItem('orderUid');
      this.getOrderDetails();
    });
    this.genericService.getConfigurations().subscribe((result) => {
      this.configuration = result;
    });
    this.authService.selectedStore.subscribe((data) => {
      if (data) {
        this.selectedStore = data;
        this.markerPositionStore = {
          lat: this.selectedStore?.lat,
          lng: this.selectedStore?.lon,
        };
      }
    });
  }

  ngOnInit() {
    // this.genericService.setPageTitleAndDescription('order-detail', '')
  }

  reset() {
    this.isDrawerOpen = false;
  }
  closeOrderitems($event) {
    this.isDrawerOpen = false;
    this.isOrderItemsOpen = $event;
  }
  openDrawer(type) {
    this.isDrawerOpen = true;
    this.isOrderItemsOpen = false;

    if (type === 'orderItems') {
      this.isOrderItemsOpen = true;
    }
  }
  getOrderDetails() {
    const params = { locale: 'en' };
    this.orderDetailService.getOrderDetails(params, this.orderId).subscribe((result) => {
      this.orderDetails = result;
      if (this.orderDetails.notes) {
        this.orderDetails.deliveryInstructions = this.orderDetails.notes.split('\n');
        this.orderDetails.deliveryInstructions.shift();
      }

      this.center = {
        lat: result.deliveryLat,
        lng: result.deliveryLon,
      };
      this.markerPosition = {
        lat: result.deliveryLat,
        lng: result.deliveryLon,
      };
      if (result.deliveryStatus === 'DELIVERING') {
        // this.orderDetails.workerLocation.lat = 33.89765;
        // this.orderDetails.workerLocation.lon = 35.470644;
        // this.intervalTimer = setInterval(() => {
        //   this.getOrderDetails();
        // }, 60000);
        this.markerPositionWorker = {
          lat: this.orderDetails?.workerLocation?.lat,
          lng: this.orderDetails?.workerLocation?.lon,
        };
        this.setMapDirection();
      } else {
        // clearInterval(this.intervalTimer);
      }
    });
  }
  public openInfoWindow(marker: MapMarker, infoWindow: MapInfoWindow) {
    infoWindow.open(marker);
  }
  setMapDirection() {
    const request: google.maps.DirectionsRequest = {
      destination: { lat: this.markerPosition?.lat, lng: this.markerPosition?.lng },
      origin: {
        lat: this.orderDetails?.workerLocation?.lat
          ? this.orderDetails.workerLocation?.lat
          : this.selectedStore?.lat,
        lng: this.orderDetails?.workerLocation?.lon
          ? this.orderDetails?.workerLocation?.lon
          : this.selectedStore?.lon,
      },
      travelMode: google.maps.TravelMode.DRIVING,
    };
    this.directionsResults$ = this.mapDirectionsService
      .route(request)
      .pipe(map((response) => response.result));
  }

  copyDriverContact() {
    const $targetEl: HTMLElement = document.getElementById('tooltipContent');
    const $triggerEl: HTMLElement = document.getElementById('tooltipButton');
    this.tooltip = new Tooltip($targetEl, $triggerEl, this.optionsTooltip, this.instanceOptions);
    navigator.clipboard
      .writeText(this.orderDetails.workerPhoneNumber)
      .then(() => {
        this.toolTipMessage = 'Phone number copied to clipboard';
        this.tooltip.show();

        setTimeout(() => {
          this.tooltip.hide();
        }, 2000);
      })
      .catch((err) => (this.toolTipMessage = 'Error copying text: ' + err));
  }
  closeConfrimationPopUp($event) {
    this.isModalOpen = false;
    this.isConfirmationOpen = $event;
  }

  resetModal() {
    this.isModalOpen = false;
  }
  openModal() {
    this.isModalOpen = true;
    this.isConfirmationOpen = true;
  }
  openCancelOrderConfirmationModal() {
    this.openModal();
  }
  cancelOrder() {
    const params = {
      locale: 'en',
      os: 'Web',
      uid: this.user.uid,
      idUser: this.user.idUser,
      appName: environment.eddressAppConfig.appName,
      cancelationReason: this.cancelationReason,
      orderUid: this.orderDetails.uid,
    };
    this.orderDetailService.cancelOrder(params, this.orderId).subscribe((response) => {
      this.closeConfrimationPopUp(false);
      this.notification.errorNotification(response.description);
      this.getOrderDetails();
    });
  }
}
