import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { Observable } from 'rxjs';
import { AppRoutesEnum } from '@core/models/appRoutes.enum';
import { environment } from '@environments/environment';

@Injectable()
export class AuthenticationGuard implements CanActivate {
  isAuthenticated = false;

  constructor(private router: Router, private authService: AuthService) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let validUrl = false;
    if (
      route.url[0].path === 'privacy-policy' &&
      (environment.eddressAppConfig.appName === 'pronto' ||
        environment.eddressAppConfig.appName === 'afc')
    ) {
      validUrl = true;
    }
    return validUrl;
    // return this._hasAuthenticatedUser();
  }

  _hasAuthenticatedUser(): Observable<boolean> {
    this.authService.isAuthenticated.subscribe((data) => (this.isAuthenticated = data));

    if (!this.isAuthenticated) {
      this.router.navigateByUrl(AppRoutesEnum.LOGIN_URL);
    }

    return this.authService.isAuthenticated;
  }
}
