import { Pipe, PipeTransform } from '@angular/core';
import { OrderStatusEnum } from '@app/core/models/orderStatus.enum';

@Pipe({
    name: 'orderStatus',
    standalone: true,
})
export class OrderStatusPipe implements PipeTransform {
  transform(status: string): string {
    let orderStatus;
    switch (status) {
      case OrderStatusEnum.NEW:
        orderStatus = 'New';
        break;
      case OrderStatusEnum.DRAFT:
        orderStatus = 'Draft';
        break;
      case OrderStatusEnum.ASSIGNED:
        orderStatus = 'Assigned';
        break;
      case OrderStatusEnum.OUT_FOR_DELIVERY:
        orderStatus = 'Out for delivery';
        break;
      case OrderStatusEnum.PICKED_UP:
        orderStatus = 'Picked up';
        break;
      case OrderStatusEnum.PICKING_UP:
        orderStatus = 'Picking up';
        break;
      case OrderStatusEnum.DELIVERING:
        orderStatus = 'Delivering';
        break;
      case OrderStatusEnum.DELIVERED:
        orderStatus = 'Delivered';
        break;
      case OrderStatusEnum.RETURNING:
        orderStatus = 'Returning';
        break;
      case OrderStatusEnum.RETURNED:
        orderStatus = 'Returned';
        break;
      case OrderStatusEnum.FAILED:
        orderStatus = 'Failed';
        break;
      case OrderStatusEnum.PICKUP_FAILED:
        orderStatus = 'Pickup Failed';
        break;
      case OrderStatusEnum.DELIVERY_FAILED:
        orderStatus = 'Delivery Failed';
        break;
      case OrderStatusEnum.RETURN_FAILED:
        orderStatus = 'Return Failed';
        break;
      case OrderStatusEnum.CONFIRMED:
        orderStatus = 'Confirmed';
        break;
      case OrderStatusEnum.COMPLETE:
        orderStatus = 'Completed';
        break;
      case OrderStatusEnum.CANCELED:
        orderStatus = 'Canceled';
        break;
      case OrderStatusEnum.RETURNED_TO_CUSTOMER:
        orderStatus = 'Returned to customer';
        break;
      case OrderStatusEnum.DONE:
        orderStatus = 'Completed';
        break;
      case OrderStatusEnum.RETURN_CANCELED:
        orderStatus = 'Return Canceled';
        break;
      case OrderStatusEnum.NEW_CANCELED:
        orderStatus = 'Canceled';
        break;
      case OrderStatusEnum.UNFULFILLED:
        orderStatus = 'Unfulfilled';
        break;
      case OrderStatusEnum.PENDING:
        orderStatus = 'Pending';
        break;
      case OrderStatusEnum.PROCESSING:
        orderStatus = 'Processing';
        break;
      case OrderStatusEnum.READY:
        orderStatus = 'Ready';
        break;
      case OrderStatusEnum.DELAYED:
        orderStatus = 'Delayed';
        break;
      default:
        orderStatus = '';
        break;
    }
    return orderStatus;
  }
}
