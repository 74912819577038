import { LocalStorageService } from '@app/core/services/localstorage.service';
import { Component, OnInit } from '@angular/core';
import { MyAccountService } from './services/my-account.service';
import { LoaderService } from '@app/core/services/loader.service';
import { Router } from '@angular/router';
import { NotificationService } from '@app/core/services/notification.service';
import { ReferFriendService } from '../refer-friend/services/refer-friend.service';
import { MyWalletService } from '../my-wallet/services/my-wallet.service';
import { GenericService } from '@app/core/services/generics.service';
import { TooltipOptions, InstanceOptions, TooltipInterface, Tooltip } from 'flowbite';
import { AuthService } from '@app/core/services/auth.service';
import { ConfirmationPopupComponent } from '../../shared/components/confirmation-popup/confirmation-popup.component';
import { ModalComponent } from '../../shared/components/modal/modal.component';
import { EditProfileComponent } from './components/edit-profile/edit-profile.component';
import { NgIf, DecimalPipe, DatePipe } from '@angular/common';
import { DrawerComponent } from '../../shared/drawer/drawer.component';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrl: './my-account.component.scss',
  standalone: true,
  imports: [
    DrawerComponent,
    NgIf,
    EditProfileComponent,
    ModalComponent,
    ConfirmationPopupComponent,
    DecimalPipe,
    DatePipe,
  ],
})
export class MyAccountComponent implements OnInit {
  userInfo;
  user;
  isDrawerOpen: boolean = false;
  isProfileOpen: boolean = false;

  isModalOpen: boolean = false;
  isConfirmationOpen: boolean = false;
  confirmationTitle = '';

  configuration;
  selectedUser;

  referralDetail;
  amountInWallet;
  cashBackEarned;

  optionsTooltip: TooltipOptions = {
    placement: 'top',
    triggerType: 'click',
    onHide: () => {
      console.log('tooltip is shown');
    },
    onShow: () => {
      console.log('tooltip is hidden');
    },
    onToggle: () => {
      console.log('tooltip is toggled');
    },
  };

  instanceOptions: InstanceOptions = {
    id: 'tooltipContent',
    override: true,
  };

  tooltip: TooltipInterface;

  toolTipMessage;

  constructor(
    private myAccountService: MyAccountService,
    private loaderService: LoaderService,
    public router: Router,
    private localstorage: LocalStorageService,
    private notification: NotificationService,
    private referFriendService: ReferFriendService,
    private myWalletService: MyWalletService,
    private genericService: GenericService,
    public authService: AuthService
  ) {
    this.genericService.getConfigurations().subscribe((result) => {
      this.configuration = result;
    });
  }
  ngOnInit() {
    // this.genericService.setPageTitleAndDescription('my-account', '')
    this.user = this.localstorage.getItem('user');
    this.getUserAccountInfo();
    this.getAppInviteLink();
    this.getWalletHistory();
  }
  getUserAccountInfo() {
    this.loaderService.open();
    this.myAccountService.getUserAccountInformation().subscribe(
      (result) => {
        this.userInfo = result;
        const user = this.authService.getUserObject();
        user.fullName = this.userInfo.fullName;
        this.localstorage.setItem('user', user);
        this.loaderService.close();
      },
      (err) => {
        this.loaderService.close();
      }
    );
  }

  getAppInviteLink() {
    const params = {};
    this.referFriendService.appInviteLink(params).subscribe((result) => {
      this.referralDetail = result;
    });
  }

  copyMessage(val: string) {
    const $targetEl: HTMLElement = document.getElementById('tooltipContent');
    const $triggerEl: HTMLElement = document.getElementById('tooltipButton');
    this.tooltip = new Tooltip($targetEl, $triggerEl, this.optionsTooltip, this.instanceOptions);
    navigator.clipboard
      .writeText(val)
      .then(() => {
        this.toolTipMessage = 'Promocode copied to clipboard';
        this.tooltip.show();

        setTimeout(() => {
          this.tooltip.hide();
        }, 2000);
      })
      .catch((err) => (this.toolTipMessage = 'Error copying text: ' + err));
  }
  getWalletHistory() {
    this.loaderService.open();
    this.myWalletService.getWalletHistory().subscribe(
      (result) => {
        this.loaderService.close();
        this.amountInWallet = result.data?.amountInWallet;
        this.cashBackEarned = result.data?.cashBackEarned;
      },
      (error) => {
        this.loaderService.close();
      }
    );
  }

  openModal() {
    this.isModalOpen = true;
    this.isConfirmationOpen = true;
  }

  reset() {
    this.isDrawerOpen = false;
  }
  openDeleteUserConfirmationModal(user) {
    this.selectedUser = user;
    this.confirmationTitle = 'Are you sure you want to delete ' + user.fullName + ' user?';
    this.openModal();
  }
  closeProfileDrawer($event) {
    this.isDrawerOpen = false;
    this.isProfileOpen = $event;
  }
  openDrawer() {
    this.isDrawerOpen = true;
    this.isProfileOpen = true;
  }
  onSubmit(form) {
    this.loaderService.open();
    this.myAccountService.saveUserAccountInformation(form).subscribe(
      (result) => {
        this.loaderService.close();
        this.reset();
        this.getUserAccountInfo();
      },
      (err) => {
        this.loaderService.close();
      }
    );
  }

  closeConfrimationPopUp($event) {
    this.isModalOpen = false;
    this.isConfirmationOpen = $event;
  }

  resetModal() {
    this.isModalOpen = false;
  }

  deletAccount() {
    this.loaderService.open();
    this.closeConfrimationPopUp(true);
    this.myAccountService.deleteUserAccount().subscribe(
      () => {
        this.loaderService.close();
        this.notification.successNotification('Account deleted successfully');
      },
      (err) => {
        this.loaderService.close();
      }
    );
  }

  redirectToWallet() {
    this.router.navigate(['user/my-wallet']);
  }
}
