import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewEncapsulation,
  SimpleChanges,
} from '@angular/core';
import { SearchPipe } from '../../../shared/pipes/search.pipe';
import { FormsModule } from '@angular/forms';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-filter-item',
    templateUrl: './filter-item.component.html',
    styleUrl: './filter-item.component.scss',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        NgIf,
        FormsModule,
        NgFor,
        SearchPipe,
    ],
})
export class FilterItemComponent implements OnInit {
  @Input() label;

  @Input() isFilterOpen;

  upArrow = 'assets/images/category/up-arrow.svg';
  downArrow = 'assets/images/category/down-arrow.svg';

  @Input() mainItemsList = [];

  batchList = [];

  searchList = [];

  numberOriginalRecords = 10;
  numberMoreRecords = 2;

  searchValue = '';

  @Output() selectedFilter = new EventEmitter<boolean>();

  ngOnInit() {
    this.resetList();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['mainItemsList']) {
      this.mainItemsList = changes['mainItemsList']?.currentValue;
      this.resetList();
    }
  }
  resetList() {
    this.batchList = this.mainItemsList?.slice(0, this.numberOriginalRecords);
  }

  showMore() {
    const nextBatch = this.mainItemsList?.slice(
      this.batchList.length,
      this.batchList.length + this.numberMoreRecords
    );
    this.batchList = [...this.batchList, ...nextBatch];
  }

  showLess() {
    this.resetList();
  }

  showFilter() {
    this.isFilterOpen = !this.isFilterOpen;
    this.selectedFilter.emit(this.label);
  }

  search(event) {
    this.searchValue = event.target.value;

    this.searchList = this.batchList.filter((record) =>
      record.label.toLowerCase().includes(this.searchValue.toLowerCase())
    );

    if (this.searchValue === '') {
      this.searchList = [];
    }
  }
}
