import { Inject, Injectable, Injector } from '@angular/core';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { AppRoutesEnum } from '@core/models/appRoutes.enum';
import { UserRolesEnum } from '@core/models/userRoles.enum';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class EddressFacadeService {
  private _authService: AuthService;
  appURLEnum = AppRoutesEnum;
  private user: any;
  userRolesEnum = UserRolesEnum;

  public get authService(): AuthService {
    if (!this._authService) {
      this._authService = this.injector.get(AuthService);
    }
    return this._authService;
  }

  setCompanyInfo(data) {
    const companyIfo = data.companyInfo;
    localStorage.setItem('companyIfo', companyIfo);
  }
  setAppFavicon(icon: string) {
    this._document.getElementById('appFavicon').setAttribute('href', icon);
  }
  public populateUser() {
    return this.authService.populateUser();
  }
  public populateDeliveryAddress() {
    return this.authService.populateDeliveryAddress();
  }
  public populateSelectedStore() {
    return this.authService.populateSelectedStore();
  }
  public populateHomePageData() {
    return this.authService.populateHomePageData();
  }
  public populateCompanyConfigurations() {
    return this.authService.populateHomePageData();
  }
  constructor(
    private injector: Injector,
    public router: Router,
    @Inject(DOCUMENT) private _document: HTMLDocument
  ) {
    this.authService.currentUser.subscribe((user) => {
      this.user = user;
    });
  }
}
