import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-confirmation-popup',
    templateUrl: './confirmation-popup.component.html',
    styleUrl: './confirmation-popup.component.scss',
    standalone: true,
})
export class ConfirmationPopupComponent {
  @Input() title;
  @Output() closeModal = new EventEmitter<boolean>();
  @Output() onConfirm = new EventEmitter<boolean>();
  close() {
    this.closeModal.emit(false);
  }
  onConfirmFn() {
    this.onConfirm.emit();
  }
}
