<main class="flex-1 flex flex-col items-start justify-start pt-0 px-0 pb-0 bg-base-white">
  <section
    class="mb-[-10px] self-stretch bg-base-white overflow-hidden flex flex-col items-center justify-start pt-3 pr-[15px] pl-[23px] gap-[12px_0px] text-left text-sm text-base-black font-text-sm-semibold border-l-[1px] border-solid border-gray-200"
  >
    <div class="w-[376px] relative leading-[20px] text-gray-600 hidden">
      Lorem ipsum dolor sit amet.
    </div>
    <div
      class="self-stretch flex flex-row flex-wrap items-start justify-start pt-0 pb-3 pr-px pl-0 text-xl text-gray-900"
    >
      <div class="flex-1 flex flex-col items-start justify-start pt-3 pb-0 px-0">
        <div class="w-[352px] flex flex-row items-center justify-start gap-[0px_16px]">
          <img
            class="h-6 w-6 relative rounded-lg"
            loading="lazy"
            alt="markerpin icon"
            src="assets/images/home/marker-pin-02.svg"
          />

          <h3
            class="m-0 flex-1 relative text-inherit leading-[30px] font-semibold font-inherit inline-block min-w-[119px]"
          >
            Deliver to {{ headerTitle }}
          </h3>
        </div>
      </div>
      <div
        (click)="close()"
        class="rounded-lg overflow-hidden flex flex-row items-center justify-center p-2 cursor-pointer"
      >
        <img
          class="h-6 w-6 relative overflow-hidden shrink-0"
          loading="lazy"
          alt="close icon"
          src="assets/images/home/xclose.svg"
        />
      </div>
    </div>

    <div
      *ngFor="let address of addressList; let i = index"
      class="self-stretch h-[66px] flex flex-row items-start justify-start py-0 pr-[9px] pl-0 box-border"
    >
      <div
        class="self-stretch flex-1 rounded-8xs bg-base-white flex flex-col items-start justify-center border-[1px] border-solid border-gray-2001"
      >
        <div
          class="self-stretch rounded-t-8xs rounded-b-none flex flex-row items-center justify-center py-3 px-2.5"
        >
          <div class="flex-1 flex flex-row items-center justify-start gap-[0px_12px]">
            <div class="flex flex-row items-center justify-center">
              <input
                id="default-radio-{{ i }}"
                type="radio"
                value="{{ address.eddressCode }}"
                name="default-radio"
                [(ngModel)]="selectedAddress"
                (ngModelChange)="onChangeAddress()"
                class="w-4 h-4 text-brand-600 bg-gray-100 border-gray-300 focus:ring-brand-500 dark:focus:ring-brand-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
            </div>
            <div class="relative leading-[20px]">
              <p class="[margin-block-start:0] [margin-block-end:14px]">{{ address.name }}:</p>
              <p class="m-0 font-semibold">
                {{ address.addressDetailInfo }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="self-stretch flex flex-row items-start justify-start py-0 pr-[9px] pl-0">
      <div class="flex-1 flex flex-col items-start justify-start gap-[12px_0px]">
        <div class="self-stretch flex flex-col items-start justify-start gap-[16px_0px]">
          <button
            type="button"
            *ngIf="addressList.length > 0"
            [disabled]="!selectedAddress"
            (click)="onConfirmAddress()"
            class="cursor-pointer disabled:opacity-75 py-2.5 px-3.5 bg-brand-600 self-stretch rounded-lg shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] overflow-hidden flex flex-row items-center justify-center gap-[0px_4px] border-[1px] border-solid border-brand-600"
          >
            <img class="h-5 w-5 relative overflow-hidden shrink-0 hidden min-h-[20px]" alt="hidden icon" />

            <div class="flex flex-row items-center justify-center">
              <div
                class="relative text-sm leading-[20px] font-semibold font-text-sm-semibold text-base-white text-left"
              >
                Confirm Location
              </div>
            </div>
            <img class="h-5 w-5 relative overflow-hidden shrink-0 hidden min-h-[20px]" alt="hidden icon" />
          </button>
          <button
            (click)="newLocationFn()"
            class="cursor-pointer py-2.5 px-3.5 bg-brand-50 self-stretch rounded-lg shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] overflow-hidden flex flex-row items-center justify-center gap-[0px_4px] border-[1px] border-solid border-brand-300"
          >
            <img class="h-5 w-5 relative overflow-hidden shrink-0 hidden min-h-[20px]" alt="hidden icon" />

            <div class="flex flex-row items-center justify-center py-0 px-px">
              <div
                class="relative text-sm leading-[20px] font-semibold font-text-sm-semibold text-brand-700 text-left"
              >
                Add New Location
              </div>
            </div>
            <img class="h-5 w-5 relative overflow-hidden shrink-0 hidden min-h-[20px]" alt="hidden icon" />
          </button>
        </div>
      </div>
    </div>
  </section>
</main>
