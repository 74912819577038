var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
import instances from '../../dom/instances';
var Default = {
  placement: 'center',
  backdropClasses: 'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40',
  backdrop: 'dynamic',
  closable: true,
  onHide: function () {},
  onShow: function () {},
  onToggle: function () {}
};
var DefaultInstanceOptions = {
  id: null,
  override: true
};
var Modal = /** @class */function () {
  function Modal(targetEl, options, instanceOptions) {
    if (targetEl === void 0) {
      targetEl = null;
    }
    if (options === void 0) {
      options = Default;
    }
    if (instanceOptions === void 0) {
      instanceOptions = DefaultInstanceOptions;
    }
    this._eventListenerInstances = [];
    this._instanceId = instanceOptions.id ? instanceOptions.id : targetEl.id;
    this._targetEl = targetEl;
    this._options = __assign(__assign({}, Default), options);
    this._isHidden = true;
    this._backdropEl = null;
    this._initialized = false;
    this.init();
    instances.addInstance('Modal', this, this._instanceId, instanceOptions.override);
  }
  Modal.prototype.init = function () {
    var _this = this;
    if (this._targetEl && !this._initialized) {
      this._getPlacementClasses().map(function (c) {
        _this._targetEl.classList.add(c);
      });
      this._initialized = true;
    }
  };
  Modal.prototype.destroy = function () {
    if (this._initialized) {
      this.removeAllEventListenerInstances();
      this._destroyBackdropEl();
      this._initialized = false;
    }
  };
  Modal.prototype.removeInstance = function () {
    instances.removeInstance('Modal', this._instanceId);
  };
  Modal.prototype.destroyAndRemoveInstance = function () {
    this.destroy();
    this.removeInstance();
  };
  Modal.prototype._createBackdrop = function () {
    var _a;
    if (this._isHidden) {
      var backdropEl = document.createElement('div');
      backdropEl.setAttribute('modal-backdrop', '');
      (_a = backdropEl.classList).add.apply(_a, this._options.backdropClasses.split(' '));
      document.querySelector('body').append(backdropEl);
      this._backdropEl = backdropEl;
    }
  };
  Modal.prototype._destroyBackdropEl = function () {
    if (!this._isHidden) {
      document.querySelector('[modal-backdrop]').remove();
    }
  };
  Modal.prototype._setupModalCloseEventListeners = function () {
    var _this = this;
    if (this._options.backdrop === 'dynamic') {
      this._clickOutsideEventListener = function (ev) {
        _this._handleOutsideClick(ev.target);
      };
      this._targetEl.addEventListener('click', this._clickOutsideEventListener, true);
    }
    this._keydownEventListener = function (ev) {
      if (ev.key === 'Escape') {
        _this.hide();
      }
    };
    document.body.addEventListener('keydown', this._keydownEventListener, true);
  };
  Modal.prototype._removeModalCloseEventListeners = function () {
    if (this._options.backdrop === 'dynamic') {
      this._targetEl.removeEventListener('click', this._clickOutsideEventListener, true);
    }
    document.body.removeEventListener('keydown', this._keydownEventListener, true);
  };
  Modal.prototype._handleOutsideClick = function (target) {
    if (target === this._targetEl || target === this._backdropEl && this.isVisible()) {
      this.hide();
    }
  };
  Modal.prototype._getPlacementClasses = function () {
    switch (this._options.placement) {
      // top
      case 'top-left':
        return ['justify-start', 'items-start'];
      case 'top-center':
        return ['justify-center', 'items-start'];
      case 'top-right':
        return ['justify-end', 'items-start'];
      // center
      case 'center-left':
        return ['justify-start', 'items-center'];
      case 'center':
        return ['justify-center', 'items-center'];
      case 'center-right':
        return ['justify-end', 'items-center'];
      // bottom
      case 'bottom-left':
        return ['justify-start', 'items-end'];
      case 'bottom-center':
        return ['justify-center', 'items-end'];
      case 'bottom-right':
        return ['justify-end', 'items-end'];
      default:
        return ['justify-center', 'items-center'];
    }
  };
  Modal.prototype.toggle = function () {
    if (this._isHidden) {
      this.show();
    } else {
      this.hide();
    }
    // callback function
    this._options.onToggle(this);
  };
  Modal.prototype.show = function () {
    if (this.isHidden) {
      this._targetEl.classList.add('flex');
      this._targetEl.classList.remove('hidden');
      this._targetEl.setAttribute('aria-modal', 'true');
      this._targetEl.setAttribute('role', 'dialog');
      this._targetEl.removeAttribute('aria-hidden');
      this._createBackdrop();
      this._isHidden = false;
      // Add keyboard event listener to the document
      if (this._options.closable) {
        this._setupModalCloseEventListeners();
      }
      // prevent body scroll
      document.body.classList.add('overflow-hidden');
      // callback function
      this._options.onShow(this);
    }
  };
  Modal.prototype.hide = function () {
    if (this.isVisible) {
      this._targetEl.classList.add('hidden');
      this._targetEl.classList.remove('flex');
      this._targetEl.setAttribute('aria-hidden', 'true');
      this._targetEl.removeAttribute('aria-modal');
      this._targetEl.removeAttribute('role');
      this._destroyBackdropEl();
      this._isHidden = true;
      // re-apply body scroll
      document.body.classList.remove('overflow-hidden');
      if (this._options.closable) {
        this._removeModalCloseEventListeners();
      }
      // callback function
      this._options.onHide(this);
    }
  };
  Modal.prototype.isVisible = function () {
    return !this._isHidden;
  };
  Modal.prototype.isHidden = function () {
    return this._isHidden;
  };
  Modal.prototype.addEventListenerInstance = function (element, type, handler) {
    this._eventListenerInstances.push({
      element: element,
      type: type,
      handler: handler
    });
  };
  Modal.prototype.removeAllEventListenerInstances = function () {
    this._eventListenerInstances.map(function (eventListenerInstance) {
      eventListenerInstance.element.removeEventListener(eventListenerInstance.type, eventListenerInstance.handler);
    });
    this._eventListenerInstances = [];
  };
  Modal.prototype.getAllEventListenerInstances = function () {
    return this._eventListenerInstances;
  };
  return Modal;
}();
export function initModals() {
  // initiate modal based on data-modal-target
  document.querySelectorAll('[data-modal-target]').forEach(function ($triggerEl) {
    var modalId = $triggerEl.getAttribute('data-modal-target');
    var $modalEl = document.getElementById(modalId);
    if ($modalEl) {
      var placement = $modalEl.getAttribute('data-modal-placement');
      var backdrop = $modalEl.getAttribute('data-modal-backdrop');
      new Modal($modalEl, {
        placement: placement ? placement : Default.placement,
        backdrop: backdrop ? backdrop : Default.backdrop
      });
    } else {
      console.error("Modal with id ".concat(modalId, " does not exist. Are you sure that the data-modal-target attribute points to the correct modal id?."));
    }
  });
  // toggle modal visibility
  document.querySelectorAll('[data-modal-toggle]').forEach(function ($triggerEl) {
    var modalId = $triggerEl.getAttribute('data-modal-toggle');
    var $modalEl = document.getElementById(modalId);
    if ($modalEl) {
      var modal_1 = instances.getInstance('Modal', modalId);
      if (modal_1) {
        var toggleModal = function () {
          modal_1.toggle();
        };
        $triggerEl.addEventListener('click', toggleModal);
        modal_1.addEventListenerInstance($triggerEl, 'click', toggleModal);
      } else {
        console.error("Modal with id ".concat(modalId, " has not been initialized. Please initialize it using the data-modal-target attribute."));
      }
    } else {
      console.error("Modal with id ".concat(modalId, " does not exist. Are you sure that the data-modal-toggle attribute points to the correct modal id?"));
    }
  });
  // show modal on click if exists based on id
  document.querySelectorAll('[data-modal-show]').forEach(function ($triggerEl) {
    var modalId = $triggerEl.getAttribute('data-modal-show');
    var $modalEl = document.getElementById(modalId);
    if ($modalEl) {
      var modal_2 = instances.getInstance('Modal', modalId);
      if (modal_2) {
        var showModal = function () {
          modal_2.show();
        };
        $triggerEl.addEventListener('click', showModal);
        modal_2.addEventListenerInstance($triggerEl, 'click', showModal);
      } else {
        console.error("Modal with id ".concat(modalId, " has not been initialized. Please initialize it using the data-modal-target attribute."));
      }
    } else {
      console.error("Modal with id ".concat(modalId, " does not exist. Are you sure that the data-modal-show attribute points to the correct modal id?"));
    }
  });
  // hide modal on click if exists based on id
  document.querySelectorAll('[data-modal-hide]').forEach(function ($triggerEl) {
    var modalId = $triggerEl.getAttribute('data-modal-hide');
    var $modalEl = document.getElementById(modalId);
    if ($modalEl) {
      var modal_3 = instances.getInstance('Modal', modalId);
      if (modal_3) {
        var hideModal = function () {
          modal_3.hide();
        };
        $triggerEl.addEventListener('click', hideModal);
        modal_3.addEventListenerInstance($triggerEl, 'click', hideModal);
      } else {
        console.error("Modal with id ".concat(modalId, " has not been initialized. Please initialize it using the data-modal-target attribute."));
      }
    } else {
      console.error("Modal with id ".concat(modalId, " does not exist. Are you sure that the data-modal-hide attribute points to the correct modal id?"));
    }
  });
}
if (typeof window !== 'undefined') {
  window.Modal = Modal;
  window.initModals = initModals;
}
export default Modal;
