import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';
import { OrderStatusPipe } from '../../../../shared/pipes/orderStatus.pipe';
import { NgFor, NgIf, NgClass, DecimalPipe } from '@angular/common';

@Component({
    selector: 'app-order-item',
    templateUrl: './order-item.component.html',
    styleUrl: './order-item.component.scss',
    standalone: true,
    imports: [
        NgFor,
        NgIf,
        NgClass,
        DecimalPipe,
        OrderStatusPipe,
    ],
})
export class OrderItemComponent {
  @Input() orders;
  @Input() title;
  @Output() viewOrder = new EventEmitter<boolean>();
  @Output() reOrder = new EventEmitter<boolean>();

  constructor(public router: Router) {}

  viewOrderFn(order) {
    const orderUid = order.orderUid ? order.orderUid : order?.uid;

    // if (this.title === 'Past Orders') {
    //   this.viewOrder.emit(order);
    // } else {
    //   this.router.navigate(['order-detail/' + orderUid]);
    // }
    this.router.navigate(['order-detail/' + orderUid]);
  }

  formatDate(date) {
    return moment(date).format('ll');
  }

  reOrderFn(order) {
    this.reOrder.emit(order);
  }
}
