
<header class="fixed top-0 left-0 w-full z-[31]">
  <div
  class="self-stretch bg-brand-600 flex flex-row items-center justify-between py-2 px-16 z-[0] text-xs text-base-white"
>
  <div class="w-80 hidden md:flex flex-row items-center justify-start gap-[6px]">
    <img
      class="w-4 relative h-4 overflow-hidden shrink-0"
      alt="delivery location icon"
      src="assets/images/home/markerpin01.svg"
    />
    <ng-container *ngIf="!deliveryAddress">
      <b
        (click)="openDrawer('addresses')"
        data-tooltip-trigger="none"
        data-tooltip-target="tooltip-right"
        data-tooltip-placement="right"
        data-tooltip-style="light"
        class="relative leading-[18px] cursor-pointer"
        id="deliverToTueny"
        >Do we deliver to you?
        <!-- <span class="[text-decoration:underline]"
        >Tueny Building, Baydoun street Beirut</span
      > -->
      </b>
      <div
        id="tooltip-right"
        role="tooltip"
        (click)="hideAddressBadge = true"
        [ngClass]="hideAddressBadge ? 'hidden' : 'visible'"
        class="absolute z-10 inline-block px-3 cursor-pointer py-2 text-sm font-medium text-white border border-blue-700 rounded-lg shadow-sm opacity-100 tooltip bg-blue-700"
      >
        <!-- Do we deliver to you? Add your address to check. -->
        <span
          >Add your address to check. 🏠
          <!-- <img [src]="'assets/images/home/chevrondown.svg'"
        /> -->
        </span>
        <div class="tooltip-arrow" data-popper-arrow></div>
      </div>
    </ng-container>
    <ng-container *ngIf="deliveryAddress">
      <b class="cursor-pointer" (click)="openDrawer('addresses')"
        >Deliver to: {{ deliveryAddress?.name }}
        <!-- <span class="[text-decoration:underline]">{{ deliveryAddress?.addressDetailInfo }}</span> -->
      </b>
    </ng-container>
  </div>
  <b *ngIf="deliveringETA" class="w-80 relative leading-[18px] inline-block text-center shrink-0"
    >Next Order: delivering within {{ deliveringETA }}</b
  >
  <b *ngIf="deliverToETA" class="w-80 relative leading-[18px] inline-block text-center shrink-0"
    >Next Order: delivering at {{ deliverToETA }}</b
  >
  <div class="w-80 hidden md:flex flex-row items-center justify-end gap-[12px]">
    <!-- <div id="dropdownLangButton" data-dropdown-toggle="dropdownLang"
      class="flex flex-row items-end justify-start gap-[2px] cursor-pointer">
      <b class="relative leading-[18px]">{{ selectedLanguage.name }}</b>
      <img class="w-4 relative h-4 overflow-hidden shrink-0 cursor-pointer"  alt="down icon"
        src="assets/images/home/chevrondown.svg" />
    </div> -->
    <!-- Dropdown menu lang -->
    <!-- <div id="dropdownLang" class="z-100 hidden bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700">
      <ul class="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownLangButton">
        <li *ngFor="let lang of languages" (click)="onLangChange(lang)">
          <div class="self-stretch flex flex-row items-center justify-start py-px px-1.5">
            <header
              class="flex-1 rounded-md bg-gray-50 flex flex-row items-center justify-start py-2.5 pr-2.5 pl-2 gap-[0px_8px] hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer">
              <div class="relative leading-[24px] inline-block box-border pr-5">
                {{ lang?.name }}
              </div>

              <img *ngIf="selectedLanguage?.value === lang?.value"
                class="h-5 w-5 relative overflow-hidden shrink-0 object-left" alt="s"
                src="assets/images/category/check.svg" />
            </header>
          </div>
        </li>
      </ul>
    </div> -->
    <!-- <div id="dropdownCurrencyButton" data-dropdown-toggle="dropdownCurrency"
      class="flex flex-row items-end justify-start gap-[2px] cursor-pointer">
      <b class="relative leading-[18px]">{{ selectedCurrency?.name }}</b>
      <img class="w-4 relative h-4 overflow-hidden shrink-0 cursor-pointer"  alt="down icon"
        src="assets/images/home/chevrondown.svg" />
    </div> -->
    <!-- Dropdown menu currency -->
    <!-- <div id="dropdownCurrency"
      class="z-100 hidden bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700">
      <ul class="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownCurrencyButton">
        <li *ngFor="let currency of currencies" (click)="onCurrencyChange(currency)">
          <div class="self-stretch flex flex-row items-center justify-start py-px px-1.5">
            <header
              class="flex-1 rounded-md bg-gray-50 flex flex-row items-center justify-start py-2.5 pr-2.5 pl-2 gap-[0px_8px] hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer">
              <div class="relative leading-[24px] inline-block box-border pr-5">
                {{ currency?.name }}
              </div>

              <img *ngIf="selectedCurrency?.value === currency?.value"
                class="h-5 w-5 relative overflow-hidden shrink-0 object-left" alt="s"
                src="assets/images/category/check.svg" />
            </header>
          </div>
        </li>
      </ul>
    </div> -->
  </div>
</div>

<div
  class="self-stretch bg-base-white md:h-[140px] flex flex-col items-start justify-start pt-3 md:py-6 md:px-16 px-2 box-border md:gap-[32px] gap-[16px] z-[1] text-sm text-gray-500"
>
  <nav class="self-stretch flex flex-row items-center justify-between">
    <div
      id="menuMobile"
      data-dropdown-toggle="menuMobileDelay"
      data-dropdown-delay="500"
      class="md:hidden flex align-middle flex-row items-start justify-end gap-[5px]"
    >
      <button
        type="button"
        id="dropdownButton"
        class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
      >
        <span class="sr-only">Open main menu</span>
        <img
          class="w-5 relative h-5 overflow-hidden shrink-0"
          aria-hidden="true"
          alt="menu icon"
          src="assets/images/home/menu04.svg"
        />
      </button>

      <!-- Dropdown menu -->
      <div
        id="menuMobileDelay"
        class="z-100 hidden w-[60%] bg-white divide-y divide-gray-100 rounded-lg shadow right-24 dark:bg-gray-700"
      >
        <div
          aria-labelledby="menuMobile"
          class="w-full relative rounded-lg bg-base-white shadow-[0px_12px_16px_-4px_rgba(16,_24,_40,_0.08),_0px_4px_6px_-2px_rgba(16,_24,_40,_0.03)] box-border flex flex-col items-start justify-start p-4 gap-[8px_0px] text-left text-base text-gray-700 font-text-md-semibold border-[1px] border-solid border-gray-200"
        >
          <ul
            class="z-100 flex md:hidden flex-col mt-4 font-medium md:flex-row md:mt-0 md:space-x-8 rtl:space-x-reverse"
          >
            <ng-container *ngFor="let item of fullMenus">
              <li
                *ngIf="item?.label || item?.collection?.label"
                class="relative cursor-pointer p-2"
              >
                <span class="flex flex-row">
                  <span
                    (click)="onClickMenu(item, null)"
                    [ngClass]="item.isActive ? 'text-brand-600' : 'text-gray-900'"
                    class="block py-2 px-1 whitespace-nowrap leading-[24px] border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-brand-600 md:p-0 dark:text-white md:dark:hover:text-brand-500 dark:hover:bg-gray-700 dark:hover:text-brand-500 md:dark:hover:bg-transparent dark:border-gray-700"
                    >{{ item.label || item?.collection?.label }}
                  </span>
                  <img
                    #toggleFullMenuButtons
                    [id]="item.itemId"
                    *ngIf="item.groups"
                    (click)="toggleSubmenu(item)"
                    class="relative top-3 h-5 overflow-hidden shrink-0 cursor-pointer"
                    alt="down icon"
                    src="assets/images/home/chevron-down-dark.svg"
                  />
                </span>

                <ul
                  *ngIf="item.isOpen && (item?.label || item?.collection?.label)"
                  class="absolute left-full top-0 z-[100] w-auto grid-cols-1 mt-2 text-sm bg-white border border-gray-100 rounded-lg shadow-md dark:border-gray-700 dark:bg-gray-700"
                >
                  <li
                    *ngFor="let child of item.groups"
                    class="relative text-gray-900 dark:text-white"
                  >
                    <span
                      *ngIf="!child.groups"
                      [ngClass]="child.isActive ? 'text-brand-600' : 'text-gray-400'"
                      (click)="onClickMenu(item, child)"
                      class="text-gray-500 dark:text-gray-400 hover:text-brand-600 dark:hover:text-brand-500 cursor-pointer block px-4 py-2 whitespace-nowrap hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-brand-600"
                    >
                      {{ child.label || item?.collection?.label }}
                    </span>
                    <a
                      *ngIf="child.children"
                      href="#"
                      (click)="toggleSubmenu(child)"
                      class="text-gray-500 dark:text-gray-400 hover:text-brand-600 dark:hover:text-brand-500 cursor-pointer block px-4 py-2 whitespace-nowrap hover:bg-gray-700"
                    >
                      {{ child.label || item?.collection?.label }}
                    </a>
                    <!-- <ul *ngIf="child.isOpen" class="absolute left-full top-0 mt-2 bg-gray-800 text-white py-2 space-y-2">
                  <li *ngFor="let subChild of child.children">
                    <a 
                      [routerLink]="subChild.route" 
                      class="block px-4 py-2 hover:bg-gray-700">
                      {{ subChild.label }}
                    </a>
                  </li>
                </ul> -->
                  </li>
                </ul>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
    <div class="items-start min-w-[145px]">
      <img (click)="resetHighlightedMenu()"
        [routerLink]="['/']"
        class="relative w-auto h-[30px] md:h-[41px] cursor-pointer"
        width="290" height="82"
        alt="company logo image"
        [src]="companyLogo ? companyLogo : defaultLogo"
        id="logo"
      />
    </div>

    <div
      class="hidden self-stretch md:flex flex-row items-center justify-start cursor-pointer"
      id="frameContainer"
    >
      <div
        class="self-stretch w-[400px] rounded-tl-8xs rounded-tr-none rounded-br-none rounded-bl-8xs bg-base-white box-border overflow-hidden shrink-0 flex flex-row items-center justify-start border-t-[1px] border-solid border-gray-400 border-b-[1px] border-l-[1px]"
      >
        <div class="flex-1 flex flex-row items-center justify-start">
          <div
            class="flex-1 relative leading-[20px] font-semibold overflow-hidden text-ellipsis whitespace-nowrap"
          >
            <input
              [value]="searchString"
              [formControl]="searchStringControl"
              type="text"
              class="w-full py-2.5 px-3.5 border-0"
              placeholder="Search our Shop"
            />
          </div>
        </div>
      </div>
      <div
        (click)="onSearchBtnClick()"
        class="self-stretch w-[41px] rounded-tl-none rounded-tr-8xs rounded-br-8xs rounded-bl-none bg-brand-500 overflow-hidden shrink-0 flex flex-row items-center justify-center"
      >
        <div class="flex-1 flex flex-row items-center justify-center">
          <img
            class="w-5 relative h-5 overflow-hidden shrink-0"
            alt="search icon"
            src="assets/images/home/searchlg.svg"
          />
        </div>
      </div>
    </div>
    <div class="w-[220px] flex flex-row items-start justify-end gap-[24px]">
      <button name="cart-btn" aria-label="cart-btn" class="relative" (click)="openDrawer('cart')">
        <img
          class="w-6 relative h-6 overflow-hidden shrink-0"
          alt="shoping cart icon"
          src="assets/images/home/shoppingcart.svg"
          id="shoppingCartIcon"
        />
        <span
          *ngIf="cartItemsCount > 0"
          class="absolute top-0 right-0 px-2 py-1 translate-x-1/2 -translate-y-1/2 bg-red-500 rounded-full text-xs text-white"
          >{{ cartItemsCount }}</span
        >
      </button>

      <img (click)="resetHighlightedMenu()"
        *ngIf="userLogedIn"
        [routerLink]="'user/favorites'"
        class="w-6 hidden md:flex relative h-6 overflow-hidden shrink-0 cursor-pointer"
        alt="rounded heart icon"
        src="assets/images/home/heartrounded.svg"
      />
      <img
        *ngIf="!userLogedIn"
        (click)="openDrawer('login')"
        class="w-6 relative h-6 overflow-hidden shrink-0 cursor-pointer"
        alt="rounded heart icon"
        src="assets/images/home/heartrounded.svg"
      />

      <div *ngIf="!userLogedIn" (click)="openDrawer('login')">
        <img
          class="w-6 relative h-6 overflow-hidden shrink-0 cursor-pointer"
          alt="user icon"
          src="assets/images/home/user.svg"
          id="userIcon"
        />
      </div>

      <div *ngIf="userLogedIn">
        <div
          id="dropdownDelayButton"
          data-dropdown-toggle="dropdownDelay"
          data-dropdown-delay="500"
          class="flex align-middle flex-row items-start justify-end gap-[5px]"
        >
          <h1 class="font-bold text-brand-700 cursor-pointer">
            {{ userData?.fullName }}
          </h1>
          <img
            class="w-5 relative h-5 overflow-hidden shrink-0 cursor-pointer"
            alt="down icon"
            src="assets/images/home/chevron-down-dark.svg"
          />

          <!-- Dropdown menu -->
          <div
            id="dropdownDelay"
            class="z-100 hidden bg-white divide-y divide-gray-100 rounded-lg shadow right-24 w-[300px] dark:bg-gray-700"
          >
            <div
              aria-labelledby="dropdownDelayButton"
              class="w-full relative rounded-lg bg-base-white shadow-[0px_12px_16px_-4px_rgba(16,_24,_40,_0.08),_0px_4px_6px_-2px_rgba(16,_24,_40,_0.03)] box-border flex flex-col items-start justify-start p-4 gap-[8px_0px] text-left text-base text-gray-700 font-text-md-semibold border-[1px] border-solid border-gray-200"
            >
              <div (click)="resetHighlightedMenu()"
                [routerLink]="'user/my-account'"
                class="self-stretch rounded-md bg-base-white overflow-hidden flex flex-row items-center justify-start py-2 px-3 gap-[0px_8px] hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer"
              >
                <div class="flex-1 flex flex-row items-center justify-start gap-[0px_12px]">
                  <img
                    class="w-2.5 relative h-2.5 hidden"
                    alt="hidden icon"
                    src="assets/images/home/-dot.svg"
                  />

                  <img
                    class="w-6 relative h-6 overflow-hidden shrink-0"
                    alt="user circle icon"
                    src="assets/images/home/usercircle.svg"
                  />

                  <div class="relative leading-[24px] font-semibold">My Account</div>
                </div>
                <div
                  class="rounded-2xl bg-gray-50 hidden flex-row items-center justify-start py-0.5 px-2 text-center text-xs border-[1px] border-solid border-gray-200"
                >
                  <div class="relative leading-[18px]">10</div>
                </div>
                <img
                  class="w-5 relative h-5 overflow-hidden shrink-0 hidden"
                  alt="down icon"
                  src="assets/images/home/chevrondown.svg"
                />
              </div>
              <div (click)="resetHighlightedMenu()"
                [routerLink]="'user/orders'"
                class="self-stretch rounded-md bg-base-white overflow-hidden flex flex-row items-center justify-start py-2 px-3 gap-[0px_8px] hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer"
              >
                <div class="flex-1 flex flex-row items-center justify-start gap-[0px_12px]">
                  <img
                    class="w-2.5 relative h-2.5 hidden"
                    alt="hidden icon"
                    src="assets/images/home/-dot.svg"
                  />

                  <img
                    class="w-6 relative h-6 overflow-hidden shrink-0"
                    alt="shopping bag icon"
                    src="assets/images/home/shoppingbag01.svg"
                  />

                  <div class="relative leading-[24px] font-semibold">Orders</div>
                </div>
                <div
                  class="rounded-2xl bg-gray-50 hidden flex-row items-center justify-start py-0.5 px-2 text-center text-xs border-[1px] border-solid border-gray-200"
                >
                  <div class="relative leading-[18px]">10</div>
                </div>
                <img
                  class="w-5 relative h-5 overflow-hidden shrink-0 hidden"
                  alt="down icon"
                  src="assets/images/home/chevrondown.svg"
                />
              </div>
              <div (click)="resetHighlightedMenu()"
                [routerLink]="'user/favorites'"
                class="self-stretch rounded-md bg-base-white overflow-hidden flex flex-row items-center justify-start py-2 px-3 gap-[0px_8px] hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer"
              >
                <div class="flex-1 flex flex-row items-center justify-start gap-[0px_12px]">
                  <img
                    class="w-2.5 relative h-2.5 hidden"
                    alt="hidden icon"
                    src="assets/images/home/-dot.svg"
                  />

                  <img
                    class="w-6 relative h-6 overflow-hidden shrink-0"
                    alt="rounder heart icon"
                    src="assets/images/home/heartrounded.svg"
                  />

                  <div class="relative leading-[24px] font-semibold">Favorites</div>
                </div>
                <div
                  class="rounded-2xl bg-gray-50 hidden flex-row items-center justify-start py-0.5 px-2 text-center text-xs border-[1px] border-solid border-gray-200"
                >
                  <div class="relative leading-[18px]">10</div>
                </div>
                <img
                  class="w-5 relative h-5 overflow-hidden shrink-0 hidden"
                  alt="down icon"
                  src="assets/images/home/chevrondown.svg"
                />
              </div>
              <div (click)="resetHighlightedMenu()"
                [routerLink]="'user/addresses'"
                class="self-stretch rounded-md bg-base-white overflow-hidden flex flex-row items-center justify-start py-2 px-3 gap-[0px_8px] hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer"
              >
                <div class="flex-1 flex flex-row items-center justify-start gap-[0px_12px]">
                  <img
                    class="w-2.5 relative h-2.5 hidden"
                    alt="hidden icon"
                    src="assets/images/home/-dot.svg"
                  />

                  <img
                    class="w-6 relative h-6 overflow-hidden shrink-0"
                    alt="marker pin icon"
                    src="assets/images/home/markerpin03.svg"
                  />

                  <div class="relative leading-[24px] font-semibold">Addresses</div>
                </div>
                <div
                  class="rounded-2xl bg-gray-50 hidden flex-row items-center justify-start py-0.5 px-2 text-center text-xs border-[1px] border-solid border-gray-200"
                >
                  <div class="relative leading-[18px]">10</div>
                </div>
                <img
                  class="w-5 relative h-5 overflow-hidden shrink-0 hidden"
                  alt="down icon"
                  src="assets/images/home/chevrondown.svg"
                />
              </div>
              <div *ngIf="paymentGateway !== 'ADYENDROPIN' " (click)="resetHighlightedMenu()"
                [routerLink]="'user/payments'"
                class="self-stretch rounded-md bg-base-white overflow-hidden flex flex-row items-center justify-start py-2 px-3 gap-[0px_8px] hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer"
              >
                <div class="flex-1 flex flex-row items-center justify-start gap-[0px_12px]">
                  <img
                    class="w-2.5 relative h-2.5 hidden"
                    alt="hidden icon"
                    src="assets/images/home/-dot.svg"
                  />

                  <img
                    class="w-6 relative h-6 overflow-hidden shrink-0"
                    alt="credit card lock icon"
                    src="assets/images/home/creditcardlock.svg"
                  />

                  <div class="relative leading-[24px] font-semibold">Payments</div>
                </div>
                <div
                  class="rounded-2xl bg-gray-50 hidden flex-row items-center justify-start py-0.5 px-2 text-center text-xs border-[1px] border-solid border-gray-200"
                >
                  <div class="relative leading-[18px]">10</div>
                </div>
                <img
                  class="w-5 relative h-5 overflow-hidden shrink-0 hidden"
                  alt="down icon"
                  src="assets/images/home/chevrondown.svg"
                />
              </div>
              <div (click)="resetHighlightedMenu()"
                [routerLink]="'user/my-wallet'"
                class="self-stretch rounded-md bg-base-white overflow-hidden flex flex-row items-center justify-start py-2 px-3 gap-[0px_8px] hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer"
              >
                <div class="flex-1 flex flex-row items-center justify-start gap-[0px_12px]">
                  <img
                    class="w-2.5 relative h-2.5 hidden"
                    alt="hidden icon"
                    src="assets/images/home/-dot.svg"
                  />

                  <img
                    class="w-6 relative h-6 overflow-hidden shrink-0"
                    alt="wallet icon"
                    src="assets/images/home/wallet02.svg"
                  />

                  <div class="relative leading-[24px] font-semibold">My Wallet</div>
                </div>
                <div
                  class="rounded-2xl bg-gray-50 hidden flex-row items-center justify-start py-0.5 px-2 text-center text-xs border-[1px] border-solid border-gray-200"
                >
                  <div class="relative leading-[18px]">10</div>
                </div>
                <img
                  class="w-5 relative h-5 overflow-hidden shrink-0 hidden"
                  alt="down icon"
                  src="assets/images/home/chevrondown.svg"
                />
              </div>
              <div (click)="resetHighlightedMenu()"
                [routerLink]="'user/refer-friend'"
                class="self-stretch rounded-md bg-base-white overflow-hidden flex flex-row items-center justify-start py-2 px-3 gap-[0px_8px] hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer"
              >
                <div class="flex-1 flex flex-row items-center justify-start gap-[0px_12px]">
                  <img
                    class="w-2.5 relative h-2.5 hidden"
                    alt="hidden icon"
                    src="assets/images/home/-dot.svg"
                  />

                  <img
                    class="w-6 relative h-6 overflow-hidden shrink-0"
                    alt="user plus icon"
                    src="assets/images/home/userplus01.svg"
                  />

                  <div class="relative leading-[24px] font-semibold">Refer a Friend</div>
                </div>
                <div
                  class="rounded-2xl bg-gray-50 hidden flex-row items-center justify-start py-0.5 px-2 text-center text-xs border-[1px] border-solid border-gray-200"
                >
                  <div class="relative leading-[18px]">10</div>
                </div>
                <img
                  class="w-5 relative h-5 overflow-hidden shrink-0 hidden"
                  alt="down icon"
                  src="assets/images/home/chevrondown.svg"
                />
              </div>
              <div
                *ngIf="isLoggedIn | async"
                (click)="logout()"
                class="self-stretch rounded-md bg-base-white overflow-hidden flex flex-row items-center justify-start py-2 px-3 gap-[0px_8px] hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer"
              >
                <div class="flex-1 flex flex-row items-center justify-start gap-[0px_12px]">
                  <img
                    class="w-2.5 relative h-2.5 hidden"
                    alt="hidden icon"
                    src="assets/images/home/-dot.svg"
                  />

                  <img
                    class="w-6 relative h-6 overflow-hidden shrink-0"
                    alt="logout icon"
                    src="assets/images/home/logout04.svg"
                  />

                  <div class="relative leading-[24px] font-semibold">Logout</div>
                </div>
                <div
                  class="rounded-2xl bg-gray-50 hidden flex-row items-center justify-start py-0.5 px-2 text-center text-xs border-[1px] border-solid border-gray-200"
                >
                  <div class="relative leading-[18px]">10</div>
                </div>
                <img
                  class="w-5 relative h-5 overflow-hidden shrink-0 hidden"
                  alt="down icon"
                  src="assets/images/home/chevrondown.svg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>

  <div
    id="frameContainerMenus"
    #frameContainerMenus
    class="self-stretch sm:z-100 bg-white flex flex-row items-end justify-between text-base text-base-black"
  >
    <div
      id="mega-menu"
      class="items-center justify-between hidden w-full md:flex md:w-auto md:order-1"
    >
      <!-- <ul class="hidden md:flex flex-col mt-4 font-medium md:flex-row md:mt-0 md:space-x-8 rtl:space-x-reverse">
        <li class="cursor-pointer" *ngFor="let menu of menus" (click)="onClickMenu(menu)">
          <a [ngClass]="menu.isActive ? 'text-brand-600' : 'text-gray-900'"
            class="block py-2 px-3 leading-[24px] border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-brand-600 md:p-0 dark:text-white md:dark:hover:text-brand-500 dark:hover:bg-gray-700 dark:hover:text-brand-500 md:dark:hover:bg-transparent dark:border-gray-700">{{
            menu.label }}</a>
        </li>
        <li [hidden]="moreMenus?.length === 0">
          <button id="categories-menu-dropdown-button" data-dropdown-toggle="categories-menu-dropdown"
            class="flex items-center leading-[24px] justify-between w-full py-2 px-3 font-medium text-gray-900 border-b border-gray-100 md:w-auto hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-brand-600 md:p-0 dark:text-white md:dark:hover:text-brand-500 dark:hover:bg-gray-700 dark:hover:text-brand-500 md:dark:hover:bg-transparent dark:border-gray-700">
            More Categories
            <img class="w-5 relative h-5 ms-3 overflow-hidden shrink-0" aria-hidden="true" alt="s"
              src="assets/images/home/menu04.svg" />
          </button>
          <div id="categories-menu-dropdown"
            class="absolute z-[100] grid hidden w-auto grid-cols-1 text-sm bg-white border border-gray-100 rounded-lg shadow-md dark:border-gray-700 dark:bg-gray-700">
            <div class="p-4 pb-0 text-gray-900 md:pb-4 dark:text-white">
              <ul class="space-y-4" aria-labelledby="mega-menu-dropdown-button">
                <li *ngFor="let menu of moreMenus" (click)="onClickMenu(menu)">
                  <a
                    class="text-gray-500 dark:text-gray-400 hover:text-brand-600 dark:hover:text-brand-500 cursor-pointer">
                    {{ menu.label }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul> -->

      <ul
        class="hidden md:flex flex-col mt-4 font-medium md:flex-row md:mt-0 md:space-x-8 rtl:space-x-reverse"
      >
        <ng-container *ngFor="let item of menus">
          <li *ngIf="item?.label || item?.collection?.label" class="relative cursor-pointer">
            <!-- <a *ngIf="!item.groups" (click)="onClickMenu(item)"
            [ngClass]="item.isActive ? 'text-brand-600' : 'text-gray-900'"
            class="block py-2 px-3 leading-[24px] border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-brand-600 md:p-0 dark:text-white md:dark:hover:text-brand-500 dark:hover:bg-gray-700 dark:hover:text-brand-500 md:dark:hover:bg-transparent dark:border-gray-700">{{
            item.label }}
          </a> -->
            <span class="flex flex-row">
              <span
                (click)="onClickMenu(item, null)"
                [ngClass]="item.isActive ? 'text-brand-600' : 'text-gray-900'"
                class="block py-2 px-3 whitespace-nowrap leading-[24px] border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-brand-600 md:p-0 dark:text-white md:dark:hover:text-brand-500 dark:hover:bg-gray-700 dark:hover:text-brand-500 md:dark:hover:bg-transparent dark:border-gray-700"
                >{{ item?.label || item?.collection?.label }}
              </span>
              <img
                #toggleMenuButtons
                [id]="item.itemId"
                *ngIf="item.groups"
                (click)="toggleSubmenu(item)"
                class="relative top-1 h-5 overflow-hidden shrink-0 cursor-pointer"
                alt="down icon"
                src="assets/images/home/chevron-down-dark.svg"
              />
            </span>
            <ul
              #menus
              *ngIf="item.isOpen && (item?.label || item?.collection?.label)"
              class="absolute z-[100] w-auto grid-cols-1 mt-2 text-sm bg-white border border-gray-100 rounded-lg shadow-md dark:border-gray-700 dark:bg-gray-700"
            >
              <li *ngFor="let child of item.groups" class="relative text-gray-900 dark:text-white">
                <span
                  *ngIf="!child.groups"
                  [ngClass]="child.isActive ? 'text-brand-600' : 'text-gray-400'"
                  (click)="onClickMenu(item, child)"
                  class="text-gray-500 dark:text-gray-400 hover:text-brand-600 dark:hover:text-brand-500 cursor-pointer block px-4 py-2 whitespace-nowrap hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-brand-600"
                >
                  {{ child?.label || item?.collection?.label }}
                </span>
                <a
                  *ngIf="child.children"
                  href="#"
                  (click)="toggleSubmenu(child)"
                  class="text-gray-500 dark:text-gray-400 hover:text-brand-600 dark:hover:text-brand-500 cursor-pointer block px-4 py-2 whitespace-nowrap hover:bg-gray-700"
                >
                  {{ child?.label || item?.collection?.label }}
                </a>
                <!-- <ul *ngIf="child.isOpen" class="absolute left-full top-0 mt-2 bg-gray-800 text-white py-2 space-y-2">
                <li *ngFor="let subChild of child.children">
                  <a 
                    [routerLink]="subChild.route" 
                    class="block px-4 py-2 hover:bg-gray-700">
                    {{ subChild.label }}
                  </a>
                </li>
              </ul> -->
              </li>
            </ul>
          </li>
        </ng-container>
        <li *ngIf="moreMenus?.length !== 0">
          <button
            #toggleMoreButton
            (click)="moreMenuOpen = !moreMenuOpen"
            id="categories-menu-dropdown-button"
            class="flex whitespace-nowrap items-center leading-[24px] justify-between w-full h-auto py-2 px-3 font-medium text-gray-900 border-b border-gray-100 md:w-auto hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-brand-600 md:p-0 dark:text-white md:dark:hover:text-brand-500 dark:hover:bg-gray-700 dark:hover:text-brand-500 md:dark:hover:bg-transparent dark:border-gray-700"
          >
            More Categories
            <!-- <img
              class="w-5 relative h-5 ms-3 overflow-hidden shrink-0"
              aria-hidden="true"
              alt="more menu icon"
              src="assets/images/home/menu04.svg"
            /> -->
            <svg  class="w-5 relative h-5 ms-3 overflow-hidden shrink-0" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path [attr.stroke]="fillColor" d="M2.5 10H17.5M2.5 5H17.5M7.5 15H17.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
              
          </button>
          <ul
            #moreMenu
            class="absolute z-[100] w-auto grid-cols-1 mt-2 text-sm bg-white border border-gray-100 rounded-lg shadow-md dark:border-gray-700 dark:bg-gray-700"
            *ngIf="moreMenuOpen"
          >
            <ng-container *ngFor="let item of moreMenus">
              <li
                *ngIf="item?.label || item?.collection?.label"
                class="relative cursor-pointer p-2"
              >
                <span class="flex flex-row">
                  <span
                    (click)="onClickMenu(item, null)"
                    [ngClass]="item.isActive ? 'text-brand-600' : 'text-gray-900'"
                    class="block py-2 px-3 whitespace-nowrap leading-[24px] border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-brand-600 md:p-0 dark:text-white md:dark:hover:text-brand-500 dark:hover:bg-gray-700 dark:hover:text-brand-500 md:dark:hover:bg-transparent dark:border-gray-700"
                    >{{ item.label || item?.collection?.label }}
                  </span>
                  <img
                    #toggleMoreMenuButtons
                    [id]="item.itemId"
                    *ngIf="item.groups"
                    (click)="toggleSubmenu(item)"
                    class="relative top-1 h-5 overflow-hidden shrink-0 cursor-pointer"
                    alt="down icon"
                    src="assets/images/home/chevron-down-dark.svg"
                  />
                </span>

                <ul
                  *ngIf="item.isOpen && (item?.label || item?.collection?.label)"
                  [ngClass]="moreMenuSide === 'right' ? 'left-full' : 'right-full'"
                  class="absolute top-0 z-[100] w-auto grid-cols-1 mt-2 text-sm bg-white border border-gray-100 rounded-lg shadow-md dark:border-gray-700 dark:bg-gray-700"
                >
                  <li
                    *ngFor="let child of item.groups"
                    class="relative text-gray-900 dark:text-white"
                  >
                    <span
                      *ngIf="!child.groups"
                      [ngClass]="child.isActive ? 'text-brand-600' : 'text-gray-400'"
                      (click)="onClickMenu(item, child)"
                      class="text-gray-500 dark:text-gray-400 hover:text-brand-600 dark:hover:text-brand-500 cursor-pointer block px-4 py-2 whitespace-nowrap hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-brand-600"
                    >
                      {{ child.label || item?.collection?.label }}
                    </span>
                    <a
                      *ngIf="child.children"
                      href="#"
                      (click)="toggleSubmenu(child)"
                      class="text-gray-500 dark:text-gray-400 hover:text-brand-600 dark:hover:text-brand-500 cursor-pointer block px-4 py-2 whitespace-nowrap hover:bg-gray-700"
                    >
                      {{ child.label || item?.collection?.label }}
                    </a>
                    <!-- <ul *ngIf="child.isOpen" class="absolute left-full top-0 mt-2 bg-gray-800 text-white py-2 space-y-2">
                  <li *ngFor="let subChild of child.children">
                    <a 
                      [routerLink]="subChild.route" 
                      class="block px-4 py-2 hover:bg-gray-700">
                      {{ subChild.label }}
                    </a>
                  </li>
                </ul> -->
                  </li>
                </ul>
              </li>
            </ng-container>
          </ul>
        </li>
      </ul>
    </div>
  </div>

  <div id="menu-container" #menuContainer class="menu-container" style="visibility: hidden"></div>
</div>

<div
  class="self-stretch bg-base-white md:hidden flex flex-col items-start justify-start pb-4 md:px-16 px-2 box-border md:gap-[32px] gap-[16px] z-[1] text-sm text-gray-500"
>
  <div
    class="self-stretch md:hidden flex flex-row items-center justify-start cursor-pointer"
    id="frameContainer"
  >
    <div
      class="self-stretch w-[88%] rounded-tl-8xs rounded-tr-none rounded-br-none rounded-bl-8xs bg-base-white box-border overflow-hidden shrink-0 flex flex-row items-center justify-start border-t-[1px] border-solid border-gray-400 border-b-[1px] border-l-[1px]"
    >
      <div class="flex-1 flex flex-row items-center justify-start">
        <div
          class="flex-1 relative leading-[20px] font-semibold overflow-hidden text-ellipsis whitespace-nowrap"
        >
          <input
            [value]="searchString"
            [formControl]="searchStringControl"
            type="text"
            class="w-full py-2.5 px-3.5 border-0"
            placeholder="Search our Shop"
          />
        </div>
      </div>
    </div>
    <div
      (click)="onSearchBtnClick()"
      class="self-stretch w-[41px] rounded-tl-none rounded-tr-8xs rounded-br-8xs rounded-bl-none bg-brand-500 overflow-hidden shrink-0 flex flex-row items-center justify-center"
    >
      <div class="flex-1 flex flex-row items-center justify-center">
        <img
          class="w-5 relative h-5 overflow-hidden shrink-0"
          alt="search icon"
          src="assets/images/home/searchlg.svg"
        />
      </div>
    </div>
  </div>
</div>
</header>


<app-drawer [drawerId]="'homePageDrawer'" [isOpen]="isDrawerOpen" (reset)="reset()">
  <app-login
    *ngIf="isLoginOpen"
    (isLoginOpen)="closeLogin($event)"
    (isSignupOpen)="openDrawer('signup')"
    (isForgotPasswordOpen)="openDrawer('forgotPassword')"
  />
  <app-signup
    *ngIf="isSignupOpen"
    (isSignupOpen)="closeSignup($event)"
    (isLoginOpen)="openDrawer('login')"
  />
  <app-forgot-password
    *ngIf="isForgotPasswordOpen"
    (isForgotPasswordOpen)="closeLogin($event)"
    (isLoginOpen)="openDrawer('login')"
  />
  <app-cart *ngIf="isCartOpen" (closeDrawer)="closeCart($event)" />
  <app-add-location
    *ngIf="isLocationOpen"
    (closeDrawer)="closeLocation($event)"
    (onSubmit)="saveLocation($event)"
  />
  <app-address-list
    *ngIf="isAddressesOpen"
    [eddressCode]="deliveryAddress?.eddressCode"
    (closeDrawer)="closeAddresses($event)"
    (newLocation)="openDrawer('location')"
    (confirmLocation)="getDeliveryAddress($event)"
  ></app-address-list>
</app-drawer>
