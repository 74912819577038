import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/services/api.service';
import { Urls } from '@app/core/util/urls';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrderDetailService {
  constructor(private api: ApiService) {}
  getOrderDetails(params, orderId): Observable<any> {
    return this.api.post(Urls.GET_ORDER_DETAILS + orderId, params).pipe(map((data) => data));
  }
  cancelOrder(params, orderId): Observable<any> {
    return this.api.post(Urls.CANCEL_ORDER + orderId, params).pipe(map((data) => data));
  }
}
