import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { formatDate, NgIf, NgClass, NgFor } from '@angular/common';
import { COUNTRIES } from '@app/core/util/countries';
import { SearchPipe } from '../../../../shared/pipes/search.pipe';
import { environment } from '@environments/environment';

interface Country {
  name: string;
  code: string;
  emoji: string;
  unicode: string;
  dial_code: string;
  image: string;
}

@Component({
    selector: 'app-confirm-user-details',
    templateUrl: './confirm-user-details.component.html',
    styleUrl: './confirm-user-details.component.scss',
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        NgIf,
        NgClass,
        NgFor,
        SearchPipe,
    ],
})
export class ConfirmUserDetailsComponent implements OnInit {
  @Input() userDetails;

  @Output() closeDrawer = new EventEmitter<boolean>();
  @Output() onSubmit = new EventEmitter<boolean>();
  @Output() onDeleteAccount = new EventEmitter<boolean>();
  userProfileFormGroup: FormGroup;
  isSubmitted = false;

  open = false;
  searchValue = '';
  selectedCountry = COUNTRIES.countriesList[0];
  countries = COUNTRIES.countriesList;
  countryCode = environment.eddressAppConfig.countryCode;

  constructor(private formBuilder: FormBuilder) {}
  ngOnInit(): void {
    this._initUserProfileForm();
  }
  setDefaultCountry() {
    this.selectedCountry = this.countries.find(country => country.dial_code === this.countryCode);
  }
  _initUserProfileForm() {
    const processedBirthDate = this.userDetails?.birthDate?.replace(/\s/g, '');
    let formattedBirthDate;
    if (processedBirthDate) {
      formattedBirthDate = formatDate(processedBirthDate, 'yyyy-MM-dd', 'en-US');
    }

    this.userProfileFormGroup = this.formBuilder.group({
      fullName: [this.userDetails.fullName ? this.userDetails.fullName : '', [Validators.required]],
      email: [
        this.userDetails.email ? this.userDetails.email : '',
        [Validators.required, Validators.email],
      ],
      phoneNumber: [
        this.userDetails.phoneNumber ? this.userDetails.phoneNumber : '',
        [Validators.required],
      ],
      birthDate: [formattedBirthDate ? formattedBirthDate : ''],
    });
  }

  toggleDropdown() {
    this.open = !this.open;
  }
  selectCountry(country) {
    this.selectedCountry = country;
    this.open = false;
    this.searchValue = '';
  }

  onSubmitFn() {
    this.isSubmitted = true;
    const val = this.userProfileFormGroup.value;
    val.phoneNumber = this.selectedCountry.dial_code + val.phoneNumber;

    if (this.userProfileFormGroup.valid) {
      this.onSubmit.emit(val);
      this.closeDrawer.emit(false);
    }
  }
  close() {
    this.closeDrawer.emit(false);
  }
  deletAccount() {
    this.onDeleteAccount.emit();
  }
  get userAccountForm() {
    return this.userProfileFormGroup.controls;
  }
}
