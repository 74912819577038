import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class MetaService {

  constructor(private meta: Meta) { }

  setMetaTag(itemsArray) {
    if(itemsArray) {
      const array = itemsArray.map((item) => {
        const obj = {
          name: item.name,
          content: item.content
        }
        return obj;
      });
      this.meta.addTags(array);
    }
  }
}
