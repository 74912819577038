import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GenericService } from '@app/core/services/generics.service';
import { NgFor, DecimalPipe } from '@angular/common';

@Component({
    selector: 'app-order-items',
    templateUrl: './order-items.component.html',
    styleUrl: './order-items.component.scss',
    standalone: true,
    imports: [NgFor, DecimalPipe],
})
export class OrderItemsComponent implements OnInit {
  @Input() items = [];
  @Input() orderNumber;
  @Input() grandTotal;
  @Input() hideReoderbtn = false;
  @Output() closeDrawer = new EventEmitter<boolean>();
  configuration;
  itemsCount = 0;

  constructor(private genericService: GenericService) {
    this.genericService.getConfigurations().subscribe((result) => {
      this.configuration = result;
    });
  }

  ngOnInit(): void {
    if (this.items && this.items.length > 0) {
      this.itemsCount = this.items.reduce((accumulator, item) => {
        return accumulator + item.quantity;
      }, 0);
    }
  }

  close() {
    this.closeDrawer.emit(false);
  }

  reOrder() {}
}
