import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'search',
    standalone: true,
})
export class SearchPipe implements PipeTransform {
  transform(items: any[], field: string, value: string): any[] {
    if (!items) return [];
    if (!value) return items;
    if (!field) {
    }
    const item = items.filter((str) => {
      if (typeof str[field] === 'string' && typeof value === 'string') {
        return str[field].toLowerCase().includes(value.toLowerCase());
      } else {
        return str[field] === value;
      }
    });
    if (item.length > 0) {
      return item;
    } else {
      return [];
    }
  }
}
