export namespace FirebaseAuthErrorHandler {
    export function convertMessage(code: string): string {
        switch (code) {
            case 'auth/user-disabled': {
                return 'Sorry, your user is disabled.';
            }

            case 'auth/user-not-found': {
                return 'Sorry, user not found.';
            }

            case 'auth/email-already-in-use': {
                return 'Email is already in use. Please try another.';
            }

            case 'auth/wrong-password': {
              return 'Sorry, incorrect password entered. Please try again.';
            }

            case 'auth/internal-error': {
                return 'An error has occured. Please try again later.';
            }

            default: {
                return 'Login error try again later.';
            }
        }
    }
}