<div
  class="w-full relative bg-base-white flex flex-col items-start justify-start pt-0 px-0 pb-12 box-border gap-[0px] text-left text-sm text-gray-900 font-display-sm-semibold"
>
  <div class="flex flex-col items-start justify-start py-0 px-0 box-border md:w-[420px] z-[0]">
    <div class="w-full flex-col items-start justify-start gap-[0px]">
      <div
        class="self-stretch flex flex-row items-start justify-start py-0 px-6 relative gap-[0px_8px] text-xl"
      >
        <div
          class="flex-1 flex flex-row items-start justify-start pt-6 px-0 pb-0 gap-[0px_16px] z-[0]"
        >
          <div class="flex-1 flex flex-col items-start justify-start gap-[4px_0px]">
            <div class="self-stretch relative leading-[30px] font-semibold">Filters</div>
            <div class="self-stretch relative text-sm leading-[20px] text-gray-600">
              Apply filters to table data.
            </div>
          </div>
        </div>
        <div
          class="w-10 my-0 mx-[!important] absolute top-[12px] right-[16px] rounded-lg h-10 overflow-hidden shrink-0 flex flex-row items-center justify-center p-2 box-border z-[1] cursor-pointer"
          (click)="closeMoreFilters()"
        >
          <img
            class="w-5 relative h-5 overflow-hidden shrink-0"
            alt="close icon"
            src="assets/images/category/xclose.svg"
          />
        </div>
      </div>
      <div
        class="self-stretch flex-1 flex flex-col justify-start py-0 px-6 gap-[24px_0px] text-gray-700 mt-10 mb-10"
      >
        <app-filter-item
          [hidden]="brandsFilters.length === 0"
          [label]="'Brands'"
          [isFilterOpen]="selectedFilter === 'Brands'"
          [mainItemsList]="brandsFilters"
          (selectedFilter)="setSelectedFilter($event)"
        />

        <app-filter-item
          [hidden]="groupsFilters.length === 0"
          [label]="'Sub Categories'"
          [isFilterOpen]="selectedFilter === 'Groups'"
          [mainItemsList]="groupsFilters"
          (selectedFilter)="setSelectedFilter($event)"
        />
      </div>

      <div
        class="self-stretch flex flex-col items-center justify-start text-brand-700 border-t-[1px] border-solid border-whitesmoke fixed bottom-0 bg-white flex-1"
      >
        <div class="w-[420px] mx-auto px-6">
          <div class="flex flex-row items-center justify-between py-4 px-0">
            <!-- <div class="flex flex-row items-start justify-start cursor-pointer">
              <div class="overflow-hidden flex flex-row items-center justify-center gap-[0px_6px]">
                <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="/public/placeholder.svg" />

                <div class="relative leading-[20px] font-semibold">
                  Save filter
                </div>
                <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="/public/placeholder.svg" />
              </div>
            </div> -->
            <div class="flex flex-row items-start justify-start gap-[0px_12px] text-gray-700">
              <div
                (click)="closeMoreFilters()"
                class="rounded-lg bg-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] overflow-hidden flex flex-row items-center justify-center py-2.5 px-3.5 gap-[0px_4px] border-[1px] border-solid border-gray-300 cursor-pointer"
              >
                <!-- <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="/public/placeholder.svg" /> -->

                <div class="flex flex-row items-center justify-center py-0 px-0.5">
                  <div class="relative leading-[20px] font-semibold">Cancel</div>
                </div>
                <!-- <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon"
                  src="assets/images/category/placeholder.svg" /> -->
              </div>
              <div
                (click)="applyMoreFilterFn()"
                class="rounded-lg bg-brand-600 shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] overflow-hidden flex flex-row items-center justify-center py-2.5 px-3.5 gap-[0px_4px] text-white border-[1px] border-solid border-brand-600 cursor-pointer"
              >
                <!-- <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon"
                  src="assets/images/category/placeholder.svg" /> -->

                <div class="flex flex-row items-center justify-center py-0 px-0.5">
                  <div class="relative leading-[20px] font-semibold">Apply</div>
                </div>
                <!-- <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="/public/placeholder.svg" /> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
