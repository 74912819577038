import { Urls } from '@app/core/util/urls';
import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/services/api.service';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MyAccountService {
  constructor(private api: ApiService) {}
  getUserAccountInformation(): Observable<any> {
    return this.api.get(Urls.USER).pipe(map((data) => data));
  }
  saveUserAccountInformation(params) {
    return this.api.post(Urls.UPDATE_USER, params).pipe(
      map((data) => {
        return data;
      })
    );
  }
  deleteUserAccount() {
    return this.api.delete(Urls.DELETE_USER).pipe(
      map((data) => {
        return data;
      })
    );
  }
}
