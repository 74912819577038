<div class="md:grid md:grid-cols-4 px-4 gap-[0px_32px] py-8">
  <div class="md:col-span-3">
    <div class="self-stretch flex-1 flex flex-row items-start justify-start gap-[12px_0px]">
      <div
        class="w-[880px] rounded-lg bg-base-white box-border hidden flex-row items-center justify-between p-6 border-[1px] border-solid border-whitesmoke"
      >
        <div class="relative leading-[20px] font-semibold text-brand-600" id="changeText">
          Change
        </div>
      </div>
    </div>
    <div
      class="self-stretch flex-1 rounded-lg bg-base-white flex flex-col items-start justify-start py-6 px-0 gap-[16px_0px] text-lg text-gray-900 border-[1px] border-solid border-whitesmoke"
    >
      <div class="self-stretch flex flex-col items-center justify-start py-0 px-6 relative">
        <div class="self-stretch flex flex-row items-start justify-start gap-[0px_16px] z-[0]">
          <img
            class="w-8 relative rounded-9xl h-8"
            alt="check circle image"
            src="assets/images/home/check-circle.svg"
          />

          <div class="flex-1 flex flex-col items-start justify-start">
            <h1 class="self-stretch relative leading-[28px] font-semibold">
              {{ orderDetails?.status }}
            </h1>
            <div class="self-stretch relative text-sm leading-[20px] text-gray-600">
              Order #{{ orderDetails?.sequenceNumber }}
            </div>
          </div>
        </div>
        <div
          *ngIf="orderDetails?.eta"
          class="!m-[0] absolute right-[12px] rounded-lg h-11 overflow-hidden shrink-0 flex-row items-center justify-center p-2 box-border z-[1]"
        >
          <span class="self-stretch relative leading-[20px]">ETA: </span>
          <span class="leading-[20px] font-semibold">{{ orderDetails?.eta }}</span>
        </div>
        <div class="w-[400px] h-5 hidden z-[2]"></div>
        <img
          class="w-[400px] relative max-w-full overflow-hidden h-px shrink-0 hidden z-[3]"
          alt="divider image"
          src="assets/images/home/divider.svg"
        />
      </div>
    </div>
    <div class="md:pl-6">
      <google-map
        height="360px"
        width="100%"
        [options]="options"
        [center]="center"
        [zoom]="zoom"
        [draggable]="true"
      >
        <map-marker
          #marker="mapMarker"
          [position]="markerPosition"
          [options]="markerOptions"
          (mapClick)="openInfoWindow(marker, infoWindow)"
        />
        <map-marker
          *ngIf="orderDetails?.isNotInStorePickup"
          #mapStoreMarker="mapMarker"
          [position]="markerPositionStore"
          [options]="markerOptionsStore"
          (mapClick)="openInfoWindow(mapStoreMarker, infoWindowStore)"
        />
        <map-marker
          *ngIf="orderDetails?.isNotInStorePickup"
          #mapWorkerMarker="mapMarker"
          [position]="markerPositionWorker"
          [options]="markerOptionsWorker"
          (mapClick)="openInfoWindow(mapWorkerMarker, infoWindowworker)"
        />
        <map-info-window #infoWindow="mapInfoWindow">
          <div class="flex flex-col text-center gap-2">
            <h2 class="text-lg">Shipping Address</h2>

            <span class="font-bold"> {{ orderDetails?.delivery?.addressDetails }}</span>
          </div>
        </map-info-window>
        <map-info-window #infoWindowStore="mapInfoWindow">
          <div class="flex flex-col text-center gap-2">
            <h2 class="text-lg">Store Name</h2>
            <span class="font-bold"> {{ selectedStore?.name }}</span>
            <span class="font-bold"> {{ selectedStore?.storeAddress }}</span>
          </div>
        </map-info-window>
        <map-info-window #infoWindowworker="mapInfoWindow">
          <div class="flex flex-col text-center gap-2">
            <h2 class="text-lg">Rider Info</h2>
            <span class="font-bold"> {{ orderDetails?.workerName }}</span>
            <span class="font-bold"> {{ orderDetails?.workerPhoneNumber }}</span>
          </div>
        </map-info-window>
        @if (directionsResults$ | async; as directionsResults) {
        <map-directions-renderer [options]="directionOptions" [directions]="directionsResults" />
        }
      </google-map>
    </div>
    <div
      class="self-stretch flex flex-col justify-center md:items-start md:justify-start py-3 px-6 gap-[12px_0px] text-sm text-base-black"
    >
      <div class="self-stretch flex flex-row items-start justify-start">
        <div
          class="flex-1 h-[52px] flex flex-col items-start justify-start gap-[4px_0px] min-w-[160px]"
        >
          <div class="self-stretch relative leading-[20px]">
            Deliver to {{ orderDetails?.delivery.addressName }}:
          </div>
          <div class="self-stretch relative leading-[20px] font-semibold">
            {{ orderDetails?.delivery.addressDetails }}
          </div>
        </div>
      </div>
      <div class="self-stretch relative bg-whitesmoke h-px"></div>
      <div class="self-stretch flex flex-row items-start justify-start">
        <div
          class="flex-1 h-[52px] flex flex-col items-start justify-start gap-[4px_0px] min-w-[160px]"
        >
          <div class="self-stretch relative leading-[20px]">Payment Method:</div>
          <div class="self-stretch relative leading-[20px] font-semibold">
            {{ orderDetails?.paymentMethod === "CASH" ? "Cash on Delivery" : "Credit Card" }}
          </div>
        </div>
      </div>
      <div class="self-stretch relative bg-whitesmoke h-px"></div>
      <div
        class="self-stretch flex flex-row items-start justify-start"
        *ngIf="orderDetails?.deliveryInstructions"
      >
        <div class="flex-1 flex flex-col items-start justify-start gap-[4px_0px] min-w-[160px]">
          <div class="self-stretch relative leading-[20px]">Delivery Instructions:</div>
          <div
            class="self-stretch relative leading-[20px] font-semibold"
            *ngFor="let note of orderDetails?.deliveryInstructions"
          >
            {{ note }}
          </div>
        </div>
      </div>
      <div class="self-stretch relative bg-whitesmoke h-px"></div>
      <div
        class="flex flex-col-reverse md:flex-row justify-center md:items-start md:justify-start gap-[10px_10px] text-brand-700"
      >
        <div
          (click)="openDrawer('orderItems')"
          class="rounded-lg bg-brand-50 shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] box-border h-10 overflow-hidden flex flex-row items-center justify-center py-2.5 px-3.5 gap-[0px_4px] border-[1px] border-solid border-brand-300 cursor-pointer"
        >
          <img
            class="w-5 relative h-5 overflow-hidden shrink-0"
            alt="shopping bag image"
            src="assets/images/home/shoppingbag01.svg"
          />

          <div class="flex flex-row items-center justify-center py-0 px-0.5">
            <div class="relative leading-[20px] font-semibold">View Order Details</div>
          </div>
          <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />
        </div>
        <div
          id="tooltipContent"
          role="tooltip"
          class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
        >
          {{ toolTipMessage }}
          <!-- <div class="tooltip-arrow"></div> -->
        </div>
        <div
          *ngIf="orderDetails?.statusEnum === 'NEW'"
          id="tooltipButton"
          class="rounded-lg bg-snow shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] box-border h-10 overflow-hidden flex flex-row items-center justify-center py-2.5 px-3.5 gap-[4px] border-[1px] border-solid border-brand-300 cursor-pointer"
          (click)="openCancelOrderConfirmationModal()"
        >
          <div class="flex flex-row items-center justify-center py-0 px-0.5">
            <div class="relative leading-[20px] font-semibold">Cancel Order</div>
          </div>
          <img
            class="w-5 relative h-5 overflow-hidden shrink-0 hidden"
            alt="hidden icon"
            src="/public/placeholder.svg"
          />
        </div>
        <div
          *ngIf="
            orderDetails?.workerUid &&
            (orderDetails?.deliveryStatus === 'OUT_FOR_DELIVERY' ||
              orderDetails?.deliveryStatus === 'DELIVERING')
          "
          id="tooltipButton"
          class="rounded-lg bg-snow shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] box-border h-10 overflow-hidden flex flex-row items-center justify-center py-2.5 px-3.5 gap-[4px] border-[1px] border-solid border-brand-300 cursor-pointer"
          (click)="copyDriverContact()"
        >
          <img
            class="w-5 relative h-5 overflow-hidden shrink-0"
            alt="phone icon image"
            src="assets/images/phoneoutgoing02.svg"
          />
          <div class="flex flex-row items-center justify-center py-0 px-0.5">
            <div class="relative leading-[20px] font-semibold">Contact Driver</div>
          </div>
          <!-- <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="/public/placeholder.svg" /> -->
        </div>
        <div
          [routerLink]="'/'"
          class="rounded-lg bg-brand-600 shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] box-border h-10 overflow-hidden flex flex-row items-center justify-center py-2.5 px-3.5 gap-[0px_4px] text-base-white border-[1px] border-solid border-brand-600 cursor-pointer"
        >
          <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />

          <div class="flex flex-row items-center justify-center py-0 px-0.5">
            <div class="relative leading-[20px] font-semibold">Continue Shopping</div>
          </div>
          <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />
        </div>
      </div>
    </div>
  </div>

  <div
    class="md:col-span-1 md:w-[370px] rounded-lg bg-base-white box-border flex flex-col justify-between md:items-start md:justify-start p-6 gap-[16px_0px] text-gray-900 border-[1px] border-solid border-whitesmoke"
  >
    <div class="relative text-xl leading-[30px] font-semibold">Order Status</div>
    <div class="md:w-[352px] relative bg-whitesmoke h-px"></div>
    <div
      *ngIf="orderDetails?.isNotInStorePickup"
      class="md:w-[400px] flex flex-col justify-between md:items-start md:justify-start text-gray-700"
    >
      <!-- Order Received -->
      <div class="md:w-[400px] flex flex-row items-start justify-start gap-[0px_12px]">
        <div
          class="self-stretch flex flex-col items-center justify-start pt-0 px-0 pb-1 gap-[4px_0px]"
        >
          <img
            class="w-6 relative rounded-xl h-6 overflow-hidden shrink-0"
            alt="status icon"
            [src]="
              orderDetails?.statusEnum === 'NEW' ||
              orderDetails?.statusEnum === 'CONFIRMED' ||
              orderDetails?.statusEnum === 'COMPLETE' ||
              orderDetails?.statusEnum === 'ON_THE_WAY'
                ? 'assets/images/home/content.svg'
                : 'assets/images/home/step-icon-base.svg'
            "
          />

          <div
            [ngClass]="
              (orderDetails?.statusEnum !== 'NEW' &&
                orderDetails?.statusEnum === 'CONFIRMED' &&
                orderDetails?.statusEnum !== 'COMPLETE' &&
                orderDetails?.statusEnum !== 'ON_THE_WAY') ||
              (orderDetails?.statusEnum !== 'NEW' &&
                orderDetails?.statusEnum !== 'CONFIRMED' &&
                orderDetails?.statusEnum !== 'ON_THE_WAY' &&
                orderDetails?.statusEnum === 'COMPLETE') ||
              (orderDetails?.statusEnum !== 'NEW' &&
                orderDetails?.statusEnum !== 'CONFIRMED' &&
                orderDetails?.statusEnum !== 'COMPLETE' &&
                orderDetails?.statusEnum === 'ON_THE_WAY')
                ? 'bg-brand-600 '
                : 'bg-gray-300'
            "
            class="w-0.5 flex-1 relative rounded-sm"
          ></div>
        </div>
        <div class="flex-1 flex flex-col items-start justify-start pt-0.5 px-0 pb-6">
          <div class="self-stretch relative leading-[20px] font-semibold">Order Received</div>
        </div>
      </div>
      <!-- Order Confirmed -->
      <div class="md:w-[400px] flex flex-row items-start justify-start gap-[0px_12px]">
        <div
          class="self-stretch flex flex-col items-center justify-start pt-0 px-0 pb-1 gap-[4px_0px]"
        >
          <img
            class="w-6 relative rounded-xl h-6 overflow-hidden shrink-0"
            alt="status icon"
            [src]="
              orderDetails?.statusEnum === 'CONFIRMED' ||
              orderDetails?.statusEnum === 'COMPLETE' ||
              orderDetails?.statusEnum === 'ON_THE_WAY'
                ? 'assets/images/home/content.svg'
                : 'assets/images/home/step-icon-base.svg'
            "
          />

          <div
            [ngClass]="
              (orderDetails?.statusEnum !== 'NEW' &&
                orderDetails?.statusEnum !== 'CONFIRMED' &&
                orderDetails?.statusEnum !== 'ON_THE_WAY' &&
                orderDetails?.statusEnum === 'COMPLETE') ||
              (orderDetails?.statusEnum !== 'NEW' &&
                orderDetails?.statusEnum !== 'CONFIRMED' &&
                orderDetails?.statusEnum !== 'COMPLETE' &&
                orderDetails?.statusEnum === 'ON_THE_WAY')
                ? 'bg-brand-600 '
                : 'bg-gray-300'
            "
            class="w-0.5 flex-1 relative rounded-sm"
          ></div>
        </div>
        <div class="flex-1 flex flex-col items-start justify-start pt-0.5 px-0 pb-6">
          <div class="self-stretch relative leading-[20px] font-semibold">Order Confirmed</div>
        </div>
      </div>

      <!-- <div
        class="self-stretch flex flex-row items-start justify-start gap-[0px_12px] text-brand-700"
      >
        <div
          class="self-stretch flex flex-col items-center justify-start pt-0 px-0 pb-1 gap-[4px_0px]"
        >
          <img
            class="w-6 relative rounded-xl h-6 overflow-hidden shrink-0"
            alt="ss"
            src="assets/images/home/content-in-progress.svg"
          />

          <div class="w-0.5 flex-1 relative rounded-sm bg-gray-300"></div>
        </div>
        <div class="flex-1 flex flex-col items-start justify-start pt-0.5 px-0 pb-6">
          <div class="self-stretch relative leading-[20px] font-semibold">
            Order is being processed
          </div>
          <div class="w-[308px] relative leading-[20px] text-brand-600 hidden">
            A few details about your company
          </div>
        </div>
      </div> -->

      <!-- <div class="self-stretch flex flex-row items-start justify-start gap-[0px_12px]">
        <div
          class="self-stretch flex flex-col items-center justify-start pt-0 px-0 pb-1 gap-[4px_0px]"
        >
          <img
            class="w-6 relative rounded-xl h-6 overflow-hidden shrink-0"
            alt="s"
            src="assets/images/home/step-icon-base.svg"
          />

          <div class="w-0.5 flex-1 relative rounded-sm bg-gray-300"></div>
        </div>
        <div class="flex-1 flex flex-col items-start justify-start pt-0.5 px-0 pb-6">
          <div class="self-stretch relative leading-[20px] font-semibold">Order on its way</div>
          <div class="w-[308px] relative leading-[20px] text-gray-600 hidden">
            Start collaborating with your team
          </div>
        </div>
      </div> -->

      <!-- Order On the Way -->
      <div class="md:w-[400px] flex flex-row items-start justify-start gap-[0px_12px]">
        <div
          class="self-stretch flex flex-col items-center justify-start pt-0 px-0 pb-1 gap-[4px_0px]"
        >
          <img
            class="w-6 relative rounded-xl h-6 overflow-hidden shrink-0"
            alt="status icon"
            [src]="
              orderDetails?.dispatchStatus === 'COMPLETE' ||
              orderDetails?.dispatchStatus === 'ON_THE_WAY'
                ? 'assets/images/home/content.svg'
                : 'assets/images/home/step-icon-base.svg'
            "
          />
          <div
            [ngClass]="
              orderDetails?.dispatchStatus !== 'NEW' &&
              orderDetails?.dispatchStatus !== 'CONFIRMED' &&
              orderDetails?.dispatchStatus === 'COMPLETE'
                ? 'bg-brand-600 '
                : 'bg-gray-300'
            "
            class="w-0.5 flex-1 relative rounded-sm"
          ></div>
        </div>

        <div class="flex-1 flex flex-col items-start justify-start pt-0.5 px-0 pb-6">
          <div class="self-stretch relative leading-[20px] font-semibold">Order on its way</div>
        </div>
      </div>

      <!-- Order Delivered -->
      <div class="md:w-[400px] flex flex-row items-start justify-start gap-[0px_12px]">
        <div
          class="self-stretch flex flex-col items-center justify-start pt-0 px-0 pb-1 gap-[4px_0px]"
        >
          <img
            class="w-6 relative rounded-xl h-6 overflow-hidden shrink-0"
            alt="status icon"
            [src]="
              orderDetails?.dispatchStatus === 'COMPLETE'
                ? 'assets/images/home/content.svg'
                : 'assets/images/home/step-icon-base.svg'
            "
          />
        </div>
        <div class="flex-1 flex flex-col items-start justify-start pt-0.5 px-0 pb-6">
          <div class="self-stretch relative leading-[20px] font-semibold">Delivered</div>
        </div>
      </div>
    </div>

    <div
      *ngIf="!orderDetails?.isNotInStorePickup"
      class="md:w-[400px] flex flex-col justify-between md:items-start md:justify-start text-gray-700"
    >
      <!-- Order Received -->
      <div class="md:w-[400px] flex flex-row items-start justify-start gap-[0px_12px]">
        <div
          class="self-stretch flex flex-col items-center justify-start pt-0 px-0 pb-1 gap-[4px_0px]"
        >
          <img
            class="w-6 relative rounded-xl h-6 overflow-hidden shrink-0"
            alt="status icon"
            [src]="
              orderDetails?.statusEnum === 'NEW' ||
              orderDetails?.statusEnum === 'CONFIRMED' ||
              orderDetails?.statusEnum === 'COMPLETE' ||
              orderDetails?.statusEnum === 'READY'
                ? 'assets/images/home/content.svg'
                : 'assets/images/home/step-icon-base.svg'
            "
          />

          <div
            [ngClass]="
              (orderDetails?.statusEnum !== 'NEW' &&
                orderDetails?.statusEnum === 'CONFIRMED' &&
                orderDetails?.statusEnum !== 'COMPLETE' &&
                orderDetails?.statusEnum !== 'READY') ||
              (orderDetails?.statusEnum !== 'NEW' &&
                orderDetails?.statusEnum !== 'CONFIRMED' &&
                orderDetails?.statusEnum !== 'READY' &&
                orderDetails?.statusEnum === 'COMPLETE') ||
              (orderDetails?.statusEnum !== 'NEW' &&
                orderDetails?.statusEnum !== 'CONFIRMED' &&
                orderDetails?.statusEnum !== 'COMPLETE' &&
                orderDetails?.statusEnum === 'READY')
                ? 'bg-brand-600 '
                : 'bg-gray-300'
            "
            class="w-0.5 flex-1 relative rounded-sm"
          ></div>
        </div>
        <div class="flex-1 flex flex-col items-start justify-start pt-0.5 px-0 pb-6">
          <div class="self-stretch relative leading-[20px] font-semibold">Order Received</div>
        </div>
      </div>
      <!-- Order Confirmed -->
      <div class="md:w-[400px] flex flex-row items-start justify-start gap-[0px_12px]">
        <div
          class="self-stretch flex flex-col items-center justify-start pt-0 px-0 pb-1 gap-[4px_0px]"
        >
          <img
            class="w-6 relative rounded-xl h-6 overflow-hidden shrink-0"
            alt="status icon"
            [src]="
              orderDetails?.statusEnum === 'CONFIRMED' ||
              orderDetails?.statusEnum === 'COMPLETE' ||
              orderDetails?.statusEnum === 'READY'
                ? 'assets/images/home/content.svg'
                : 'assets/images/home/step-icon-base.svg'
            "
          />

          <div
            [ngClass]="
              (orderDetails?.statusEnum !== 'NEW' &&
                orderDetails?.statusEnum !== 'CONFIRMED' &&
                orderDetails?.statusEnum !== 'READY' &&
                orderDetails?.statusEnum === 'COMPLETE') ||
              (orderDetails?.statusEnum !== 'NEW' &&
                orderDetails?.statusEnum !== 'CONFIRMED' &&
                orderDetails?.statusEnum !== 'COMPLETE' &&
                orderDetails?.statusEnum === 'READY')
                ? 'bg-brand-600 '
                : 'bg-gray-300'
            "
            class="w-0.5 flex-1 relative rounded-sm"
          ></div>
        </div>
        <div class="flex-1 flex flex-col items-start justify-start pt-0.5 px-0 pb-6">
          <div class="self-stretch relative leading-[20px] font-semibold">Order Confirmed</div>
        </div>
      </div>
      <!-- Order On the Way -->
      <div class="md:w-[400px] flex flex-row items-start justify-start gap-[0px_12px]">
        <div
          class="self-stretch flex flex-col items-center justify-start pt-0 px-0 pb-1 gap-[4px_0px]"
        >
          <img
            class="w-6 relative rounded-xl h-6 overflow-hidden shrink-0"
            alt="status icon"
            [src]="
              orderDetails?.statusEnum === 'COMPLETE' || orderDetails?.statusEnum === 'READY'
                ? 'assets/images/home/content.svg'
                : 'assets/images/home/step-icon-base.svg'
            "
          />
          <div
            [ngClass]="
              orderDetails?.statusEnum !== 'NEW' &&
              orderDetails?.statusEnum !== 'CONFIRMED' &&
              orderDetails?.statusEnum === 'COMPLETE'
                ? 'bg-brand-600 '
                : 'bg-gray-300'
            "
            class="w-0.5 flex-1 relative rounded-sm"
          ></div>
        </div>

        <div class="flex-1 flex flex-col items-start justify-start pt-0.5 px-0 pb-6">
          <div class="self-stretch relative leading-[20px] font-semibold">Order Ready</div>
        </div>
      </div>

      <!-- Order Delivered -->
      <div class="md:w-[400px] flex flex-row items-start justify-start gap-[0px_12px]">
        <div
          class="self-stretch flex flex-col items-center justify-start pt-0 px-0 pb-1 gap-[4px_0px]"
        >
          <img
            class="w-6 relative rounded-xl h-6 overflow-hidden shrink-0"
            alt="status icon"
            [src]="
              orderDetails?.statusEnum === 'COMPLETE'
                ? 'assets/images/home/content.svg'
                : 'assets/images/home/step-icon-base.svg'
            "
          />
        </div>
        <div class="flex-1 flex flex-col items-start justify-start pt-0.5 px-0 pb-6">
          <div class="self-stretch relative leading-[20px] font-semibold">Order Completed</div>
        </div>
      </div>
    </div>

    <div class="relative text-xl leading-[30px] font-semibold">Order Summary</div>
    <div class="md:w-[352px] relative bg-whitesmoke h-px"></div>
    <div class="self-stretch flex flex-row items-center justify-between text-gray-800">
      <div class="relative leading-[20px] font-semibold">Subtotal</div>
      <div class="relative leading-[20px] font-semibold">
        {{ configuration?.tenantCurrencySymbol }}{{ orderDetails?.subTotal | number : "1.2-2" }}
      </div>
    </div>
    <div
      *ngIf="orderDetails?.isNotInStorePickup"
      class="self-stretch flex flex-row items-center justify-between text-gray-800"
    >
      <div class="relative leading-[20px] font-semibold">Delivery Charge</div>
      <div class="relative leading-[20px] font-semibold">
        {{ configuration?.tenantCurrencySymbol
        }}{{ orderDetails?.deliveryCharge | number : "1.2-2" }}
      </div>
    </div>
    <div
      *ngIf="
        orderDetails?.isNotInStorePickup &&
        orderDetails?.deliveryVat &&
        configuration?.showDeliveryVat
      "
      class="self-stretch flex flex-row items-center justify-between text-gray-800"
    >
      <div class="relative leading-[20px] font-semibold">Delivery Vat</div>
      <div class="relative leading-[20px] font-semibold">
        {{ configuration?.tenantCurrencySymbol }}{{ orderDetails?.deliveryVat | number : "1.2-2" }}
      </div>
    </div>
    <div
      *ngIf="orderDetails?.vat && configuration?.showItemsVat"
      class="self-stretch flex flex-row items-center justify-between text-gray-800"
    >
      <div class="relative leading-[20px] font-semibold">Vat</div>
      <div class="relative leading-[20px] font-semibold">
        {{ configuration?.tenantCurrencySymbol }}{{ orderDetails?.vat | number : "1.2-2" }}
      </div>
    </div>
    <div
      *ngIf="orderDetails?.discountAmount"
      class="self-stretch text-brand-600 flex flex-row items-center justify-between"
    >
      <div class="relative leading-[20px] font-semibold">Discount</div>
      <div class="relative leading-[20px] font-semibold">
        -{{ configuration?.tenantCurrencySymbol
        }}{{ orderDetails?.discountAmount | number : "1.2-2" }}
      </div>
    </div>
    <div class="self-stretch flex flex-row items-center justify-between text-base">
      <b class="relative leading-[24px]">Total</b>
      <b class="relative text-xl leading-[30px]"
        >{{ configuration?.tenantCurrencySymbol }}{{ orderDetails?.total | number : "1.2-2" }}</b
      >
    </div>
    <div
      *ngIf="orderDetails?.deductionFromWallet"
      class="self-stretch text-brand-600 flex flex-row items-center justify-between"
    >
      <div class="relative leading-[20px] font-semibold">Wallet</div>
      <div class="relative leading-[20px] font-semibold">
        -{{ configuration?.tenantCurrencySymbol
        }}{{ orderDetails?.deductionFromWallet | number : "1.2-2" }}
      </div>
    </div>
    <div
      *ngIf="orderDetails?.amountDue"
      class="self-stretch flex flex-row items-center justify-between"
    >
      <div class="relative leading-[20px] font-semibold">Amount Due</div>
      <div class="relative leading-[20px] font-semibold">
        {{ configuration?.tenantCurrencySymbol }}{{ orderDetails?.amountDue | number : "1.2-2" }}
      </div>
    </div>
  </div>
</div>
<app-drawer [drawerId]="'orderDetailsDrawer'" [isOpen]="isDrawerOpen" (reset)="reset()">
  <app-order-items
    [orderNumber]="orderDetails?.sequenceNumber"
    [items]="orderDetails?.items"
    [grandTotal]="orderDetails?.subTotal"
    [hideReoderbtn]="true"
    *ngIf="isOrderItemsOpen"
    (closeDrawer)="closeOrderitems($event)"
  />
</app-drawer>

<app-modal [modalId]="'cancelOrderModal'" [isOpen]="isModalOpen" (reset)="resetModal()">
  <div class="relative p-4 w-full max-w-md max-h-full">
    <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
      <button
        (click)="closeConfrimationPopUp(false)"
        type="button"
        class="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
        data-modal-hide="popup-modal"
      >
        <svg
          class="w-3 h-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 14 14"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
          />
        </svg>
        <span class="sr-only">Close modal</span>
      </button>
      <div class="p-4 md:p-5 text-center">
        <svg
          class="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 20 20"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
          />
        </svg>
        <h3 class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
          Are you sure you want to cancel this order ?
        </h3>
        <div class="self-stretch flex flex-col items-start justify-start gap-[6px_0px] mb-[15px]">
          <div class="self-stretch flex flex-col items-start justify-start gap-[6px_0px]">
            <input
              type="text"
              placeholder="Reason"
              class="self-stretch rounded-lg bg-base-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] flex flex-row items-center justify-start py-2.5 px-3.5 gap-[0px_8px] text-base text-gray-900 border-[1px] border-solid border-gray-300"
              [(ngModel)]="cancelationReason"
            />
          </div>
        </div>
        <button
          [disabled]="!cancelationReason"
          (click)="cancelOrder()"
          data-modal-hide="popup-modal"
          type="button"
          class="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center"
        >
          Yes, I'm sure
        </button>
        <button
          (click)="closeConfrimationPopUp(false)"
          data-modal-hide="popup-modal"
          type="button"
          class="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
        >
          No, cancel
        </button>
      </div>
    </div>
  </div>
</app-modal>
