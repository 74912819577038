import { createReducer, on } from "@ngrx/store";
import { addToBanner } from "../actions/banner.action";
import { initialBannerState } from "../states/bannerState";



export const bannerReducer = createReducer(initialBannerState, 
    on(addToBanner, (state, action) => {
    return {...state, banners: action.banners, isLoaded: action.isLoaded};
})
);