<div class="flex-1 flex flex-col items-start justify-start gap-[12px_0px] text-gray-900">
  <h1 class="relative leading-[24px] font-semibold">My Wallet</h1>
  <div class="self-stretch flex flex-row items-start justify-start gap-[0px_16px] text-xs text-base-white">
    <div class="flex-1 rounded-lg bg-brand-700 flex flex-col items-start justify-start p-4 gap-[6px_0px]">
      <img class="w-6 relative h-6 overflow-hidden shrink-0" alt="wallet icon" src="assets/images/user/wallet02.svg" />

      <div class="self-stretch relative leading-[18px]">Available Balance</div>
      <div class="relative text-sm leading-[20px] font-semibold">
        {{ configuration?.tenantCurrencySymbol }} {{ amountInWallet || 0 | number : "1.2-2" }}
      </div>
    </div>
    <div class="flex-1 rounded-lg bg-gray-700 flex flex-col items-start justify-start p-4 gap-[6px_0px]">
      <img class="w-6 relative h-6 overflow-hidden shrink-0" alt="cashback icon" src="assets/images/user/coinsswap01.svg" />

      <div class="self-stretch relative leading-[18px]">Cashback</div>
      <div class="relative text-sm leading-[20px] font-semibold">
        {{ configuration?.tenantCurrencySymbol }} {{ cashBackEarned | number : "1.2-2" }}
      </div>
    </div>
  </div>
  <div
    class="self-stretch rounded-xl bg-base-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] overflow-hidden flex flex-col items-start justify-start text-lg border-[1px] border-solid border-gray-2001">
    <div class="self-stretch bg-base-white flex flex-col items-start justify-start gap-[20px_0px]">
      <div class="self-stretch flex flex-row items-start justify-start pt-5 px-6 pb-0 gap-[0px_16px] z-[1]">
        <div class="self-stretch flex-1 flex flex-col items-start justify-center gap-[4px_0px]">
          <div class="self-stretch flex flex-row items-center justify-start gap-[0px_8px]">
            <div class="relative leading-[28px] font-semibold">Transactions</div>
            <div *ngIf="userWalletHistory?.length > 0"
              class="rounded-md bg-base-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] flex flex-row items-center justify-start py-0.5 px-1.5 text-center text-xs text-gray-700 border-[1px] border-solid border-gray-300">
              <div class="relative leading-[18px]">
                {{ userWalletHistory?.length }} transactions
              </div>
            </div>
          </div>
        </div>
      </div>
      <img class="self-stretch relative max-w-full overflow-hidden h-px shrink-0 z-[0]" alt="divider icon"
        src="assets/images/user/divider.svg" />
    </div>
    <div class="self-stretch bg-base-white flex flex-row items-start justify-start text-sm text-gray-600">
      <div class="flex-1 flex flex-col items-start justify-start max-w-[200px]">
        <div
          class="self-stretch bg-base-white box-border h-11 flex flex-row items-center justify-start py-3 px-6 text-xs border-b-[1px] border-solid border-gray-2001">
          <div class="flex flex-row items-center justify-start">
            <div class="relative leading-[18px]">Added By</div>
          </div>
        </div>
        <div
          class="self-stretch box-border h-[52px] flex flex-row items-center justify-start py-4 px-6 border-b-[1px] border-solid border-gray-2001"
          *ngFor="let userWallet of userWalletHistory">
          <div class="relative leading-[20px] font-semibold">{{ userWallet.addedBy || "-" }}</div>
        </div>
      </div>
      <div class="flex-1 flex flex-col items-start justify-start">
        <div
          class="self-stretch bg-base-white box-border h-11 flex flex-row items-center justify-start py-3 px-6 text-xs border-b-[1px] border-solid border-gray-2001">
          <div class="flex flex-row items-center justify-start">
            <div class="relative leading-[18px]">Description</div>
          </div>
        </div>
        <div
          class="self-stretch box-border flex flex-row items-center justify-start py-4 px-6 gap-[0px_12px] border-b-[1px] border-solid border-gray-2001"
          *ngFor="let userWallet of userWalletHistory">
          <!-- <img
            class="w-[46px] relative rounded h-8 object-cover"
            alt="ss"
            src="assets/images/user/payment-method-icon@2x.png"
          /> -->

          <div class="relative leading-[20px]">{{ userWallet.description || "-" }}</div>
        </div>
      </div>
      <div class="flex-1 flex flex-col items-start justify-start">
        <div
          class="self-stretch bg-base-white box-border h-11 flex flex-row items-center justify-start py-3 px-6 text-xs border-b-[1px] border-solid border-gray-2001">
          <div class="flex flex-row items-center justify-start">
            <div class="relative leading-[18px]">Date</div>
          </div>
        </div>
        <div
          class="self-stretch box-border h-[52px] flex flex-row items-center justify-start py-4 px-6 border-b-[1px] border-solid border-gray-2001"
          *ngFor="let userWallet of userWalletHistory">
          <div class="relative leading-[20px]">
            {{ userWallet.createdDate | date : "dd, MMMM, YYYY" }}
          </div>
        </div>
      </div>
      <!-- <div class="w-[77px] flex flex-col items-start justify-start">
        <div
          class="self-stretch bg-base-white box-border h-11 flex flex-row items-center justify-start py-3 px-6 text-xs border-b-[1px] border-solid border-gray-2001"
        >
          <div class="flex flex-row items-center justify-start">
            <div class="relative leading-[18px]">Points</div>
          </div>
        </div>
        <div
          class="self-stretch box-border h-[52px] flex flex-row items-center justify-start py-4 px-6 border-b-[1px] border-solid border-gray-2001"
          *ngFor="let userWallet of userWalletHistory"
        >
          <div class="relative leading-[20px]">{{ userWallet.points }}</div>
        </div>
      </div> -->
      <div class="md:w-[108px] flex flex-col items-start justify-start">
        <div
          class="self-stretch bg-base-white box-border h-11 flex flex-row items-center justify-start py-3 px-6 text-xs border-b-[1px] border-solid border-gray-2001">
          <div class="flex flex-row items-center justify-start">
            <div class="relative leading-[18px]">Amount</div>
          </div>
        </div>
        <div
          class="self-stretch box-border h-[52px] flex flex-row items-center justify-start py-4 px-6 border-b-[1px] border-solid border-gray-2001"
          *ngFor="let userWallet of userWalletHistory">
          <div class="relative leading-[20px]">
            {{ userWallet.amount | number :'1.2-2' }}{{ userWallet.currency }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>