import { Component, OnInit } from '@angular/core';
import { AddressesService } from './services/addresses.service';
import { LoaderService } from '@app/core/services/loader.service';
import { User } from '@app/core/models/user.model';
import { AuthService } from '@app/core/services/auth.service';
import { NotificationService } from '@app/core/services/notification.service';
import { environment } from '@environments/environment';
import { ConfirmationPopupComponent } from '../../shared/components/confirmation-popup/confirmation-popup.component';
import { ModalComponent } from '../../shared/components/modal/modal.component';
import { AddLocationComponent } from '../../shared/components/add-location/add-location.component';
import { DrawerComponent } from '../../shared/drawer/drawer.component';
import { NgFor, NgIf } from '@angular/common';
import sortBy from 'lodash/sortBy';
import cloneDeep from 'lodash/cloneDeep';
import { GenericService } from '@app/core/services/generics.service';


@Component({
    selector: 'app-addresses',
    templateUrl: './addresses.component.html',
    styleUrl: './addresses.component.scss',
    standalone: true,
    imports: [
        NgFor,
        NgIf,
        DrawerComponent,
        AddLocationComponent,
        ModalComponent,
        ConfirmationPopupComponent,
    ],
})
export class AddressesComponent implements OnInit {
  isDrawerOpen: boolean = false;
  isLocationOpen: boolean = false;
  isModalOpen: boolean = false;
  isConfirmationOpen: boolean = false;
  selectedAddress;
  confirmationTitle = '';
  addresses = [];
  user: User;
  constructor(
    private addressesService: AddressesService,
    private loaderService: LoaderService,
    private authService: AuthService,
    private notification: NotificationService,
    private genericService: GenericService
  ) {
    this.authService.currentUser.subscribe((data) => {
      this.user = data;
    });
  }
  ngOnInit() {
    this.genericService.setPageTitleAndDescription('addresses', '')
    this.getUserAddresses();
  }
  reset() {
    this.isDrawerOpen = false;
  }

  closeLocation($event) {
    this.isDrawerOpen = false;
    this.isLocationOpen = $event;
  }
  openDrawer() {
    this.isDrawerOpen = true;
    this.isLocationOpen = true;
  }
  resetModal() {
    this.isModalOpen = false;
  }
  closeConfrimationPopUp($event) {
    this.isModalOpen = false;
    this.isConfirmationOpen = $event;
  }
  openModal() {
    this.isModalOpen = true;
    this.isConfirmationOpen = true;
  }
  getUserAddresses() {
    this.loaderService.open();
    this.addressesService.getUserAddresses(this.user?.uid).subscribe(
      (result) => {
        this.addresses = result;
        this.addresses = sortBy(this.addresses, (o) => o.name);
        this.loaderService.close();
      },
      (err) => {
        this.loaderService.close();
      }
    );
  }
  newAddress() {
    this.selectedAddress = null;
    this.openDrawer();
  }
  editAddress(address) {
    this.selectedAddress = null;
    this.selectedAddress = cloneDeep(address);
    this.openDrawer();
  }
  saveLocation(location) {
    const params = {
      address: {
        locality: location.locality,
        state: '',
        uid: this.user.uid,
        version: '3.1.9',
        street: location.street,
        building: location.building,
        locale: 'es',
        os: 'web',
        type: '',
        appName: environment.eddressAppConfig.appName,
        coordinates: location.coordinates,
        city: '',
        phoneCountryCode: '0',
        idUser: this.user.idUser,
        phoneCountryId: '0',
        notes: '',
        name: location.name,
        creationLat: location.coordinates.lat,
        creationLon: location.coordinates?.lon,
        eddressCode: this.selectedAddress?.eddressCode,
        isDefault: this.selectedAddress?.isDefault,
      },
    };
    this.loaderService.open();
    this.addressesService.saveUserAddress(params).subscribe(
      (result) => {
        this.loaderService.close();
        if (this.selectedAddress) {
          this.notification.successNotification('Address updated successfully');
        } else {
          this.notification.successNotification('Address saved successfully');
        }

        this.closeLocation(true);
        this.getUserAddresses();
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      },
      (err) => {
        this.loaderService.close();
      }
    );
  }
  openDeleteAddressConfirmationModal(address) {
    this.selectedAddress = address;
    this.confirmationTitle = 'Are you sure you want to delete ' + address.name + ' address?';
    this.openModal();
  }
  deleteAddress() {
    const params = {
      locale: 'es',
      os: 'web',
      uid: this.selectedAddress.uid,
      idUser: this.user.idUser,
      code: this.selectedAddress.eddressCode,
      appName: environment.eddressAppConfig.appName,
      version: '3.1.9',
    };
    this.closeConfrimationPopUp(true);
    this.loaderService.open();
    this.addressesService.deleteUserAddress(params).subscribe(
      () => {
        this.loaderService.close();
        this.notification.successNotification('Address deleted successfully');
        this.getUserAddresses();
      },
      (err) => {
        this.loaderService.close();
      }
    );
  }
  makeDefault(address) {
    this.selectedAddress = address;
    this.selectedAddress.isDefault = true;
    this.saveLocation(address);
  }
}
