import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/services/api.service';
import { Urls } from '@app/core/util/urls';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(private api: ApiService) {}
  getProductDetail(params): Observable<any> {
    return this.api
      .get('api/market/app/store/' + params.storeId + '/product/' + params.slug)
      .pipe(map((data) => data));
  }
  getPublicProductDetail(params): Observable<any> {
    return this.api
      .get(
        'api/market/app/public/store/' + params.storeId + '/product/' + params.slug
      )
      .pipe(map((data) => data));
  }

  addToFavorites(params): Observable<any> {
    return this.api.post(Urls.ADD_TO_FAVORITES, params).pipe(map((data) => data));
  }
}
