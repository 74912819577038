import { Injectable, InjectionToken } from '@angular/core';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
export const apiBaseUrl = new InjectionToken<string>('apiBaseUrl');
import { map, distinctUntilChanged } from 'rxjs/operators';
import { ApiService } from '@core/services/api.service';
import { LocalStorageService } from './localstorage.service';
import { Urls } from '../util/urls';
import { AuthService } from './auth.service';
import cloneDeep from 'lodash/cloneDeep';
import { Meta, Title } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { AppState } from '@app/app.state';
import { selectPagesState } from '@app/store/selectors/pageTitles.selector';

@Injectable({
  providedIn: 'root',
})
export class GenericService {
  constructor(
    public api: ApiService,
    private localStorage: LocalStorageService,
    private authService: AuthService,
    private titleService: Title,
    private meta: Meta,
    private store: Store<AppState>
  ) {}
  public defaultLocations = new BehaviorSubject<any>([]);
  public homeStoresData = new BehaviorSubject<any>([]);
  private companyConfigurations = new BehaviorSubject<any>({});

  private companyConfigurationsSubject = new BehaviorSubject<any>(null);
  public configurations = this.companyConfigurationsSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  private marketAppSettingSubject = new BehaviorSubject<any>(null);
  public marketAppSetting = this.marketAppSettingSubject;

  private updateProductSubject = new BehaviorSubject<any>(null);
  public updateProductItem = this.updateProductSubject.asObservable().pipe(distinctUntilChanged());

  public searchString = new BehaviorSubject<any>('');
  public cartCount = new BehaviorSubject<any>('');

  setCompanyConfigurations(config) {
    this.companyConfigurationsSubject.next(config);
    this.setCompanyConfigurationsObject(config);
  }
  setCompanyConfigurationsObject(config) {
    this.localStorage.setItem('configurations', config);
  }
  getCompanyConfigurationsObject() {
    return this.localStorage.getItem('configurations');
  }
  removeCompanyConfigurations() {
    this.companyConfigurationsSubject.next(null);
  }
  populateCompanyConfigurations() {
    this.setCompanyConfigurations(this.getCompanyConfigurationsObject());
  }

  setMarketAppSetting(appSetting) {
    this.marketAppSettingSubject.next(appSetting);
    this.setMarketAppSettingObject(appSetting);
  }
  setMarketAppSettingObject(appSetting) {
    this.localStorage.setItem('marketAppSetting', appSetting);
  }
  getMarketAppSettingObject() {
    return this.localStorage.getItem('marketAppSetting');
  }
  removeMarketAppSetting() {
    this.marketAppSettingSubject.next(null);
  }
  populateMarketAppSetting() {
    this.setMarketAppSetting(this.getMarketAppSettingObject());
  }

  getConfigurations() {
    return this.companyConfigurations.asObservable();
  }
  setConfigurations(data: any) {
    this.companyConfigurations.next(data);
  }

  setCartCount(cartCount: Number) {
    this.cartCount.next(cartCount);
  }
  getCartCount() {
    return this.cartCount.asObservable();
  }

  setSearchString(searchString: string) {
    this.searchString.next(searchString);
  }
  getSearchString() {
    return this.searchString.asObservable();
  }

  getDefaultLocations() {
    return this.defaultLocations.asObservable();
  }
  setDefaultLocations(data: any) {
    this.defaultLocations.next(data);
  }
  setUpdateProductItem(product) {
    this.updateProductSubject.next(product);
  }

  public checkCredibility(params): Observable<any> {
    return this.api.post(Urls.CHECK_CREDIBILITY, params).pipe(
      map((data) => {
        return data;
      })
    );
  }
  public getMarketPlaceAppData(params): Observable<any> {
    return this.api.post(Urls.MARKETPLACE_APP_DATA, params).pipe(
      map((data) => {
        return data;
      })
    );
  }
  public getPublicStores(params): Observable<any> {
    return this.api.post(Urls.SERVICES_PUBLIC_STORES, params).pipe(
      map((data) => {
        return data;
      })
    );
  }
  public getPublicHomepages(storeId, params): Observable<any> {
    return this.api.post(Urls.SERVICES_PUBLIC + storeId + Urls.SERVICES_HOME_PAGES, params).pipe(
      map((data) => {
        return data;
      })
    );
  }
  public getPublicBanners(storeId, params): Observable<any> {
    return this.api.post(Urls.SERVICES_PUBLIC + storeId + Urls.SERVICES_BANNERS, params).pipe(
      map((data) => {
        return data;
      })
    );
  }
  public getStores(params): Observable<any> {
    return this.api.post(Urls.SERVICES_STORES, params).pipe(
      map((data) => {
        return data;
      })
    );
  }
  public getHomepages(storeId, params): Observable<any> {
    return this.api.post(Urls.SERVICES + storeId + Urls.SERVICES_HOME_PAGES, params).pipe(
      map((data) => {
        return data;
      })
    );
  }
  public getBanners(storeId, params): Observable<any> {
    return this.api.post(Urls.SERVICES + storeId + Urls.SERVICES_BANNERS, params).pipe(
      map((data) => {
        return data;
      })
    );
  }
  public manageBasket(params): Observable<any> {
    params.quantity = params.quantity === 0 ? -1 : params.quantity;
    return this.api.post(Urls.MANAGE_BASKET, params).pipe(
      map((data) => {
        this.getBasket().subscribe();
        return data;
      })
    );
  }
  public clearBasket(): Observable<any> {
    return this.api.delete(Urls.CLEAR_BASKET).pipe(
      map((data) => {
        this.getBasket().subscribe();
        return data;
      })
    );
  }
  public getBasket(): Observable<any> {
    return this.api.get(Urls.VIEW_BASKET).pipe(
      map((data) => {
        this.setCartCount(data?.data?.quantity);
        return data;
      })
    );
  }
  calculateBasket(params) {
    return this.api.post(Urls.CALCULATE_BASKET, params).pipe(map((data) => data));
  }
  public getServicesAPIData(params): Observable<any> {
    return this.api.post(Urls.SERVICES, params).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getPagesDetail(tenantUid): Observable<any> {
    return this.api.get(Urls.PAGES_DETAIL + tenantUid).pipe(map((data) => data));
  }
  async getPagesDetailAsPromise(tenantUid): Promise<any> {
    try {
      return await lastValueFrom(this.getPagesDetail(tenantUid));
    } catch (error) {
      console.error('Error fetching pages', error);
      throw error;
    }
  }
  updateHomepageProducts(product, page) {
    let subscribeCount = 0;
    this.authService.homePageData.subscribe((result) => {
      if (result) {
        result.homeSections.forEach((section) => {
          let selectedProductIndex = section.items.findIndex(
            (item) => item.itemId === product.itemId
          );
          if (selectedProductIndex !== -1) {
            section.items[selectedProductIndex] = product;
          }
        });

        let newResult;
        if (page === 'cart') {
          newResult = cloneDeep(result);
        } else {
          newResult = result;
        }
        subscribeCount += 1;
        if (subscribeCount <= 1) {
          this.setUpdateProductItem(product);
          this.authService.setHomePageData(newResult);
        }
      }
    });
  }
  hideBackdrop() {
    const backdrop = document.getElementsByClassName('back-drop');
    for (var i = 0; i < backdrop.length; i++) {
      const el: any = backdrop[i];
      el.style.display = 'none';
    }
  }

  getCompanyInfo(appName): Observable<any[]> {
    return this.api.get(Urls.COMPANY_SETTINGS + appName).pipe(
      map((data) => {
        return data;
      })
    );
  }
  public contactUs(operationUid, params): Observable<any> {
    return this.api.post(`api/market/app/public/website/${operationUid}/contactus`, params).pipe(
      map((data) => {
        return data;
      })
    );
  }

  checkCanonicalTag(): void {
    const canonicalTags = document.querySelectorAll('link[rel="canonical"]');

    let tagExists = false;

    canonicalTags.forEach((tag) => {
      if (tag.getAttribute('href') === window.location.href) {
        tagExists = true;
      }
    });

    if (!tagExists) {
      this.addCanonicalTag(window.location.href);
    }
  }

  addCanonicalTag(href: string): void {
    const link: HTMLLinkElement = document.createElement('link');
    link.setAttribute('rel', 'canonical');
    link.setAttribute('href', href);
    document.head.appendChild(link);
  }

  setPageTitleAndDescription(pagepath, prefix): void {
    this.store.select(selectPagesState).subscribe((data) => {
      const pageTitleList = data.pageData;
      const page = pageTitleList.find((p) => p.path === pagepath);
      if (page) {
        if (page.title)
          prefix
            ? this.titleService.setTitle(`${prefix} | ${page.title}`)
            : this.titleService.setTitle(page.title);
        if (page.description) {
          // Check if meta description exists
          const metaDescription = this.meta.getTag('name="description"');
          if (metaDescription) {
            this.meta.updateTag({ name: 'description', content: page.description });
          } else {
            this.meta.addTag({ name: 'description', content: page.description });
          }
        } else {
          const metaDescription = this.meta.getTag('name="description"');
          if (metaDescription) {
            metaDescription.remove();
          }
        }
      }
    });
  }
  setPageMetaDescription(description) {
    if (description) {
      const metaDescription = this.meta.getTag('name="description"');
      if (metaDescription) {
        this.meta.updateTag({ name: 'description', content: description });
      } else {
        this.meta.addTag({ name: 'description', content: description });
      }
    } else {
      const metaDescription = this.meta.getTag('name="description"');
      if (metaDescription) {
        metaDescription.remove();
      }
    }
  }
  setPageMetaKeywords(keywords) {
    if (keywords) {
      const metaKeywords = this.meta.getTag('name="keywords"');
      if (metaKeywords) {
        this.meta.updateTag({ name: 'keywords', content: keywords });
      } else {
        this.meta.addTag({ name: 'keywords', content: keywords });
      }
    } else {
      const metaKeywords = this.meta.getTag('name="keywords"');
      if (metaKeywords) {
        metaKeywords.remove();
      }
    }
  }
}
