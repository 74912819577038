@if (homePageBannerState$ | async; as homePageBannerState) {
  <app-landing-slider *ngIf="homePageBannerState.banners.length > 0" [banners]="homePageBannerState.banners" />
}


<ng-container *ngFor="let section of homeSections">
  <app-landing-best-sellers *ngIf="section.type === 'PRODUCTS'" [title]="section?.label" [items]="section.items"
    (openAddressDrawer)="openDrawer('addresses')" (openLoginDrawer)="openDrawer('login')" />
  <app-landing-categories *ngIf="section.type === 'BLOCKS' && section.isCollectionSection"
    [sectionName]="section?.label" [categories]="section.items" />
  <app-landing-categories *ngIf="section.type === 'COLLECTIONS'" [sectionName]="section?.label"
    [categories]="section.items" />
</ng-container>

<app-newsletter />

<app-drawer [drawerId]="'landingPageDrawer'" [isOpen]="isDrawerOpen" (reset)="reset()">
  <app-add-location *ngIf="isLocationOpen" (closeDrawer)="closeLocation($event)" (onSubmit)="saveLocation($event)" />
  <app-address-list *ngIf="isAddressesOpen" [eddressCode]="deliveryAddress?.eddressCode"
    (closeDrawer)="closeAddresses($event)" (newLocation)="openDrawer('location')"
    (confirmLocation)="getDeliveryAddress($event)"></app-address-list>

  <app-login *ngIf="isLoginDrawerOpen" (isLoginOpen)="closeLogin($event)" (isSignupOpen)="openDrawer('signup')"
    (isForgotPasswordOpen)="openDrawer('forgotPassword')" />

  <app-signup *ngIf="isSignupDrawerOpen" (isSignupOpen)="closeSignup($event)" (isLoginOpen)="openDrawer('login')" />
  <app-forgot-password *ngIf="isForgotPasswordDrawerOpen" (isForgotPasswordOpen)="closeLogin($event)"
    (isLoginOpen)="openDrawer('login')" />
</app-drawer>