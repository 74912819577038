var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
import instances from '../../dom/instances';
var Default = {
  placement: 'left',
  bodyScrolling: false,
  backdrop: true,
  edge: false,
  edgeOffset: 'bottom-[60px]',
  backdropClasses: 'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-30',
  onShow: function () {},
  onHide: function () {},
  onToggle: function () {}
};
var DefaultInstanceOptions = {
  id: null,
  override: true
};
var Drawer = /** @class */function () {
  function Drawer(targetEl, options, instanceOptions) {
    if (targetEl === void 0) {
      targetEl = null;
    }
    if (options === void 0) {
      options = Default;
    }
    if (instanceOptions === void 0) {
      instanceOptions = DefaultInstanceOptions;
    }
    this._eventListenerInstances = [];
    this._instanceId = instanceOptions.id ? instanceOptions.id : targetEl.id;
    this._targetEl = targetEl;
    this._options = __assign(__assign({}, Default), options);
    this._visible = false;
    this._initialized = false;
    this.init();
    instances.addInstance('Drawer', this, this._instanceId, instanceOptions.override);
  }
  Drawer.prototype.init = function () {
    var _this = this;
    // set initial accessibility attributes
    if (this._targetEl && !this._initialized) {
      this._targetEl.setAttribute('aria-hidden', 'true');
      this._targetEl.classList.add('transition-transform');
      // set base placement classes
      this._getPlacementClasses(this._options.placement).base.map(function (c) {
        _this._targetEl.classList.add(c);
      });
      this._handleEscapeKey = function (event) {
        if (event.key === 'Escape') {
          // if 'Escape' key is pressed
          if (_this.isVisible()) {
            // if the Drawer is visible
            _this.hide(); // hide the Drawer
          }
        }
      };
      // add keyboard event listener to document
      document.addEventListener('keydown', this._handleEscapeKey);
      this._initialized = true;
    }
  };
  Drawer.prototype.destroy = function () {
    if (this._initialized) {
      this.removeAllEventListenerInstances();
      this._destroyBackdropEl();
      // Remove the keyboard event listener
      document.removeEventListener('keydown', this._handleEscapeKey);
      this._initialized = false;
    }
  };
  Drawer.prototype.removeInstance = function () {
    instances.removeInstance('Drawer', this._instanceId);
  };
  Drawer.prototype.destroyAndRemoveInstance = function () {
    this.destroy();
    this.removeInstance();
  };
  Drawer.prototype.hide = function () {
    var _this = this;
    // based on the edge option show placement classes
    if (this._options.edge) {
      this._getPlacementClasses(this._options.placement + '-edge').active.map(function (c) {
        _this._targetEl.classList.remove(c);
      });
      this._getPlacementClasses(this._options.placement + '-edge').inactive.map(function (c) {
        _this._targetEl.classList.add(c);
      });
    } else {
      this._getPlacementClasses(this._options.placement).active.map(function (c) {
        _this._targetEl.classList.remove(c);
      });
      this._getPlacementClasses(this._options.placement).inactive.map(function (c) {
        _this._targetEl.classList.add(c);
      });
    }
    // set accessibility attributes
    this._targetEl.setAttribute('aria-hidden', 'true');
    this._targetEl.removeAttribute('aria-modal');
    this._targetEl.removeAttribute('role');
    // enable body scroll
    if (!this._options.bodyScrolling) {
      document.body.classList.remove('overflow-hidden');
    }
    // destroy backdrop
    if (this._options.backdrop) {
      this._destroyBackdropEl();
    }
    this._visible = false;
    // callback function
    this._options.onHide(this);
  };
  Drawer.prototype.show = function () {
    var _this = this;
    if (this._options.edge) {
      this._getPlacementClasses(this._options.placement + '-edge').active.map(function (c) {
        _this._targetEl.classList.add(c);
      });
      this._getPlacementClasses(this._options.placement + '-edge').inactive.map(function (c) {
        _this._targetEl.classList.remove(c);
      });
    } else {
      this._getPlacementClasses(this._options.placement).active.map(function (c) {
        _this._targetEl.classList.add(c);
      });
      this._getPlacementClasses(this._options.placement).inactive.map(function (c) {
        _this._targetEl.classList.remove(c);
      });
    }
    // set accessibility attributes
    this._targetEl.setAttribute('aria-modal', 'true');
    this._targetEl.setAttribute('role', 'dialog');
    this._targetEl.removeAttribute('aria-hidden');
    // disable body scroll
    if (!this._options.bodyScrolling) {
      document.body.classList.add('overflow-hidden');
    }
    // show backdrop
    if (this._options.backdrop) {
      this._createBackdrop();
    }
    this._visible = true;
    // callback function
    this._options.onShow(this);
  };
  Drawer.prototype.toggle = function () {
    if (this.isVisible()) {
      this.hide();
    } else {
      this.show();
    }
  };
  Drawer.prototype._createBackdrop = function () {
    var _a;
    var _this = this;
    if (!this._visible) {
      var backdropEl = document.createElement('div');
      backdropEl.setAttribute('drawer-backdrop', '');
      (_a = backdropEl.classList).add.apply(_a, this._options.backdropClasses.split(' '));
      document.querySelector('body').append(backdropEl);
      backdropEl.addEventListener('click', function () {
        _this.hide();
      });
    }
  };
  Drawer.prototype._destroyBackdropEl = function () {
    if (this._visible) {
      document.querySelector('[drawer-backdrop]').remove();
    }
  };
  Drawer.prototype._getPlacementClasses = function (placement) {
    switch (placement) {
      case 'top':
        return {
          base: ['top-0', 'left-0', 'right-0'],
          active: ['transform-none'],
          inactive: ['-translate-y-full']
        };
      case 'right':
        return {
          base: ['right-0', 'top-0'],
          active: ['transform-none'],
          inactive: ['translate-x-full']
        };
      case 'bottom':
        return {
          base: ['bottom-0', 'left-0', 'right-0'],
          active: ['transform-none'],
          inactive: ['translate-y-full']
        };
      case 'left':
        return {
          base: ['left-0', 'top-0'],
          active: ['transform-none'],
          inactive: ['-translate-x-full']
        };
      case 'bottom-edge':
        return {
          base: ['left-0', 'top-0'],
          active: ['transform-none'],
          inactive: ['translate-y-full', this._options.edgeOffset]
        };
      default:
        return {
          base: ['left-0', 'top-0'],
          active: ['transform-none'],
          inactive: ['-translate-x-full']
        };
    }
  };
  Drawer.prototype.isHidden = function () {
    return !this._visible;
  };
  Drawer.prototype.isVisible = function () {
    return this._visible;
  };
  Drawer.prototype.addEventListenerInstance = function (element, type, handler) {
    this._eventListenerInstances.push({
      element: element,
      type: type,
      handler: handler
    });
  };
  Drawer.prototype.removeAllEventListenerInstances = function () {
    this._eventListenerInstances.map(function (eventListenerInstance) {
      eventListenerInstance.element.removeEventListener(eventListenerInstance.type, eventListenerInstance.handler);
    });
    this._eventListenerInstances = [];
  };
  Drawer.prototype.getAllEventListenerInstances = function () {
    return this._eventListenerInstances;
  };
  return Drawer;
}();
export function initDrawers() {
  document.querySelectorAll('[data-drawer-target]').forEach(function ($triggerEl) {
    // mandatory
    var drawerId = $triggerEl.getAttribute('data-drawer-target');
    var $drawerEl = document.getElementById(drawerId);
    if ($drawerEl) {
      var placement = $triggerEl.getAttribute('data-drawer-placement');
      var bodyScrolling = $triggerEl.getAttribute('data-drawer-body-scrolling');
      var backdrop = $triggerEl.getAttribute('data-drawer-backdrop');
      var edge = $triggerEl.getAttribute('data-drawer-edge');
      var edgeOffset = $triggerEl.getAttribute('data-drawer-edge-offset');
      new Drawer($drawerEl, {
        placement: placement ? placement : Default.placement,
        bodyScrolling: bodyScrolling ? bodyScrolling === 'true' ? true : false : Default.bodyScrolling,
        backdrop: backdrop ? backdrop === 'true' ? true : false : Default.backdrop,
        edge: edge ? edge === 'true' ? true : false : Default.edge,
        edgeOffset: edgeOffset ? edgeOffset : Default.edgeOffset
      });
    } else {
      console.error("Drawer with id ".concat(drawerId, " not found. Are you sure that the data-drawer-target attribute points to the correct drawer id?"));
    }
  });
  document.querySelectorAll('[data-drawer-toggle]').forEach(function ($triggerEl) {
    var drawerId = $triggerEl.getAttribute('data-drawer-toggle');
    var $drawerEl = document.getElementById(drawerId);
    if ($drawerEl) {
      var drawer_1 = instances.getInstance('Drawer', drawerId);
      if (drawer_1) {
        var toggleDrawer = function () {
          drawer_1.toggle();
        };
        $triggerEl.addEventListener('click', toggleDrawer);
        drawer_1.addEventListenerInstance($triggerEl, 'click', toggleDrawer);
      } else {
        console.error("Drawer with id ".concat(drawerId, " has not been initialized. Please initialize it using the data-drawer-target attribute."));
      }
    } else {
      console.error("Drawer with id ".concat(drawerId, " not found. Are you sure that the data-drawer-target attribute points to the correct drawer id?"));
    }
  });
  document.querySelectorAll('[data-drawer-dismiss], [data-drawer-hide]').forEach(function ($triggerEl) {
    var drawerId = $triggerEl.getAttribute('data-drawer-dismiss') ? $triggerEl.getAttribute('data-drawer-dismiss') : $triggerEl.getAttribute('data-drawer-hide');
    var $drawerEl = document.getElementById(drawerId);
    if ($drawerEl) {
      var drawer_2 = instances.getInstance('Drawer', drawerId);
      if (drawer_2) {
        var hideDrawer = function () {
          drawer_2.hide();
        };
        $triggerEl.addEventListener('click', hideDrawer);
        drawer_2.addEventListenerInstance($triggerEl, 'click', hideDrawer);
      } else {
        console.error("Drawer with id ".concat(drawerId, " has not been initialized. Please initialize it using the data-drawer-target attribute."));
      }
    } else {
      console.error("Drawer with id ".concat(drawerId, " not found. Are you sure that the data-drawer-target attribute points to the correct drawer id"));
    }
  });
  document.querySelectorAll('[data-drawer-show]').forEach(function ($triggerEl) {
    var drawerId = $triggerEl.getAttribute('data-drawer-show');
    var $drawerEl = document.getElementById(drawerId);
    if ($drawerEl) {
      var drawer_3 = instances.getInstance('Drawer', drawerId);
      if (drawer_3) {
        var showDrawer = function () {
          drawer_3.show();
        };
        $triggerEl.addEventListener('click', showDrawer);
        drawer_3.addEventListenerInstance($triggerEl, 'click', showDrawer);
      } else {
        console.error("Drawer with id ".concat(drawerId, " has not been initialized. Please initialize it using the data-drawer-target attribute."));
      }
    } else {
      console.error("Drawer with id ".concat(drawerId, " not found. Are you sure that the data-drawer-target attribute points to the correct drawer id?"));
    }
  });
}
if (typeof window !== 'undefined') {
  window.Drawer = Drawer;
  window.initDrawers = initDrawers;
}
export default Drawer;
