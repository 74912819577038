import { NgIf } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { GenericService } from '@app/core/services/generics.service';
import { NotificationService } from '@app/core/services/notification.service';
import { environment } from '@environments/environment';
import { RecaptchaModule, RecaptchaComponent, RecaptchaFormsModule  } from 'ng-recaptcha';

@Component({
  selector: 'app-contact-us',
  standalone: true,
  imports: [
    FormsModule,
    NgIf,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule
  ],
  templateUrl: './contact-us.component.html',
  styleUrl: './contact-us.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class ContactUsComponent implements OnInit {
  contactFormGroup: FormGroup;
  contactList = [];
  isSubmitted = false;
  fillColor;
  recaptchaSiteKey = environment.recaptcha.siteKey
  constructor(
    private formBuilder: FormBuilder,
    private genericService: GenericService,
    private notification: NotificationService,
  ) {}
  ngOnInit() {
    const rootStyles = getComputedStyle(document.documentElement);
    this.fillColor = rootStyles.getPropertyValue('--brand-700').trim();
    this._initLocationForm()
    if(environment.eddressAppConfig.appName === 'afc') {
      this.contactList = [
        {
          branchName: 'Jeddah Branch',
          address: 'Sondus Building, 1st Floor,Hail Street,419 Jeddah 21411, Saudi Arabia',
          tel: '+966-11 / 834-3444',
          mobile: '+966-540-536-723',
          email: 'info@arabianfoodcorp.com' 
        },
        {
          branchName: 'Riyadh Branch',
          address: 'Noora Al Sheikh Building, Office #15, Maahad Al Idara Street, Riyadh, Saudi Arabia ',
          tel: '+966-11 / 834-3444',
          mobile: '+966-540-536-723',
          email: 'info@arabianfoodcorp.com' 
        },
        {
          branchName: 'Dammam Branch',
          address: 'Noora Al Sheikh Building, Office #15, Maahad Al Idara Street, Riyadh, Saudi Arabia ',
          tel: '+966-11 / 834-3444',
          mobile: '+966-540-536-723',
          email: 'info@arabianfoodcorp.com' 
        }
      ]
    }
    // if(environment.eddressAppConfig.appName === 'pronto') {
    //   this.contactList = [
    //     {
    //       branchName: 'Main Branch',
    //       address: '',
    //       tel: ' (441) 621-1111',
    //       mobile: ' (441) 621-1111',
    //       email: 'info@pronto.bm' 
    //     }
    //   ]
    // }
  }
  _initLocationForm() {
    this.contactFormGroup = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: [''],
      email: ['', [Validators.required, Validators.email]],
      body: ['', Validators.required],
      googleCaptchaToken: ['', Validators.required]
    });
  }
  onSubmitFn() {
    this.isSubmitted = true;
    const formValues = this.contactFormGroup.value;

    if (this.contactFormGroup.valid) {
      this.genericService.contactUs(environment.eddressAppConfig.operationUid, formValues).subscribe(() => {
        this.notification.successNotification('Thank you for your message. It has been sent.');
        this.isSubmitted = false;
        this.contactFormGroup.reset();
      })
    } else {
      this.isSubmitted = false;
      this.contactFormGroup.markAllAsTouched();
    }
  }
  get contactForm() {
    return this.contactFormGroup.controls;
  }
}
