// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  eddressAppConfig: {
    apiBaseUrl: 'https://prod-api.eddress.co/',
    favIcon: 'assets/images/companies-favIcons/pronto-favicon.png',
    companyTitle: 'Pronto Website',
    homePageTitle: 'Online Grocery Shopping Made Easy',
    appName: 'pronto',
    operationUid: 'pronto',
    countryCode: '+1441',
    pushToken:
      'BA9MuIuuQGj4aX6PUt8DPp6lY1DuuHUWqPE4jR7cgC5Ooc-5-HUotVx0E3ftQHOqKUsBqojCyuTfDSF_PZxPYBI',
    pushTokenProjectId: 'pronto-83d5a',
    stripePublicKey:
      'pk_test_51LojQzAioBvl7Vd8Mgx7GgYMqdt2x0sE7O2M5QrSCjXROn7NnSvSLZzLlx4FOEEX9smd0nGlAhlELo3cyfrpLcFZ00rbJZS8hG',
    appUrls: {
      ios: 'https://apps.apple.com/us/app/pronto-shopping/id1621448175',
      android: 'https://play.google.com/store/apps/details?id=bm.pronto',
    },
    paymentGateway: 'CARDKNOX',
  },
  firebaseConfig: {
    apiKey: 'AIzaSyCZnVh_vCm1y6dxpRB8J4lLAEYALXgHaEs',
    authDomain: 'pronto-83d5a.firebaseapp.com',
    projectId: 'pronto-83d5a',
    storageBucket: 'pronto-83d5a.appspot.com',
    messagingSenderId: '395045398198',
    appId: '1:395045398198:web:914957fdb522176e24e817',
    measurementId: 'G-828J4LN6JQ',
  },
  recaptcha: {
    siteKey: '6Lf-4XcqAAAAAGfPVVT132iS-3cIz_Sabxi804G_',
  },
};
