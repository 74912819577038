<div
  class="self-stretch bg-gray-50 flex flex-row items-center justify-start py-8 px-6 md:px-16 gap-[10px] z-[5] text-11xl">
  <h1 class="flex-1 relative leading-[38px] font-bold">{{sectionName}}</h1>
</div>
<div *ngIf="categories?.length > 0"
  class="self-stretch bg-gray-50 items-center justify-center  px-6 py-8 md:px-16 gap-[32px_32px] cursor-pointer grid grid-cols-2 sm:grid-cols-4 md:grid-cols-4 lg:grid-cols-6"
  id="frameContainerCategory2">
  <ng-container *ngFor="let category of categories">
    <div class="rounded-lg bg-base-white box-border shrink-0 pt-0 px-0 pb-3 z-[0] border-[1px] border-solid
      border-gray-2001 duration-700 ease-in-out overflow-hidden min-h-[230px]">
      <div class="flex items-center justify-center">
        <div
          class="self-stretch w-52 h-52 overflow-hidden rounded-3xs p-[30px] gap-[0px_10px] bg-cover bg-no-repeat bg-[top]">
          <img loading="lazy" (click)="onCategoryClick(category)"
            class="w-full h-full object-cover relative overflow-hidden shrink-0" width="640" height="640"
            [src]="category.imageUrl ? category?.imageUrl : 'assets/images/home/no-image-icon.png'"
            alt="category image" />
        </div>
      </div>
      <h3 (click)="onCategoryClick(category)"
        class="self-stretch p-[20px] pt-0 flex flex-col items-center justify-center md:leading-[28px] text-lg">
        {{ category?.label }}
      </h3>
    </div>
  </ng-container>
</div>