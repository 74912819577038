var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
import instances from '../../dom/instances';
var Default = {
  minValue: null,
  maxValue: null,
  onIncrement: function () {},
  onDecrement: function () {}
};
var DefaultInstanceOptions = {
  id: null,
  override: true
};
var InputCounter = /** @class */function () {
  function InputCounter(targetEl, incrementEl, decrementEl, options, instanceOptions) {
    if (targetEl === void 0) {
      targetEl = null;
    }
    if (incrementEl === void 0) {
      incrementEl = null;
    }
    if (decrementEl === void 0) {
      decrementEl = null;
    }
    if (options === void 0) {
      options = Default;
    }
    if (instanceOptions === void 0) {
      instanceOptions = DefaultInstanceOptions;
    }
    this._instanceId = instanceOptions.id ? instanceOptions.id : targetEl.id;
    this._targetEl = targetEl;
    this._incrementEl = incrementEl;
    this._decrementEl = decrementEl;
    this._options = __assign(__assign({}, Default), options);
    this._initialized = false;
    this.init();
    instances.addInstance('InputCounter', this, this._instanceId, instanceOptions.override);
  }
  InputCounter.prototype.init = function () {
    var _this = this;
    if (this._targetEl && !this._initialized) {
      this._inputHandler = function (event) {
        {
          var target = event.target;
          // check if the value is numeric
          if (!/^\d*$/.test(target.value)) {
            // Regex to check if the value is numeric
            target.value = target.value.replace(/[^\d]/g, ''); // Remove non-numeric characters
          }
          // check for max value
          if (_this._options.maxValue !== null && parseInt(target.value) > _this._options.maxValue) {
            target.value = _this._options.maxValue.toString();
          }
          // check for min value
          if (_this._options.minValue !== null && parseInt(target.value) < _this._options.minValue) {
            target.value = _this._options.minValue.toString();
          }
        }
      };
      this._incrementClickHandler = function () {
        _this.increment();
      };
      this._decrementClickHandler = function () {
        _this.decrement();
      };
      // Add event listener to restrict input to numeric values only
      this._targetEl.addEventListener('input', this._inputHandler);
      if (this._incrementEl) {
        this._incrementEl.addEventListener('click', this._incrementClickHandler);
      }
      if (this._decrementEl) {
        this._decrementEl.addEventListener('click', this._decrementClickHandler);
      }
      this._initialized = true;
    }
  };
  InputCounter.prototype.destroy = function () {
    if (this._targetEl && this._initialized) {
      this._targetEl.removeEventListener('input', this._inputHandler);
      if (this._incrementEl) {
        this._incrementEl.removeEventListener('click', this._incrementClickHandler);
      }
      if (this._decrementEl) {
        this._decrementEl.removeEventListener('click', this._decrementClickHandler);
      }
      this._initialized = false;
    }
  };
  InputCounter.prototype.removeInstance = function () {
    instances.removeInstance('InputCounter', this._instanceId);
  };
  InputCounter.prototype.destroyAndRemoveInstance = function () {
    this.destroy();
    this.removeInstance();
  };
  InputCounter.prototype.getCurrentValue = function () {
    return parseInt(this._targetEl.value) || 0;
  };
  InputCounter.prototype.increment = function () {
    // don't increment if the value is already at the maximum value
    if (this._options.maxValue !== null && this.getCurrentValue() >= this._options.maxValue) {
      return;
    }
    this._targetEl.value = (this.getCurrentValue() + 1).toString();
    this._options.onIncrement(this);
  };
  InputCounter.prototype.decrement = function () {
    // don't decrement if the value is already at the minimum value
    if (this._options.minValue !== null && this.getCurrentValue() <= this._options.minValue) {
      return;
    }
    this._targetEl.value = (this.getCurrentValue() - 1).toString();
    this._options.onDecrement(this);
  };
  return InputCounter;
}();
export function initInputCounters() {
  document.querySelectorAll('[data-input-counter]').forEach(function ($targetEl) {
    var targetId = $targetEl.id;
    var $incrementEl = document.querySelector('[data-input-counter-increment="' + targetId + '"]');
    var $decrementEl = document.querySelector('[data-input-counter-decrement="' + targetId + '"]');
    var minValue = $targetEl.getAttribute('data-input-counter-min');
    var maxValue = $targetEl.getAttribute('data-input-counter-max');
    // check if the target element exists
    if ($targetEl) {
      if (!instances.instanceExists('InputCounter', $targetEl.getAttribute('id'))) {
        new InputCounter($targetEl, $incrementEl ? $incrementEl : null, $decrementEl ? $decrementEl : null, {
          minValue: minValue ? parseInt(minValue) : null,
          maxValue: maxValue ? parseInt(maxValue) : null
        });
      }
    } else {
      console.error("The target element with id \"".concat(targetId, "\" does not exist. Please check the data-input-counter attribute."));
    }
  });
}
if (typeof window !== 'undefined') {
  window.InputCounter = InputCounter;
  window.initInputCounters = initInputCounters;
}
export default InputCounter;
