import { LabelType, NgxSliderModule } from '@angular-slider/ngx-slider';
import { Options } from '@angular-slider/ngx-slider/options';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';

import { Dropdown } from 'flowbite';
import type { DropdownOptions, DropdownInterface } from 'flowbite';
import type { InstanceOptions } from 'flowbite';
import { FormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-range-filter',
    templateUrl: './range-filter.component.html',
    styleUrl: './range-filter.component.scss',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        NgIf,
        NgxSliderModule,
        FormsModule,
    ],
})
export class RangeFilterComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() label;
  @Input() menuId;
  @Input() buttonId;
  @Input() currencySymbol = '';
  @Output() applyPriceFilter = new EventEmitter<any>();
  minValue: number = 1;
  maxValue: number = 100000;
  minPrice = 0;
  maxPrice = 100000;
  rangeOptions: Options;

  selectedFilters = [];
  disselectedFilters = [];

  previousValue = '';

  dropdown: DropdownInterface;

  options: DropdownOptions = {
    placement: 'bottom',
    triggerType: 'click',
    offsetSkidding: 0,
    offsetDistance: 10,
    delay: 300,
    onHide: () => {
      console.log('dropdown has been hidden');
    },
    onShow: () => {
      console.log('dropdown has been shown');
    },
    onToggle: () => {
      console.log('dropdown has been toggled');
    },
  };

  instanceOptions: InstanceOptions = {
    id: '',
    override: true,
  };

  ngOnInit() {
    if (!this.currencySymbol) {
      this.currencySymbol = '$';
    }
    this.rangeOptions = {
      floor: 0,
      ceil: 100000,
      minLimit: 1,
      translate: (value: number, label: LabelType): string => {
        switch (label) {
          case LabelType.Low:
            return this.currencySymbol + value;
          case LabelType.High:
            return this.currencySymbol + value;
          default:
            return this.currencySymbol + value;
        }
      },
    };
  }

  ngOnChanges(changes: SimpleChanges) {}

  ngAfterViewInit() {
    this.instanceOptions.id = this.menuId;

    const $targetEl: HTMLElement = document.getElementById(this.menuId);
    const $triggerEl: HTMLElement = document.getElementById(this.buttonId);

    this.dropdown = new Dropdown($targetEl, $triggerEl, this.options, this.instanceOptions);
  }

  applyPriceFilterFn() {
    const range = {
      minvalue: this.minValue,
      maxvalue: this.maxValue,
    };
    this.dropdown.hide();
    this.applyPriceFilter.emit(range);
  }
  validatePositiveNumber() {
    if (this.minValue < 1) {
      this.minValue = 1;
    }
    if (this.maxValue < 1) {
      this.maxValue = 1;
    }
  }
  setPriceValues() {
    this.minPrice = this.minValue;
    this.maxPrice = this.maxValue;
  }
  setRangeValues() {
    if (this.minPrice < this.maxPrice) {
      this.minValue = this.minPrice;
      this.maxValue = this.maxPrice;
    }
  }
}
