import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/services/api.service';
import { Urls } from '@app/core/util/urls';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CheckoutService {
  constructor(private api: ApiService) {}
  placeOrder(params): Observable<any> {
    return this.api.post(Urls.PLACE_ORDER_NEW, params).pipe(map((data) => data));
  }
  validatePromoCode(params): Observable<any> {
    return this.api.post(Urls.VALIDATE_PROMO_CODE, params).pipe(map((data) => data));
  }
  checkPermanentPromo(params): Observable<any> {
    return this.api.post(Urls.CHECK_PERMANENT_PROMO, params).pipe(map((data) => data));
  }
  paymentSession(gateway, params): Observable<any> {
    return this.api.get(Urls.PAYMENT_SESSION + gateway, params).pipe(map((data) => data));
  }
  getSchedule(storeId, params): Observable<any> {
    return this.api.post(Urls.SCHEDULES + storeId, params).pipe(map((data) => data));
  }
}
