import { Banner } from "@app/core/models/banner.model";

export interface BannerState {
    banners: Banner[];
    isLoaded: boolean;
  }

export const initialBannerState: BannerState = {
    banners: [],
    isLoaded: false,
  };
  