import { Injectable } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Regex } from "@shared/enums/regex.enum"

@Injectable({
  providedIn: "root"
})
export class ValidatorService {
  public static getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
    let config = {
      'required': 'This field is required',
      'invalidEmailAddress': 'Invalid email address',
      'minlength': `Minimum length ${validatorValue.requiredLength}`
    };

    return config[validatorName];
  }
  public static passwordValidator(control: FormControl): { [key: string]: any } | null {
    const passwordRegexp = new RegExp(Regex.PASSWORD_PATTERN);

    if (control.value && !passwordRegexp.test(control.value)) {
      return { invalidPassword: true };
    }
    return null;
  }

  public static passwordValidatorSpecialCharacters(control: FormControl): { [key: string]: any } | null {
    const passwordRegexp = new RegExp(Regex.PASSWORD_SPECIAL_CHARACTERS);

    if (control.value && !passwordRegexp.test(control.value)) {
      return { invalidSpecialCharacters: true };
    }
    return null;
  }

  public emailValidator(control: FormControl): { [key: string]: any } | null {
    const emailRegexp = new RegExp(Regex.EMAIL_PATTERN);

    if (control.value && !emailRegexp.test(control.value)) {
      return { invalidEmail: true };
    }
    return null;
  }

  public static matchWithValidator(toControlName: string): { [key: string]: any } {
    let ctrl: FormControl;
    let toCtrl: FormControl;
    return function matchWith(control: FormControl): { [key: string]: any } | null {
      if (!control.parent) {
        return null;
      }

      if (!ctrl) {
        ctrl = control;
        toCtrl = control.parent.get(toControlName) as FormControl;

        if (!toCtrl) {
          return null;
        }

        toCtrl.valueChanges.subscribe(() => {
          ctrl.updateValueAndValidity();
        });
      }

      if (ctrl.value !== "" && toCtrl.value !== ctrl.value) {
        return {
          matchWith: true
        };
      }
      return null;
    };
  }
}
