<div
    class="w-[400px] md:w-[485px] self-stretch flex-1 bg-white overflow-hidden flex flex-col items-center justify-start border-l-[1px] border-solid border-gray-200">
    <div
        class="self-stretch flex flex-row items-start justify-start py-0 px-0 relative gap-[0px_8px] text-xl text-gray-900">
        <div class="flex-1 flex flex-row items-start justify-start pt-6 px-0 pb-0 gap-[0px_16px] z-[0]">
            <img class="w-10 relative rounded-lg h-10" alt="feature icon" src="assets/images/home/featured-icon.svg" />

            <div class="flex-1 flex flex-col items-start justify-start gap-[4px_0px]">
                <div class="self-stretch relative leading-[30px] font-semibold">Payment details</div>
                <div class="self-stretch relative text-sm leading-[20px] text-gray-600">
                    Update your plan payment details.
                </div>
            </div>
        </div>
        <div (click)="close()"
            class="w-10 !m-[0] absolute top-[12px] right-[16px] rounded-lg h-10 overflow-hidden shrink-0 flex flex-row items-center justify-center p-2 box-border z-[1] cursor-pointer">
            <img class="w-5 relative h-5 overflow-hidden shrink-0" alt="close icon" src="assets/images/home/xclose.svg" />
        </div>
    </div>
    <div class="self-stretch flex-1 flex flex-col items-center justify-start py-0 px-0 gap-[24px_0px]">
        <div class="self-stretch flex flex-col items-start justify-start text-base text-white font-manrope">
            <div class="iframe-container w-[400px] md:w-[485px] h-[85vh]" >
                <iframe [src]="pageSrc | safe" id="embeddedFrame" width="100%" height="100%" loading="lazy"></iframe>
            </div>
            <div class="self-stretch relative bg-gray-200 h-px"></div>
        </div>
        <!-- <div class="self-stretch flex flex-col items-center justify-start border-t-[1px] border-solid border-gray-200">
            <div class="self-stretch flex flex-row items-center justify-end py-4 px-6">
                <div class="flex flex-row items-start justify-start gap-[0px_12px]">
                    <div (click)="close()"
                        class="rounded-lg cursor-pointer bg-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] overflow-hidden flex flex-row items-center justify-center py-2.5 px-3.5 gap-[0px_4px] border-[1px] border-solid border-gray-300">
                        <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />

                        <div class="flex flex-row items-center justify-center py-0 px-0.5">
                            <div class="relative leading-[20px] font-semibold">Cancel</div>
                        </div>
                        <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />
                    </div>
                    <div (click)="onSubmitFn()"
                        class="rounded-lg cursor-pointer bg-brand-600 shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] overflow-hidden flex flex-row items-center justify-center py-2.5 px-3.5 gap-[0px_4px] text-white border-[1px] border-solid border-brand-600">
                        <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />

                        <div class="flex flex-row items-center justify-center py-0 px-0.5">
                            <div class="relative leading-[20px] font-semibold">Confirm</div>
                        </div>
                        <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />
                    </div>
                </div>
            </div>
        </div> -->
    </div>