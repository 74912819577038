<div
  class="self-stretch flex flex-col items-center justify-start pt-12 px-0 pb-6 gap-[24px] text-sm text-gray-600"
>
  <div
    class="self-stretch py-0 px-4 md:px-16 gap-[64px] flex-row flex-wrap items-center justify-between md:hidden grid grid-cols-2 lg:grid-cols-4"
  >
    <div class="self-stretch">
      <div
        class="flex-1 flex flex-col justify-start gap-[24px] min-w-[320px] max-w-[320px]"
        *ngIf="companyLogo !== ''"
      >
        <!-- <img
          class="w-[220px] relative h-[80px]"
          alt="s"
          [src]="companyLogo ? companyLogo : 'assets/images/home/logo.svg'"
        /> -->
        <img class="w-[220px] relative h-[80px]" width="440" height="160" alt="company logo image" [src]="companyLogo" />

        <div class="self-stretch relative leading-[20px]" *ngIf="companyDescription !== ''">
          {{ companyDescription }}
        </div>
        <div
          *ngIf="appName !== 'afc'"
          class="flex flex-row justify-start gap-[12px] mix-blend-luminosity"
        >
          <img
            class="w-[46px] relative rounded-md h-8 object-cover"
            alt="card image"
            src="assets/images/home/payment-method-icon@2x.png"
          />

          <img
            class="w-[46px] relative rounded-md h-8 object-cover"
            alt="card image"
            src="assets/images/home/Mastercard.svg"
          />

          <img class="w-[46px] relative rounded h-8" alt="card image" src="assets/images/home/PayPal.svg" />

          <img
            class="w-[46px] relative rounded-md h-8 object-cover"
            alt="card image"
            src="assets/images/home/payment-method-icon-3@2x.png"
          />
        </div>
      </div>
    </div>
  </div>
  <div
    class="self-stretch py-0 px-4 md:px-16 gap-[64px] flex-row flex-wrap items-center justify-between grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4"
  >
    <div class="self-stretch hidden md:flex">
      <div class="flex-1 flex flex-col justify-start gap-[24px] min-w-[320px] max-w-[320px]">
        <img class="w-[182px] h-auto relative" width="182" height="52" alt="company logo image" loading="lazy" [src]="companyLogo ? companyLogo : defaultLogo" />

        <div class="self-stretch relative leading-[20px]">
          {{ companyDescription }}
        </div>
        <div
          *ngIf="appName !== 'afc'"
          class="flex flex-row justify-start gap-[12px] mix-blend-luminosity"
        >
          <img
            class="w-[46px] relative rounded-md h-8 object-cover"
            alt="card image"
            src="assets/images/home/payment-method-icon@2x.png"
          />

          <img
            class="w-[46px] relative rounded-md h-8 object-cover"
            alt="card image"
            src="assets/images/home/Mastercard.svg"
          />

          <img class="w-[46px] relative rounded h-8" alt="card image" src="assets/images/home/PayPal.svg" />

          <img
            class="w-[46px] relative rounded-md h-8 object-cover"
            alt="card image"
            src="assets/images/home/payment-method-icon-3@2x.png"
          />
        </div>
      </div>
    </div>
    <div class="self-stretch">
      <div class="flex flex-row gap-[55px] md:gap-[180px]">
        <div class="flex flex-col justify-start gap-[16px] min-w-[96px]">
          <h2 class="self-stretch relative leading-[18px] font-semibold">Company Info</h2>
          <div class="self-stretch flex flex-col justify-start gap-[12px] text-sm text-gray-600">
            <ng-container *ngIf="appName === 'afc'">
              <div class="flex flex-row items-center justify-start cursor-pointer">
                <div class="overflow-hidden flex flex-row items-center justify-center gap-[6px]">
                  <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />

                  <a class="relative leading-[20px] font-semibold" [routerLink]="'about-us'"
                    >About Us</a
                  >
                  <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />
                </div>
              </div>
            </ng-container>
            <ng-container>
              <div class="flex flex-row items-center justify-start cursor-pointer">
                <div class="overflow-hidden flex flex-row items-center justify-center gap-[6px]">
                  <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />

                  <a class="relative leading-[20px] font-semibold" [routerLink]="'contact-us'"
                    >Contact Us</a
                  >
                  <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />
                </div>
              </div>
            </ng-container>

            <div
              class="flex flex-col justify-start gap-[16px] min-w-[96px] cursor-pointer"
              id="footerLinksColumn2"
              *ngIf="companyInfoPages && companyInfoPages.length > 0"
            >
              <div
                class="w-full self-stretch flex flex-col justify-start gap-[12px] text-sm text-gray-600"
              >
                <div
                  class="flex flex-row items-center justify-start"
                  *ngFor="let item of companyInfoPages"
                >
                  <div
                    class="overflow-hidden flex flex-row items-center justify-center gap-[6px]"
                    (click)="onClickContentPage(item)"
                  >
                    <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />

                    <div class="relative leading-[20px] font-semibold">{{ item.title }}</div>
                    <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="self-stretch">
      <div
        class="flex flex-col justify-start gap-[16px] min-w-[96px] cursor-pointer"
        id="footerLinksColumn2"
        *ngIf="legalPages && legalPages.length > 0"
      >
        <h2 class="self-stretch relative leading-[18px] font-semibold">Legal</h2>
        <div
          class="w-full self-stretch flex flex-col justify-start gap-[12px] text-sm text-gray-600"
        >
          <div class="flex flex-row items-center justify-start" *ngFor="let item of legalPages">
            <div
              class="overflow-hidden flex flex-row items-center justify-center gap-[6px]"
              (click)="onClickContentPage(item)"
            >
              <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />

              <div class="relative leading-[20px] font-semibold">{{ item.title }}</div>
              <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="md:hidden flex"></div>
    <div class="self-stretch">
      <div class="flex flex-col items-end md:w-full justify-start gap-[16px] text-sm text-gray-900">
        <div class="flex flex-col items-end justify-start gap-[16px]">
          <div class="self-stretch relative items-end leading-[20px] font-semibold">
            Get the app
          </div>
          <a name="redirectToAppLink1" aria-label="redirectToAppLink1" [href]="iosAppUrl" *ngIf="iosAppUrl" target="_blank">
            <img
              (click)="redirectToAppLink('ios')"
              class="relative rounded-6xs max-w-full overflow-hidden h-10 shrink-0 cursor-pointer"
              alt="app store icon image"
              src="assets/images/home/mobile-app-store-badge.svg"
            />
          </a>

          <a name="redirectToAppLink2" aria-label="redirectToAppLink2" [href]="androidAppUrl" *ngIf="androidAppUrl" target="_blank">
            <img
              (click)="redirectToAppLink('android')"
              class="w-[135px] relative rounded-8xs h-10 overflow-hidden shrink-0 cursor-pointer"
              alt="play store icon image"
              src="assets/images/home/storegoogle-play.svg"
            />
          </a>
        </div>
      </div>
    </div>
  </div>

  <div
    class="w-full h-auto self-stretch flex flex-row flex-wrap items-center justify-between pt-6 px-4 md:px-16 pb-0 text-lightslategray border-t-[1px] border-solid border-gray-2001"
  >
    <div class="relative leading-[20px] font-semibold" *ngIf="copyrightInfo">
      {{ copyrightInfo }}
    </div>
    <div class="flex flex-row items-center justify-start gap-[16px]">
      <a [href]="facebookUrl" *ngIf="facebookUrl" target="_blank">
        <img
          class="w-5 relative h-5 overflow-hidden shrink-0"
          alt="facebook  icon"
          src="assets/images/home/facebook.svg"
        />
      </a>

      <!-- <img class="w-5 relative h-5 overflow-hidden shrink-0" alt="s" src="assets/images/home/twitter.svg" /> -->

      <a [href]="instagramUrl" *ngIf="instagramUrl" target="_blank">
        <img
          class="w-5 relative h-5 overflow-hidden shrink-0"
          alt="instagram icon"
          src="assets/images/home/instagram.svg"
        />
      </a>

      <a [href]="twitterUrl" *ngIf="twitterUrl" target="_blank">
        <img
          class="w-5 relative h-5 overflow-hidden shrink-0"
          alt="twitter icon"
          src="assets/images/home/social-x.svg"
        />
      </a>

      <a [href]="linkedInUrl" *ngIf="linkedInUrl" target="_blank">
        <img
          class="w-6 relative h-6 overflow-hidden shrink-0"
          alt="linkedIn icon"
          src="assets/images/home/social-linkedin.svg"
        />
      </a>

      <!-- <img class="w-6 relative h-6 hidden" alt="hidden icon" src="assets/images/home/social-icon.svg" /> -->
    </div>
  </div>
</div>

<!-- <div class="self-stretch flex flex-col items-center justify-start pt-12 px-0 pb-6 gap-[24px] text-sm text-gray-600">
  <div class="self-stretch flex flex-col md:flex-row justify-start py-0 px-4 md:px-16 gap-[64px]">
    <div class="flex-1 flex flex-col justify-start gap-[24px] min-w-[320px] max-w-[320px]">
      <img class="w-[182px] relative h-[41px]" alt=s""
        [src]="companyLogo ? companyLogo : 'assets/images/home/logo.svg'" />

      <div class="self-stretch relative leading-[20px]">
        eddress is a white-labeled marketplace enabler that provides businesses with all the tools
        and support they need to digitize their operations.
      </div>
      <div class="flex flex-row  justify-start gap-[12px] mix-blend-luminosity">
        <img class="w-[46px] relative rounded-md h-8 object-cover" alt=s""
          src="assets/images/home/payment-method-icon@2x.png" />

        <img class="w-[46px] relative rounded-md h-8 object-cover" alt=s"" src="assets/images/home/Mastercard.svg" />

        <img class="w-[46px] relative rounded h-8" alt="s" src="assets/images/home/PayPal.svg" />

        <img class="w-[46px] relative rounded-md h-8 object-cover" alt="s"
          src="assets/images/home/payment-method-icon-3@2x.png" />
      </div>
    </div>
    <div
      class=" w-full flex flex-col md:flex-row  justify-start gap-[32px] md:gap-[180px] min-w-[800px] text-xs text-gray-500">
      <div class="flex flex-row gap-[55px] md:gap-[180px]">
        <div class="flex flex-col justify-start gap-[16px] min-w-[96px]">
          <div class="self-stretch relative leading-[18px] font-semibold">Categories</div>
          <div class="self-stretch flex flex-col justify-start gap-[12px] text-sm text-gray-600">
            <ng-container *ngFor="let collection of collections; let i = index">
              <div *ngIf="i <= 5" class="flex flex-row items-center justify-start cursor-pointer"
                (click)="onClickMenu(collection)">
                <div class="overflow-hidden flex flex-row items-center justify-center gap-[6px]">
                  <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />

                  <div class="relative leading-[20px] font-semibold">{{ collection?.label }}</div>
                  <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="flex flex-col justify-start gap-[16px] min-w-[96px]">
          <div class="self-stretch relative leading-[18px] font-semibold">Help Center</div>
          <div class="self-stretch flex flex-col justify-start gap-[12px] text-sm text-gray-600">
            <div class="flex flex-row items-center justify-start">
              <div class="overflow-hidden flex flex-row items-center justify-center gap-[6px]">
                <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />

                <div class="relative leading-[20px] font-semibold">Your Order</div>
                <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />
              </div>
            </div>
            <div class="flex flex-row items-center justify-start">
              <div class="overflow-hidden flex flex-row items-center justify-center gap-[6px]">
                <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />

                <div class="relative leading-[20px] font-semibold">Hotline 24/7</div>
                <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />
              </div>
            </div>
            <div class="flex flex-row items-center justify-start cursor-pointer" id="footerLinkContainer2">
              <div class="overflow-hidden flex flex-row items-center justify-center gap-[6px]">
                <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />

                <div class="relative leading-[20px] font-semibold">FAQ</div>
                <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />
              </div>
            </div>
            <div class="flex flex-row items-center justify-start">
              <div class="overflow-hidden flex flex-row items-center justify-center gap-[6px]">
                <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />

                <div class="relative leading-[20px] font-semibold">Wishlist</div>
                <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />
              </div>
            </div>
            <div class="hidden flex-row items-center justify-start text-base text-gray-true-600">
              <div class="overflow-hidden flex flex-row items-center justify-center gap-[8px]">
                <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />

                <div class="relative leading-[24px] font-semibold">Tutorials</div>
                <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />
              </div>
            </div>
            <div class="hidden flex-row items-center justify-start text-base text-gray-true-600">
              <div class="overflow-hidden flex flex-row items-center justify-center gap-[8px]">
                <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />

                <div class="relative leading-[24px] font-semibold">Support</div>
                <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-row gap-[32px] md:gap-[240px]">
        <div class=" flex flex-col justify-start gap-[16px] min-w-[96px] cursor-pointer" id="footerLinksColumn2"
          *ngIf="contentPages && contentPages.length > 0">
          <div class="self-stretch relative leading-[18px] font-semibold">Legal</div>
          <div class="w-full self-stretch flex flex-col justify-start gap-[12px] text-sm text-gray-600">
            <div class="flex flex-row items-center justify-start" *ngFor="let item of contentPages">
              <div class="overflow-hidden flex flex-row items-center justify-center gap-[6px]"
                (click)="onClickContentPage(item.id)">
                <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />

                <div class="relative leading-[20px] font-semibold">{{item.title}}</div>
                <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col md:items-end md:w-full justify-start gap-[16px] text-sm text-gray-900">
          <div class="flex flex-col items-start justify-start gap-[16px]">
            <div class="self-stretch relative items-end leading-[20px] font-semibold">Get the app</div>
            <img class="self-stretch relative rounded-6xs max-w-full overflow-hidden h-10 shrink-0" alt="s"
              src="assets/images/home/mobile-app-store-badge.svg" />

            <img class="w-[135px] relative rounded-8xs h-10 overflow-hidden shrink-0" alt=s""
              src="assets/images/home/storegoogle-play.svg" />
          </div>
        </div>
      </div>

    </div>
  </div>
  <div
    class="self-stretch flex flex-row flex-wrap items-center justify-between pt-6 px-4 md:px-16 pb-0 text-lightslategray border-t-[1px] border-solid border-gray-2001">
    <div class="relative leading-[20px] font-semibold">
      © 2023 Eddress Shop. All rights reserved.
    </div>
    <div class="flex flex-row items-center justify-start gap-[16px]">
      <img class="w-5 relative h-5 overflow-hidden shrink-0" alt="s" src="assets/images/home/instagram.svg" />

      <img class="w-5 relative h-5 overflow-hidden shrink-0" alt="s" src="assets/images/home/facebook.svg" />

      <img class="w-5 relative h-5 overflow-hidden shrink-0" alt="s" src="assets/images/home/twitter.svg" />

      <img class="w-6 relative h-6 overflow-hidden shrink-0 hidden" alt="hidden icon" src="assets/images/home/social-icon.svg" />

      <img class="w-6 relative h-6 hidden" alt="hidden icon" src="assets/images/home/social-icon.svg" />
    </div>
  </div>
</div> -->
