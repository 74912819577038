<div
  class="md:hidden w-full self-stretch overflow-x-auto flex flex-row items-center gap-5 justify-start py-6 px-2 md:pr-0 md:pl-0  relative  cursor-pointer z-[4] text-gray-800 [&::-webkit-scrollbar]:hidden carousel-container"
  style="position: relative" id="frameContainerAccountMenu" data-carousel="static" #frameContainerAccountMenu>


  <div *ngFor="let menu of submenus" [ngClass]="menu.isActive ? 'text-brand-600' : 'text-gray-900'"
    (click)="onClickMenu(menu)"
    class=" rounded-md bg-brand-100  items-center justify-start py-2 px-3 gap-[0px_8px]  dark:hover:text-white cursor-pointer">
    <div class="flex flex-row items-center justify-start gap-[0px_12px]">

      <img class="w-6 relative h-6 overflow-hidden shrink-0" alt="menu icon" [src]="menu.icon" />

      <div class="relative leading-[24px] font-semibold whitespace-nowrap">{{ menu.name }}</div>
    </div>
  </div>


</div>
<div class="flex flex-row py-8 px-4 md:px-16 gap-[0px_32px] text-base text-gray-700">
  <div
    class="w-80 rounded-lg bg-base-white box-border hidden md:flex flex-col items-start justify-start p-4 gap-[8px_0px] border-[1px] border-solid border-gray-2001">
    <div *ngFor="let menu of submenus" [ngClass]="menu.isActive ? 'text-brand-600' : 'text-gray-900'"
      (click)="onClickMenu(menu)"
      class="self-stretch rounded-md bg-brand-100 overflow-hidden flex flex-row items-center justify-start py-2 px-3 gap-[0px_8px] hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer">
      <div class="flex-1 flex flex-row items-center justify-start gap-[0px_12px]">
        <img class="w-2.5 relative h-2.5 hidden" alt="hidden icon" />

        <img class="w-6 relative h-6 overflow-hidden shrink-0" alt="menu icon" [src]="menu.icon" />

        <div class="relative leading-[24px] font-semibold">{{ menu.name }}</div>
      </div>
      <div
        class="rounded-2xl bg-gray-50 hidden flex-row items-center justify-start py-0.5 px-2 text-center text-xs text-gray-700 border-[1px] border-solid border-gray-2001">
        <div class="relative leading-[18px]">10</div>
      </div>
      <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" />
    </div>
  </div>



  <div class="flex-grow">
    <router-outlet></router-outlet>
  </div>
</div>