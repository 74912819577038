import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'app-stores-address-modal',
    templateUrl: './stores-address-modal.component.html',
    styleUrl: './stores-address-modal.component.scss',
    standalone: true,
})
export class StoresAddressModalComponent {
  @Output() closeModal = new EventEmitter<boolean>();
  close() {
    this.closeModal.emit(false);
  }
}
