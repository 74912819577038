<div
    class=" bg-base-white overflow-hidden flex flex-col items-center justify-start"
>
    <div
        class="self-stretch bg-base-white overflow-hidden flex flex-col items-center justify-start px-0"
    >
        <div
            class="w-full flex flex-col items-center justify-start py-0 px-8 box-border max-w-[1280px]"
        >
            <div
                class="self-stretch flex flex-col items-center justify-start gap-[48px]"
            >
                <div
                    class="w-full flex flex-col items-center justify-start gap-[24px] max-w-[800px]"
                >
                    <div
                        class="self-stretch flex flex-col items-start justify-start gap-[12px]"
                    >
                    </div>
                   <app-inner-content [innerContent]="contentPage?.content"></app-inner-content>
                </div>
            </div>
        </div>
    </div>
</div>