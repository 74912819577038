import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/services/api.service';
import { Urls } from '@app/core/util/urls';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReferFriendService {
  constructor(private api: ApiService) {}
  appInviteLink(params): Observable<any> {
    return this.api.get(Urls.APP_INVITE_LINK, params).pipe(map((data) => data));
  }
}
