import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-about-us',
  standalone: true,
  imports: [],
  templateUrl: './about-us.component.html',
  styleUrl: './about-us.component.scss',
  encapsulation:  ViewEncapsulation.None
})
export class AboutUsComponent {

}
