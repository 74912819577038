<div class="flex-1 flex flex-col items-start justify-start gap-[12px_0px] text-xl text-gray-900">
  <h1 class="relative leading-[30px] font-semibold">My Account</h1>
  <div
    class="self-stretch rounded-lg bg-base-white flex flex-col items-start justify-start text-lg border-[1px] border-solid border-gray-2001">
    <div class="self-stretch flex flex-col items-start justify-start gap-[20px_0px]">
      <div
        class="self-stretch flex flex-col md:flex-row items-start justify-start pt-5 px-6 pb-0 gap-[16px_16px] z-[1]">
        <div class="flex-1 flex flex-row items-center justify-start gap-[0px_16px]">
          <img class="w-14 relative rounded-181xl h-14" alt="username icon" src="assets/images/user/avatar.svg" />

          <div class="flex-1 flex flex-col items-start justify-start">
            <div class="self-stretch flex flex-row items-center justify-start gap-[0px_8px]">
              <div class="relative leading-[28px] font-semibold">
                {{ userInfo?.fullName }}
              </div>
              <div
                class="rounded-md bg-base-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] hidden flex-row items-center justify-start py-0.5 px-1.5 gap-[0px_4px] text-center text-xs text-gray-700 border-[1px] border-solid border-gray-300">
                <img class="w-2 relative h-2" alt="online icon" />

                <div class="relative leading-[18px]">Online</div>
              </div>
            </div>
            <div
              class="self-stretch relative text-sm leading-[20px] text-gray-600 overflow-hidden text-ellipsis whitespace-nowrap">
              {{ userInfo?.email }}
            </div>
          </div>
        </div>
        <div class="flex flex-row items-center justify-start gap-[0px_12px] text-sm text-gray-600"
          id="actionsContainer">
          <div
            class="rounded-lg overflow-hidden hidden flex-row items-center justify-center py-2.5 px-3.5 gap-[0px_4px]">
            <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" />

            <div class="flex flex-row items-center justify-center py-0 px-0.5">
              <div class="relative leading-[20px] font-semibold">Tertiary</div>
            </div>
            <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" />
          </div>
          <div (click)="openDrawer()"
            class="rounded-lg bg-brand-50 shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] overflow-hidden flex flex-row items-center justify-center py-2.5 px-3.5 gap-[0px_4px] text-brand-700 border-[1px] border-solid border-brand-300 cursor-pointer">
            <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" />

            <div class="flex flex-row items-center justify-center py-0 px-0.5">
              <div class="relative leading-[20px] font-semibold">Edit Profile</div>
            </div>
            <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" />
          </div>
          <div
            class="rounded-lg bg-base-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] overflow-hidden hidden flex-row items-center justify-center py-2.5 px-3.5 gap-[0px_4px] text-gray-700 border-[1px] border-solid border-gray-300">
            <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" />

            <div class="flex flex-row items-center justify-center py-0 px-0.5">
              <div class="relative leading-[20px] font-semibold">Archive</div>
            </div>
            <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" />
          </div>
          <div
            class="rounded-lg bg-brand-600 shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] overflow-hidden hidden flex-row items-center justify-center py-2.5 px-3.5 gap-[0px_4px] text-base-white border-[1px] border-solid border-brand-600">
            <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" />

            <div class="flex flex-row items-center justify-center py-0 px-0.5">
              <div class="relative leading-[20px] font-semibold">View profile</div>
            </div>
            <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" />
          </div>
        </div>
        <div class="hidden flex-col items-start justify-start">
          <img class="w-5 relative h-5 overflow-hidden shrink-0" alt="hidden icon" />
        </div>
      </div>
      <img class="self-stretch relative max-w-full overflow-hidden h-px shrink-0 z-[0]" alt="divider icon"
        src="assets/images/user/divider.svg" />
    </div>
    <div class="self-stretch flex flex-col items-start justify-start py-3 px-6 text-sm text-gray-600">
      <div class="flex flex-row items-start justify-start gap-[0px_10px]">
        <div
          class="md:w-[188px] box-border h-[52px] flex flex-col items-start justify-start gap-[4px_0px] min-w-[160px] border-r-[1px] border-solid border-gray-300">
          <div class="self-stretch relative leading-[20px]">Mobile Number</div>
          <div class="self-stretch relative leading-[20px] font-semibold">
            {{ userInfo?.phoneNumber ? userInfo.phoneNumber : "-" }}
          </div>
        </div>
        <div
          class="md:w-[188px] box-border h-[52px] flex flex-col items-start justify-start gap-[4px_0px] min-w-[160px] border-r-[1px] border-solid border-gray-300">
          <div class="relative leading-[20px] whitespace-pre-wrap">Date of Birth</div>
          <div class="self-stretch relative leading-[20px] font-semibold">
            {{ userInfo?.birthDate ? (userInfo?.birthDate | date : "MMM d, yyyy") : "-" }}
          </div>
        </div>
        <div
          class="md:w-[188px] box-border h-[52px] hidden md:flex flex-col items-start justify-start gap-[4px_0px] min-w-[160px] ">
          <div class="relative leading-[20px]">Total Orders</div>
          <div class="self-stretch relative leading-[20px] font-semibold">
            {{ userInfo?.ordersCount ? userInfo?.ordersCount : "-" }}
          </div>
        </div>
        <!-- <div
          class="w-[188px] h-[52px] flex flex-col items-start justify-start gap-[4px_0px] min-w-[160px]"
        >
          <div class="relative leading-[20px]">Loyalty</div>
          <div class="self-stretch relative leading-[20px] font-semibold">
            {{ userInfo?.loyalty }}Blue
          </div>
        </div> -->
      </div>
      <div class="md:hidden flex flex-row items-start justify-start gap-[0px_10px]">
        <div
          class="md:w-[188px] box-border h-[52px] flex flex-col items-start justify-start gap-[4px_0px] min-w-[160px] border-r-[1px] border-solid border-gray-300">
          <div class="relative leading-[20px]">Total Orders</div>
          <div class="self-stretch relative leading-[20px] font-semibold">
            {{ userInfo?.ordersCount ? userInfo?.ordersCount : "-" }}
          </div>
        </div>
        <!-- <div
          class="w-[188px] h-[52px] flex flex-col items-start justify-start gap-[4px_0px] min-w-[160px]"
        >
          <div class="relative leading-[20px]">Loyalty</div>
          <div class="self-stretch relative leading-[20px] font-semibold">
            {{ userInfo?.loyalty }}Blue
          </div>
        </div> -->
      </div>
    </div>
  </div>
  <div class="self-stretch flex flex-col md:flex-row items-start justify-start gap-[0px_12px] text-sm">
    <div
      class="flex-1 rounded-lg bg-base-white flex flex-col items-start justify-start p-6 gap-[16px_0px] border-[1px] border-solid border-gray-2001">
      <div class="relative text-base leading-[24px] font-semibold">My Wallet</div>
      <div class="self-stretch relative leading-[20px]">
        Invite your pals using your unique referral link, and both of you can enjoy exclusive
        rewards. It's a win-win!
      </div>
      <div class="self-stretch flex flex-row items-start justify-start gap-[0px_16px] text-xs text-base-white">
        <div class="flex-1 rounded-lg bg-brand-700 flex flex-col items-start justify-start p-4 gap-[6px_0px]">
          <img class="w-6 relative h-6 overflow-hidden shrink-0" alt="wallet icon" src="assets/images/user/wallet02.svg" />

          <div class="self-stretch relative leading-[18px]">Available Balance</div>
          <div class="relative text-sm leading-[20px] font-semibold">
            {{ configuration?.tenantCurrencySymbol }} {{ amountInWallet || 0 | number : "1.2-2" }}
          </div>
        </div>
        <div class="flex-1 rounded-lg bg-gray-700 flex flex-col items-start justify-start p-4 gap-[6px_0px]">
          <img class="w-6 relative h-6 overflow-hidden shrink-0" alt="cashback icon" src="assets/images/user/coinsswap01.svg" />

          <div class="self-stretch relative leading-[18px]">Cashback</div>
          <div class="relative text-sm leading-[20px] font-semibold">
            {{ configuration?.tenantCurrencySymbol }} {{ cashBackEarned || 0 | number : "1.2-2" }}
          </div>
        </div>
      </div>
      <div class="self-stretch relative bg-gray-2001 h-px"></div>
      <div (click)="redirectToWallet()"
        class="overflow-hidden flex flex-row items-center justify-center gap-[0px_6px] text-brand-700 cursor-pointer">
        <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" />

        <div class="relative leading-[20px] font-semibold">Manage Wallet</div>
        <img class="w-5 relative h-5 overflow-hidden shrink-0" alt="right icon" src="assets/images/user/chevronright.svg" />
      </div>
    </div>
    <div
      class="self-stretch flex-1 rounded-lg bg-base-white flex flex-col items-center justify-between p-6 text-center border-[1px] border-solid border-gray-2001 gap-[16px]">
      <img class="w-[53px] relative h-[53px] overflow-hidden shrink-0" alt="like icon" src="assets/images/user/like.svg" />

      <div class="md:w-[359.18px] relative text-base leading-[24px] font-semibold inline-block">
        Earn extra cash by referring a friend! 🌟
      </div>
      <div class="md:w-[365.45px] relative leading-[20px] inline-block">
        {{ referralDetail?.referralSharedMessage }}
      </div>
      <div
        class="w-[47px] rounded-md bg-base-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] box-border hidden flex-row items-center justify-start py-0.5 px-1.5 gap-[0px_4px] text-xs text-gray-700 border-[1px] border-solid border-gray-300">
        <img class="w-2 relative h-2" alt="online icon" />

        <div class="relative leading-[18px]">Online</div>
      </div>
      <a [href]="referralDetail?.inviteLink" target="_blank"
        class="rounded-lg bg-brand-600 shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] overflow-hidden flex flex-row items-center justify-center py-2.5 px-3.5 gap-[0px_4px] text-left text-base-white border-[1px] border-solid border-brand-600 cursor-pointer">
        <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" />

        <div class="flex flex-row items-center justify-center py-0 px-0.5">
          <div class="relative leading-[20px] font-semibold">Invite Friends</div>
        </div>
        <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" />
      </a>
      <div class="flex flex-row items-center justify-start gap-[0px_8px] text-left text-gray-600">
        <div class="relative leading-[20px] overflow-hidden text-ellipsis whitespace-nowrap">
          Or share your code
        </div>
        <div id="tooltipContent" role="tooltip"
          class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
          {{toolTipMessage}}
          <!-- <div class="tooltip-arrow"></div> -->
        </div>
        <div class="overflow-hidden flex flex-row items-center justify-center gap-[0px_6px]" id="tooltipButton">
          <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" />

          <div class="relative leading-[20px] font-semibold">{{ referralDetail?.promoCode }}</div>
          <img (click)="copyMessage(referralDetail?.promoCode)"
            class="w-5 relative h-5 overflow-hidden shrink-0 cursor-pointer" alt="copy icon"
            src="assets/images/user/copy07.svg" />
        </div>
      </div>
    </div>
  </div>
</div>

<app-drawer [drawerId]="'myAccountDrawer'" [isOpen]="isDrawerOpen" (reset)="reset()">
  <app-edit-profile *ngIf="isProfileOpen" [userDetails]="userInfo" (closeDrawer)="closeProfileDrawer($event)"
    (onSubmit)="onSubmit($event)" (onDeleteAccount)="openDeleteUserConfirmationModal(user)" />
</app-drawer>

<app-modal [modalId]="'profileModal'" [isOpen]="isModalOpen" (reset)="resetModal()">
  <app-confirmation-popup *ngIf="isConfirmationOpen" [title]="confirmationTitle"
    (closeModal)="closeConfrimationPopUp($event)" (onConfirm)="deletAccount()" />
</app-modal>