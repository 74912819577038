import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import moment from 'moment';
import { NgIf, NgFor, NgClass, DatePipe, JsonPipe } from '@angular/common';
import { Calendar } from 'primeng/calendar';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import { environment } from '@environments/environment';
import { AuthService } from '@app/core/services/auth.service';
import { LoaderService } from '@app/core/services/loader.service';
import { NotificationService } from '@app/core/services/notification.service';
import sortBy from 'lodash/sortBy';

@Component({
  selector: 'app-delivery-time-schedule',
  templateUrl: './delivery-time-schedule.component.html',
  styleUrl: './delivery-time-schedule.component.scss',
  standalone: true,
  imports: [NgIf, NgFor, NgClass, CalendarModule, FormsModule, DatePipe, JsonPipe],
})
export class DeliveryTimeScheduleComponent implements OnInit {
  @ViewChild('calendar') calendar: Calendar;
  @Input() deliverySchedule = [];
  @Output() closeDrawer = new EventEmitter<boolean>();
  @Output() onSubmit = new EventEmitter<any>();
  timeEntries = [];
  showTimeEntries = false;
  selectedDate;
  selectedTime;
  scheduleDate: Date | null = null;
  minDate: Date;
  weekDays = [1, 2, 3, 4, 5, 6, 7];
  disableDays = [];
  selectedStore;
  formatedSchedule: any = [];
  listenerAttached: boolean = false;
  appName = environment.eddressAppConfig.appName;
  constructor(
    private authService: AuthService,
    private loaderService: LoaderService,
    private notification: NotificationService
  ) {
    const today = new Date();
    this.minDate = new Date();
    this.minDate.setDate(today.getDate() + 1); // Set minDate to tomorrow
    if (this.appName === 'afc') {
      this.authService.selectedStore.subscribe((data) => {
        if (data) {
          this.selectedStore = data;
          this.formSchedule();
        }
      });
    }
  }

  ngOnInit(): void {
    if (this.appName !== 'afc') {
      const schedulelist = [];
      for (let [key, value] of Object.entries(this.deliverySchedule)) {
        const date: any = moment({
          year: value.date[0],
          month: value.date[1] - 1,
          day: value.date[2],
        });
        value.newDate = new Date(date._d);
        schedulelist.push(value);
      }
      this.deliverySchedule = schedulelist;
      this.deliverySchedule = sortBy(this.deliverySchedule, (o) => o.newDate);
    }
  }

  ngAfterViewChecked() {
    this.highlightDisabledays();
  }

  highlightDisabledays() {
    if(this.calendar) {
    const calendarEl = this.calendar.el.nativeElement.querySelectorAll('.p-datepicker-calendar td');

    // Loop through each day in the calendar
    calendarEl.forEach((cell: HTMLElement) => {
      const dateText = cell.querySelector('.p-ripple')?.textContent;

      if (dateText) {
        const year = this.calendar.currentYear;
        const month = this.calendar.currentMonth;
        const date = new Date(year, month, parseInt(dateText));

        if (this.disableDays.includes(date.getDay())) {
          // 5 = Friday
          // Apply custom styles to grey out Fridays
          // cell.style.backgroundColor = '#f0f0f0'; // Light grey background
          cell.style.color = '#a0a0a0'; // Light grey text
          // cell.style.textDecoration = 'line-through';
          cell.style.pointerEvents = 'auto'; // Ensure it's clickable
        }
      }
    });
  }
  }
  close() {
    this.closeDrawer.emit(false);
  }
  chooseTime(schedule) {
    this.selectedDate = schedule.newDate;
    this.showTimeEntries = true;
    this.timeEntries = schedule.timeEntries;
  }
  back() {
    this.showTimeEntries = false;
  }
  selectTime(timeslot) {
    if (this.appName === 'afc') {
      const date = moment(this.scheduleDate).format('MMMM DD');
      const dateTime = date + ', ' + timeslot.label;
      this.onSubmit.emit(dateTime);
    } else {
      const date = moment(this.selectedDate).format('MMMM DD');
      const dateTime = date + ', ' + timeslot.label;
      this.onSubmit.emit(dateTime);
    }
  }
  onDateClick(event: Date) {
    this.timeEntries.length = 0;

    const date = new Date(event); // Replace with any date
    const dayOfWeek = this.getDayOfWeek(date);
    const schedule = this.formatedSchedule.find((s) => s.dayOfWeek === dayOfWeek);
    if (schedule) {
      this.loaderService.open();
      setTimeout(() => {
        this.timeEntries.push({
          label: `${schedule.timeOpenString} - ${schedule.timeClosedString}`,
        });
        this.showTimeEntries = true;

        this.loaderService.close();
      }, 1000);
    } else {
      this.showTimeEntries = false;
      const message = `No order accepted on ${this.getDayName(
        date
      )} kindly select another day for delivery`;
      this.notification.infoNotification(message);
    }
  }
  getDayOfWeek(date) {
    const day = date.getDay();
    return day === 0 ? 7 : day; // If Sunday (0), return 7; else return the day
  }
  getDayName(date) {
    const daysOfWeek = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    return daysOfWeek[date.getDay()];
  }
  formSchedule() {
    let activeDays = [];
    this.selectedStore.schedule.forEach((schedule) => {
      activeDays.push(schedule.dayOfWeek);
    });
    this.disableDays = this.weekDays.filter((d) => !activeDays.includes(d));
    this.disableDays = this.disableDays.map((d) => (d === 7 ? 0 : d));

    const schedule = this.selectedStore.schedule;

    // Create SubNodes
    const dayOfWeek = ['1', '2', '3', '4', '5', '6', '7'];
    const timeClosed = [
      '61200000',
      '61200000',
      '61200000',
      '61200000',
      '61200000',
      '61200000',
      '61200000',
    ];
    const timeClosedString = [
      '5:00 PM',
      '5:00 PM',
      '5:00 PM',
      '5:00 PM',
      '5:00 PM',
      '5:00 PM',
      '5:00 PM',
    ];
    const timeInterval = ['60', '60', '60', '60', '60', '60', '60'];
    const timeOpen = [
      '32400000',
      '32400000',
      '32400000',
      '32400000',
      '32400000',
      '32400000',
      '32400000',
    ];
    const timeOpenString = [
      '9:00 AM',
      '9:00 AM',
      '9:00 AM',
      '9:00 AM',
      '9:00 AM',
      '9:00 AM',
      '9:00 AM',
    ];

    let objectsMutable = [];

    let scday = 0;

    for (let stringIndex = 0; stringIndex < 7; stringIndex++) {
      let dictMutable = {};
      let dict = {};
      let offDay = false;

      if (scday < schedule.length) {
        const dayWeek = String(schedule[scday].dayOfWeek);
        if (dayWeek === dayOfWeek[stringIndex]) {
          dict = schedule[scday];
          scday++;
        } else {
          offDay = true;
        }
      } else {
        offDay = true;
      }

      if (offDay) {
        dictMutable = {
          dailyOrderLimit: '0',
          dayOfWeek: dayOfWeek[stringIndex],
          isActive: '0',
          timeClosed: timeClosed[stringIndex],
          timeClosedString: timeClosedString[stringIndex],
          timeInterval: timeInterval[stringIndex],
          timeOpen: timeOpen[stringIndex],
          timeOpenString: timeOpenString[stringIndex],
        };
        dict = dictMutable;
      }

      objectsMutable.push(dict);
    }

    const objects = objectsMutable;
    this.formatedSchedule = objects;
  }
}
