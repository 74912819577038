import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '@app/core/services/notification.service';

@Component({
  selector: 'app-payment-return',
  templateUrl: './payment-return.component.html',
  styleUrl: './payment-return.component.scss',
  standalone: true,
  imports: [],
})
export class PaymentReturnComponent {
  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private notification: NotificationService
  ) {
    this.route.queryParamMap.subscribe((params) => {
      // const redirectResult = params.get('redirectResult');
      // const type = params.get('type');
      let resultCode = params.get('resultCode');
      const orderUid = localStorage.getItem('orderUid');

      let result = resultCode.match(/authorised/);

      if (result) {
        resultCode = result[0];
      }
      if (resultCode === 'authorised') {
        this.notification.successNotification('Order placed successfully');
        setTimeout(() => {
          this.router.navigate(['order-detail/' + orderUid]);
        }, 1000);
      } else {
        this.router.navigate(['checkout']);
      }
    });
  }
}
