'use strict';

var $ = require('../internals/export');
var aCallable = require('../internals/a-callable');
var aMap = require('../internals/a-map');
var MapHelpers = require('../internals/map-helpers');
var get = MapHelpers.get;
var has = MapHelpers.has;
var set = MapHelpers.set;

// `Map.prototype.getOrInsertComputed` method
// https://github.com/tc39/proposal-upsert
$({
  target: 'Map',
  proto: true,
  real: true,
  forced: true
}, {
  getOrInsertComputed: function getOrInsertComputed(key, callbackfn) {
    aMap(this);
    aCallable(callbackfn);
    if (has(this, key)) return get(this, key);
    // CanonicalizeKeyedCollectionKey
    if (key === 0 && 1 / key === -Infinity) key = 0;
    var value = callbackfn(key);
    set(this, key, value);
    return value;
  }
});