import { Component, OnInit } from '@angular/core';
import { OrdersService } from './services/orders.service';
import { MyAccountService } from '../my-account/services/my-account.service';
import { LoaderService } from '@app/core/services/loader.service';
import { Router } from '@angular/router';
import { GenericService } from '@app/core/services/generics.service';
import { AuthService } from '@app/core/services/auth.service';
import { ModalComponent } from '../../shared/components/modal/modal.component';
import { CartComponent } from '../../layout/header/components/cart/cart.component';
import { OrderItemsComponent } from '../../order-detail/components/order-items/order-items.component';
import { DrawerComponent } from '../../shared/drawer/drawer.component';
import { OrderItemComponent } from './components/order-item/order-item.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-orders',
    templateUrl: './orders.component.html',
    styleUrl: './orders.component.scss',
    standalone: true,
    imports: [
        NgIf,
        OrderItemComponent,
        DrawerComponent,
        OrderItemsComponent,
        CartComponent,
        ModalComponent,
    ],
})
export class OrdersComponent implements OnInit {
  currentOrders = [];
  pastOrders = [];

  numberOrdersToFetch = 10;

  isDrawerOpen: boolean = false;
  isOrderItemsOpen: boolean = false;
  isCartOpen: boolean = false;

  selectedOrder;
  cartItems = [];
  missingItems = [];
  deliveryAddress;

  isModalOpen = false;
  isConfirmationOpen = false;
  selectedStore;

  constructor(
    private ordersService: OrdersService,
    private loaderService: LoaderService,
    public router: Router,
    private genericService: GenericService,
    private authService: AuthService
  ) {
    this.authService.deliveryAddress.subscribe((data) => {
      if (data) {
        this.deliveryAddress = data;
      }
    });
    this.authService.selectedStore.subscribe((data) => {
      if (data) {
        this.selectedStore = data;
      }
    });
  }
  ngOnInit() {
    this.genericService.setPageTitleAndDescription('orders', '')
    this.getUserOrders();
  }

  getUserOrders() {
    this.loaderService.open();
    this.ordersService.getOrders(this.numberOrdersToFetch).subscribe(
      (result) => {
        result.forEach((order) => {
          const orderStatus = order.orderStatus;
          if (
            orderStatus === 'COMPLETE' ||
            orderStatus === 'DONE' ||
            orderStatus === 'CANCELED' ||
            orderStatus === 'NEW_CANCELED'
          ) {
            this.pastOrders.push(order);
          } else {
            this.currentOrders.push(order);
          }
        });
        this.loaderService.close();
      },
      (err) => {
        this.loaderService.close();
      }
    );
  }

  reset() {
    this.isDrawerOpen = false;
  }

  openDrawer() {
    this.isCartOpen = false;
    this.isDrawerOpen = true;
    this.isOrderItemsOpen = true;
  }
  openCartDrawer() {
    this.isOrderItemsOpen = false;
    this.isDrawerOpen = true;
    this.isCartOpen = true;
  }

  closeOrderitems($event) {
    this.isDrawerOpen = false;
    this.isOrderItemsOpen = $event;
  }
  closeCart($event) {
    this.isDrawerOpen = false;
    this.isCartOpen = $event;
  }

  getViewOrder(event) {
    this.selectedOrder = event;
    this.openDrawer();
  }
  resetModal() {
    this.isModalOpen = false;
  }
  openModal() {
    this.isModalOpen = true;
    this.isConfirmationOpen = true;
  }
  closeConfrimationPopUp($event) {
    this.isModalOpen = false;
    this.isConfirmationOpen = $event;
  }
  openReOrderConfirmationModal(order) {
    this.selectedOrder = order;
    this.getCartDetail();
  }
  removeItemsFromCart() {
    this.cartItems.forEach((item) => {
      item.quantity = -1;
      this.manageBasket(item, this.selectedStore.id);
    });
    this.loaderService.open();
    setTimeout(() => {
      this.loaderService.close();
      this.addItemsToCart();
    }, 1000);
  }
  addItemsToCart() {
    this.selectedOrder.items.forEach((item) => {
      this.manageBasket(item, this.selectedOrder.storeId);
    });
    this.loaderService.open();
    setTimeout(() => {
      this.loaderService.close();
      this.openCartDrawer();
    }, 1000);
  }
  getCartDetail() {
    this.genericService.getBasket().subscribe((result) => {
      this.cartItems = result?.data?.items;
      const cartItemsCount = result?.data?.quantity;

      if (cartItemsCount > 0) {
        this.openModal();
      } else {
        this.addItemsToCart();
      }
    });
  }
  manageBasket(item, storeId) {
    const params = {
      storeId: storeId,
      productId: item.id,
      quantity: item.quantity,
    };
    this.genericService.manageBasket(params).subscribe((result) => {});
  }
}
