<div class="self-stretch flex-1 flex flex-col justify-start py-8 px-4 md:px-16 gap-[32px_0px]">
  <b
    class="relative leading-[24px] cursor-pointer text-brand-600"
    id="backToCart"
    (click)="viewCart()"
    >Back to Cart</b
  >
  <div
    class="self-stretch flex flex-col md:flex-row justify-start gap-[32px_32px] text-sm text-base-black"
  >
    <form
      [formGroup]="checkoutFormGroup"
      class="flex-1 flex flex-col items-start justify-start gap-[12px_0px]"
    >
      <div
        class="self-stretch rounded-8xs bg-base-white box-border md:h-[68px] flex flex-col justify-center border-[1px] border-solid border-gray-201"
      >
        <div
          class="self-stretch rounded-t-8xs rounded-b-none flex flex-col md:flex-row md:items-center justify-between py-3 px-6"
        >
          <div class="flex flex-row items-center justify-start gap-[0px_12px]">
            <div class="flex flex-row items-center justify-center">
              <input
                id="address-radio-1"
                type="radio"
                value="delivery"
                name="addressType"
                formControlName="addressType"
                (ngModelChange)="onChangeAddressType($event)"
                class="w-4 h-4 text-brand-600 bg-gray-100 border-gray-300 focus:ring-brand-500 dark:focus:ring-brand-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 cursor-pointer"
              />
            </div>
            <div class="relative leading-[20px]">
              <p class="[margin-block-start:0] [margin-block-end:14px]">
                Deliver to {{ deliveryAddress?.name }}:
              </p>
              <p class="m-0 font-semibold">{{ deliveryAddress?.addressDetailInfo }}</p>
            </div>
          </div>
          <div
            (click)="openDrawer('addresses')"
            class="relative ml-7 leading-[20px] font-semibold text-brand-600 cursor-pointer"
            id="changeAddressText"
          >
            Change Address
          </div>
        </div>
      </div>
      <div
        class="self-stretch rounded-8xs bg-base-white box-border h-14 flex flex-col items-start justify-center border-[1px] border-solid border-gray-201"
      >
        <div
          class="self-stretch rounded-t-8xs rounded-b-none flex flex-row items-center justify-start py-3 px-6"
        >
          <div class="flex flex-row items-center justify-start gap-[0px_12px]">
            <div class="flex flex-row items-center justify-center">
              <input
                id="address-radio-2"
                type="radio"
                value="pickup"
                name="addressType"
                formControlName="addressType"
                (ngModelChange)="onChangeAddressType($event)"
                class="w-4 h-4 text-brand-600 bg-gray-100 border-gray-300 focus:ring-brand-500 dark:focus:ring-brand-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 cursor-pointer"
              />
            </div>
            <div class="relative leading-[20px] font-semibold">Self pickup from the store</div>
          </div>
        </div>
      </div>

      <div
        class="self-stretch rounded-lg bg-base-white flex flex-col items-start justify-center p-6 gap-[16px_0px] border-[1px] border-solid border-gray-201"
      >
        <div class="self-stretch flex flex-row items-center justify-between">
          <div
            class="flex flex-row items-center justify-start gap-[0px_16px] border-solid border-gray-201"
          >
            <div class="relative leading-[20px] font-semibold">Delivery Time</div>
          </div>
          <!-- <div
            *ngIf="selectedStore?.hasTimeSchedule"
            (click)="openDrawer('deliveryTime')"
            class="relative ml-7 leading-[20px] font-semibold text-brand-600 cursor-pointer"
            id="changeAddressText"
          >
            Change Delivery Time
          </div> -->
        </div>
        <div class="relative cursor-pointer">
          <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
            <svg
              class="w-6 h-6 text-gray-500 dark:text-gray-400"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="24" height="24" fill="white" />
              <circle
                cx="12"
                cy="12"
                r="9"
                stroke="#000000"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12 6V12H6"
                stroke="#000000"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <input  (click)="selectedStore?.hasTimeSchedule ? openDrawer('deliveryTime'): ''"
            readonly
            type="text"
            formControlName="deliveryDate"
            class="bg-gray-50 border cursor-pointer border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-brand-500 focus:border-brand-500 block w-full min-w-[280px] ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-brand-500 dark:focus:border-brand-500"
          />
        </div>
      </div>

      <div
        class="self-stretch rounded-lg bg-base-white flex flex-col items-start justify-center p-6 gap-[16px_0px] border-[1px] border-solid border-gray-201"
      >
        <div class="self-stretch flex flex-row items-center justify-between">
          <div
            class="flex flex-row items-center justify-start gap-[0px_16px] border-solid border-gray-201"
          >
            <div class="relative leading-[20px] font-semibold">Delivery Instructions</div>
          </div>
        </div>
        <div class="self-stretch flex flex-col items-start justify-center gap-[16px_0px]">
          <div
            class="flex flex-row items-center mt-1 gap-[16px_10px]"
            *ngFor="let note of predefinedNotes"
          >
            <input
              id="default-checkbox"
              type="checkbox"
              value=""
              [(ngModel)]="note.isSelected"
              [ngModelOptions]="{ standalone: true }"
              class="w-4 h-4 text-brand-600 bg-gray-100 border-gray-300 rounded focus:ring-brand-500 dark:focus:ring-brand-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <span class="font-semibold">{{ note?.value }}</span>
          </div>

          <div class="self-stretch flex flex-row items-center justify-center gap-[0px_6px]">
            <div class="flex-1 flex flex-col items-start justify-start gap-[6px_0px]">
              <div class="flex flex-row items-center justify-start w-full">
                <input
                  type="text"
                  placeholder="Any additional delivery notes?"
                  class="flex-1 overflow-hidden text-ellipsis whitespace-nowrap focus:outline-none px-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-brand-500 focus:border-brand-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-brand-500 dark:focus:border-brand-500"
                  formControlName="notes"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="self-stretch rounded-lg bg-base-white flex flex-col items-start justify-center p-6 gap-[16px_0px] border-[1px] border-solid border-gray-201"
      >
        <div class="self-stretch flex flex-row items-center justify-between">
          <div
            class="flex flex-row items-center justify-start gap-[0px_16px] border-solid border-gray-201"
          >
            <img
              class="w-8 relative rounded-lg h-8"
              alt="credit card icon"
              src="assets/images/home/credit-card-02.svg"
            />

            <div class="relative leading-[20px] font-semibold">Payment Method</div>
          </div>
          <!-- <div
            class="relative leading-[20px] font-semibold text-brand-600 cursor-pointer"
            (click)="openDrawer('paymentCard')"
          >
            Add
          </div> -->
        </div>
        <div class="self-stretch flex flex-col items-start justify-center gap-[16px_0px]">
          @for(option of paymentOptions; track option.uid) {
          <div
            *ngIf="option.uid === 'credit'"
            class="self-stretch rounded-8xs flex flex-col items-start justify-start border-[1px] border-solid border-gray-201"
          >
            <div
              class="self-stretch rounded-t-8xs rounded-b-none bg-gray-100 flex flex-row items-center justify-between p-3"
            >
              <div class="w-[331px] flex flex-row items-center justify-start gap-[0px_6px]">
                <div class="flex flex-row items-center justify-center">
                  <input
                    id="payment-radio-1"
                    type="radio"
                    [value]="option.uid"
                    name="paymentMethod"
                    formControlName="paymentMethod"
                    (ngModelChange)="onChangePaymentType($event)"
                    class="w-4 h-4 text-brand-600 bg-gray-100 border-gray-300 focus:ring-brand-500 dark:focus:ring-brand-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 cursor-pointer"
                  />
                </div>
                <div class="relative leading-[20px] font-semibold">Pay by Card</div>
              </div>
              <div
                *ngIf="appName !== 'afc'"
                class="flex flex-row items-center justify-start gap-[0px_8px]"
              >
                <img
                  class="w-[46px] relative rounded-md h-8 object-cover"
                  alt="credit card icon"
                  src="assets/images/home/payment-method-icon@2x.png"
                />

                <img
                  class="w-[46px] relative rounded-md h-8 object-cover"
                  alt="credit card icon"
                  src="assets/images/home/Mastercard.svg"
                />

                <img
                  class="w-[46px] relative rounded h-8 object-cover"
                  alt="credit card icon"
                  src="assets/images/home/PayPal.svg"
                />
              </div>
            </div>
            @if(this.checkoutFormGroup?.get('paymentMethod')?.value === 'credit' ) {
            <div
              class="self-stretch flex flex-col items-start justify-start p-3 gap-[12px_0px] text-gray-700"
            >
              <select
                id="countries"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-brand-700 focus:border-brand-700 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-brand-700 dark:focus:border-brand-700"
                formControlName="creditCardUid"
                (click)="onCardChange($event)"
              >
                <!-- @if(paymentCards.length === 0) {
                  <option value="addCard">Add Card</option>
                } @else {
                  <option value="">Choose a card</option>
                } -->
                <option value="addCard">Add Card</option>
                <option style="display: none" value="">Choose a card</option>
                @for (card of paymentCards; track card.id) {
                <option [value]="card.uid">{{ card.cardNumber }}</option>
                } @empty {
                <!-- Empty list of users -->
                }
              </select>
              <div
                *ngIf="
                  !checkoutFormGroup.controls['creditCardUid'].valid &&
                  checkoutFormGroup.controls['creditCardUid'].dirty
                "
                class="text-red-700"
              >
                Credit Card is required
              </div>
            </div>
            }
          </div>
          <div
            *ngIf="option.uid !== 'credit'"
            class="self-stretch rounded-8xs flex flex-col items-start justify-start border-[1px] border-solid border-gray-201"
          >
            <div
              class="self-stretch rounded-t-8xs rounded-b-none flex flex-row items-center justify-between p-3"
            >
              <div class="w-full flex flex-row items-center justify-start gap-[0px_6px]">
                <div class="flex flex-row items-center justify-center">
                  <input
                    id="payment-radio-2"
                    type="radio"
                    [value]="option.uid"
                    name="paymentMethod"
                    formControlName="paymentMethod"
                    (ngModelChange)="onChangePaymentType($event)"
                    class="w-4 h-4 text-brand-600 bg-gray-100 border-gray-300 focus:ring-brand-500 dark:focus:ring-brand-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 cursor-pointer"
                  />
                </div>
                <div class="relative leading-[20px] font-semibold">{{ option.name }}</div>
              </div>
              <div *ngIf="option.uid === 'cash'" class="flex flex-row items-center justify-start">
                <img
                  class="w-[30px] relative h-[30px] overflow-hidden shrink-0"
                  alt="credit card icon"
                  src="assets/images/home/bank-note-03.svg"
                />
              </div>
            </div>
          </div>
          }
        </div>
      </div>
    </form>
    <div
      class="md:w-[400px] rounded-lg bg-base-white box-border flex flex-col justify-start p-6 gap-[16px_0px] text-gray-800 border-[1px] border-solid border-gray-201"
    >
      <h1 class="relative text-xl leading-[30px] font-semibold text-gray-900">Order Summary</h1>
      <div class="w-[352px] relative bg-gray-201 h-px"></div>
      <div class="self-stretch flex flex-row items-center justify-between">
        <div class="relative leading-[20px] font-semibold">Subtotal</div>
        <div class="relative leading-[20px] font-semibold">
          {{ configuration?.tenantCurrencySymbol
          }}{{ checkOutSummery?.subtotalPrice | number : "1.2-2" }}
        </div>
      </div>
      <div class="self-stretch flex flex-row items-center justify-between">
        <div class="relative leading-[20px] font-semibold">Delivery Charge</div>
        <div class="relative leading-[20px] font-semibold">
          {{ configuration?.tenantCurrencySymbol
          }}{{ checkOutSummery?.deliveryPrice | number : "1.2-2" }}
        </div>
      </div>
      <div
        *ngIf="checkOutSummery?.deliveryVatAmount && configuration?.showDeliveryVat"
        class="self-stretch flex flex-row items-center justify-between"
      >
        <div class="relative leading-[20px] font-semibold">Delivery Vat</div>
        <div class="relative leading-[20px] font-semibold">
          {{ configuration?.tenantCurrencySymbol
          }}{{ checkOutSummery?.deliveryVatAmount | number : "1.2-2" }}
        </div>
      </div>
      <div
        *ngIf="checkOutSummery?.deliveryVatAmount && configuration?.showItemsVat"
        class="self-stretch flex flex-row items-center justify-between"
      >
        <div class="relative leading-[20px] font-semibold">Vat</div>
        <div class="relative leading-[20px] font-semibold">
          {{ configuration?.tenantCurrencySymbol
          }}{{ checkOutSummery?.itemsVatAmount | number : "1.2-2" }}
        </div>
      </div>
      <div class="top-[151px] left-[20px] w-[352px] flex flex-col justify-start">
        <div
          *ngIf="promoCodeApplied && validPromoCode && checkOutSummery?.discount > 0"
          class="self-stretch flex flex-row items-center justify-between text-brand-600"
        >
          <div class="relative leading-[20px] font-semibold">
            <!-- {{
              validPromoCode.discountType === "DISCOUNT"
                ? validPromoCode.discountValue + "%"
                : validPromoCode.discountValue
            }} -->
            Promo Code Discount
          </div>
          <div class="w-[121px] flex flex-row items-center justify-end gap-[0px_4px]">
            <div class="relative leading-[20px] font-semibold">
              -{{ configuration?.tenantCurrencySymbol
              }}{{ checkOutSummery?.discount | number : "1.2-2" }}
            </div>
            <img
              (click)="removePromoCodeConfirmation()"
              class="w-5 relative h-5 overflow-hidden shrink-0 cursor-pointer"
              alt="credit card icon"
              src="assets/images/home/x.svg"
            />
          </div>
        </div>
      </div>
      <div class="flex flex-row justify-start text-brand-600 cursor-pointer">
        <div
          *ngIf="!promoCodeApplied"
          class="relative leading-[20px] font-semibold"
          (click)="addPromoCode()"
        >
          + Add Promo Code
        </div>
      </div>
      <div
        *ngIf="isAddPromoCode"
        class="md:w-[352px] h-[41px] flex flex-row items-center justify-start text-gray-500"
      >
        <input
          class="self-stretch w-[311px] rounded-tl-8xs rounded-tr-none rounded-br-none rounded-bl-8xs bg-base-white box-border overflow-hidden shrink-0 flex flex-row items-center justify-start py-2.5 px-3.5 border-t-[1px] border-solid border-gray-400 border-b-[1px] border-l-[1px]"
          id="promo-code"
          [(ngModel)]="promoCode"
          type="text"
          placeholder="Promo Code"
        />
        <div
          (click)="applyPromoCode()"
          class="self-stretch w-[41px] rounded-tl-none rounded-tr-8xs rounded-br-8xs rounded-bl-none bg-brand-500 overflow-hidden shrink-0 flex flex-row items-center justify-center cursor-pointer"
        >
          <div class="flex-1 flex flex-row items-center justify-center">
            <img
              class="w-5 relative h-5 overflow-hidden shrink-0"
              alt="credit card icon"
              src="assets/images/home/check.svg"
            />
          </div>
        </div>
      </div>
      <div class="w-[352px] relative bg-gray-201 h-px"></div>
      <div class="self-stretch flex flex-row items-center justify-between text-base text-gray-900">
        <b class="relative leading-[24px]">Total</b>
        <b class="relative text-xl leading-[30px]"
          >{{ configuration?.tenantCurrencySymbol
          }}{{ checkOutSummery?.totalPrice | number : "1.2-2" }}</b
        >
      </div>
      <div class="w-[352px] relative bg-gray-201 h-px"></div>
      <div
        *ngIf="checkOutSummery?.deductionFromWalletInServiceCurrency"
        class="self-stretch text-brand-600 flex flex-row items-center justify-between"
      >
        <div class="relative leading-[20px] font-semibold">Wallet</div>
        <div class="relative leading-[20px] font-semibold">
          -{{ configuration?.tenantCurrencySymbol
          }}{{ checkOutSummery?.deductionFromWalletInServiceCurrency | number : "1.2-2" }}
        </div>
      </div>
      <div class="w-[352px] relative bg-gray-201 h-px"></div>
      <div
        *ngIf="validPromoCode || checkOutSummery?.deductionFromWalletInServiceCurrency"
        class="self-stretch flex flex-row items-center justify-between"
      >
        <div class="relative leading-[20px] font-semibold">Amount Due</div>
        <div class="relative leading-[20px] font-semibold">
          {{ configuration?.tenantCurrencySymbol
          }}{{ checkOutSummery?.amountDue | number : "1.2-2" }}
        </div>
      </div>

      <div class="md:w-[344px] flex flex-row justify-start gap-[0px_8px] text-xs text-gray-700">
        <!-- <div class="flex flex-row items-center justify-center pt-0.5 px-0 pb-0">
          <div
            class="w-4 relative rounded box-border h-4 overflow-hidden shrink-0 border-[1px] border-solid border-gray-300"
          ></div>
        </div> -->

        <div class="flex items-center mt-1">
          <input
            [(ngModel)]="isTermConditions"
            id="default-checkbox"
            type="checkbox"
            value=""
            class="w-4 h-4 text-brand-600 bg-gray-100 border-gray-300 rounded focus:ring-brand-500 dark:focus:ring-brand-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
        </div>

        <div class="flex-1 flex flex-col justify-start">
          <div class="self-stretch relative leading-[18px]">
            <span>By placing this order, I confirm that I have read and agreed with the </span>
            <a
              [href]="termsAndConditionsUrl || 'page-not-found'"
              target="_blank"
              class="[text-decoration:underline] text-brand-700 cursor-pointer"
              >Terms & Conditions.</a
            >
          </div>
          <div class="w-80 relative text-sm leading-[20px] text-gray-600 hidden">
            Save my login details for next time.
          </div>
        </div>
      </div>
      <button
        type="button"
        [disabled]="!isTermConditions"
        (click)="placeOrder()"
        class="md:w-[352px] rounded-lg bg-brand-600 disabled:opacity-75 shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] box-border h-10 overflow-hidden shrink-0 flex flex-row items-center justify-center py-2.5 px-3.5 gap-[0px_4px] text-base-white border-[1px] border-solid border-brand-600 cursor-pointer"
      >
        <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden image" />

        <div class="flex flex-row items-center justify-center py-0 px-0.5">
          <div class="relative leading-[20px] font-semibold">Place Order</div>
        </div>
        <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden image" />
      </button>
      <div class="self-stretch flex flex-col items-center justify-start gap-[8px_0px] text-xs">
        <div class="self-stretch flex flex-row items-center justify-between">
          <div class="relative leading-[18px]">Available payment methods</div>
        </div>
        <div
          *ngIf="appName !== 'afc'"
          class="flex flex-row items-start justify-start gap-[0px_12px] mix-blend-normal"
        >
          <img
            class="w-[34px] relative rounded h-6 object-cover mix-blend-normal"
            alt="credit card icon"
            src="assets/images/home/payment-method-icon@2x.png"
          />

          <img
            class="w-[34px] relative rounded h-6 object-cover mix-blend-normal"
            alt="credit card icon"
            src="assets/images/home/Mastercard.svg"
          />

          <img
            class="w-[34px] relative rounded h-6 mix-blend-normal"
            alt="credit card icon"
            src="assets/images/home/PayPal.svg"
          />

          <img
            class="w-[34px] relative rounded h-6 object-cover mix-blend-normal"
            alt="credit card icon"
            src="assets/images/home/payment-method-icon-3@2x.png"
          />
        </div>
      </div>
    </div>
  </div>
  <div
    id="adyen-backrop"
    class="bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-30 modal-backdrop-color __web-inspector-hide-shortcut__"
  ></div>
</div>

<app-drawer [drawerId]="'checkoutDrawer'" [isOpen]="isDrawerOpen" (reset)="reset()">
  <app-address-list
    *ngIf="isAddressesOpen"
    [eddressCode]="deliveryAddress?.eddressCode"
    (closeDrawer)="closeAddresses($event)"
    (newLocation)="openDrawer('location')"
    (confirmLocation)="getDeliveryAddress($event)"
  ></app-address-list>
  <app-add-location
    *ngIf="isLocationOpen"
    (closeDrawer)="closeLocation($event)"
    (onSubmit)="saveLocation($event)"
  />
  <app-missing-items
    *ngIf="isMissingItemDrawerOpen"
    [items]="missingItems"
    (closeDrawer)="closeMissingDrawer($event)"
  />
  <app-add-payment-card
    *ngIf="isPaymentDrawerOpen && paymentGateway === 'STRIPE'"
    (closeDrawer)="closePaymentCardDrawer($event)"
    (onSubmit)="addCreditCard($event)"
  />
  <app-add-cardknox-card
    *ngIf="isPaymentDrawerOpen && paymentGateway === 'CARDKNOX'"
    (closeDrawer)="closePaymentCardDrawer($event)"
    (onSubmit)="addCardKnoxCreditCard($event)"
  ></app-add-cardknox-card>

  <app-delivery-time-schedule
    [deliverySchedule]="schedule"
    *ngIf="isDeliveryTimeDrawerOpen"
    (closeDrawer)="closeDeliveryDrawer($event)"
    (onSubmit)="changeDeliveryTime($event)"
  />
</app-drawer>
<app-drawer
  [drawerId]="'checkoutBackdropDrawer'"
  [backdrop]="false"
  [isOpen]="isBackdropDrawerOpen"
  (reset)="reset()"
>
  <app-add-adyen-card
    *ngIf="isPaymentDrawerOpen && paymentGateway === 'ADYENDROPIN'"
    (closeDrawer)="closePaymentCardDrawer($event)"
    [orderDetail]="orderDetail"
    (onSubmit)="onSubmitPayment($event)"
  ></app-add-adyen-card>
</app-drawer>
<app-modal [modalId]="'checkOutModal'" [isOpen]="isModalOpen" (reset)="resetModal()">
  <app-confirmation-popup
    *ngIf="isConfirmationOpen"
    [title]="confirmationTitle"
    (closeModal)="closeConfrimationPopUp($event)"
    (onConfirm)="removePromoCode()"
  />
</app-modal>
