import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  AfterViewInit,
  ViewEncapsulation,
} from '@angular/core';
import { DrawerInterface, DrawerOptions, InstanceOptions, Drawer } from 'flowbite';

@Component({
    selector: 'app-drawer',
    templateUrl: './drawer.component.html',
    styleUrl: './drawer.component.scss',
    standalone: true,
    encapsulation: ViewEncapsulation.None
})
export class DrawerComponent implements OnChanges, AfterViewInit {
  @Input() isOpen: boolean;
  @Input() backdrop: boolean = true;
  @Input() drawerId;

  @Output() reset: EventEmitter<boolean> = new EventEmitter<boolean>();

  // options with default values
  drawer: DrawerInterface;
  options: DrawerOptions = {
    placement: 'right',
    backdrop: true,
    bodyScrolling: false,
    edge: true,
    edgeOffset: '',
    backdropClasses:
      'back-drop bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-[31] modal-backdrop-color',
    onHide: () => {
      const backdrop = document.getElementsByClassName('back-drop');
      for (var i = 0; i < backdrop.length; i++) {
        const el: any = backdrop[i];
        el.style.display = 'none';
      }
      this.reset.emit(true);
    },
    onShow: () => {},
    onToggle: () => {},
  };

  // instance options object
  instanceOptions: InstanceOptions = {
    id: 'drawer',
    override: true,
  };

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isOpen'].currentValue) {
      this.openDrawer();
    }

    if (!changes['isOpen'].currentValue) {
      this.onCloseDrawer();
    }
  }
  ngAfterViewInit() {
    this.options.backdrop = this.backdrop;
    const $targetEl: any = document.getElementById(this.drawerId);
    this.drawer = new Drawer($targetEl, this.options, this.instanceOptions);
  }

  openDrawer() {
    this.drawer?.show();
  }

  onCloseDrawer() {
    this.drawer?.hide();
  }
}
