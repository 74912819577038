// my-feature.selectors.ts
import { AppState } from '@app/app.state';
import { createSelector } from '@ngrx/store';
import { PageTitlesState } from '../states/pageTitlesState';


export const selectPageTitlesState = (state: AppState) => state.pages;

export const selectPagesState = createSelector(
    selectPageTitlesState,
  (state: PageTitlesState) => state // Replace with the correct property
);
export const selectDataLoaded = createSelector(
    selectPageTitlesState,
  (state: PageTitlesState) => state.isLoaded // Boolean flag indicating if the data is loaded
);
