<div class="self-stretch flex flex-col  justify-start py-8 px-4 md:px-16 text-gray-900">
  <div class="self-stretch flex flex-col md:flex-row  justify-start gap-[0px_48px]">
    <div class="flex-1 flex flex-col  justify-start gap-[12px_0px]">
      <div class="self-stretch flex flex-col items-start justify-start">
        <h1 class="self-stretch relative leading-[30px] font-semibold">Your Cart</h1>
      </div>

      <div class="self-stretch flex flex-row items-start justify-start text-xs text-gray-800">
        <div class="flex-1 flex flex-col items-start justify-start">
          <div class="self-stretch grid md:grid-cols-3 items-start justify-start gap-[24px_24px] mb-3">
            <ng-container *ngFor="let item of cartItems">
              <app-view-cart-item [item]="item" (increaseQuantity)="increaseItemQuantity($event)"
                (decreaseQuantity)="decreaseItemQuantity($event)" (itemToRemove)="removeItem($event)" />
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="cartItems?.length > 0" class="flex flex-col  justify-start gap-[12px_0px]">
      <div class="relative leading-[30px] font-semibold">Order Summary</div>
      <div
        class="md:w-[400px] rounded-lg bg-base-white box-border flex flex-col  justify-start p-6 gap-[16px_0px] text-sm text-gray-800 border-[1px] border-solid border-gray-250">
        <div class="self-stretch flex flex-row items-center justify-between">
          <div class="relative leading-[20px] font-semibold">Subtotal</div>
          <div class="relative leading-[20px] font-semibold">
            {{ configuration?.tenantCurrencySymbol }}
            {{ cartSummery?.subtotalPrice | number : "1.2-2" }}
          </div>
        </div>
        <div class="self-stretch flex flex-row items-center justify-between">
          <div class="relative leading-[20px] font-semibold">Delivery Charge</div>
          <div class="relative leading-[20px] font-semibold">
            {{ configuration?.tenantCurrencySymbol }}{{ cartSummery?.deliveryPrice | number : "1.2-2" }}
          </div>
        </div>
        <!-- <div class="self-stretch flex flex-row items-center justify-between">
          <div class="relative leading-[20px] font-semibold">Taxes</div>
          <div class="relative leading-[20px] font-semibold">$2.00</div>
        </div> -->
        <div class="md:w-[352px] relative bg-gray-250 h-px"></div>
        <div class="self-stretch flex flex-row items-center justify-between text-base text-gray-900">
          <b class="relative leading-[24px]">Total</b>
          <b class="relative text-xl leading-[30px]">{{ configuration?.tenantCurrencySymbol }}{{ cartSummery?.totalPrice
            |
            number : "1.2-2" }}</b>
        </div>
        <div (click)="redirectToCheckout()"
          class="md:w-[352px] rounded-lg bg-brand-600 shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] box-border h-10 overflow-hidden shrink-0 flex flex-row items-center justify-center py-2.5 px-3.5 gap-[0px_4px] text-base-white border-[1px] border-solid border-brand-600 cursor-pointer">
          <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon"
            src="" />

          <div class="flex flex-row items-center justify-center py-0 px-0.5 cursor-pointer">
            <div class="relative leading-[20px] font-semibold">Continue to Checkout</div>
          </div>
          <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon"
            src="" />
        </div>
        <div class="self-stretch flex flex-col items-center justify-start gap-[8px_0px] text-xs">
          <div class="self-stretch flex flex-row items-center justify-between">
            <div class="relative leading-[18px]">Available payment methods</div>
          </div>
          <div class="flex flex-row items-start justify-start gap-[0px_12px] mix-blend-normal">
            <img class="w-[34px] relative rounded h-6 object-cover mix-blend-normal"  alt="credit card icon"
              src="assets/images/home/payment-method-icon@2x.png" />

            <img class="w-[34px] relative rounded h-6 object-cover mix-blend-normal"  alt="credit card icon"
              src="assets/images/home/Mastercard.svg" />

            <img class="w-[34px] relative rounded h-6 mix-blend-normal"  alt="credit card icon" src="assets/images/home/PayPal.svg" />

            <img class="w-[34px] relative rounded h-6 object-cover mix-blend-normal"  alt="credit card icon"
              src="assets/images/view-cart/payment-method-icon@2x.png" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="cartItems?.length === 0">
  <div class="flex flex-col items-center">
    <div class="flex-grow text-center">
      <img class="w-24 h-24 ml-[105px] mb-5 relative overflow-hidden shrink-0" alt="shoping cart icon"
        src="assets/images/home/shoppingcart.svg" id="shoppingCartIcon" />
      <h1 class="font-bold text-xl">YOUR CART IS EMPTY :(</h1>
      <span class="text-base">Looks like you haven't added anything here yet.</span>
    </div>
  </div>
</div>
<app-drawer [drawerId]="'viewCartDrawer'" [isOpen]="isDrawerOpen" (reset)="reset()">
  <app-missing-items *ngIf="isMissingItemDrawerOpen" [items]="missingItems"
    (closeDrawer)="closeMissingDrawer($event)" />
</app-drawer>