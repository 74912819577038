<div class="p-4">
  <div
    class="flex-1 w-[360px] bg-white overflow-hidden flex flex-col items-center justify-start gap-[24px_0px] border-l-[1px] border-solid border-gray-200"
  >
    <div
      class="self-stretch flex flex-row items-start justify-start py-0 px-0 relative gap-[0px_8px]"
    >
      <div
        class="flex-1 flex flex-row items-center justify-start pt-3 px-0 pb-0 gap-[0px_16px] z-[0]"
      >
        <div class="flex-1 flex flex-col items-start justify-start gap-[4px_0px] text-11xl">
          <div class="self-stretch relative leading-[36px] font-semibold">Choose A Date</div>
        </div>
      </div>
      <div
        (click)="close()"
        class="w-10 my-0 mx-[!important] absolute top-[12px] right-[16px] rounded-lg h-10 overflow-hidden shrink-0 flex flex-row items-center justify-center p-2 box-border z-[1]"
      >
        <img
          class="w-6 relative h-6 overflow-hidden shrink-0 cursor-pointer"
          alt="close icon"
          src="assets/images/home/xclose.svg"
        />
      </div>
    </div>
  </div>

  <div class="mt-10 self-stretch flex flex-col items-start justify-start gap-[24px_0px]">
    <div class="self-stretch flex flex-col items-start justify-start gap-[15px_0px] cursor-pointer">
      <b
        class="relative leading-[24px] cursor-pointer text-brand-600"
        id="back"
        *ngIf="showTimeEntries && appName !== 'afc'"
        (click)="back()"
        >Back</b
      >
      <ng-container *ngIf="!showTimeEntries && appName !== 'afc'">
        <div
          *ngFor="let schedule of deliverySchedule"
          (click)="schedule.isActive ? chooseTime(schedule) : ''"
          [ngClass]="schedule.isActive ? 'bg-base-white' : 'bg-gray-300'"
          class="flex-1 w-full p-3 rounded-lg flex flex-row items-center justify-start gap-[0px_4px] border-[1px] border-solid border-gray-250"
        >
          <div class="flex-1 flex flex-col items-start justify-start py-1 px-3 gap-[4px_0px]">
            <div
              [ngClass]="schedule.isActive ? 'font-semibold' : ''"
              class="self-stretch relative leading-[18px]"
            >
              {{ schedule.label }}
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="appName === 'afc'">
        <p-calendar
          #calendar
          [minDate]="minDate"
          [(ngModel)]="scheduleDate"
          (onSelect)="onDateClick($event)"
          [showIcon]="true"
          [inline]="true"
          (onMonthChange)="highlightDisabledays()"
        ></p-calendar>
      </ng-container>
      <ng-container *ngIf="showTimeEntries">
        <div
          *ngFor="let timeslot of timeEntries"
          (click)="selectTime(timeslot); close()"
          class="flex-1 w-full p-3 rounded-lg bg-base-white flex flex-row items-center justify-start gap-[0px_4px] border-[1px] border-solid border-gray-250"
        >
          <div class="flex-1 flex flex-col items-start justify-start py-1 px-3 gap-[4px_0px]">
            <div class="self-stretch relative leading-[18px] font-semibold">
              {{ timeslot.label }}
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <!-- <button type="button" (click)="onSubmitFn()"
            class="cursor-pointer disabled:opacity-75 [border:none] p-0 bg-[transparent] self-stretch flex flex-row items-center justify-center">
            <div
                class="flex-1 rounded-lg bg-brand-600 shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] overflow-hidden flex flex-row items-center justify-center p-2.5 gap-[0px_6px] border-[1px] border-solid border-brand-600">
                <img class="h-5 w-5 relative overflow-hidden shrink-0 hidden" alt="hidden icon" />

                <div class="flex flex-row items-center justify-center py-0 pr-0 pl-0.5">
                    <div class="flex flex-col items-start justify-start py-0 px-0">
                        <div
                            class="relative text-base leading-[24px] font-semibold font-text-md-semibold text-base-white text-left">
                            Update
                        </div>
                    </div>
                </div>
                <img class="h-5 w-5 relative overflow-hidden shrink-0 hidden" alt="hidden icon" />
            </div>
        </button> -->
  </div>
</div>
