import { Component, OnInit } from '@angular/core';
import { FavoritesService } from './services/favorites.service';
import { LoaderService } from '@app/core/services/loader.service';
import { AuthService } from '@app/core/services/auth.service';
import { GenericService } from '@app/core/services/generics.service';
import { ProductItemComponent } from '../../shared/product-item/product-item.component';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-favorites',
    templateUrl: './favorites.component.html',
    styleUrl: './favorites.component.scss',
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        ProductItemComponent,
    ],
})
export class FavoritesComponent implements OnInit {
  productList: any[] = [];
  selectedStore;
  constructor(
    private favoritesService: FavoritesService,
    private loaderService: LoaderService,
    private authService: AuthService,
    private genericService: GenericService
  ) {
    this.authService.selectedStore.subscribe((data) => {
      if (data) {
        this.selectedStore = data;
      }
    });
  }
  ngOnInit() {
    this.genericService.setPageTitleAndDescription('favorites', '')
    this.getFavoriteProducts();
  }
  getFavoriteProducts() {
    this.loaderService.open();
    this.favoritesService.getFavoriteProducts(this.selectedStore.id).subscribe(
      (result) => {
        this.productList = result || [];
        this.loaderService.close();
      },
      (err) => {
        this.loaderService.close();
      }
    );
  }
  onUpdateProduct(product) {
    this.genericService.updateHomepageProducts(product, '');
  }
}
