import { Component, OnInit } from '@angular/core';
import { ReferFriendService } from './services/refer-friend.service';
import { TooltipOptions, InstanceOptions, TooltipInterface, Tooltip } from 'flowbite';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-refer-friend',
  templateUrl: './refer-friend.component.html',
  styleUrl: './refer-friend.component.scss',
  standalone: true,
  imports: [NgIf],
})
export class ReferFriendComponent implements OnInit {
  constructor(private referFriendService: ReferFriendService) {}
  referralDetail;

  optionsTooltip: TooltipOptions = {
    placement: 'top',
    triggerType: 'click',
    onHide: () => {
      // console.log('tooltip is shown');
    },
    onShow: () => {
      // console.log('tooltip is hidden');
    },
    onToggle: () => {
      // console.log('tooltip is toggled');
    },
  };

  instanceOptions: InstanceOptions = {
    id: 'tooltipContent',
    override: true,
  };

  tooltip: TooltipInterface;

  toolTipMessage;

  ngOnInit() {
    // this.genericService.setPageTitleAndDescription('refer-friend', '')
    this.getAppInviteLink();
  }

  getAppInviteLink() {
    const params = {};
    this.referFriendService.appInviteLink(params).subscribe((result) => {
      this.referralDetail = result;
    });
  }
  copyMessage(val: string) {
    const $targetEl: HTMLElement = document.getElementById('tooltipContent');
    const $triggerEl: HTMLElement = document.getElementById('tooltipButton');
    this.tooltip = new Tooltip($targetEl, $triggerEl, this.optionsTooltip, this.instanceOptions);
    navigator.clipboard
      .writeText(val)
      .then(() => {
        this.toolTipMessage = 'Promocode copied to clipboard';
        this.tooltip.show();

        setTimeout(() => {
          this.tooltip.hide();
        }, 2000);
      })
      .catch((err) => (this.toolTipMessage = 'Error copying text: ' + err));
  }
}
