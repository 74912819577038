import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  public isLoader = new BehaviorSubject<any>(false);
  constructor() {}

  getLoader() {
    return this.isLoader.asObservable();
  }

  open() {
    this.isLoader.next(true);
  }
  close() {
    this.isLoader.next(false);
  }
}
