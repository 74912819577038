<div
  class="md:w-[400px] self-stretch flex-1 bg-white overflow-hidden flex flex-col items-center justify-start gap-[24px_0px] border-l-[1px] border-solid border-gray-200">
  <div
    class="self-stretch flex flex-row items-start justify-start py-0 px-2 md:px-6 relative gap-[0px_8px] text-xl text-gray-900">
    <div class="flex-1 flex flex-row items-start justify-start pt-6 px-0 pb-0 gap-[0px_16px] z-[0]">
      <img class="w-10 relative rounded-lg h-10" alt="feature icon" src="assets/images/home/featured-icon.svg" />

      <div class="flex-1 flex flex-col items-start justify-start gap-[4px_0px]">
        <div class="self-stretch relative leading-[30px] font-semibold">Payment details</div>
        <div class="self-stretch relative text-sm leading-[20px] text-gray-600">
          Update your plan payment details.
        </div>
      </div>
    </div>
    <div (click)="close()"
      class="w-10 !m-[0] absolute top-[12px] right-[16px] rounded-lg h-10 overflow-hidden shrink-0 flex flex-row items-center justify-center p-2 box-border z-[1] cursor-pointer">
      <img class="w-5 relative h-5 overflow-hidden shrink-0" alt="close icon" src="assets/images/home/xclose.svg" />
    </div>
  </div>
  <div class="self-stretch flex-1 flex flex-col items-center justify-start py-0 px-6 gap-[24px_0px]">
    <div class="self-stretch flex flex-col items-start justify-start text-base text-white font-manrope">
      <div
        class="self-stretch relative rounded-[15px] [background:linear-gradient(45deg,_#101828,_#475467)] shadow-[8px_10px_16px_rgba(0,_0,_0,_0.05)] box-border h-[203px] overflow-hidden shrink-0 border-[1px] border-solid border-gray1">
        <img class="absolute top-[142px] left-[255px] rounded w-[45.33px] h-8 object-cover"  alt="credit card icon"
          src="assets/images/home/payment-method-icon@2x.png" />

        <div class="absolute top-[152px] left-[16px] tracking-[0.15em] font-semibold">
          1234 1234 1234 1234
        </div>
        <div class="absolute top-[128px] left-[16px] text-xs tracking-[0.05em] uppercase font-semibold">
          OLIVIA RHYE
        </div>
        <div class="absolute top-[128px] left-[172px] text-xs tracking-[0.05em] font-semibold text-right">
          06/24
        </div>
        <div class="absolute top-[20px] left-[20px] font-semibold font-inter">Bank Name</div>
        <img class="absolute top-[20px] right-[20px] w-5 h-6 overflow-hidden" alt="hidden icon"
          src="assets/images/home/paypass-icon.svg" />
      </div>
    </div>
    <form [formGroup]="userPaymentCardGroup"
      class="self-stretch flex flex-col items-start justify-start gap-[16px_0px]">
      <div class="self-stretch flex flex-col items-start justify-start gap-[6px_0px]">
        <div class="self-stretch flex flex-col items-start justify-start gap-[6px_0px] mb-4">
          <div class="relative leading-[20px]">Name on card</div>
          <input type="text" placeholder="Name on card"
            class="self-stretch rounded-lg bg-base-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] flex flex-row items-center justify-start py-2.5 px-3.5 gap-[0px_8px] text-base text-gray-900 border-[1px] border-solid border-gray-300"
            formControlName="nameOnCard" />
          <span *ngIf="
              userAccountForm['nameOnCard'].invalid &&
              userAccountForm['nameOnCard'].errors['required'] &&
              isSubmitted
            " class="text-error-500">Name is required</span>
        </div>
        <ngx-stripe-card-group class="w-full" [stripe]="stripe" [elementsOptions]="elementsOptions">
          <div class="self-stretch flex-1 flex-col items-start justify-start gap-[6px] mb-4">
            <div class="relative leading-[20px] mb-3">Card Number</div>
            <ngx-stripe-card-number class="mb-3" containerClass="stripe-element" [options]="cardOptions"
              (change)="changeCardNumber($event)"></ngx-stripe-card-number>
            <span class="text-error-500" *ngIf="incomplete_number">Card number is required.</span>
          </div>

          <div class="self-stretch flex flex-row items-start justify-start gap-[0px_16px]">
            <div class="flex-1 flex flex-col items-start justify-start gap-[6px_0px]">
              <div class="self-stretch flex-1 flex-col items-start justify-start gap-[6px] mb-4">
                <div class="relative leading-[20px] mb-3">Expiry</div>
                <ngx-stripe-card-expiry containerClass="stripe-element" [options]="cardOptions"
                  (change)="changeCardNumber($event)"></ngx-stripe-card-expiry>
                <span class="text-error-500" *ngIf="incomplete_expiry">Expiry is required.</span>
              </div>
            </div>
            <div class="flex-1 flex flex-col items-start justify-start gap-[6px_0px]">
              <div class="self-stretch flex-1 flex-col items-start justify-start gap-[6px] mb-4">
                <div class="relative leading-[20px] mb-3">CVC</div>
                <ngx-stripe-card-cvc containerClass="stripe-element" [options]="cardOptions"
                  (change)="changeCardNumber($event)"></ngx-stripe-card-cvc>
                <span class="text-error-500" *ngIf="incomplete_cvc">CVC is required.</span>
              </div>
            </div>
          </div>
        </ngx-stripe-card-group>
      </div>
    </form>
    <div class="self-stretch relative bg-gray-200 h-px"></div>
  </div>
  <div class="self-stretch flex flex-col items-center justify-start border-t-[1px] border-solid border-gray-200">
    <div class="self-stretch flex flex-row items-center justify-end py-4 px-6">
      <div class="flex flex-row items-start justify-start gap-[0px_12px]">
        <div (click)="close()"
          class="rounded-lg cursor-pointer bg-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] overflow-hidden flex flex-row items-center justify-center py-2.5 px-3.5 gap-[0px_4px] border-[1px] border-solid border-gray-300">
          <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />

          <div class="flex flex-row items-center justify-center py-0 px-0.5">
            <div class="relative leading-[20px] font-semibold">Cancel</div>
          </div>
          <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />
        </div>
        <div (click)="onSubmitFn()"
          class="rounded-lg cursor-pointer bg-brand-600 shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] overflow-hidden flex flex-row items-center justify-center py-2.5 px-3.5 gap-[0px_4px] text-white border-[1px] border-solid border-brand-600">
          <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />

          <div class="flex flex-row items-center justify-center py-0 px-0.5">
            <div class="relative leading-[20px] font-semibold">Confirm</div>
          </div>
          <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />
        </div>
      </div>
    </div>
  </div>
</div>