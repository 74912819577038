<div class="w-full h-auto self-stretch bg-gray-100 flex-col items-center justify-start py-12 px-0 box-border">
    <div class="self-stretch flex flex-row items-start justify-between py-0 px-4 md:px-16 flex-wrap">
        <div class="flex-1 flex flex-col items-start justify-start gap-[8px_0px] w-full md:w-auto px-4 md:px-0">
            <h4 class="self-stretch relative leading-[30px] font-semibold w-full break-words">
                Never miss our updates about new arrivals and special offers
            </h4>
            <h5 class="self-stretch relative text-base leading-[24px] text-gray-true-600">
                Get the latest news & updates from the shop
            </h5>
        </div>
        <form [formGroup]="newsletterForm" class="mt-4 md:mt-0 w-full md:w-auto px-4 md:px-0">
            <div class="w-full md:w-[400px] flex flex-row items-start justify-start gap-[0px_16px] text-sm text-gray-900">
                <div class="flex-1 flex flex-col items-start justify-start gap-[6px_0px]">
                    <div class="self-stretch flex flex-col items-start justify-start gap-[6px_0px]">
                        <div class="w-[100px] relative leading-[20px] hidden">
                            Stay up to date
                        </div>
                        <div class="self-stretch flex flex-row items-center justify-start py-0.5 px-0 gap-[0px_8px] text-base text-gray-true-500">
                            <div class="flex-1 flex flex-row items-center justify-start">
                                <input 
                                    type="text" 
                                    placeholder="Enter your email"
                                    formControlName="newsletterEmail"
                                    class="flex-1 pl-0 pr-0 leading-[24px] overflow-hidden text-ellipsis whitespace-nowrap bg-transparent outline-none text-gray-true-500 border-0 focus:ring-0 border-b-2 border-gray-300 focus:outline-none focus:border-gray-500" 
                                    [class.border-red-500]="isSubmitted && newsletterForm.controls['newsletterEmail'].invalid"
                                    />
                            </div>
                            <img class="w-4 h-4 hidden" alt="hidden icon" />
                        </div>
                        <div *ngIf="isSubmitted && newsletterForm.get('newsletterEmail').hasError('required')" class="text-red-500">Email is required</div>
                        <div *ngIf="isSubmitted && isSuccess" class="text-green-500">You have been subscribed successfully</div>
                    </div>
                    <div class="w-80 relative leading-[20px] text-gray-600 hidden">
                    This is a hint text to help user.
                    </div>
                </div>
                <div class="rounded-lg bg-brand-600 shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] overflow-hidden flex flex-row items-center justify-center py-2.5 px-4 gap-[0px_6px] text-base text-base-white border-[1px] border-solid border-brand-600">
                    <img
                        class="w-5 relative h-5 overflow-hidden shrink-0 hidden"
                        alt="hidde icon"
                    />

                    <div class="flex flex-row items-center justify-center py-0 px-0.5">
                        <button type="submit" class="relative leading-[24px] font-semibold" (click)="onSubmit()">
                            Subscribe
                        </button>
                    </div>
                    <img
                        class="w-5 relative h-5 overflow-hidden shrink-0 hidden"
                        alt="hidden icon"
                    />
                </div>
            </div>
        </form>
    </div>
</div>
