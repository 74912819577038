<div
  class="flex-1 rounded-lg bg-base-white flex flex-row items-center justify-start gap-[0px_4px] border-[1px] border-solid border-gray-250">
  <img class="w-[100px] rounded-3xs h-[100px] object-cover" alt="product image" [src]="item?.product?.imageUrl" />

  <div class="flex-1 flex flex-col items-start justify-start py-1 px-3 gap-[4px_0px]">
    <div class="self-stretch relative leading-[18px] font-semibold">
      {{ item?.product?.label }}
    </div>
    <div class="self-stretch relative leading-[18px]">
      {{ item.quantity }}
    </div>
    <div class="self-stretch flex flex-row items-center justify-between text-base text-brand-500">
      <div class="flex flex-row items-center justify-start">
        <b class="relative leading-[24px]">{{ configuration?.tenantCurrencySymbol }}{{ item?.product?.price | number :
          "1.2-2"
          }}</b>
      </div>
      <div class="w-[74px] relative h-7 text-base-white">
        <div class="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-mid bg-brand-500"></div>
        <b class="absolute h-4/5 w-[8.43%] top-[7.14%] left-[44.59%] leading-[24px] inline-block">{{
          item.quantity
          }}</b>
        <img
          class="absolute h-[59.82%] w-[21.62%] top-[21.43%] right-[6.76%] bottom-[18.75%] left-[71.62%] max-w-full overflow-hidden max-h-full cursor-pointer"
          alt="plus icon" src="assets/images/view-cart/plus.svg" (click)="incrementQuantity()" />

        <img
          class="absolute h-[60.5%] w-[21.62%] top-[21.43%] right-[70.27%] bottom-[18.07%] left-[8.11%] max-w-full overflow-hidden max-h-full cursor-pointer"
          alt="trash icon" src="assets/images/view-cart/trash03.svg" *ngIf="item.quantity === 1" (click)="removeItem()" />
        <img
          class="absolute h-[60.5%] w-[21.62%] top-[21.43%] right-[70.27%] bottom-[18.07%] left-[8.11%] max-w-full overflow-hidden max-h-full cursor-pointer"
          alt="minus icon" src="assets/images/view-cart/minus.svg" *ngIf="item.quantity > 1" (click)="decrementQuantity()" />
      </div>
    </div>
  </div>
</div>