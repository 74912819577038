export class Urls {
  public static MARKETPLACE_APP_DATA: string = 'api/market/app/public/marketPlaceAppData';
  public static CHECK_CREDIBILITY: string = 'v1/app/checkCredibility/';
  public static SERVICES: string = 'api/market/app/services/';
  public static SERVICES_PUBLIC: string = 'api/market/app/public/services/';
  public static SERVICES_STORES: string = 'api/market/app/services/stores';
  public static SERVICES_PUBLIC_STORES: string = 'api/market/app/public/services/stores';
  public static SERVICES_BANNERS: string = '/banners';
  public static SERVICES_HOME_PAGES: string = '/homepages';
  public static SAVE_ADDRESS: string = 'api/market/app/saveAddress/';
  public static DELETE_ADDRESS: string = 'v1/app/deleteAddress/';
  public static MANAGE_BASKET: string = 'v1/app/manageBasket/';
  public static CLEAR_BASKET: string = 'v1/app/clearBasket/';
  public static VIEW_BASKET: string = 'v1/app/viewBasket/';
  public static PLACE_ORDER_NEW: string = 'v1/app/placeOrderNew/';
  public static CALCULATE_BASKET: string = 'api/market/app/calculateBasket/';
  public static ADDRESS_LIST: string = 'api/market/app/address/';
  public static RECENT_ORDERS: string = 'api/market/app/orders/recent/';
  public static ADMIN_SEARCH: string = 'v1/api/searchengine/public/';
  public static USER_SEARCH: string = 'api/searchengine/user/search';
  public static GET_ORDER_DETAILS: string = 'v1/app/getOrderDetails/';
  public static CANCEL_ORDER: string = 'v1/app/cancelOrder/';
  public static VALIDATE_PROMO_CODE: string = 'v1/app/validatePromoCode';
  public static ADD_TO_FAVORITES: string = 'api/market/app/addToFavorites';
  public static CHECK_PERMANENT_PROMO: string = 'api/market/app/checkPermanentPromo';
  public static APP_INVITE_LINK: string = 'api/market/app/appInviteLink/';
  public static GET_FAVORITES: string = 'api/market/app/favorites/';
  public static PAYMENT_SESSION: string = 'api/market/app/paymentSession/';
  public static DELETE_USER: string = 'api/market/app/deleteUser';
  public static USER: string = 'api/market/app/public/users';
  public static UPDATE_USER: string = 'api/market/app/updateUserDetails';
  public static WALLET_HISTORY: string = 'api/market/app/walletHistory';
  public static ADD_CREDIT_CARD: string = 'v1/app/addCreditCard';
  public static DELETE_CREDIT_CARD: string = 'v1/app/deleteCreditCard';
  public static GET_CREDIT_CARDS: string = 'v1/app/getCreditCards';
  public static PRODUCT_ITEMS: string = 'api/market/admin/product-items';
  public static AUTHENTICATE_PUBLIC: string = 'api/market/app/public/authenticate';
  public static SCHEDULES: string = 'v1/app/store/schedules/';
  public static CREATE_OTP: string = 'api/v2/user-mgmt/otp/create';
  public static VERIFY_OTP: string = 'api/v2/user-mgmt/otp/verify';
  public static COMPANY_SETTINGS: string = 'api/market/app/public/website/settings/';
  public static PAGES_DETAIL: string = 'api/market/app/public/webPageDetails/';
}
