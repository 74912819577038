import { createReducer, on } from "@ngrx/store";
import { addToPageTitles } from "../actions/pageTitles.action";
import { initialPageTitleState } from "../states/pageTitlesState";



export const pageTitlesReducer = createReducer(initialPageTitleState, 
    on(addToPageTitles, (state, action) => {
    return {...state, pageData: action.pageData,};
})
);