import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { EddressFacadeService } from '@app/core/services/eddressFacade.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { Router } from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import { NotificationService } from '@app/core/services/notification.service';
import { FirebaseAuthErrorHandler } from '@app/core/error/firebaseError';
import { LoaderService } from '@app/core/services/loader.service';
import { environment } from '@environments/environment';
import { COUNTRIES } from '@app/core/util/countries';
import firebase from 'firebase/compat/app';
import { GenericService } from '@app/core/services/generics.service';
import { SearchPipe } from '../../../../shared/pipes/search.pipe';
import { NgOtpInputModule } from 'ng-otp-input';
import { NgIf, NgClass, NgFor } from '@angular/common';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
  standalone: true,
  imports: [NgIf, FormsModule, ReactiveFormsModule, NgClass, NgFor, NgOtpInputModule, SearchPipe],
})
export class LoginComponent implements OnInit {
  @Output() isLoginOpen = new EventEmitter<boolean>();
  @Output() isSignupOpen = new EventEmitter<boolean>();
  @Output() isForgotPasswordOpen = new EventEmitter<boolean>();
  loginFormGroup: FormGroup;
  loginOptions = {
    loginWithEmail: false,
    loginWithFacebook: false,
    loginWithGoogle: false,
    loginWithPhone: false,
    loginWithOtpEnabled: false,
  };

  disableLoginBtn = false;
  isSubmitted = false;
  isLoginWithPhone = false;
  open = false;
  searchValue = '';
  selectedCountry = COUNTRIES.countriesList[0];
  countries = COUNTRIES.countriesList;
  countryCode = environment.eddressAppConfig.countryCode;

  phoneNumber: string;
  verificationCode: string;
  recaptchaVerifier: firebase.auth.RecaptchaVerifier;
  confirmationResult: firebase.auth.ConfirmationResult;
  showOtpField = false;
  marketAppSettings;
  timeLeft: number = 30;
  interval;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    public eddress: EddressFacadeService,
    public authService: AuthService,
    private notification: NotificationService,
    private loaderService: LoaderService,
    private genericService: GenericService
  ) {
    this.genericService.marketAppSetting.subscribe((data) => {
      if (data) {
        this.marketAppSettings = data;
      }
    });
  }

  ngOnInit(): void {
    this.setDefaultCountry();
    for (let [key, value] of Object.entries(
      this.marketAppSettings?.marketPlaceAppAttributes?.authentication
    )) {
      const loginValue: any = value;
      if (key === 'loginWithEmail') this.loginOptions.loginWithEmail = loginValue.value;
      if (key === 'loginWithFacebook') this.loginOptions.loginWithFacebook = loginValue.value;
      if (key === 'loginWithGoogle') this.loginOptions.loginWithGoogle = loginValue.value;
      if (key === 'loginWithPhone') this.loginOptions.loginWithPhone = loginValue.value;
      if (key === 'loginWithOtpEnabled') this.loginOptions.loginWithOtpEnabled = loginValue.value;
    }
    this._initLoginForm();
    this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      size: 'invisible',
      callback: (response) => {
        // reCAPTCHA solved, allow sendVerificationCode to be called.
        // this.sendVerificationCode();
      },
      'expired-callback': () => {
        // Response expired. Ask user to solve reCAPTCHA again.
      },
    });
    this.recaptchaVerifier.render();
  }

  toggleDropdown() {
    this.open = !this.open;
  }
  setDefaultCountry() {
    this.selectedCountry = this.countries.find((country) => country.dial_code === this.countryCode);
  }
  selectCountry(country) {
    this.selectedCountry = country;
    console.log('this.selectedCountry: ', this.selectedCountry);
    this.open = false;
    this.searchValue = '';
  }

  closeLogin() {
    this.isLoginOpen.emit(false);
  }

  openSignup() {
    this.isSignupOpen.emit(true);
  }

  openForgotPassword() {
    this.isForgotPasswordOpen.emit(true);
  }

  private _initLoginForm() {
    this.loginFormGroup = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
  }

  loginByFacebook() {
    return this.authService.FacebookAuth();
  }

  loginByGoogle() {
    this.closeLogin();
    return this.authService.GoogleAuth();
  }
  loginWithPhone() {
    this.isLoginWithPhone = true;
  }

  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.pauseTimer();
      }
    }, 1000);
  }
  pauseTimer() {
    clearInterval(this.interval);
  }
  sendVerificationCode() {
    if (this.loginOptions.loginWithOtpEnabled) {
      this.verifyByWhatsApp();
    } else {
      this.verifyByFirebase();
    }
  }
  verifyByWhatsApp() {
    const phoneNumber = this.selectedCountry.dial_code + this.phoneNumber;
    const params = {
      phone: phoneNumber,
      appName: environment.eddressAppConfig.appName,
      useWhatsApp: true,
      tenantUid: this.marketAppSettings.tenantUid,
      operationUid: environment.eddressAppConfig.operationUid,
    };
    this.loaderService.open();
    this.authService.createWhatsAppOtp(params).subscribe(
      (result) => {
        this.showOtpField = true;
        this.startTimer();
        this.loaderService.close();
      },
      (error) => {
        this.loaderService.close();
      }
    );
  }
  verifyWhatsAppOTPCODE(code) {
    if (code.length === 6) {
      const phoneNumber = this.selectedCountry.dial_code + this.phoneNumber;
      const params = {
        language: 'en',
        phone: phoneNumber,
        code: code,
        operationUid: environment.eddressAppConfig.operationUid,
        appName: environment.eddressAppConfig.appName,
        tenantUid: this.marketAppSettings.tenantUid,
      };
      this.authService.verifyWhatsAppOtp(params).subscribe((result) => {
        this.loginAfterPhoneNumberVerification(result, 'whatsApp');
      });
    }
  }
  verifyByFirebase() {
    const phoneNumber = this.selectedCountry.dial_code + this.phoneNumber;
    this.loaderService.open();
    this.authService.attemptAuthByPhoneNumber(phoneNumber, this.recaptchaVerifier).subscribe(
      (confirmationResult) => {
        this.confirmationResult = confirmationResult;
        this.showOtpField = true;
        this.startTimer();
        this.loaderService.close();
      },
      (error) => {
        const errorMessage = FirebaseAuthErrorHandler.convertMessage(error['code']);
        this.notification.errorNotification(errorMessage);
        this.loaderService.close();
      }
    );
  }
  verifyFirebaseCode(code: string): void {
    if (code.length === 6) {
      this.confirmationResult
        .confirm(code)
        .then((result) => {
          this.loginAfterPhoneNumberVerification(result, 'firebase');
        })
        .catch((error) => {
          console.error();
          this.loaderService.close();
          this.notification.errorNotification('Code verification failed');
          this.showOtpField = false;
        });
    }
  }

  verifyCode(code) {
    if (this.loginOptions.loginWithOtpEnabled) {
      this.verifyWhatsAppOTPCODE(code);
    } else {
      this.verifyFirebaseCode(code);
    }
  }

  loginAfterPhoneNumberVerification(result, type) {
    let credentials;
    if (type === 'firebase') {
      const multiFactor: any = result.user.multiFactor;
      const user: any = multiFactor.user;
      credentials = {
        phone: this.phoneNumber,
        pushToken: user.accessToken,
        os: 'web',
        authUid: user.uid,
        authProvider: result.additionalUserInfo.providerId,
        appName: environment.eddressAppConfig.appName,
        fullName: null,
        email: null,
        appIdentifier: '',
        // pushTokenProjectId: 'goyolla-47a47',
      };
    } else {
      credentials = {
        phone: result.phoneNumber,
        // pushToken: user.accessToken,
        os: 'web',
        authUid: result.userUid,
        authProvider: result.providerId,
        appName: environment.eddressAppConfig.appName,
        fullName: null,
        email: null,
        appIdentifier: '',
        // pushTokenProjectId: 'goyolla-47a47',
      };
    }

    this.loaderService.open();
    this.authService.backendAuthenticate(credentials).subscribe((data) => {
      this.loaderService.close();
      if (!data.fullName) data.fullName = '';
      this.authService.setToken(data.jwtToken);
      this.authService.setUserAuthentication(data);
      this.closeLogin();
      setTimeout(() => {
        window.location.reload();
      }, 2000);
      this.disableLoginBtn = false;
    });
  }

  onSubmit() {
    this.isSubmitted = true;
    const val = this.loginFormGroup.value;

    if (val.email && val.password) {
      const email = val.email;
      const password = val.password;

      let uid = '';
      this.loaderService.open();
      this.authService.attemptAuthByEmailAndPassword(email, password).subscribe(
        (result) => {
          uid = result.user.uid;

          const credentials = {
            appName: environment.eddressAppConfig.appName, // from frontend
            authUid: uid,
            email: email,
            fullName: '',
            appIdentifier: '', // not needed on web
            os: 'web',
            locale: 'es', // from frontend localized
            authProvider: password,
          };

          this.authService.backendAuthenticate(credentials).subscribe((data) => {
            this.loaderService.close();
            // this.notification.successNotification('Login Successfully');
            // this.authService.setLoginWithExpiryForLocalStorage(result.jwt);
            this.authService.setToken(data.jwtToken);
            this.authService.setUserAuthentication(data);
            this.closeLogin();
            window.location.reload();
            this.disableLoginBtn = false;
          });
        },
        (error) => {
          this.loaderService.close();
          const errorMessage = FirebaseAuthErrorHandler.convertMessage(error['code']);
          this.notification.errorNotification(errorMessage);
          this.disableLoginBtn = false;
        }
      );
    }
  }

  get loginForm() {
    return this.loginFormGroup.controls;
  }
}
