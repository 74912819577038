<div class="wrapper">
  <section class="about-wrap text-center bg-gray-50 py-16">
    <div class="container">
      <h2 class="section-title">Contact</h2>

      <p class="section-para">
        For any sort of enquiries please fill below form to contact us. You can call / contact us at
        our provided branch contact numbers as well.
      </p>

      <div class="contact-wrap">
        <div class="container">
          <div class="default-form-wrap p-4 md:px-[70px]">
            <div class="flex justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="125"
                height="51.33"
                viewBox="0 0 125 51.33"
              >
                <g id="iconfinder_MessageSent_1737385" transform="translate(-23.012 -160.325)">
                  <path
                    id="Path_74"
                    data-name="Path 74"
                    d="M294.136,229.3l-17.624-11.136,17.5-12.942a1.933,1.933,0,1,0-2.3-3.108L266.9,220.462l-.01-.008-.01.008-24.814-18.351a1.933,1.933,0,1,0-2.3,3.108l17.5,12.942L239.641,229.3a1.934,1.934,0,1,0,2.067,3.27L260.6,220.629l5.139,3.8a1.933,1.933,0,0,0,2.3,0l5.138-3.8,18.893,11.938a1.934,1.934,0,0,0,2.067-3.27Z"
                    transform="translate(-157.903 -30.311)"
                    [attr.fill]="fillColor"
                  ></path>
                  <path
                    id="Path_75"
                    data-name="Path 75"
                    d="M262.706,160.325H211.777c-7.478,0-13.561,6.624-13.561,14.766v21.8c0,8.143,6.084,14.767,13.561,14.767h50.929c7.479,0,13.563-6.624,13.563-14.767v-21.8C276.269,166.949,270.185,160.325,262.706,160.325Zm9.123,36.563c0,5.485-4.084,9.933-9.123,9.933H211.777c-5.038,0-9.122-4.448-9.122-9.933v-21.8c0-5.485,4.084-9.932,9.122-9.932h50.929c5.04,0,9.123,4.447,9.123,9.932v21.8Z"
                    transform="translate(-128.257)"
                    [attr.fill]="fillColor"
                  ></path>
                  <path
                    id="Path_76"
                    data-name="Path 76"
                    d="M60.274,204.391H25.2a2.192,2.192,0,0,0,0,4.384h35.07a2.192,2.192,0,0,0,0-4.384Z"
                    transform="translate(0 -32.246)"
                    [attr.fill]="fillColor"
                  ></path>
                  <path
                    id="Path_77"
                    data-name="Path 77"
                    d="M83.937,245.8H56.432a2.257,2.257,0,0,0,0,4.384H83.937a2.257,2.257,0,0,0,0-4.384Z"
                    transform="translate(-23.191 -62.546)"
                    [attr.fill]="fillColor"
                  ></path>
                  <path
                    id="Path_78"
                    data-name="Path 78"
                    d="M102.5,287.2H80.926c-.745,0-1.348.982-1.348,2.191s.6,2.193,1.348,2.193H102.5c.744,0,1.348-.981,1.348-2.193S103.243,287.2,102.5,287.2Z"
                    transform="translate(-41.382 -92.842)"
                    [attr.fill]="fillColor"
                  ></path>
                </g>
              </svg>
            </div>

            <form
              [formGroup]="contactFormGroup"
              class="m-0 self-stretch rounded-xl flex flex-col items-start justify-start gap-[24px_0px]"
            >
              <div class="self-stretch flex flex-col items-start justify-start gap-[20px_0px]">
                <div
                  class="self-stretch flex flex-col items-start justify-start gap-[6px_0px]"
                ></div>
                <div class="self-stretch flex flex-col md:flex-row gap-[20px]">
                  <div class="flex-1 flex flex-col items-start justify-start gap-[6px_0px]">
                    <div
                      class="relative text-sm leading-[20px] font-text-md-semibold text-gray-700 text-left"
                    >
                      First Name
                    </div>
                    <div class="flex flex-row items-center justify-start w-full">
                      <input
                        type="text"
                        placeholder="First Name"
                        class="flex-1 overflow-hidden text-ellipsis whitespace-nowrap rounded-lg border-gray-300 focus:outline-none px-2"
                        formControlName="firstName"
                      />
                    </div>
                    <span
                      *ngIf="contactForm['firstName'].invalid && contactForm['firstName'].touched"
                      class="p-error"
                    >
                      <span
                        class="text-error-500"
                        *ngIf="contactForm['firstName'].errors['required']"
                        >First Name is required</span
                      >
                    </span>
                  </div>
                  <div class="flex-1 flex flex-col items-start justify-start gap-[6px_0px]">
                    <div
                      class="relative text-sm leading-[20px] font-text-md-semibold text-gray-700 text-left"
                    >
                      Last Name
                    </div>
                    <div class="flex flex-row items-center justify-start w-full">
                      <input
                        type="text"
                        placeholder="Last Name"
                        class="flex-1 overflow-hidden text-ellipsis whitespace-nowrap rounded-lg border-gray-300 focus:outline-none px-2"
                        formControlName="lastName"
                      />
                    </div>
                    <span
                      *ngIf="contactForm['lastName'].invalid && contactForm['lastName'].touched"
                      class="p-error"
                    >
                      <span
                        class="text-error-500"
                        *ngIf="contactForm['lastName'].errors['required']"
                        >Last Name is required</span
                      >
                    </span>
                  </div>
                </div>
                <div class="self-stretch flex flex-col items-center gap-[0px_6px]">
                  <div class="self-stretch flex flex-col items-start justify-start gap-[6px_0px]">
                    <div
                      class="relative text-sm leading-[20px] font-text-md-semibold text-gray-700 text-left"
                    >
                      Email
                    </div>
                    <div class="flex flex-row items-center justify-start w-full">
                      <input
                        type="email"
                        placeholder="Email"
                        class="flex-1 overflow-hidden text-ellipsis whitespace-nowrap rounded-lg border-gray-300 focus:outline-none px-2"
                        formControlName="email"
                      />
                    </div>
                    <span
                      *ngIf="contactForm['email'].invalid && contactForm['email'].touched"
                      class="p-error"
                    >
                      <span class="text-error-500" *ngIf="contactForm['email'].errors['required']"
                        >Email is required</span
                      >
                      <span class="text-error-500" *ngIf="contactForm['email'].errors['email']"
                        >Please enter a valid email address.</span
                      >
                    </span>
                  </div>
                  <div
                    class="self-stretch flex flex-col items-start justify-start gap-[6px_0px] mt-4"
                  >
                    <div
                      class="relative text-sm leading-[20px] font-text-md-semibold text-gray-700 text-left"
                    >
                      Message
                    </div>
                    <div class="self-stretch flex flex-row items-center justify-start">
                      <textarea
                        type="text"
                        rows="10"
                        placeholder="Message"
                        class="flex-1 overflow-hidden text-ellipsis whitespace-nowrap rounded-lg border-gray-300 focus:outline-none px-2"
                        formControlName="body"
                      ></textarea>
                    </div>
                    <span
                      *ngIf="contactForm['body'].invalid && contactForm['body'].touched"
                      class="p-error"
                    >
                      <span class="text-error-500" *ngIf="contactForm['body'].errors['required']"
                        >Message is required</span
                      >
                    </span>
                  </div>

                  <div
                    class="self-stretch flex flex-col items-start justify-start gap-[6px_0px] mt-4"
                  >
                    <div class="self-stretch flex flex-row items-center justify-start">
                      <re-captcha
                        formControlName="googleCaptchaToken"
                        [siteKey]="recaptchaSiteKey"
                      ></re-captcha>
                    </div>
                    <span
                      *ngIf="
                        contactForm['googleCaptchaToken'].invalid &&
                        contactForm['googleCaptchaToken'].touched
                      "
                      class="p-error"
                    >
                      <span
                        class="text-error-500"
                        *ngIf="contactForm['googleCaptchaToken'].errors['required']"
                        >Please verify that you are not a robot.</span
                      >
                    </span>
                  </div>
                </div>
              </div>
              <button
                type="button"
                [disabled]="isSubmitted"
                (click)="onSubmitFn()"
                class="w-[100px] disabled:opacity-75 [border:none] p-0 bg-[transparent] self-stretch flex flex-row items-center justify-center"
              >
                <div
                  class="flex-1 rounded-lg bg-brand-600 shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] overflow-hidden flex flex-row items-center justify-center p-2.5 gap-[0px_6px] border-[1px] border-solid border-brand-600"
                >
                  <img class="h-5 w-5 relative overflow-hidden shrink-0 hidden" alt="hidde image" />

                  <div class="flex flex-row items-center justify-center py-0 pr-0 pl-0.5">
                    <div class="flex flex-col items-start justify-start py-0 px-0">
                      <div
                        class="relative text-base leading-[24px] font-semibold font-text-md-semibold text-base-white text-left"
                      >
                        Submit
                      </div>
                    </div>
                  </div>
                  <img class="h-5 w-5 relative overflow-hidden shrink-0 hidden" alt="hidde image" />
                </div>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="contact-wrap p-0" *ngIf="contactList.length > 0">
    <div class="cw-top bg-brand-600">
      <div class="container">
        <h2 class="section-title text-center !text-white">Contact Us</h2>
        <div
          class="self-stretch grid md:grid-cols-3 place-items-center gap-[12px_30px] text-sm text-gray-600 px-4 md:px-[200px]"
        >
          @for (contact of contactList; track contact.branchName) {
          <div class="flex flex-row items-start justify-between gap-[10px]">
            <svg
              id="Layer_3"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="30"
              height="30"
              viewBox="0 0 30 30"
            >
              <defs>
                <clipPath id="clip-path">
                  <path
                    id="Path_39"
                    data-name="Path 39"
                    d="M15,0A15,15,0,1,0,30,15,15,15,0,0,0,15,0Zm.794,21.962a1.709,1.709,0,0,1-1.257.446,1.757,1.757,0,0,1-1.275-.436,1.637,1.637,0,0,1-.452-1.232,1.646,1.646,0,0,1,.442-1.246,1.792,1.792,0,0,1,1.285-.421A1.745,1.745,0,0,1,15.8,19.5a1.908,1.908,0,0,1-.005,2.459Zm3.238-8.944a7,7,0,0,1-1.864,1.766,5.71,5.71,0,0,0-1.191,1.059,1.644,1.644,0,0,0-.25.952v.588h-2.6v-.726a3.212,3.212,0,0,1,.4-1.638A5.272,5.272,0,0,1,15,13.538a6.16,6.16,0,0,0,1.359-1.2,1.732,1.732,0,0,0,.328-1.03,1.116,1.116,0,0,0-.471-.971,2.244,2.244,0,0,0-1.315-.334,7.546,7.546,0,0,0-3.355.962L10.478,8.819a9.347,9.347,0,0,1,4.641-1.226,4.937,4.937,0,0,1,3.213.971,3.171,3.171,0,0,1,1.191,2.59A3.445,3.445,0,0,1,19.032,13.018Z"
                    fill="#fff"
                  ></path>
                </clipPath>
              </defs>
              <g id="Mask_Group_1" data-name="Mask Group 1" clip-path="url(#clip-path)">
                <g id="iconfinder_ic_perm_contact_cal_48px_352586">
                  <path
                    id="Path_44"
                    data-name="Path 44"
                    d="M23.75,3.75H22.5V1.25H20v2.5H10V1.25H7.5v2.5H6.25a2.5,2.5,0,0,0-2.5,2.5v17.5a2.5,2.5,0,0,0,2.5,2.5h17.5a2.5,2.5,0,0,0,2.5-2.5V6.25A2.5,2.5,0,0,0,23.75,3.75ZM15,7.5a3.75,3.75,0,1,1-3.75,3.75A3.753,3.753,0,0,1,15,7.5Zm7.5,15H7.5V21.25c0-2.5,5-3.875,7.5-3.875s7.5,1.375,7.5,3.875Z"
                    fill="#fff"
                  ></path>
                  <path id="Path_45" data-name="Path 45" d="M0,0H30V30H0Z" fill="none"></path>
                </g>
              </g>
            </svg>
            <div class="flex flex-col gap-[15px]">
              <h3 class="text-lg font-bold text-white">{{ contact.branchName }}</h3>
              <p class="hidden text-base">
                {{ contact.address }}
              </p>
              <p class="hidden text-white text-base">
                Tel : <a [href]="'tel:' + contact.tel">{{ contact.tel }}</a>
              </p>
              <p class="text-white text-base">
                Mobile : <a href="'tel:'+contact.mobile">{{ contact.mobile }}</a>
              </p>
              <p class="text-white text-base">
                Email :
                <a [href]="'mailto:' + contact.email">{{ contact.email }}</a>
              </p>
            </div>
          </div>
          }
        </div>
      </div>
    </div>
  </section>
</div>
