import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/services/api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const websiteControllerBaseUrl = 'api/market/app/public';

@Injectable({
  providedIn: 'root',
})
export class LayoutServiceService {
  constructor(private api: ApiService) {}

  getAllContentPages(params, appName): Observable<any[]> {
    return this.api.get(`${websiteControllerBaseUrl}/contentPages/${appName}/published`, params).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getContentPageDetails(slug, appName): Observable<any[]> {
    return this.api.get(`${websiteControllerBaseUrl}/contentPages/${appName}/${slug}`).pipe(
      map((data) => {
        return data;
      })
    );
  }
}
