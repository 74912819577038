import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/services/api.service';
import { Observable, map } from 'rxjs';
import { Urls } from '@app/core/util/urls';

@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  constructor(private api: ApiService) {}

  getOrders(orderCount): Observable<any> {
    return this.api.get(Urls.RECENT_ORDERS + orderCount).pipe(map((data) => data));
  }
}
