import { Component, OnInit } from '@angular/core';
import { PaymentsService } from './services/payments.service';
import { LoaderService } from '@app/core/services/loader.service';
import { User } from '@app/core/models/user.model';
import { AuthService } from '@app/core/services/auth.service';
import { environment } from '@environments/environment';
import { NotificationService } from '@app/core/services/notification.service';
import { ConfirmationPopupComponent } from '../../shared/components/confirmation-popup/confirmation-popup.component';
import { ModalComponent } from '../../shared/components/modal/modal.component';
import { AddCardknoxCardComponent } from './components/add-cardknox-card/add-cardknox-card.component';
import { AddPaymentCardComponent } from './components/add-payment-card/add-payment-card.component';
import { DrawerComponent } from '../../shared/drawer/drawer.component';
import { NgFor, NgIf } from '@angular/common';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrl: './payments.component.scss',
  standalone: true,
  imports: [
    NgFor,
    NgIf,
    DrawerComponent,
    AddPaymentCardComponent,
    AddCardknoxCardComponent,
    ModalComponent,
    ConfirmationPopupComponent,
  ],
})
export class PaymentsComponent implements OnInit {
  isDrawerOpen: boolean = false;
  isPaymentDrawerOpen: boolean = false;
  isModalOpen: boolean = false;
  isConfirmationOpen: boolean = false;
  selectedPaymentCard;
  user: User;
  confirmationTitle = '';
  paymentGateway = environment.eddressAppConfig.paymentGateway;
  payments = [
    // {
    //   cardType: 'MasterCard',
    //   cardNumber: '**** 5076',
    //   expiryDate: '09/2024',
    //   isDefault: true,
    // },
    // {
    //   cardType: 'Visa ',
    //   cardNumber: '**** 2015',
    //   expiryDate: '09/2024',
    //   isDefault: false,
    // },
  ];
  constructor(
    private paymentsService: PaymentsService,
    private loaderService: LoaderService,
    private authService: AuthService,
    private notification: NotificationService
  ) {
    this.authService.currentUser.subscribe((data) => {
      this.user = data;
    });
  }
  ngOnInit() {
    // this.genericService.setPageTitleAndDescription('payments', '')
    this.getPaymentCards();
  }
  reset() {
    this.isDrawerOpen = false;
  }

  closePaymentCardDrawer($event) {
    this.isDrawerOpen = false;
    this.isPaymentDrawerOpen = $event;
  }
  openDrawer() {
    this.isDrawerOpen = true;
    this.isPaymentDrawerOpen = true;
  }
  resetModal() {
    this.isModalOpen = false;
  }
  closeConfrimationPopUp($event) {
    this.isModalOpen = false;
    this.isConfirmationOpen = $event;
  }
  openModal() {
    this.isModalOpen = true;
    this.isConfirmationOpen = true;
  }
  getPaymentCards() {
    this.loaderService.open();
    this.paymentsService.getPaymentCards({}).subscribe(
      (result) => {
        this.payments = result;
        this.loaderService.close();
      },
      (err) => {
        this.loaderService.close();
      }
    );
  }
  newPaymentCard() {
    this.selectedPaymentCard = null;
    this.openDrawer();
  }
  editPaymentCard(card) {
    this.selectedPaymentCard = card;
    this.openDrawer();
  }
  deletePaymentCard(card) {
    this.selectedPaymentCard = card;
    this.openModal();
  }
  addCreditCard(event) {
    this.loaderService.open();
    const params = {
      cardToken: event.token,
      email: this.user.email,
      locale: 'en',
      name: event.nameOnCard,
      paymentGateway: 'STRIPE',
      uid: this.user.uid,
      cardNumber: event.cardNumber,
      expiryMonth: event.expiryMonth,
      expiryYear: event.expiryYear,
    };
    this.paymentsService.addCreditCard(params).subscribe(
      (result) => {
        this.loaderService.close();
        this.notification.successNotification('Credit card added successfully');
        this.getPaymentCards();
      },
      (error) => {
        this.loaderService.close();
      }
    );
    this.resetModal();
  }
  addCardKnoxCreditCard(event) {
    const params = {
      cardToken: event.xToken,
      email: this.user.email,
      locale: 'en',
      os: 'Web',
      name: event.xName,
      paymentGateway: 'CARDKNOX',
      uid: this.user.uid,
      cardNumber: event.xMaskedCardNumber,
      expiryMonth: event.xExp.substring(0, 2),
      expiryYear: event.xExp.substring(2),
    };
    this.loaderService.open();
    this.paymentsService.addCreditCard(params).subscribe(
      (result) => {
        this.loaderService.close();
        this.notification.successNotification('Credit card added successfully');
        this.getPaymentCards();
      },
      (error) => {
        this.loaderService.close();
      }
    );
    this.resetModal();
  }

  openDeleteCardConfirmationModal(card) {
    this.selectedPaymentCard = card;
    this.confirmationTitle = 'Are you sure you want to delete ' + card.name + ' card?';
    this.openModal();
  }
  deleteCreditCard() {
    this.closeConfrimationPopUp(true);
    this.loaderService.open();
    this.paymentsService.deleteCreditCard(this.selectedPaymentCard.uid).subscribe(
      () => {
        this.loaderService.close();
        this.notification.successNotification('Credit card deleted successfully');
        this.getPaymentCards();
      },
      (err) => {
        this.loaderService.close();
      }
    );
  }
}
