<div
  class="w-[400px] md:w-[485px] self-stretch flex-1 bg-white overflow-hidden flex flex-col items-center justify-start border-l-[1px] border-solid border-gray-200"
>
  <div
    class="self-stretch flex flex-row items-start justify-start py-0 px-0 relative gap-[0px_8px] text-xl text-gray-900"
  >
    <div class="flex-1 flex flex-row items-start justify-start pt-6 px-0 pb-0 gap-[0px_16px] z-[0]">
      <img
        class="w-10 relative rounded-lg h-10"
        alt="payment detail icon"
        src="assets/images/home/featured-icon.svg"
      />

      <div class="flex-1 flex flex-col items-start justify-start gap-[4px_0px]">
        <div class="self-stretch relative leading-[30px] font-semibold">Payment details</div>
        <!-- <div class="self-stretch relative text-sm leading-[20px] text-gray-600">
          Update your plan payment details.
        </div> -->
      </div>
    </div>
    <div
      (click)="closeAndCancelOrder()"
      class="w-10 !m-[0] absolute top-[12px] right-[16px] rounded-lg h-10 overflow-hidden shrink-0 flex flex-row items-center justify-center p-2 box-border z-[1] cursor-pointer"
    >
      <img
        class="w-5 relative h-5 overflow-hidden shrink-0"
        alt="close icon"
        src="assets/images/home/xclose.svg"
      />
    </div>
  </div>
  <div
    class="self-stretch flex-1 flex flex-col items-center justify-start py-0 px-0 gap-[24px_0px]"
  >
    <div
      class="self-stretch flex flex-col items-start justify-start text-base text-white font-manrope"
    >
      <div class="w-full mt-10">
        <div id="adyen-checkout"></div>
      </div>
      <div class="self-stretch relative bg-gray-200 h-px"></div>
    </div>
  </div>
</div>
