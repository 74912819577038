import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import { GenericService } from '@app/core/services/generics.service';
import { LoaderService } from '@app/core/services/loader.service';
import { NotificationService } from '@app/core/services/notification.service';
import { CartService } from '@app/product/services/cart.service';
import { ProductService } from '@app/product/services/product.service';
import { AddressesService } from '@app/user-information/addresses/services/addresses.service';
import { environment } from '@environments/environment';
import { NgClass, NgIf, DecimalPipe } from '@angular/common';

@Component({
    selector: 'app-product-item',
    templateUrl: './product-item.component.html',
    styleUrl: './product-item.component.scss',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        NgClass,
        NgIf,
        DecimalPipe,
    ],
})
export class ProductItemComponent implements OnInit {
  @Input() item: any;
  @Input() colectionId;
  @Input() isWidthenable = false;
  @Output() openAddressDrawer: EventEmitter<any> = new EventEmitter<any>();
  @Output() openLoginDrawer: EventEmitter<any> = new EventEmitter<any>();
  @Output() onUpdateFavorites: EventEmitter<any> = new EventEmitter<any>();
  @Output() onUpdateProduct: EventEmitter<any> = new EventEmitter<any>();
  quantity: number;
  quantityCount: number = 0;
  configuration;
  selectedStore;
  user = null;
  deliveryAddress;
  constructor(
    private cartService: CartService,
    private genericService: GenericService,
    public router: Router,
    private authService: AuthService,
    private loaderService: LoaderService,
    private productService: ProductService
  ) {
    this.authService.currentUser.subscribe((data) => {
      if (Object.keys(data).length !== 0) {
        this.user = data;
      }
    });
    this.genericService.updateProductItem.subscribe((data) => {
      if(data) {
        if(this.item?.itemId === data?.itemId) {
          this.quantityCount = data.basketTotalQuantity
        }
      }
    })
    this.cartService.getProducts().subscribe((res) => {});
    this.genericService.getConfigurations().subscribe((result) => {
      this.configuration = result;
    });
    const stores = this.genericService.getStoresData();
    if (stores?.length > 0) {
      this.selectedStore = stores[0];
    }
    this.authService.deliveryAddress.subscribe((data) => {
      if (data) {
        this.deliveryAddress = data;
      }
    });
  }
  ngOnInit() {
    if (!this.item?.productItem) {
      this.item = {
        productItem: this.item,
        basketTotalQuantity: this.item?.basketTotalQuantity,
        favorite: this.item?.isFavorite,
        isFavorite: this.item?.isFavorite,
        itemId: this.item?.id,
        sortOrder: this.item?.sortOrder,
        type: this.item?.itemType,
        // uid: 'OLA2uFZsS2K3xbWEB4xsUQ',
      };
    }

    this.quantityCount = this.item?.basketTotalQuantity || 0;
  }
  removeQuantity(item) {
    this.quantityCount -= 1;
    item.basketTotalQuantity = this.quantityCount;
    item.productItem.basketTotalQuantity = this.quantityCount;
    // item.cartQuantity = this.quantityCount;
    // this.cartService.removeCartItem(item);
    this.item = item;
    this.manageBasket();
  }

  addQuantity(item) {
    if (!this.user && this.user === null) {
      this.openLoginDrawer.emit('');
    } else if (this.deliveryAddress) {
      this.quantityCount += 1;
      item.basketTotalQuantity = this.quantityCount;
      item.productItem.basketTotalQuantity = this.quantityCount;
      // item.cartQuantity = this.quantityCount;
      // this.cartService.addtoCart(item);
      this.item = item;
      this.manageBasket();
    } else {
      this.openAddressDrawer.emit('');
    }
  }
  addToFavourite(item) {
    const params = {
      addItem: !item.isFavorite,
      productId: item?.productItem?.id,
    };
    this.loaderService.open();
    this.productService.addToFavorites(params).subscribe((result) => {
      this.loaderService.close();
      item.isFavorite = !item.isFavorite;
      item.productItem.isFavorite = item.isFavorite;
      this.item = item;
      this.onUpdateFavorites.emit();
      this.onUpdateProduct.emit(this.item);
    });
  }
  viewProduct(product) {
    this.router.navigate(['/product', product?.productItem?.slug]);
  }
  manageBasket() {
    const params = {
      storeId: this.selectedStore.id,
      productId: this.item?.productItem?.id,
      quantity: this.quantityCount,
    };
    this.genericService.manageBasket(params).subscribe((result) => {
      this.onUpdateProduct.emit(this.item);
    });
  }
}
