import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { SearchService } from './services/search.service';
import { GenericService } from '@app/core/services/generics.service';
import { LoaderService } from '@app/core/services/loader.service';
import { AddressesService } from '@app/user-information/addresses/services/addresses.service';
import { NotificationService } from '@app/core/services/notification.service';
import { AuthService } from '@app/core/services/auth.service';
import { environment } from '@environments/environment';
import { ForgotPasswordComponent } from '../layout/header/components/forgot-password/forgot-password.component';
import { SignupComponent } from '../layout/header/components/signup/signup.component';
import { LoginComponent } from '../layout/header/components/login/login.component';
import { AddressListComponent } from '../shared/components/address-list/address-list.component';
import { AddLocationComponent } from '../shared/components/add-location/add-location.component';
import { DrawerComponent } from '../shared/drawer/drawer.component';
import { NoResultComponent } from './components/no-result/no-result.component';
import { ProductItemComponent } from '../shared/product-item/product-item.component';
import { NgIf, NgFor } from '@angular/common';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrl: './search.component.scss',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    ProductItemComponent,
    NoResultComponent,
    DrawerComponent,
    AddLocationComponent,
    AddressListComponent,
    LoginComponent,
    SignupComponent,
    ForgotPasswordComponent,
  ],
})
export class SearchComponent implements OnInit, OnDestroy {
  searchParams = {
    query: '',
    page: 1,
    storeId: '',
    tenantUid: '',
    // filters: [],
  };
  searchString = '';
  selectedStore;
  productList: any[] = [];
  user;
  configuration;

  isDrawerOpen: boolean = false;
  isLocationOpen: boolean = false;
  isAddressesOpen: boolean = false;
  isLoginDrawerOpen: boolean = false;
  isSignupDrawerOpen: boolean = false;
  isForgotPasswordDrawerOpen: boolean = false;
  isFilterOpen: boolean = false;

  deliveryAddress;
  isApiResponed = false;

  constructor(
    private searchService: SearchService,
    private genericService: GenericService,
    private loaderService: LoaderService,
    private addressesService: AddressesService,
    private notification: NotificationService,
    private authService: AuthService
  ) {
    this.authService.selectedStore.subscribe((data) => {
      if (data) {
        this.selectedStore = data;
      }
    });
    this.genericService.configurations.subscribe((data) => {
      if (data) {
        this.configuration = data;
      }
    });
    this.authService.currentUser.subscribe((data) => {
      if (Object.keys(data).length !== 0) {
        this.user = data;
      }
    });
    this.genericService.getSearchString().subscribe((searchString) => {
      this.searchString = searchString;
      this.getProducts();
    });
  }
  ngOnInit() {
    // this.genericService.setPageTitleAndDescription('search-products', '')
  }

  getProducts() {
    if (
      this.configuration &&
      this.configuration.tenantUid &&
      this.selectedStore &&
      this.selectedStore.id
    ) {
      this.searchParams.query = this.searchString;
      this.searchParams.page = 0;
      this.searchParams.tenantUid = this.configuration.tenantUid;
      this.searchParams.storeId = this.selectedStore.id;
      this.loaderService.open();
      this.searchService.searchProducts(this.searchParams).subscribe(
        (response) => {
          this.isApiResponed = true;
          this.productList = response?.items;
          this.loaderService.close();
        },
        (error) => {
          this.isApiResponed = true;
          this.loaderService.close();
        }
      );
    } else {
      this.isApiResponed = true;
    }
  }

  onSearchBtnClick(searchString) {
    this.searchString = searchString;
    this.genericService.setSearchString(this.searchString);
    if (this.searchString.length > 0) {
      this.getProducts();
    }
  }
  openDrawer(type) {
    this.isDrawerOpen = true;

    this.isLocationOpen = false;
    this.isAddressesOpen = false;
    this.isLoginDrawerOpen = false;
    this.isForgotPasswordDrawerOpen = false;
    this.isSignupDrawerOpen = false;
    this.isFilterOpen = false;
    if (type === 'location') {
      this.isLocationOpen = true;
    } else if (type === 'addresses') {
      this.isAddressesOpen = true;
    } else if (type === 'login') {
      this.isLoginDrawerOpen = true;
    } else if (type === 'signup') {
      this.isSignupDrawerOpen = true;
      this.isLoginDrawerOpen = false;
    } else if (type === 'forgotPassword') {
      this.isForgotPasswordDrawerOpen = true;
      this.isLoginDrawerOpen = false;
    }
  }
  closeLocation($event) {
    this.isDrawerOpen = false;
    this.isLocationOpen = $event;
  }
  closeAddresses($event) {
    this.isDrawerOpen = false;
    this.isLocationOpen = $event;
  }

  closeLogin($event) {
    this.isDrawerOpen = false;
    this.isLoginDrawerOpen = $event;
  }

  closeSignup($event) {
    this.isDrawerOpen = false;
    this.isSignupDrawerOpen = $event;
  }

  closeForgotPassword($event) {
    this.isDrawerOpen = false;
    this.isForgotPasswordDrawerOpen = $event;
  }

  reset() {
    this.isDrawerOpen = false;
    this.isDrawerOpen = false;
    this.isLocationOpen = false;
  }
  getDeliveryAddress(deliveryAddress) {
    if (this.deliveryAddress !== deliveryAddress) {
      window.location.reload();
    }
    this.deliveryAddress = deliveryAddress;
    this.authService.setUserDeliveryAddress(this.deliveryAddress);
  }
  saveLocation(location) {
    const params = {
      address: {
        locality: location.locality,
        state: '',
        uid: this.user.uid,
        version: '3.1.9',
        street: location.street,
        building: location.building,
        locale: 'es',
        os: 'web',
        type: '',
        appName: environment.eddressAppConfig.appName,
        coordinates: location.coordinates,
        city: '',
        phoneCountryCode: '0',
        idUser: this.user.idUser,
        phoneCountryId: '0',
        notes: '',
        name: location.name,
        creationLat: location.coordinates.lat,
        creationLon: location.coordinates?.lon,
      },
    };
    this.loaderService.open();
    this.addressesService.saveUserAddress(params).subscribe(
      (result) => {
        this.loaderService.close();
        this.notification.successNotification('Address saved successfully');
        this.closeLocation(true);
      },
      (err) => {
        this.loaderService.close();
      }
    );
  }
  onUpdateProduct(product) {
    this.genericService.updateHomepageProducts(product, '');
  }
  ngOnDestroy() {
    this.genericService.setSearchString('');
  }
}
