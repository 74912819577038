import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from '@core/services/api.service';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { LoaderService } from '../services/loader.service';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HomePageResolver implements Resolve<any> {
  constructor(
    private api: ApiService,
    private http: HttpClient,
    private loaderService: LoaderService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const url = location.host;
    let companyName: string = url.split('.')[0];
    if (companyName === 'portal' || companyName === 'dash') {
      companyName = url.split('.')[1];
    }
    // this.loaderService.open();
    return this.api
      .post('api/market/app/public/marketPlaceAppData', {
        appName: environment.eddressAppConfig.appName,
        appVersionCode: 1,
        operationUid: environment.eddressAppConfig.operationUid,
        os: 'web',
        // pushToken:
        //   'dyPI2GW7RGe93azt5QoMoB:APA91bGLhLyQKSYwpWkwuo6k4Y2hfhmXQALvEc4nLqSKmRpn8in6dP2hvRUfUe6eX9fgOuRu4zlfaoaT_AZTpJNOkm433ErV3_De6ViyVKNiYrqzq-QtcxOByVvuWgVT5dhdhJjOm4O4',
        // pushTokenProjectId: 'eddress-986',
        locale: 'en',
      })
      .pipe(
        map((data) => {
          // this.loaderService.close();
          return data;
        })
      );
  }
}
