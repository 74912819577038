import { ViewCartService } from './services/view-cart.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import { GenericService } from '@app/core/services/generics.service';
import { LoaderService } from '@app/core/services/loader.service';
import { MissingItemsComponent } from '../shared/components/missing-items/missing-items.component';
import { DrawerComponent } from '../shared/drawer/drawer.component';
import { ViewCartItemComponent } from '../shared/view-cart-item/view-cart-item.component';
import { NgFor, NgIf, DecimalPipe } from '@angular/common';

@Component({
    selector: 'app-view-cart',
    templateUrl: './view-cart.component.html',
    styleUrl: './view-cart.component.scss',
    standalone: true,
    imports: [
        NgFor,
        ViewCartItemComponent,
        NgIf,
        DrawerComponent,
        MissingItemsComponent,
        DecimalPipe,
    ],
})
export class ViewCartComponent implements OnInit {
  cartItems: any[] = [];
  missingItems: any[] = [];

  cartItemTriple;

  @ViewChild('frameContainer') frameContainer: ElementRef;
  @ViewChild('nextButton') nextButton: ElementRef;
  @ViewChild('previousButton') previousButton: ElementRef;

  initialScrollPosition: number;
  buttonInitialRight: number;
  buttonInitialLeft: number;

  itemWidth: number;
  currentIndex: number = 0;

  numberItems: number = 8;

  visibleItems: any[] = [];

  totalPrice = 0;

  configuration;
  selectedStore;
  cartSummery;
  deliveryAddress;

  isDrawerOpen: boolean = false;
  isMissingItemDrawerOpen: boolean = false;

  constructor(
    private viewCartService: ViewCartService,
    private router: Router,
    private genericService: GenericService,
    private loaderService: LoaderService,
    private authService: AuthService
  ) {
    const stores = this.genericService.getStoresData();
    if (stores?.length > 0) {
      this.selectedStore = stores[0];
    }
    this.genericService.getConfigurations().subscribe((result) => {
      this.configuration = result;
    });
    this.authService.deliveryAddress.subscribe((data) => {
      if (data) {
        this.deliveryAddress = data;
      }
    });
  }

  ngOnInit() {
    this.genericService.setPageTitleAndDescription('view-cart', '')
    this.getCartDetail();
    this.itemWidth = this.frameContainer?.nativeElement?.offsetWidth / this.numberItems;
    this.updateContainerScroll();
  }

  ngAfterViewInit() {
    // this.getTotalPrice();

    this.initialScrollPosition = this.frameContainer?.nativeElement?.scrollLeft;
    this.buttonInitialRight =
      window.innerWidth - this.nextButton?.nativeElement?.getBoundingClientRect().right;
    this.buttonInitialLeft = this.previousButton?.nativeElement?.getBoundingClientRect().left;
  }

  redirectToCheckout() {
    this.router.navigate(['/checkout']);
  }

  updateContainerScroll() {
    const scrollAmount = this.currentIndex * this.itemWidth;
    this.frameContainer?.nativeElement?.scrollTo({
      left: scrollAmount,
      behavior: 'smooth',
    });
  }
  openDrawer() {
    this.isDrawerOpen = true;
    this.isMissingItemDrawerOpen = true;
  }
  closeMissingDrawer($event) {
    this.isDrawerOpen = false;
    this.isMissingItemDrawerOpen = $event;
  }
  reset() {
    this.isDrawerOpen = false;
  }

  increaseItemQuantity(item: any) {
    item.quantity += 1;
    this.manageBasket(item);
  }

  decreaseItemQuantity(item: any) {
    item.quantity -= 1;
    this.manageBasket(item);
    // console.log('item: ', item);
    // const index = this.cartItems.findIndex((cartItem) => cartItem.name === item.name);
    // if (index !== -1) {
    //   this.cartItems[index].quantity--;
    // }

    // const params = {};

    // this.viewCartService.addCartItemQuantity(params);
  }

  removeItem(item: any) {
    item.quantity = 0;
    this.manageBasket(item);
  }

  manageBasket(item) {
    const params = {
      storeId: this.selectedStore.id,
      productId: item?.productId,
      quantity: item.quantity,
    };
    this.genericService.manageBasket(params).subscribe((result) => {
      item.product.basketTotalQuantity = item.quantity;
      const product = {
        basketTotalQuantity: item.quantity,
        favorite: item.product.isFavorite,
        isFavorite: item.product.isFavorite,
        itemId: item.productId,
        productItem: item.product,
        sortOrder: item.product.sortOrder,
        type: item.product.itemType,
      };
      this.genericService.updateHomepageProducts(product, '');
      this.getCartDetail();
    });
  }
  getCartDetail() {
    this.genericService.getBasket().subscribe((result) => {
      this.cartItems = result?.data?.items || [];
      this.calculateBasket();
    });
  }

  calculateBasket() {
    const backetitems = this.cartItems.map((item) => {
      const product = item.product;
      const obj = {
        // collectionId: '6478b7cb790d915f07fc16bd',
        collectionName: product.collection,
        customizationItems: product.customizationItems,
        // groupId: '6478b817790d915f07fc16bf',
        groupLabel: product.group,
        hasPriceRange: product.hasPriceRange,
        id: product.id,
        itemsOrdered: item.quantity,
        price: product.price,
        vatFree: product.vatFree,
        vatPercent: product.vatPercent,
      };
      return obj;
    });
    const params = {
      dynamicDeliveryParam: {
        deliveryLat: this.deliveryAddress?.coordinates?.lat,
        deliveryLon: this.deliveryAddress?.coordinates?.lon,
      },
      isPayWithWallet: true,
      items: backetitems,
      locale: 'es',
      orderHasDelivery: true,
      serviceProviderId: this.selectedStore.id,
      tip: 0.0,
    };
    this.loaderService.open();
    this.genericService.calculateBasket(params).subscribe(
      (result) => {
        this.loaderService.close();
        if (result.missingItems && result.missingItems.length > 0) {
          this.missingItems = result.missingItems;
          const productIds = result.missingItems.map((i) => i.id);
          this.cartItems = this.cartItems.filter((c) => !productIds.includes(c.id));
          this.openDrawer();
          this.calculateBasket();
          this.missingItems.forEach((item) => {
            const itemObj = { productId: item.id, product: item, quantity: item.quantityOnHand };
            this.manageBasket(itemObj);
          });
        }
        this.cartSummery = result;
      },
      (err) => {
        this.loaderService.close();
      }
    );
  }
}
