import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-no-result',
    templateUrl: './no-result.component.html',
    styleUrl: './no-result.component.scss',
    standalone: true,
    imports: [FormsModule, RouterLink],
})
export class NoResultComponent {
  @Input() public searchString;
  @Output() public onSearch: EventEmitter<any> = new EventEmitter<any>();
  onSearchBtnClick() {
    if (this.searchString.length > 0) {
      this.onSearch.emit(this.searchString);
    }
  }
  onClearSearch() {
    if (this.searchString.length === 0) {
      this.onSearch.emit(this.searchString);
    }
  }
}
