export const COUNTRIES = {
    countriesList: [
        {
          name: 'Afghanistan',
          code: 'AF',
          emoji: '🇦🇫',
          unicode: 'U+1F1E6 U+1F1EB',
          dial_code: '+93',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AF.svg',
        },
        {
          name: 'Albania',
          code: 'AL',
          emoji: '🇦🇱',
          unicode: 'U+1F1E6 U+1F1F1',
          dial_code: '+355',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AL.svg',
        },
        {
          name: 'Andorra',
          code: 'AD',
          emoji: '🇦🇩',
          unicode: 'U+1F1E6 U+1F1E9',
          dial_code: '+376',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AD.svg',
        },
        {
          name: 'Angola',
          code: 'AO',
          emoji: '🇦🇴',
          unicode: 'U+1F1E6 U+1F1F4',
          dial_code: '+244',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AO.svg',
        },
        {
          name: 'Anguilla',
          code: 'AI',
          emoji: '🇦🇮',
          unicode: 'U+1F1E6 U+1F1EE',
          dial_code: '+1264',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AI.svg',
        },
        {
          name: 'Antarctica',
          code: 'AQ',
          emoji: '🇦🇶',
          unicode: 'U+1F1E6 U+1F1F6',
          dial_code: '+672',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AQ.svg',
        },
        {
          name: 'Antigua & Barbuda',
          code: 'AG',
          emoji: '🇦🇬',
          unicode: 'U+1F1E6 U+1F1EC',
          dial_code: '+1268',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AG.svg',
        },
        {
          name: 'Argentina',
          code: 'AR',
          emoji: '🇦🇷',
          unicode: 'U+1F1E6 U+1F1F7',
          dial_code: '+54',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AR.svg',
        },
        {
          name: 'Armenia',
          code: 'AM',
          emoji: '🇦🇲',
          unicode: 'U+1F1E6 U+1F1F2',
          dial_code: '+374',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AM.svg',
        },
        {
          name: 'Aruba',
          code: 'AW',
          emoji: '🇦🇼',
          unicode: 'U+1F1E6 U+1F1FC',
          dial_code: '+297',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AW.svg',
        },
        {
          name: 'Ascension Island',
          code: 'AC',
          emoji: '🇦🇨',
          unicode: 'U+1F1E6 U+1F1E8',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AC.svg',
        },
        {
          name: 'Australia',
          code: 'AU',
          emoji: '🇦🇺',
          unicode: 'U+1F1E6 U+1F1FA',
          dial_code: '+61',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AU.svg',
        },
        {
          name: 'Austria',
          code: 'AT',
          emoji: '🇦🇹',
          unicode: 'U+1F1E6 U+1F1F9',
          dial_code: '+43',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AT.svg',
        },
        {
          name: 'Azerbaijan',
          code: 'AZ',
          emoji: '🇦🇿',
          unicode: 'U+1F1E6 U+1F1FF',
          dial_code: '+994',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AZ.svg',
        },
        {
          name: 'Bahamas',
          code: 'BS',
          emoji: '🇧🇸',
          unicode: 'U+1F1E7 U+1F1F8',
          dial_code: '+1242',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BS.svg',
        },
        {
          name: 'Bahrain',
          code: 'BH',
          emoji: '🇧🇭',
          unicode: 'U+1F1E7 U+1F1ED',
          dial_code: '+973',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BH.svg',
        },
        {
          name: 'Bangladesh',
          code: 'BD',
          emoji: '🇧🇩',
          unicode: 'U+1F1E7 U+1F1E9',
          dial_code: '+880',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BD.svg',
        },
        {
          name: 'Barbados',
          code: 'BB',
          emoji: '🇧🇧',
          unicode: 'U+1F1E7 U+1F1E7',
          dial_code: '+1246',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BB.svg',
        },
        {
          name: 'Belarus',
          code: 'BY',
          emoji: '🇧🇾',
          unicode: 'U+1F1E7 U+1F1FE',
          dial_code: '+375',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BY.svg',
        },
        {
          name: 'Belgium',
          code: 'BE',
          emoji: '🇧🇪',
          unicode: 'U+1F1E7 U+1F1EA',
          dial_code: '+32',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BE.svg',
        },
        {
          name: 'Belize',
          code: 'BZ',
          emoji: '🇧🇿',
          unicode: 'U+1F1E7 U+1F1FF',
          dial_code: '+501',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BZ.svg',
        },
        {
          name: 'Benin',
          code: 'BJ',
          emoji: '🇧🇯',
          unicode: 'U+1F1E7 U+1F1EF',
          dial_code: '+229',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BJ.svg',
        },
        {
          name: 'Bermuda',
          code: 'BM',
          emoji: '🇧🇲',
          unicode: 'U+1F1E7 U+1F1F2',
          dial_code: '+1441',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BM.svg',
        },
        {
          name: 'Bhutan',
          code: 'BT',
          emoji: '🇧🇹',
          unicode: 'U+1F1E7 U+1F1F9',
          dial_code: '+975',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BT.svg',
        },
        {
          name: 'Bolivia',
          code: 'BO',
          emoji: '🇧🇴',
          unicode: 'U+1F1E7 U+1F1F4',
          dial_code: '+591',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BO.svg',
        },
        {
          name: 'Bosnia & Herzegovina',
          code: 'BA',
          emoji: '🇧🇦',
          unicode: 'U+1F1E7 U+1F1E6',
          dial_code: '+387',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BA.svg',
        },
        {
          name: 'Botswana',
          code: 'BW',
          emoji: '🇧🇼',
          unicode: 'U+1F1E7 U+1F1FC',
          dial_code: '+267',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BW.svg',
        },
        {
          name: 'Brazil',
          code: 'BR',
          emoji: '🇧🇷',
          unicode: 'U+1F1E7 U+1F1F7',
          dial_code: '+55',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BR.svg',
        },
        {
          name: 'British Indian Ocean Territory',
          code: 'IO',
          emoji: '🇮🇴',
          unicode: 'U+1F1EE U+1F1F4',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IO.svg',
        },
        {
          name: 'Brunei',
          code: 'BN',
          emoji: '🇧🇳',
          unicode: 'U+1F1E7 U+1F1F3',
          dial_code: '+673',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BN.svg',
        },
        {
          name: 'Bulgaria',
          code: 'BG',
          emoji: '🇧🇬',
          unicode: 'U+1F1E7 U+1F1EC',
          dial_code: '+359',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BG.svg',
        },
        {
          name: 'Burkina Faso',
          code: 'BF',
          emoji: '🇧🇫',
          unicode: 'U+1F1E7 U+1F1EB',
          dial_code: '+226',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BF.svg',
        },
        {
          name: 'Burundi',
          code: 'BI',
          emoji: '🇧🇮',
          unicode: 'U+1F1E7 U+1F1EE',
          dial_code: '+257',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BI.svg',
        },
        {
          name: 'Cabo Verde',
          code: 'CV',
          emoji: '🇨🇻',
          unicode: 'U+1F1E8 U+1F1FB',
          dial_code: '+238',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CV.svg',
        },
        {
          name: 'Cambodia',
          code: 'KH',
          emoji: '🇰🇭',
          unicode: 'U+1F1F0 U+1F1ED',
          dial_code: '+855',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KH.svg',
        },
        {
          name: 'Cameroon',
          code: 'CM',
          emoji: '🇨🇲',
          unicode: 'U+1F1E8 U+1F1F2',
          dial_code: '+237',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CM.svg',
        },
        {
          name: 'Canada',
          code: 'CA',
          emoji: '🇨🇦',
          unicode: 'U+1F1E8 U+1F1E6',
          dial_code: '+1',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CA.svg',
        },
        {
          name: 'Cayman Islands',
          code: 'KY',
          emoji: '🇰🇾',
          unicode: 'U+1F1F0 U+1F1FE',
          dial_code: '+1345',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KY.svg',
        },
        {
          name: 'Central African Republic',
          code: 'CF',
          emoji: '🇨🇫',
          unicode: 'U+1F1E8 U+1F1EB',
          dial_code: '+236',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CF.svg',
        },
        {
          name: 'Chad',
          code: 'TD',
          emoji: '🇹🇩',
          unicode: 'U+1F1F9 U+1F1E9',
          dial_code: '+235',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TD.svg',
        },
        {
          name: 'Chile',
          code: 'CL',
          emoji: '🇨🇱',
          unicode: 'U+1F1E8 U+1F1F1',
          dial_code: '+56',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CL.svg',
        },
        {
          name: 'China',
          code: 'CN',
          emoji: '🇨🇳',
          unicode: 'U+1F1E8 U+1F1F3',
          dial_code: '+86',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CN.svg',
        },
        {
          name: 'Christmas Island',
          code: 'CX',
          emoji: '🇨🇽',
          unicode: 'U+1F1E8 U+1F1FD',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CX.svg',
        },
        {
          name: 'Cocos (Keeling) Islands',
          code: 'CC',
          emoji: '🇨🇨',
          unicode: 'U+1F1E8 U+1F1E8',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CC.svg',
        },
        {
          name: 'Colombia',
          code: 'CO',
          emoji: '🇨🇴',
          unicode: 'U+1F1E8 U+1F1F4',
          dial_code: '+57',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CO.svg',
        },
        {
          name: 'Comoros',
          code: 'KM',
          emoji: '🇰🇲',
          unicode: 'U+1F1F0 U+1F1F2',
          dial_code: '+269',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KM.svg',
        },
        {
          name: 'Congo - Brazzaville',
          code: 'CG',
          emoji: '🇨🇬',
          unicode: 'U+1F1E8 U+1F1EC',
          dial_code: '+242',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CG.svg',
        },
        {
          name: 'Congo - Kinshasa',
          code: 'CD',
          emoji: '🇨🇩',
          unicode: 'U+1F1E8 U+1F1E9',
          dial_code: '+243',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CD.svg',
        },
        {
          name: 'Cook Islands',
          code: 'CK',
          emoji: '🇨🇰',
          unicode: 'U+1F1E8 U+1F1F0',
          dial_code: '+682',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CK.svg',
        },
        {
          name: 'Costa Rica',
          code: 'CR',
          emoji: '🇨🇷',
          unicode: 'U+1F1E8 U+1F1F7',
          dial_code: '+506',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CR.svg',
        },
        {
          name: 'Croatia',
          code: 'HR',
          emoji: '🇭🇷',
          unicode: 'U+1F1ED U+1F1F7',
          dial_code: '+385',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HR.svg',
        },
        {
          name: 'Cuba',
          code: 'CU',
          emoji: '🇨🇺',
          unicode: 'U+1F1E8 U+1F1FA',
          dial_code: '+53',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CU.svg',
        },
        {
          name: 'Curaçao',
          code: 'CW',
          emoji: '🇨🇼',
          unicode: 'U+1F1E8 U+1F1FC',
          dial_code: '+599',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CW.svg',
        },
        {
          name: 'Cyprus',
          code: 'CY',
          emoji: '🇨🇾',
          unicode: 'U+1F1E8 U+1F1FE',
          dial_code: '+357',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CY.svg',
        },
        {
          name: 'Czechia',
          code: 'CZ',
          emoji: '🇨🇿',
          unicode: 'U+1F1E8 U+1F1FF',
          dial_code: '+420',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CZ.svg',
        },
        {
          name: 'Côte d’Ivoire',
          code: 'CI',
          emoji: '🇨🇮',
          unicode: 'U+1F1E8 U+1F1EE',
          dial_code: '+225',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CI.svg',
        },
        {
          name: 'Denmark',
          code: 'DK',
          emoji: '🇩🇰',
          unicode: 'U+1F1E9 U+1F1F0',
          dial_code: '+45',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DK.svg',
        },
        {
          name: 'Djibouti',
          code: 'DJ',
          emoji: '🇩🇯',
          unicode: 'U+1F1E9 U+1F1EF',
          dial_code: '+253',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DJ.svg',
        },
        {
          name: 'Dominica',
          code: 'DM',
          emoji: '🇩🇲',
          unicode: 'U+1F1E9 U+1F1F2',
          dial_code: '+1767',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DM.svg',
        },
        {
          name: 'Dominican Republic',
          code: 'DO',
          emoji: '🇩🇴',
          unicode: 'U+1F1E9 U+1F1F4',
          dial_code: '+1 809, +1 829, +1 849',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DO.svg',
        },
        {
          name: 'Ecuador',
          code: 'EC',
          emoji: '🇪🇨',
          unicode: 'U+1F1EA U+1F1E8',
          dial_code: '+593',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EC.svg',
        },
        {
          name: 'Egypt',
          code: 'EG',
          emoji: '🇪🇬',
          unicode: 'U+1F1EA U+1F1EC',
          dial_code: '+20',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EG.svg',
        },
        {
          name: 'El Salvador',
          code: 'SV',
          emoji: '🇸🇻',
          unicode: 'U+1F1F8 U+1F1FB',
          dial_code: '+503',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SV.svg',
        },
        {
          name: 'Equatorial Guinea',
          code: 'GQ',
          emoji: '🇬🇶',
          unicode: 'U+1F1EC U+1F1F6',
          dial_code: '+240',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GQ.svg',
        },
        {
          name: 'Eritrea',
          code: 'ER',
          emoji: '🇪🇷',
          unicode: 'U+1F1EA U+1F1F7',
          dial_code: '+291',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ER.svg',
        },
        {
          name: 'Estonia',
          code: 'EE',
          emoji: '🇪🇪',
          unicode: 'U+1F1EA U+1F1EA',
          dial_code: '+372',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EE.svg',
        },
        {
          name: 'Eswatini',
          code: 'SZ',
          emoji: '🇸🇿',
          unicode: 'U+1F1F8 U+1F1FF',
          dial_code: '+268',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SZ.svg',
        },
        {
          name: 'Ethiopia',
          code: 'ET',
          emoji: '🇪🇹',
          unicode: 'U+1F1EA U+1F1F9',
          dial_code: '+251',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ET.svg',
        },
        {
          name: 'Falkland Islands',
          code: 'FK',
          emoji: '🇫🇰',
          unicode: 'U+1F1EB U+1F1F0',
          dial_code: '+500',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FK.svg',
        },
        {
          name: 'Faroe Islands',
          code: 'FO',
          emoji: '🇫🇴',
          unicode: 'U+1F1EB U+1F1F4',
          dial_code: '+298',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FO.svg',
        },
        {
          name: 'Fiji',
          code: 'FJ',
          emoji: '🇫🇯',
          unicode: 'U+1F1EB U+1F1EF',
          dial_code: '+679',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FJ.svg',
        },
        {
          name: 'Finland',
          code: 'FI',
          emoji: '🇫🇮',
          unicode: 'U+1F1EB U+1F1EE',
          dial_code: '+358',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FI.svg',
        },
        {
          name: 'France',
          code: 'FR',
          emoji: '🇫🇷',
          unicode: 'U+1F1EB U+1F1F7',
          dial_code: '+33',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FR.svg',
        },
        {
          name: 'French Guiana',
          code: 'GF',
          emoji: '🇬🇫',
          unicode: 'U+1F1EC U+1F1EB',
          dial_code: '+594',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GF.svg',
        },
        {
          name: 'French Polynesia',
          code: 'PF',
          emoji: '🇵🇫',
          unicode: 'U+1F1F5 U+1F1EB',
          dial_code: '+689',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PF.svg',
        },
        {
          name: 'French Southern Territories',
          code: 'TF',
          emoji: '🇹🇫',
          unicode: 'U+1F1F9 U+1F1EB',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TF.svg',
        },
        {
          name: 'Gabon',
          code: 'GA',
          emoji: '🇬🇦',
          unicode: 'U+1F1EC U+1F1E6',
          dial_code: '+241',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GA.svg',
        },
        {
          name: 'Gambia',
          code: 'GM',
          emoji: '🇬🇲',
          unicode: 'U+1F1EC U+1F1F2',
          dial_code: '+220',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GM.svg',
        },
        {
          name: 'Georgia',
          code: 'GE',
          emoji: '🇬🇪',
          unicode: 'U+1F1EC U+1F1EA',
          dial_code: '+995',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GE.svg',
        },
        {
          name: 'Germany',
          code: 'DE',
          emoji: '🇩🇪',
          unicode: 'U+1F1E9 U+1F1EA',
          dial_code: '+49',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DE.svg',
        },
        {
          name: 'Ghana',
          code: 'GH',
          emoji: '🇬🇭',
          unicode: 'U+1F1EC U+1F1ED',
          dial_code: '+233',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GH.svg',
        },
        {
          name: 'Gibraltar',
          code: 'GI',
          emoji: '🇬🇮',
          unicode: 'U+1F1EC U+1F1EE',
          dial_code: '+350',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GI.svg',
        },
        {
          name: 'Greece',
          code: 'GR',
          emoji: '🇬🇷',
          unicode: 'U+1F1EC U+1F1F7',
          dial_code: '+30',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GR.svg',
        },
        {
          name: 'Greenland',
          code: 'GL',
          emoji: '🇬🇱',
          unicode: 'U+1F1EC U+1F1F1',
          dial_code: '+299',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GL.svg',
        },
        {
          name: 'Grenada',
          code: 'GD',
          emoji: '🇬🇩',
          unicode: 'U+1F1EC U+1F1E9',
          dial_code: '+1473',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GD.svg',
        },
        {
          name: 'Guadeloupe',
          code: 'GP',
          emoji: '🇬🇵',
          unicode: 'U+1F1EC U+1F1F5',
          dial_code: '+590',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GP.svg',
        },
        {
          name: 'Guam',
          code: 'GU',
          emoji: '🇬🇺',
          unicode: 'U+1F1EC U+1F1FA',
          dial_code: '+1671',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GU.svg',
        },
        {
          name: 'Guatemala',
          code: 'GT',
          emoji: '🇬🇹',
          unicode: 'U+1F1EC U+1F1F9',
          dial_code: '+502',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GT.svg',
        },
        {
          name: 'Guernsey',
          code: 'GG',
          emoji: '🇬🇬',
          unicode: 'U+1F1EC U+1F1EC',
          dial_code: '+44',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GG.svg',
        },
        {
          name: 'Guinea',
          code: 'GN',
          emoji: '🇬🇳',
          unicode: 'U+1F1EC U+1F1F3',
          dial_code: '+224',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GN.svg',
        },
        {
          name: 'Guinea-Bissau',
          code: 'GW',
          emoji: '🇬🇼',
          unicode: 'U+1F1EC U+1F1FC',
          dial_code: '+245',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GW.svg',
        },
        {
          name: 'Guyana',
          code: 'GY',
          emoji: '🇬🇾',
          unicode: 'U+1F1EC U+1F1FE',
          dial_code: '+592',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GY.svg',
        },
        {
          name: 'Haiti',
          code: 'HT',
          emoji: '🇭🇹',
          unicode: 'U+1F1ED U+1F1F9',
          dial_code: '+509',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HT.svg',
        },
        {
          name: 'Heard & McDonald Islands',
          code: 'HM',
          emoji: '🇭🇲',
          unicode: 'U+1F1ED U+1F1F2',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HM.svg',
        },
        {
          name: 'Honduras',
          code: 'HN',
          emoji: '🇭🇳',
          unicode: 'U+1F1ED U+1F1F3',
          dial_code: '+504',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HN.svg',
        },
        {
          name: 'Hong Kong SAR China',
          code: 'HK',
          emoji: '🇭🇰',
          unicode: 'U+1F1ED U+1F1F0',
          dial_code: '+852',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HK.svg',
        },
        {
          name: 'Hungary',
          code: 'HU',
          emoji: '🇭🇺',
          unicode: 'U+1F1ED U+1F1FA',
          dial_code: '+36',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HU.svg',
        },
        {
          name: 'Iceland',
          code: 'IS',
          emoji: '🇮🇸',
          unicode: 'U+1F1EE U+1F1F8',
          dial_code: '+354',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IS.svg',
        },
        {
          name: 'India',
          code: 'IN',
          emoji: '🇮🇳',
          unicode: 'U+1F1EE U+1F1F3',
          dial_code: '+91',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IN.svg',
        },
        {
          name: 'Indonesia',
          code: 'ID',
          emoji: '🇮🇩',
          unicode: 'U+1F1EE U+1F1E9',
          dial_code: '+62',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ID.svg',
        },
        {
          name: 'Iran',
          code: 'IR',
          emoji: '🇮🇷',
          unicode: 'U+1F1EE U+1F1F7',
          dial_code: '+98',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IR.svg',
        },
        {
          name: 'Iraq',
          code: 'IQ',
          emoji: '🇮🇶',
          unicode: 'U+1F1EE U+1F1F6',
          dial_code: '+964',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IQ.svg',
        },
        {
          name: 'Ireland',
          code: 'IE',
          emoji: '🇮🇪',
          unicode: 'U+1F1EE U+1F1EA',
          dial_code: '+353',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IE.svg',
        },
        {
          name: 'Isle of Man',
          code: 'IM',
          emoji: '🇮🇲',
          unicode: 'U+1F1EE U+1F1F2',
          dial_code: '+44',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IM.svg',
        },
        {
          name: 'Israel',
          code: 'IL',
          emoji: '🇮🇱',
          unicode: 'U+1F1EE U+1F1F1',
          dial_code: '+972',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IL.svg',
        },
        {
          name: 'Italy',
          code: 'IT',
          emoji: '🇮🇹',
          unicode: 'U+1F1EE U+1F1F9',
          dial_code: '+39',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IT.svg',
        },
        {
          name: 'Jamaica',
          code: 'JM',
          emoji: '🇯🇲',
          unicode: 'U+1F1EF U+1F1F2',
          dial_code: '+1 876',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JM.svg',
        },
        {
          name: 'Japan',
          code: 'JP',
          emoji: '🇯🇵',
          unicode: 'U+1F1EF U+1F1F5',
          dial_code: '+81',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JP.svg',
        },
        {
          name: 'Jersey',
          code: 'JE',
          emoji: '🇯🇪',
          unicode: 'U+1F1EF U+1F1EA',
          dial_code: '+44',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JE.svg',
        },
        {
          name: 'Jordan',
          code: 'JO',
          emoji: '🇯🇴',
          unicode: 'U+1F1EF U+1F1F4',
          dial_code: '+962',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JO.svg',
        },
        {
          name: 'Kazakhstan',
          code: 'KZ',
          emoji: '🇰🇿',
          unicode: 'U+1F1F0 U+1F1FF',
          dial_code: '+7',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KZ.svg',
        },
        {
          name: 'Kenya',
          code: 'KE',
          emoji: '🇰🇪',
          unicode: 'U+1F1F0 U+1F1EA',
          dial_code: '+254',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KE.svg',
        },
        {
          name: 'Kiribati',
          code: 'KI',
          emoji: '🇰🇮',
          unicode: 'U+1F1F0 U+1F1EE',
          dial_code: '+686',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KI.svg',
        },
        {
          name: 'Kosovo',
          code: 'XK',
          emoji: '🇽🇰',
          unicode: 'U+1F1FD U+1F1F0',
          dial_code: '+383',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/XK.svg',
        },
        {
          name: 'Kuwait',
          code: 'KW',
          emoji: '🇰🇼',
          unicode: 'U+1F1F0 U+1F1FC',
          dial_code: '+965',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KW.svg',
        },
        {
          name: 'Kyrgyzstan',
          code: 'KG',
          emoji: '🇰🇬',
          unicode: 'U+1F1F0 U+1F1EC',
          dial_code: '+996',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KG.svg',
        },
        {
          name: 'Laos',
          code: 'LA',
          emoji: '🇱🇦',
          unicode: 'U+1F1F1 U+1F1E6',
          dial_code: '+856',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LA.svg',
        },
        {
          name: 'Latvia',
          code: 'LV',
          emoji: '🇱🇻',
          unicode: 'U+1F1F1 U+1F1FB',
          dial_code: '+371',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LV.svg',
        },
        {
          name: 'Lebanon',
          code: 'LB',
          emoji: '🇱🇧',
          unicode: 'U+1F1F1 U+1F1E7',
          dial_code: '+961',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LB.svg',
        },
        {
          name: 'Lesotho',
          code: 'LS',
          emoji: '🇱🇸',
          unicode: 'U+1F1F1 U+1F1F8',
          dial_code: '+266',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LS.svg',
        },
        {
          name: 'Liberia',
          code: 'LR',
          emoji: '🇱🇷',
          unicode: 'U+1F1F1 U+1F1F7',
          dial_code: '+231',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LR.svg',
        },
        {
          name: 'Libya',
          code: 'LY',
          emoji: '🇱🇾',
          unicode: 'U+1F1F1 U+1F1FE',
          dial_code: '+218',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LY.svg',
        },
        {
          name: 'Liechtenstein',
          code: 'LI',
          emoji: '🇱🇮',
          unicode: 'U+1F1F1 U+1F1EE',
          dial_code: '+423',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LI.svg',
        },
        {
          name: 'Lithuania',
          code: 'LT',
          emoji: '🇱🇹',
          unicode: 'U+1F1F1 U+1F1F9',
          dial_code: '+370',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LT.svg',
        },
        {
          name: 'Luxembourg',
          code: 'LU',
          emoji: '🇱🇺',
          unicode: 'U+1F1F1 U+1F1FA',
          dial_code: '+352',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LU.svg',
        },
        {
          name: 'Macao SAR China',
          code: 'MO',
          emoji: '🇲🇴',
          unicode: 'U+1F1F2 U+1F1F4',
          dial_code: '+853',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MO.svg',
        },
        {
          name: 'Madagascar',
          code: 'MG',
          emoji: '🇲🇬',
          unicode: 'U+1F1F2 U+1F1EC',
          dial_code: '+261',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MG.svg',
        },
        {
          name: 'Malawi',
          code: 'MW',
          emoji: '🇲🇼',
          unicode: 'U+1F1F2 U+1F1FC',
          dial_code: '+265',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MW.svg',
        },
        {
          name: 'Malaysia',
          code: 'MY',
          emoji: '🇲🇾',
          unicode: 'U+1F1F2 U+1F1FE',
          dial_code: '+60',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MY.svg',
        },
        {
          name: 'Maldives',
          code: 'MV',
          emoji: '🇲🇻',
          unicode: 'U+1F1F2 U+1F1FB',
          dial_code: '+960',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MV.svg',
        },
        {
          name: 'Mali',
          code: 'ML',
          emoji: '🇲🇱',
          unicode: 'U+1F1F2 U+1F1F1',
          dial_code: '+223',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ML.svg',
        },
        {
          name: 'Malta',
          code: 'MT',
          emoji: '🇲🇹',
          unicode: 'U+1F1F2 U+1F1F9',
          dial_code: '+356',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MT.svg',
        },
        {
          name: 'Marshall Islands',
          code: 'MH',
          emoji: '🇲🇭',
          unicode: 'U+1F1F2 U+1F1ED',
          dial_code: '+692',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MH.svg',
        },
        {
          name: 'Martinique',
          code: 'MQ',
          emoji: '🇲🇶',
          unicode: 'U+1F1F2 U+1F1F6',
          dial_code: '+596',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MQ.svg',
        },
        {
          name: 'Mauritania',
          code: 'MR',
          emoji: '🇲🇷',
          unicode: 'U+1F1F2 U+1F1F7',
          dial_code: '+222',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MR.svg',
        },
        {
          name: 'Mauritius',
          code: 'MU',
          emoji: '🇲🇺',
          unicode: 'U+1F1F2 U+1F1FA',
          dial_code: '+230',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MU.svg',
        },
        {
          name: 'Mayotte',
          code: 'YT',
          emoji: '🇾🇹',
          unicode: 'U+1F1FE U+1F1F9',
          dial_code: '+262',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/YT.svg',
        },
        {
          name: 'Mexico',
          code: 'MX',
          emoji: '🇲🇽',
          unicode: 'U+1F1F2 U+1F1FD',
          dial_code: '+52',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MX.svg',
        },
        {
          name: 'Micronesia',
          code: 'FM',
          emoji: '🇫🇲',
          unicode: 'U+1F1EB U+1F1F2',
          dial_code: '+691',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FM.svg',
        },
        {
          name: 'Moldova',
          code: 'MD',
          emoji: '🇲🇩',
          unicode: 'U+1F1F2 U+1F1E9',
          dial_code: '+373',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MD.svg',
        },
        {
          name: 'Monaco',
          code: 'MC',
          emoji: '🇲🇨',
          unicode: 'U+1F1F2 U+1F1E8',
          dial_code: '+377',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MC.svg',
        },
        {
          name: 'Mongolia',
          code: 'MN',
          emoji: '🇲🇳',
          unicode: 'U+1F1F2 U+1F1F3',
          dial_code: '+976',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MN.svg',
        },
        {
          name: 'Montenegro',
          code: 'ME',
          emoji: '🇲🇪',
          unicode: 'U+1F1F2 U+1F1EA',
          dial_code: '+382',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ME.svg',
        },
        {
          name: 'Montserrat',
          code: 'MS',
          emoji: '🇲🇸',
          unicode: 'U+1F1F2 U+1F1F8',
          dial_code: '+1 664',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MS.svg',
        },
        {
          name: 'Morocco',
          code: 'MA',
          emoji: '🇲🇦',
          unicode: 'U+1F1F2 U+1F1E6',
          dial_code: '+212',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MA.svg',
        },
        {
          name: 'Mozambique',
          code: 'MZ',
          emoji: '🇲🇿',
          unicode: 'U+1F1F2 U+1F1FF',
          dial_code: '+258',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MZ.svg',
        },
        {
          name: 'Myanmar (Burma)',
          code: 'MM',
          emoji: '🇲🇲',
          unicode: 'U+1F1F2 U+1F1F2',
          dial_code: '+95',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MM.svg',
        },
        {
          name: 'Namibia',
          code: 'NA',
          emoji: '🇳🇦',
          unicode: 'U+1F1F3 U+1F1E6',
          dial_code: '+264',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NA.svg',
        },
        {
          name: 'Nauru',
          code: 'NR',
          emoji: '🇳🇷',
          unicode: 'U+1F1F3 U+1F1F7',
          dial_code: '+674',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NR.svg',
        },
        {
          name: 'Nepal',
          code: 'NP',
          emoji: '🇳🇵',
          unicode: 'U+1F1F3 U+1F1F5',
          dial_code: '+977',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NP.svg',
        },
        {
          name: 'Netherlands',
          code: 'NL',
          emoji: '🇳🇱',
          unicode: 'U+1F1F3 U+1F1F1',
          dial_code: '+31',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NL.svg',
        },
        {
          name: 'New Caledonia',
          code: 'NC',
          emoji: '🇳🇨',
          unicode: 'U+1F1F3 U+1F1E8',
          dial_code: '+687',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NC.svg',
        },
        {
          name: 'New Zealand',
          code: 'NZ',
          emoji: '🇳🇿',
          unicode: 'U+1F1F3 U+1F1FF',
          dial_code: '+64',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NZ.svg',
        },
        {
          name: 'Nicaragua',
          code: 'NI',
          emoji: '🇳🇮',
          unicode: 'U+1F1F3 U+1F1EE',
          dial_code: '+505',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NI.svg',
        },
        {
          name: 'Niger',
          code: 'NE',
          emoji: '🇳🇪',
          unicode: 'U+1F1F3 U+1F1EA',
          dial_code: '+227',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NE.svg',
        },
        {
          name: 'Nigeria',
          code: 'NG',
          emoji: '🇳🇬',
          unicode: 'U+1F1F3 U+1F1EC',
          dial_code: '+234',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NG.svg',
        },
        {
          name: 'Niue',
          code: 'NU',
          emoji: '🇳🇺',
          unicode: 'U+1F1F3 U+1F1FA',
          dial_code: '+683',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NU.svg',
        },
        {
          name: 'Norfolk Island',
          code: 'NF',
          emoji: '🇳🇫',
          unicode: 'U+1F1F3 U+1F1EB',
          dial_code: '+672',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NF.svg',
        },
        {
          name: 'North Korea',
          code: 'KP',
          emoji: '🇰🇵',
          unicode: 'U+1F1F0 U+1F1F5',
          dial_code: '+850',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KP.svg',
        },
        {
          name: 'Northern Mariana Islands',
          code: 'MP',
          emoji: '🇲🇵',
          unicode: 'U+1F1F2 U+1F1F5',
          dial_code: '+1 670',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MP.svg',
        },
        {
          name: 'Norway',
          code: 'NO',
          emoji: '🇳🇴',
          unicode: 'U+1F1F3 U+1F1F4',
          dial_code: '+47',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NO.svg',
        },
        {
          name: 'Oman',
          code: 'OM',
          emoji: '🇴🇲',
          unicode: 'U+1F1F4 U+1F1F2',
          dial_code: '+968',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/OM.svg',
        },
        {
          name: 'Pakistan',
          code: 'PK',
          emoji: '🇵🇰',
          unicode: 'U+1F1F5 U+1F1F0',
          dial_code: '+92',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PK.svg',
        },
        {
          name: 'Palau',
          code: 'PW',
          emoji: '🇵🇼',
          unicode: 'U+1F1F5 U+1F1FC',
          dial_code: '+680',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PW.svg',
        },
        {
          name: 'Palestinian Territories',
          code: 'PS',
          emoji: '🇵🇸',
          unicode: 'U+1F1F5 U+1F1F8',
          dial_code: '+970',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PS.svg',
        },
        {
          name: 'Panama',
          code: 'PA',
          emoji: '🇵🇦',
          unicode: 'U+1F1F5 U+1F1E6',
          dial_code: '+507',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PA.svg',
        },
        {
          name: 'Papua New Guinea',
          code: 'PG',
          emoji: '🇵🇬',
          unicode: 'U+1F1F5 U+1F1EC',
          dial_code: '+675',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PG.svg',
        },
        {
          name: 'Paraguay',
          code: 'PY',
          emoji: '🇵🇾',
          unicode: 'U+1F1F5 U+1F1FE',
          dial_code: '+595',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PY.svg',
        },
        {
          name: 'Peru',
          code: 'PE',
          emoji: '🇵🇪',
          unicode: 'U+1F1F5 U+1F1EA',
          dial_code: '+51',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PE.svg',
        },
        {
          name: 'Philippines',
          code: 'PH',
          emoji: '🇵🇭',
          unicode: 'U+1F1F5 U+1F1ED',
          dial_code: '+63',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PH.svg',
        },
        {
          name: 'Pitcairn Islands',
          code: 'PN',
          emoji: '🇵🇳',
          unicode: 'U+1F1F5 U+1F1F3',
          dial_code: '+64',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PN.svg',
        },
        {
          name: 'Poland',
          code: 'PL',
          emoji: '🇵🇱',
          unicode: 'U+1F1F5 U+1F1F1',
          dial_code: '+48',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PL.svg',
        },
        {
          name: 'Portugal',
          code: 'PT',
          emoji: '🇵🇹',
          unicode: 'U+1F1F5 U+1F1F9',
          dial_code: '+351',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PT.svg',
        },
        {
          name: 'Puerto Rico',
          code: 'PR',
          emoji: '🇵🇷',
          unicode: 'U+1F1F5 U+1F1F7',
          dial_code: '+1 787',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PR.svg',
        },
        {
          name: 'Qatar',
          code: 'QA',
          emoji: '🇶🇦',
          unicode: 'U+1F1F6 U+1F1E6',
          dial_code: '+974',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/QA.svg',
        },
        {
          name: 'Réunion',
          code: 'RE',
          emoji: '🇷🇪',
          unicode: 'U+1F1F7 U+1F1EA',
          dial_code: '+262',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RE.svg',
        },
        {
          name: 'Romania',
          code: 'RO',
          emoji: '🇷🇴',
          unicode: 'U+1F1F7 U+1F1F4',
          dial_code: '+40',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RO.svg',
        },
        {
          name: 'Russia',
          code: 'RU',
          emoji: '🇷🇺',
          unicode: 'U+1F1F7 U+1F1FA',
          dial_code: '+7',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RU.svg',
        },
        {
          name: 'Rwanda',
          code: 'RW',
          emoji: '🇷🇼',
          unicode: 'U+1F1F7 U+1F1FC',
          dial_code: '+250',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RW.svg',
        },
        {
          name: 'Saint Barthélemy',
          code: 'BL',
          emoji: '🇧🇱',
          unicode: 'U+1F1E7 U+1F1F1',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BL.svg',
        },
        {
          name: 'Saint Helena',
          code: 'SH',
          emoji: '🇸🇭',
          unicode: 'U+1F1F8 U+1F1ED',
          dial_code: '+290',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SH.svg',
        },
        {
          name: 'Saint Kitts & Nevis',
          code: 'KN',
          emoji: '🇰🇳',
          unicode: 'U+1F1F0 U+1F1F3',
          dial_code: '+1 869',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KN.svg',
        },
        {
          name: 'Saint Lucia',
          code: 'LC',
          emoji: '🇱🇨',
          unicode: 'U+1F1F1 U+1F1E8',
          dial_code: '+1 758',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LC.svg',
        },
        {
          name: 'Saint Martin',
          code: 'MF',
          emoji: '🇲🇫',
          unicode: 'U+1F1F2 U+1F1EB',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MF.svg',
        },
        {
          name: 'Saint Pierre & Miquelon',
          code: 'PM',
          emoji: '🇵🇲',
          unicode: 'U+1F1F5 U+1F1F2',
          dial_code: '+508',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PM.svg',
        },
        {
          name: 'Samoa',
          code: 'WS',
          emoji: '🇼🇸',
          unicode: 'U+1F1FC U+1F1F8',
          dial_code: '+685',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/WS.svg',
        },
        {
          name: 'San Marino',
          code: 'SM',
          emoji: '🇸🇲',
          unicode: 'U+1F1F8 U+1F1F2',
          dial_code: '+378',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SM.svg',
        },
        {
          name: 'Sao Tome & Principe',
          code: 'ST',
          emoji: '🇸🇹',
          unicode: 'U+1F1F8 U+1F1F9',
          dial_code: '+239',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ST.svg',
        },
        {
          name: 'Saudi Arabia',
          code: 'SA',
          emoji: '🇸🇦',
          unicode: 'U+1F1F8 U+1F1E6',
          dial_code: '+966',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SA.svg',
        },
        {
          name: 'Senegal',
          code: 'SN',
          emoji: '🇸🇳',
          unicode: 'U+1F1F8 U+1F1F3',
          dial_code: '+221',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SN.svg',
        },
        {
          name: 'Serbia',
          code: 'RS',
          emoji: '🇷🇸',
          unicode: 'U+1F1F7 U+1F1F8',
          dial_code: '+381',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RS.svg',
        },
        {
          name: 'Seychelles',
          code: 'SC',
          emoji: '🇸🇨',
          unicode: 'U+1F1F8 U+1F1E8',
          dial_code: '+248',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SC.svg',
        },
        {
          name: 'Sierra Leone',
          code: 'SL',
          emoji: '🇸🇱',
          unicode: 'U+1F1F8 U+1F1F1',
          dial_code: '+232',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SL.svg',
        },
        {
          name: 'Singapore',
          code: 'SG',
          emoji: '🇸🇬',
          unicode: 'U+1F1F8 U+1F1EC',
          dial_code: '+65',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SG.svg',
        },
        {
          name: 'Sint Maarten',
          code: 'SX',
          emoji: '🇸🇽',
          unicode: 'U+1F1F8 U+1F1FD',
          dial_code: '+1 721',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SX.svg',
        },
        {
          name: 'Slovakia',
          code: 'SK',
          emoji: '🇸🇰',
          unicode: 'U+1F1F8 U+1F1F0',
          dial_code: '+421',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SK.svg',
        },
        {
          name: 'Slovenia',
          code: 'SI',
          emoji: '🇸🇮',
          unicode: 'U+1F1F8 U+1F1EE',
          dial_code: '+386',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SI.svg',
        },
        {
          name: 'Solomon Islands',
          code: 'SB',
          emoji: '🇸🇧',
          unicode: 'U+1F1F8 U+1F1E7',
          dial_code: '+677',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SB.svg',
        },
        {
          name: 'Somalia',
          code: 'SO',
          emoji: '🇸🇴',
          unicode: 'U+1F1F8 U+1F1F4',
          dial_code: '+252',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SO.svg',
        },
        {
          name: 'South Africa',
          code: 'ZA',
          emoji: '🇿🇦',
          unicode: 'U+1F1FF U+1F1E6',
          dial_code: '+27',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZA.svg',
        },
        {
          name: 'South Georgia & South Sandwich Islands',
          code: 'GS',
          emoji: '🇬🇸',
          unicode: 'U+1F1EC U+1F1F8',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GS.svg',
        },
        {
          name: 'South Korea',
          code: 'KR',
          emoji: '🇰🇷',
          unicode: 'U+1F1F0 U+1F1F7',
          dial_code: '+82',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KR.svg',
        },
        {
          name: 'South Sudan',
          code: 'SS',
          emoji: '🇸🇸',
          unicode: 'U+1F1F8 U+1F1F8',
          dial_code: '+211',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SS.svg',
        },
        {
          name: 'Spain',
          code: 'ES',
          emoji: '🇪🇸',
          unicode: 'U+1F1EA U+1F1F8',
          dial_code: '+34',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ES.svg',
        },
        {
          name: 'Sri Lanka',
          code: 'LK',
          emoji: '🇱🇰',
          unicode: 'U+1F1F1 U+1F1F0',
          dial_code: '+94',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LK.svg',
        },
        {
          name: 'Sudan',
          code: 'SD',
          emoji: '🇸🇩',
          unicode: 'U+1F1F8 U+1F1E9',
          dial_code: '+249',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SD.svg',
        },
        {
          name: 'Suriname',
          code: 'SR',
          emoji: '🇸🇷',
          unicode: 'U+1F1F8 U+1F1F7',
          dial_code: '+597',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SR.svg',
        },
        {
          name: 'Svalbard & Jan Mayen',
          code: 'SJ',
          emoji: '🇸🇯',
          unicode: 'U+1F1F8 U+1F1EF',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SJ.svg',
        },
        {
          name: 'Sweden',
          code: 'SE',
          emoji: '🇸🇪',
          unicode: 'U+1F1F8 U+1F1EA',
          dial_code: '+46',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SE.svg',
        },
        {
          name: 'Switzerland',
          code: 'CH',
          emoji: '🇨🇭',
          unicode: 'U+1F1E8 U+1F1ED',
          dial_code: '+41',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CH.svg',
        },
        {
          name: 'Syria',
          code: 'SY',
          emoji: '🇸🇾',
          unicode: 'U+1F1F8 U+1F1FE',
          dial_code: '+963',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SY.svg',
        },
        {
          name: 'Taiwan',
          code: 'TW',
          emoji: '🇹🇼',
          unicode: 'U+1F1F9 U+1F1FC',
          dial_code: '+886',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TW.svg',
        },
        {
          name: 'Tajikistan',
          code: 'TJ',
          emoji: '🇹🇯',
          unicode: 'U+1F1F9 U+1F1EF',
          dial_code: '+992',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TJ.svg',
        },
        {
          name: 'Tanzania',
          code: 'TZ',
          emoji: '🇹🇿',
          unicode: 'U+1F1F9 U+1F1FF',
          dial_code: '+255',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TZ.svg',
        },
        {
          name: 'Thailand',
          code: 'TH',
          emoji: '🇹🇭',
          unicode: 'U+1F1F9 U+1F1ED',
          dial_code: '+66',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TH.svg',
        },
        {
          name: 'Timor-Leste',
          code: 'TL',
          emoji: '🇹🇱',
          unicode: 'U+1F1F9 U+1F1F1',
          dial_code: '+670',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TL.svg',
        },
        {
          name: 'Togo',
          code: 'TG',
          emoji: '🇹🇬',
          unicode: 'U+1F1F9 U+1F1EC',
          dial_code: '+228',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TG.svg',
        },
        {
          name: 'Tokelau',
          code: 'TK',
          emoji: '🇹🇰',
          unicode: 'U+1F1F9 U+1F1F0',
          dial_code: '+690',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TK.svg',
        },
        {
          name: 'Tonga',
          code: 'TO',
          emoji: '🇹🇴',
          unicode: 'U+1F1F9 U+1F1F4',
          dial_code: '+676',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TO.svg',
        },
        {
          name: 'Trinidad & Tobago',
          code: 'TT',
          emoji: '🇹🇹',
          unicode: 'U+1F1F9 U+1F1F9',
          dial_code: '+1 868',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TT.svg',
        },
        {
          name: 'Tunisia',
          code: 'TN',
          emoji: '🇹🇳',
          unicode: 'U+1F1F9 U+1F1F3',
          dial_code: '+216',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TN.svg',
        },
        {
          name: 'Turkey',
          code: 'TR',
          emoji: '🇹🇷',
          unicode: 'U+1F1F9 U+1F1F7',
          dial_code: '+90',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TR.svg',
        },
        {
          name: 'Turkmenistan',
          code: 'TM',
          emoji: '🇹🇲',
          unicode: 'U+1F1F9 U+1F1F2',
          dial_code: '+993',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TM.svg',
        },
        {
          name: 'Turks & Caicos Islands',
          code: 'TC',
          emoji: '🇹🇨',
          unicode: 'U+1F1F9 U+1F1E8',
          dial_code: '+1 649',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TC.svg',
        },
        {
          name: 'Tuvalu',
          code: 'TV',
          emoji: '🇹🇻',
          unicode: 'U+1F1F9 U+1F1FB',
          dial_code: '+688',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TV.svg',
        },
        {
          name: 'Uganda',
          code: 'UG',
          emoji: '🇺🇬',
          unicode: 'U+1F1FA U+1F1EC',
          dial_code: '+256',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UG.svg',
        },
        {
          name: 'Ukraine',
          code: 'UA',
          emoji: '🇺🇦',
          unicode: 'U+1F1FA U+1F1E6',
          dial_code: '+380',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UA.svg',
        },
        {
          name: 'United Arab Emirates',
          code: 'AE',
          emoji: '🇦🇪',
          unicode: 'U+1F1E6 U+1F1EA',
          dial_code: '+971',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AE.svg',
        },
        {
          name: 'United Kingdom',
          code: 'GB',
          emoji: '🇬🇧',
          unicode: 'U+1F1EC U+1F1E7',
          dial_code: '+44',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GB.svg',
        },
        {
          name: 'United States',
          code: 'US',
          emoji: '🇺🇸',
          unicode: 'U+1F1FA U+1F1F8',
          dial_code: '+1',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/US.svg',
        },
        {
          name: 'Uruguay',
          code: 'UY',
          emoji: '🇺🇾',
          unicode: 'U+1F1FA U+1F1FE',
          dial_code: '+598',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UY.svg',
        },
        {
          name: 'Uzbekistan',
          code: 'UZ',
          emoji: '🇺🇿',
          unicode: 'U+1F1FA U+1F1FF',
          dial_code: '+998',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UZ.svg',
        },
        {
          name: 'Vanuatu',
          code: 'VU',
          emoji: '🇻🇺',
          unicode: 'U+1F1FB U+1F1FA',
          dial_code: '+678',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VU.svg',
        },
        {
          name: 'Vatican City',
          code: 'VA',
          emoji: '🇻🇦',
          unicode: 'U+1F1FB U+1F1E6',
          dial_code: '+379',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VA.svg',
        },
        {
          name: 'Venezuela',
          code: 'VE',
          emoji: '🇻🇪',
          unicode: 'U+1F1FB U+1F1EA',
          dial_code: '+58',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VE.svg',
        },
        {
          name: 'Vietnam',
          code: 'VN',
          emoji: '🇻🇳',
          unicode: 'U+1F1FB U+1F1F3',
          dial_code: '+84',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VN.svg',
        },
        {
          name: 'Wallis & Futuna',
          code: 'WF',
          emoji: '🇼🇫',
          unicode: 'U+1F1FC U+1F1EB',
          dial_code: '+681',
          image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/WF.svg',
        },
      ]
}
