import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ApiService } from '@app/core/services/api.service';


@Injectable({
  providedIn: 'root'
})
export class NewsletterService {

  constructor(private api: ApiService) { }

  saveNewsletterEmail(params): Observable<any> {
    return this.api
      .post('api/market/app/public/website/newsletter/subscribe', params)
      .pipe(map((data) => data));
  }
}
