<div class="flex-1 flex flex-col items-start justify-start gap-[12px_0px] text-lg text-gray-900">
  <h1 class="relative text-xl leading-[30px] font-semibold">Orders</h1>
  <app-order-item
    *ngIf="currentOrders.length > 0"
    [title]="'Ongoing Orders'"
    [orders]="currentOrders"
  />

  <app-order-item
    *ngIf="pastOrders.length > 0"
    [title]="'Past Orders'"
    [orders]="pastOrders"
    (viewOrder)="getViewOrder($event)"
    (reOrder)="openReOrderConfirmationModal($event)"
  />
</div>
<div *ngIf="currentOrders?.length === 0 && pastOrders?.length === 0">
  <div class="flex-1 flex flex-col items-center">
    <div class="flex-grow text-center">
      <img
        class="w-24 h-24 ml-[63px] mb-5 relative overflow-hidden shrink-0"
        alt="shopping bag icon"
        src="assets/images/user/shoppingbag01.svg"
        id="ordersIcon"
      />
      <h1 class="font-bold text-5xl">Orders</h1>
      <span class="text-lg">Your orders will appear here.</span>
    </div>
  </div>
</div>

<app-drawer [drawerId]="'checkoutDrawer'" [isOpen]="isDrawerOpen" (reset)="reset()">
  <app-order-items
    [orderNumber]="selectedOrder?.sequence"
    [items]="selectedOrder?.items"
    [grandTotal]="selectedOrder.subTotal"
    *ngIf="isOrderItemsOpen"
    (closeDrawer)="closeOrderitems($event)"
  />
  <app-cart *ngIf="isCartOpen" (closeDrawer)="closeCart($event)" />
</app-drawer>

<app-modal [modalId]="'itemRemovefromBaskitModal'" [isOpen]="isModalOpen" (reset)="resetModal()">
  <div class="relative p-4 w-full max-w-md max-h-full">
    <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
      <button
        (click)="closeConfrimationPopUp(false)"
        type="button"
        class="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
        data-modal-hide="popup-modal"
      >
        <svg
          class="w-3 h-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 14 14"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
          />
        </svg>
        <span class="sr-only">Close modal</span>
      </button>
      <div class="p-4 md:p-5 text-center">
        <svg
          class="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 20 20"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
          />
        </svg>
        <h3 class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
          Are you sure you want to remove all items from your basket ?
        </h3>

        <button
          (click)="removeItemsFromCart(); closeConfrimationPopUp(false)"
          data-modal-hide="popup-modal"
          type="button"
          class="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center"
        >
          Yes, I'm sure
        </button>
        <button
          (click)="closeConfrimationPopUp(false)"
          data-modal-hide="popup-modal"
          type="button"
          class="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
        >
          No, cancel
        </button>
      </div>
    </div>
  </div>
</app-modal>
