<div
  class="w-[300px] flex-1 bg-white overflow-hidden flex flex-col items-center justify-start gap-[24px_0px] border-l-[1px] border-solid border-gray-200">
  <div class="self-stretch flex flex-row items-start justify-start py-0 px-6 relative gap-[0px_8px]">
    <div class="flex-1 flex flex-row items-center justify-start pt-6 px-0 pb-0 gap-[0px_16px] z-[0]">
      <img class="w-6 relative rounded-lg h-6" alt="shoping bag icon" src="assets/images/home/shoppingbag01.svg" />

      <div class="flex-1 flex flex-col items-start justify-start gap-[4px_0px]">
        <div class="self-stretch relative leading-[30px] font-semibold">Missing Items</div>
        <div class="w-[376px] relative text-sm leading-[20px] text-gray-600 hidden">
          Lorem ipsum dolor sit amet.
        </div>
      </div>
    </div>
    <div (click)="close()"
      class="w-10 my-0 mx-[!important] absolute top-[12px] right-[16px] rounded-lg h-10 overflow-hidden shrink-0 flex flex-row items-center justify-center p-2 box-border z-[1]">
      <img class="w-6 relative h-6 overflow-hidden shrink-0 cursor-pointer" alt="close icon"
        src="assets/images/home/xclose.svg" />
    </div>
  </div>
  <div
    class="self-stretch flex flex-col items-start justify-start py-0 px-6 box-border gap-[24px_0px] text-xs text-gray-800">
    <div class="self-stretch flex flex-col items-start justify-start text-center">
      <div class="self-stretch flex flex-row">
        <div class="relative text-base font-semibold text-red-500">
          The following items are not available or modified in this store we've adjusted your cart
          accordingly.
        </div>
      </div>
    </div>
    <div class="self-stretch relative bg-gray-201 h-px"></div>

    <div *ngFor="let item of items"
      class="self-stretch rounded-lg bg-white flex flex-row items-center justify-start gap-[0px_4px] border-[1px] border-solid border-gray-201">
      <img class="w-[100px] rounded-3xs h-[100px] object-cover opacity-50" alt="product image" [src]="item?.imageUrl" />

      <div class="flex-1 flex flex-col items-start justify-start py-1 px-3 gap-[4px_0px]">
        <div class="self-stretch relative leading-[18px] font-semibold">
          {{ item?.label }}
        </div>
        <div class="self-stretch relative leading-[18px]">
          Available Quantity: {{ item?.quantityOnHand }}
        </div>
      </div>
    </div>

    <div class="self-stretch relative bg-gray-200 h-px"></div>
    <div class="w-full self-stretch flex flex-row items-start justify-start gap-[0px_16px] text-sm text-brand-700">
      <button (click)="continueShopping()"
        class="flex-1 rounded-lg bg-brand-600 shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] overflow-hidden flex flex-row items-center justify-center py-2.5 px-3.5 gap-[0px_4px] text-white border-[1px] border-solid border-brand-600 cursor-pointer">
        <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />

        <div class="flex flex-row items-center justify-center py-0 px-0.5">
          <div class="relative leading-[20px] font-semibold">Continue Shopping</div>
        </div>
        <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />
      </button>
    </div>
  </div>
</div>