import { Injectable, Injector } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';

import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { NotificationService } from '../services/notification.service';

@Injectable({ providedIn: 'root' })
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private injector: Injector, // private loaderService: LoadingBarService
    private notificationService: NotificationService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // this.showLoader();
    const authService = this.injector.get(AuthService);
    const router = this.injector.get(Router);
    const headerSettings: { [name: string]: string | string[] } = {};
    for (const key of req.headers.keys()) {
      headerSettings[key] = req.headers.getAll(key);
    }

    const token = authService.getBearerToken();
    if (token) {
      headerSettings['Content-Type'] = 'application/json';
      headerSettings['Authorization'] = 'Bearer ' + token;
      // if (req.url !== 'https://prod-api.eddress.co/v1/api/searchengine/admin/search') {

      // }
    }
    if (req.body instanceof FormData) {
      // we are sending a file here
      headerSettings['Content-Type'] = 'multipart/form-data';
      delete headerSettings['Content-Type'];
    }
    const customHeaders = new HttpHeaders(headerSettings);

    const authReq = req.clone({
      headers: customHeaders,
    });

    return next.handle(authReq).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.onEnd();
          }
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            let msg;
            if (err.status === 401) {
              localStorage.clear();
              router.navigateByUrl('/');
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            } else if (err.status === 500) {
              //   message.changeServerErrorMessage(err.error.message);
              // router.navigateByUrl('/server-error/');
            } else if (err.status === 403) {
              router.navigateByUrl('/access-denied/');
            } else if (err.status === 400) {
              if (err.error instanceof Blob) {
                let reader = new FileReader();
                let errorStr;
                reader.onload = () => {
                  errorStr = reader.result;
                  let errorJSON = JSON.parse(errorStr);
                  msg = errorJSON.message;
                  if (errorJSON.description) {
                    msg = errorJSON.description;
                  }
                  this.notificationService.errorNotification(msg);
                };
                reader.readAsText(err.error);
              } else {
                msg = err.error.message;
                if ('User already registered using this link' === msg) {
                  router.navigateByUrl('/');
                }
                if (err.error.description) {
                  msg = err.error.description;
                }
                this.notificationService.errorNotification(msg);
              }

              // sweetAlert.sweetWarningMessage(err.error.message)
            }
            this.onEnd();
          }
        }
      )
    );
  }
  private onEnd(): void {
    // this.hideLoader();
  }
  //   private showLoader(): void {
  //     this.loaderService.start();
  //   }
  //   private hideLoader(): void {
  //     this.loaderService.complete();
  //   }
}

export const TokenInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: TokenInterceptor,
  multi: true,
};
