import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-inner-content',
    templateUrl: './inner-content.component.html',
    styleUrl: './inner-content.component.scss',
    encapsulation: ViewEncapsulation.ShadowDom,
    standalone: true
})
export class InnerContentComponent {
@Input() innerContent
}
