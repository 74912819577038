import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/services/api.service';
import { Urls } from '@app/core/util/urls';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  constructor(private api: ApiService) {}
  searchProducts(params): Observable<any> {
    return this.api.post(Urls.ADMIN_SEARCH + params.tenantUid + '/search', params).pipe(map((data) => data));
  }
}
