<div
  class="w-full relative bg-base-white flex flex-col items-center justify-start pt-0 px-8 pb-0 box-border gap-[32px] text-left text-11xl text-gray-900 font-display-sm-semibold">
  <div class="w-full flex flex-col items-center justify-start py-0 px-8 box-border max-w-[1280px] z-[0]">
    <div class="w-[360px] flex flex-col items-center justify-start gap-[32px]">
      <div class="self-stretch flex flex-col items-center justify-start gap-[40px] text-center">
        <div class="self-stretch flex flex-col items-start justify-start pt-10">
          <div class="self-stretch relative leading-[20px] font-semibold">Create an account</div>
        </div>
      </div>

      <div class="self-stretch rounded-xl flex flex-col items-center justify-start gap-[24px] text-base text-gray-700">
        <form [formGroup]="signUpForm" class="w-full">
          <div class="self-stretch flex flex-col items-start justify-start gap-[20px] text-sm">
            <div class="self-stretch flex flex-col items-start justify-start gap-[6px]">
              <div class="self-stretch flex flex-col items-start justify-start gap-[6px]">
                <div class="relative leading-[20px]">Name*</div>

                <div class="flex flex-row items-center justify-start w-full">
                  <input type="text" placeholder="Enter your name"
                    class="flex-1 overflow-hidden text-ellipsis whitespace-nowrap rounded-lg border-gray-300 focus:outline-none px-2"
                    formControlName="name" />
                </div>
                <span class="p-error" *ngIf="signUpForm.get('name').errors && isSubmitted" class="text-red-800">
                  <span class="d-block" *ngIf="signUpForm.get('name').errors['required']">Name is required</span>
                </span>
                <img class="w-4 relative h-4 hidden" alt="hidden icon" src="assets/images/home/help-icon.svg" />
              </div>
            </div>
            <div class="self-stretch flex flex-col items-start justify-start gap-[6px]">
              <div class="self-stretch flex flex-col items-start justify-start gap-[6px]">
                <div class="relative leading-[20px]">Email*</div>

                <div class="flex flex-row items-center justify-start w-full">
                  <input type="email" placeholder="Enter your email"
                    class="flex-1 overflow-hidden text-ellipsis whitespace-nowrap rounded-lg border-gray-300 focus:outline-none px-2"
                    formControlName="email" />
                </div>
                <span class="p-error" *ngIf="signUpForm.get('email').errors && isSubmitted" class="text-red-800">
                  <span class="d-block" *ngIf="signUpForm.get('email').errors['required']">Email is required</span>
                  <span *ngIf="signUpForm.get('email').errors['invalidEmail']">Please enter a valid email</span>
                </span>
                <img class="w-4 relative h-4 hidden" alt="hidden icon" src="assets/images/home/help-icon.svg" />
              </div>
            </div>
            <div class="self-stretch flex flex-col items-start justify-start gap-[6px]">
              <div class="self-stretch flex flex-col items-start justify-start gap-[6px]">
                <div class="relative leading-[20px]">Password*</div>

                <div class="flex-1 flex flex-row items-center justify-start w-full">
                  <input type="password" placeholder="Enter your password"
                    class="flex-1 overflow-hidden text-ellipsis whitespace-nowrap rounded-lg border-gray-300 focus:outline-none px-2"
                    formControlName="password" />
                </div>
                <span class="p-error" *ngIf="signUpForm.get('password').errors && isSubmitted" class="text-red-800">
                  <span class="d-block" *ngIf="signUpForm.get('password').errors['required']">Password is
                    required</span>
                </span>
                <img class="w-4 relative h-4 hidden" alt="hidden icon" src="assets/images/home/help-icon.svg" />
              </div>
            </div>
            <div class="self-stretch flex flex-col items-start justify-start gap-[6px]">
              <div class="self-stretch flex flex-col items-start justify-start gap-[6px]">
                <div class="relative leading-[20px]">Confirm Password*</div>

                <div class="flex-1 flex flex-row items-center justify-start w-full">
                  <input type="password" placeholder="Confirm password"
                    class="flex-1 overflow-hidden text-ellipsis whitespace-nowrap rounded-lg border-gray-300 focus:outline-none px-2"
                    formControlName="confirmPassword" />
                </div>
                <span class="p-error" *ngIf="signUpForm.get('confirmPassword').errors && isSubmitted"
                  class="text-red-800">
                  <span class="d-block" *ngIf="signUpForm.get('confirmPassword').errors['required']">Confirm password is
                    required</span>
                  <span *ngIf="signUpForm.get('confirmPassword').errors['passwordMismatch']">Passwords do not
                    match</span>
                </span>
                <img class="w-4 relative h-4 hidden" alt="hidden icon" src="assets/images/home/help-icon.svg" />
              </div>
            </div>
            <div class="self-stretch flex flex-col items-start justify-start gap-[12px] text-gray-600">
              <div class="self-stretch flex flex-row items-start justify-start gap-[8px]">
                <img class="w-5 relative rounded-3xs h-5 overflow-hidden shrink-0" alt="valicdation icon" [src]="
                    !signUpForm.get('password').hasError('required') &&
                    !signUpForm.get('password').hasError('minlength')
                      ? 'assets/images/home/check-icon-green.svg'
                      : 'assets/images/home/check-icon.svg'
                  " />

                <div class="flex-1 relative leading-[20px]" [ngClass]="{
                    'text-red-800':
                      signUpForm.get('password') &&
                      signUpForm.get('password').errors &&
                      signUpForm.get('password').errors['minlength'] &&
                      isSubmitted
                  }">
                  Must be at least 8 characters
                </div>
              </div>
              <div class="self-stretch flex flex-row items-start justify-start gap-[8px]">
                <img class="w-5 relative rounded-3xs h-5 overflow-hidden shrink-0" alt="validation icon" [src]="
                    !signUpForm.get('password').hasError('required') &&
                    !signUpForm.get('password').hasError('invalidSpecialCharacters')
                      ? 'assets/images/home/check-icon-green.svg'
                      : 'assets/images/home/check-icon.svg'
                  " />

                <div class="flex-1 relative leading-[20px]" [ngClass]="{
                    'text-red-800':
                      signUpForm.get('password') &&
                      signUpForm.get('password').errors &&
                      signUpForm.get('password').errors['invalidSpecialCharacters'] &&
                      isSubmitted
                  }">
                  Must contain one special character
                </div>
              </div>
            </div>
          </div>
        </form>
        <div class="self-stretch flex flex-col items-start justify-start text-base-white">
          <div
            class="self-stretch rounded-lg bg-brand-600 shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] overflow-hidden flex flex-row items-center justify-center py-2.5 px-4 gap-[6px] border-[1px] border-solid border-brand-600">
            <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />

            <div class="flex flex-1 flex-row items-center justify-center py-0 px-0.5 w-full">
              <button class="login-button relative leading-[24px] font-semibold flex-1" (click)="onSubmit()">
                Get Started
              </button>
            </div>
            <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />
          </div>
        </div>
        <div class="self-stretch flex flex-col items-center justify-center gap-[12px]">
          <div
            class="self-stretch rounded-lg bg-base-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] overflow-hidden flex flex-row items-center justify-center py-2.5 px-4 gap-[12px] border-[1px] border-solid border-gray-300 cursor-pointer">
            <div class="flex justify-center items-center">
              <img class="w-6 relative h-6 overflow-hidden shrink-0 inline-block mr-2" alt="facebook icon"
                src="assets/images/home/social-facebook.svg" />
              <div class="relative leading-[24px] inline-block font-semibold">
                Sign in with Facebook
              </div>
            </div>
          </div>
          <div
            class="w-[360px] rounded-lg bg-base-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] box-border overflow-hidden flex-row items-center justify-center py-2.5 px-4 gap-[12px] border-[1px] border-solid border-gray-300 cursor-pointer">
            <div class="flex justify-center items-center">
              <img class="w-6 relative h-6 overflow-hidden shrink-0 inline-block mr-2" alt="google icon"
                src="assets/images/home/social-google.svg" />
              <div class="relative leading-[24px] inline-block font-semibold">
                Sign in with Google
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="self-stretch flex flex-row items-start justify-center gap-[4px] text-sm text-gray-600">
        <div class="relative leading-[20px]">Already have an account?</div>
        <div class="overflow-hidden flex flex-row items-center justify-center gap-[6px] text-brand-700">
          <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />

          <div class="login-button relative leading-[20px] font-semibold" (click)="openLogin()">
            Log in
          </div>
          <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />
        </div>
      </div>
    </div>
  </div>
</div>

<div (click)="closeSignup()"
  class="xclose-container w-11 my-0 mx-[!important] absolute top-[20px] right-[30px] rounded-lg h-11 overflow-hidden shrink-0 flex flex-row items-center justify-center p-2 box-border z-[1]">
  <img class="w-6 relative h-6 overflow-hidden shrink-0"alt="close icon" src="assets/images/home/xclose.svg" />
</div>