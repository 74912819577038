import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/services/api.service';
import { Urls } from '@app/core/util/urls';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AddressesService {
  constructor(private api: ApiService) {}
  getUserAddresses(userId): Observable<any> {
    return this.api.get(Urls.ADDRESS_LIST + userId).pipe(map((data) => data));
  }
  saveUserAddress(params): Observable<any> {
    return this.api.post(Urls.SAVE_ADDRESS, params).pipe(map((data) => data));
  }
  deleteUserAddress(params): Observable<any> {
    return this.api.post(Urls.DELETE_ADDRESS, params).pipe(map((data) => data));
  }
}
