import { Injectable } from '@angular/core';
import { forkJoin } from 'rxjs';
import { ApiService } from './api.service';
import { Urls } from '../util/urls';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AppInitService {
  companyInfo: any;
  marketAppSettings: any;

  constructor(public api: ApiService) {}

  loadAppConfig(): Promise<void> {
    const params = {
      appName: environment.eddressAppConfig.appName,
      appVersionCode: 1,
      operationUid: environment.eddressAppConfig.operationUid,
      os: 'web',
      locale: 'en',
    };
    return new Promise((resolve, reject) => {
      const api1 = this.api.get(Urls.COMPANY_SETTINGS + environment.eddressAppConfig.appName);
      const api2 = this.api.post(Urls.MARKETPLACE_APP_DATA, params);

      forkJoin([api1, api2]).subscribe({
        next: ([response1, response2]) => {
          this.companyInfo = response1;
          this.marketAppSettings = response2.data?.marketAppSettings;
          resolve();
        },
        error: (error) => {
          console.error('Error loading configs', error);
          reject(error);
        },
      });
    });
  }
}
