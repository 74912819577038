<button [id]="buttonId" data-dropdown-toggle="dropdown"
    class="rounded-lg bg-base-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] overflow-hidden flex flex-row items-center justify-center py-2.5 px-3.5 gap-[0px_4px] border-[1px] border-solid border-gray-300 hover:cursor-pointer"
    type="button">
    <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="assets/images/category/filterlines.svg" />

    <div class="flex flex-row items-center justify-center py-0 px-0.5">
        <div class="relative leading-[20px] font-semibold">{{label}}</div>
    </div>
    <img class="w-5 relative h-5 overflow-hidden shrink-0" alt="down icon" src="assets/images/category/chevrondown.svg" />
</button>

<div class="z-100 absolute !ml-[30px] top-[50px] hidden bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700"
    [id]="menuId">
    <div class=" relative rounded-lg bg-base-white shadow-[0px_12px_16px_-4px_rgba(16,_24,_40,_0.08),_0px_4px_6px_-2px_rgba(16,_24,_40,_0.03)] box-border overflow-hidden flex flex-row items-start justify-start border-[1px] border-solid border-gray-200"
        aria-labelledby="dropdownButtona">
        <section class="w-full p-4 my-0 box-border text-left text-base text-gray-900 font-text-md-regular"
            *ngIf="label === 'Price'">
            <ngx-slider [(value)]="minValue" [(highValue)]="maxValue" [options]="rangeOptions"
                (valueChange)="setPriceValues()"></ngx-slider>
            <div class="self-stretch flex flex-col md:flex-row gap-4 mt-5">
                <div class="self-stretch flex flex-col items-start justify-start gap-[6px_0px]">
                    <div class="self-stretch flex flex-col items-start justify-start gap-[6px_0px]">
                        <div class="relative leading-[20px]">From {{ currencySymbol }}</div>
                        <input min="1" (blur)="setRangeValues()" [(ngModel)]="minPrice"
                            (keyup)="validatePositiveNumber()" type="number" placeholder="Min Price"
                            class="self-stretch rounded-lg bg-base-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] flex flex-row items-center justify-start py-2.5 px-3.5 gap-[0px_8px] text-base text-gray-900 border-[1px] border-solid border-gray-300" />
                    </div>
                </div>
                <div class="self-stretch flex flex-col items-start justify-start gap-[6px_0px]">
                    <div class="self-stretch flex flex-col items-start justify-start gap-[6px_0px]">
                        <div class="relative leading-[20px]">To {{ currencySymbol }}</div>
                        <input min="1" [(ngModel)]="maxPrice" type="number" (keyup)="validatePositiveNumber()"
                            (blur)="setRangeValues()" placeholder=" Max
                            Price"
                            class="self-stretch rounded-lg bg-base-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] flex flex-row items-center justify-start py-2.5 px-3.5 gap-[0px_8px] text-base text-gray-900 border-[1px] border-solid border-gray-300" />
                    </div>
                </div>
            </div>
            <div class="flex flex-row-reverse items-end justify-start mt-5 gap-[12px_12px] text-gray-700">
                <div (click)="applyPriceFilterFn()"
                    class="rounded-lg bg-brand-600 shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] overflow-hidden flex flex-row items-center justify-center py-2.5 px-3.5 gap-[0px_4px] text-white border-[1px] border-solid border-brand-600 cursor-pointer">
                    <!-- <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon"
                        src="assets/images/category/placeholder.svg" /> -->

                    <div class="flex flex-row items-center justify-center py-0 px-0.5">
                        <div class="relative leading-[20px] font-semibold">Apply</div>
                    </div>
                    <!-- <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon"
                        src="/public/placeholder.svg" /> -->
                </div>
            </div>
        </section>

    </div>
</div>