// my-feature.selectors.ts
import { AppState } from '@app/app.state';
import { createSelector } from '@ngrx/store';
import { BannerState } from '../states/bannerState';


export const selectBannerState = (state: AppState) => state.banners;

export const selectBannersState = createSelector(
    selectBannerState,
  (state: BannerState) => state // Replace with the correct property
);

export const selectDataLoaded = createSelector(
    selectBannerState,
  (state: BannerState) => state.isLoaded // Boolean flag indicating if the data is loaded
);
