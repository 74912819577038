<div class="w-full relative bg-base-white flex flex-col items-center justify-start py-12 px-8 box-border gap-[32px] text-left text-sm text-gray-900 font-text-sm-semibold"
    *ngIf="!isSent">
    <div
        class="self-stretch flex flex-col items-center justify-start py-0 px-4 gap-[32px] w-[420px] z-[1] overflow-hidden">
        <div class="self-stretch flex flex-col items-center justify-start gap-[24px] text-center text-5xl">
            <img class="w-14 relative rounded-xl h-14" alt="feature icon" src="assets/images/home/featured-icon.svg" />

            <div class="self-stretch flex flex-col items-start justify-start gap-[8px]">
                <div class="self-stretch relative leading-[32px] font-semibold">
                    Forgot password?
                </div>
                <div class="self-stretch relative text-base leading-[24px] text-gray-600">
                    No worries, we’ll send you reset instructions.
                </div>
            </div>
        </div>
        <div class="self-stretch rounded-xl flex flex-col items-center justify-start gap-[24px] text-gray-700">
            <div class="self-stretch flex flex-col items-start justify-start">
                <div class="self-stretch flex flex-col items-start justify-start gap-[6px]">
                    <form [formGroup]="formGroup" class="w-full">
                        <div class="self-stretch flex-1 flex-col items-start justify-start gap-[6px] mb-4">
                            <div class="relative leading-[20px] mb-3">Email</div>

                            <div class="flex flex-row items-center justify-start">
                                <input type="email" placeholder="Enter your email"
                                    class="flex-1 overflow-hidden text-ellipsis whitespace-nowrap rounded-lg border-gray-300 focus:outline-none px-2"
                                    formControlName="email" />
                            </div>

                            <span *ngIf="form['email'].invalid && isSubmitted" class="p-error">
                                <span *ngIf="form['email'].errors['required']">Email is required</span>
                                <span *ngIf="form['email'].errors['email']">Email is invalid</span>
                            </span>
                            <img class="w-4 relative h-4 hidden" alt="hidden icon" src="assets/images/home/help-icon.svg" />

                            <div class="w-80 relative leading-[20px] text-gray-600 hidden">
                                This is a hint text to help user.
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="self-stretch rounded-lg bg-brand-600 shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] overflow-hidden flex flex-row items-center justify-center py-2.5 px-4 gap-[6px] text-base text-base-white border-[1px] border-solid border-brand-600 cursor-pointer"
                (click)="onSubmit()">
                <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />

                <div class="flex flex-row items-center justify-center py-0 px-0.5">
                    <div class="relative leading-[24px] font-semibold">
                        Reset password
                    </div>
                </div>
                <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />
            </div>
        </div>
        <div class="overflow-hidden flex flex-row items-center justify-center gap-[6px] text-gray-600 cursor-pointer"
            (click)="openLogin()">
            <img class="w-5 relative h-5 overflow-hidden shrink-0" alt="left icon" src="assets/images/home/arrowleft.svg" />

            <div class="relative leading-[20px] font-semibold">
                Back to log in
            </div>
            <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />
        </div>
    </div>
</div>


<div class="w-full relative bg-base-white flex flex-col items-center justify-start py-12 px-8 box-border gap-[32px] text-left text-sm text-gray-600 font-text-md-regular"
    *ngIf="isSent">
    <div class="w-[480px] absolute !m-[0] top-[-164px] left-[-52px] z-[0]"></div>
    <div class="self-stretch flex flex-col items-center justify-start py-0 px-4 gap-[32px] w-[420px] z-[1]">
        <div
            class="self-stretch flex flex-col items-center justify-start gap-[24px] text-center text-5xl text-gray-900">
            <img class="w-14 relative rounded-xl h-14" alt="feature icon" src="assets/images/home/featured-icon.svg" />

            <div class="self-stretch flex flex-col items-start justify-start gap-[8px]">
                <div class="self-stretch relative leading-[32px] font-semibold">
                    Check your email
                </div>
                <div class="self-stretch relative text-base leading-[24px] text-gray-600">
                    We sent a password reset link to {{resetEmail}}
                </div>
            </div>
        </div>
        <!-- <div
            class="self-stretch rounded-lg bg-brand-600 shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] overflow-hidden flex flex-row items-center justify-center py-2.5 px-4 gap-[6px] text-base text-base-white border-[1px] border-solid border-brand-600 cursor-pointer"
        >
            <img
                class="w-5 relative h-5 overflow-hidden shrink-0 hidden"
                alt=s""
                src=""
            />

            <div class="flex flex-row items-center justify-center py-0 px-0.5">
                <div class="relative leading-[24px] font-semibold">
                    Open email app
                </div>
            </div>
            <img
                class="w-5 relative h-5 overflow-hidden shrink-0 hidden"
                alt="s"
                src=""
            />
        </div> -->
        <div class="self-stretch flex flex-row items-start justify-center gap-[4px]">
            <div class="relative leading-[20px]">Didn’t receive the email?</div>
            <div class="overflow-hidden flex flex-row items-center justify-center gap-[6px] text-brand-700 cursor-pointer"
                (click)="onSubmit()">
                <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />

                <div class="relative leading-[20px] font-semibold">
                    Click to resend
                </div>
                <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />
            </div>
        </div>
        <div class="overflow-hidden flex flex-row items-center justify-center gap-[6px] cursor-pointer"
            (click)="openLogin()">
            <img class="w-5 relative h-5 overflow-hidden shrink-0" alt="left icon" src="assets/images/home/arrowleft.svg" />

            <div class="relative leading-[20px] font-semibold">
                Back to log in
            </div>
            <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />
        </div>
    </div>
</div>