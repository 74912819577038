<div class="flex-1 flex flex-col items-start justify-start gap-[12px_0px] text-xl text-gray-900">
  <h1 class="relative leading-[30px] font-semibold">Payments</h1>
  <div class="self-stretch grid md:grid-cols-3 items-start md:justify-start gap-[12px_12px] text-sm text-gray-600">
    <div *ngFor="let payment of payments"
      class="flex-1 min-h-[150px] rounded-lg bg-base-white flex flex-col items-start justify-start border-[1px] border-solid border-gray-201">
      <div class="self-stretch flex flex-col items-start justify-start py-3 px-6 gap-[10px_0px]">
        <div class="flex flex-row items-center justify-start gap-[0px_10px]">
          <div class="relative leading-[20px] font-semibold whitespace-nowrap">
            {{ payment?.cardType }} ({{ payment?.cardNumber }})
          </div>
          <div *ngIf="payment?.isDefault"
            class="rounded-2xl bg-success-50 flex flex-row items-center justify-start py-0.5 px-2 text-center text-xs text-success-700 border-[1px] border-solid border-success-200">
            <div class="relative leading-[18px]">Default</div>
          </div>
        </div>
        <div class="relative leading-[20px]">
          <p class="[margin-block-start:0] [margin-block-end:14px]">
            Expiration Date {{ payment?.expiryDateText }}
          </p>
        </div>
        <div class="flex flex-row items-start justify-start gap-[0px_12px] text-brand-700">
          <!-- <div *ngIf="!payment?.isDefault"
            class="rounded-lg overflow-hidden flex flex-row items-center justify-center py-2.5 px-0 gap-[0px_4px] cursor-pointer">
            <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" />

            <div class="flex flex-row items-center justify-center">
              <div class="relative leading-[20px] font-semibold whitespace-nowrap">
                Make Default
              </div>
            </div>
            <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" />
          </div> -->
          <!-- <div
            (click)="editPaymentCard(payment)"
            class="rounded-lg overflow-hidden flex flex-row items-center justify-center py-2.5 px-0 gap-[0px_4px] cursor-pointer"
          >
            <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" />

            <div class="flex flex-row items-center justify-center">
              <div class="relative leading-[20px] font-semibold">Edit</div>
            </div>
            <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" />
          </div> -->
          <div
            class="rounded-lg overflow-hidden flex flex-row items-center justify-center py-2.5 px-0 gap-[0px_4px] cursor-pointer"
            (click)="deletePaymentCard(payment)">
            <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" />

            <div class="flex flex-row items-center justify-center py-0 px-0.5">
              <div class="relative leading-[20px] font-semibold">Delete</div>
            </div>
            <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" />
          </div>
        </div>
      </div>
    </div>

    <div
      class="self-stretch flex-1 min-h-[150px] min-w-[200px] rounded-lg bg-base-white flex flex-col items-center justify-center text-brand-700 border-[1px] border-solid border-gray-201"
      id="contentContainer2">
      <div (click)="newPaymentCard()"
        class="rounded-lg overflow-hidden flex flex-row items-center justify-center py-2.5 px-3 gap-[0px_4px] cursor-pointer">
        <img class="w-5 relative h-5 overflow-hidden shrink-0" alt="plus icon" src="assets/images/user/plus.svg" />

        <div class="flex flex-row items-center justify-center py-0 px-0.5">
          <div class="relative leading-[20px] font-semibold">Add New Card</div>
        </div>
        <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" />
      </div>
    </div>
  </div>
</div>

<app-drawer [drawerId]="'paymentsDrawer'" [isOpen]="isDrawerOpen" (reset)="reset()">
  <app-add-payment-card *ngIf="isPaymentDrawerOpen && paymentGateway === 'STRIPE'" [paymentCard]="selectedPaymentCard"
    (closeDrawer)="closePaymentCardDrawer($event)" (onSubmit)="addCreditCard($event)" />
  <app-add-cardknox-card *ngIf="isPaymentDrawerOpen && paymentGateway === 'CARDKNOX'"
    (closeDrawer)="closePaymentCardDrawer($event)" (onSubmit)="addCardKnoxCreditCard($event)"></app-add-cardknox-card>
</app-drawer>

<app-modal [modalId]="'paymentsModal'" [isOpen]="isModalOpen" (reset)="resetModal()">
  <app-confirmation-popup *ngIf="isConfirmationOpen" [title]="confirmationTitle"
    (closeModal)="closeConfrimationPopUp($event)" (onConfirm)="deleteCreditCard()" />
</app-modal>