import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, tap } from 'rxjs';
import { ApiService } from './api.service';
import { Urls } from '../util/urls';

@Injectable({
  providedIn: 'root'
})
export class AppInitService {
  companyInfo: any;

  constructor(public api: ApiService) {}

  // Method to load the config data asynchronously
  loadAppConfig(appName): Promise<void> {
    return firstValueFrom(
      this.api.get(Urls.COMPANY_SETTINGS + appName).pipe(
        tap(data => {
          this.companyInfo = data;
        })
      )
    ).then(() => {});
  }
}
