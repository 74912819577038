import { Component } from '@angular/core';
import AdyenCheckout from '@adyen/adyen-web';
import { CheckoutService } from '@app/checkout/services/checkout.service';
import { Input } from '@angular/core';
import { environment } from '@environments/environment';
import { PaymentTypes } from '@app/core/models/paymentType.enum';
import { EventEmitter } from '@angular/core';
import { Output } from '@angular/core';
import { AuthService } from '@app/core/services/auth.service';
import { NotificationService } from '@app/core/services/notification.service';

@Component({
  selector: 'app-add-adyen-card',
  templateUrl: './add-adyen-card.component.html',
  styleUrl: './add-adyen-card.component.scss',
  standalone: true,
})
export class AddAdyenCardComponent {
  @Input() orderDetail;
  @Output() closeDrawer = new EventEmitter<boolean>();
  @Output() onSubmit = new EventEmitter<any>();
  sessionData;
  user;
  constructor(
    private checkoutService: CheckoutService,
    private authService: AuthService,
    private notification: NotificationService
  ) {
    this.authService.currentUser.subscribe((data) => {
      this.user = data;
    });
  }
  ngOnInit(): void {
    this.intializePaymentSession();
  }

  intializePaymentSession() {
    const queryObj = {
      nonce: {
        amount: this.orderDetail?.amountDue || 5,
        channel: 'Web',
        currency: this.orderDetail?.currency || 'EUR',
        returnUrl: 'https://goyolla-47a47.web.app/payment-return',
      },
      type: PaymentTypes.SESSION,
    };
    const queryJsonString = JSON.stringify(queryObj);
    const base64Encoded = btoa(queryJsonString);
    const params = {
      query: base64Encoded,
    };
    this.checkoutService
      .paymentSession(environment.eddressAppConfig.paymentGateway, params)
      .subscribe(
        (result) => {
          this.sessionData = result?.data;
          this.initializeAdyenCheckout();
        },
        (error) => {}
      );
  }
  async initializeAdyenCheckout() {
    // Create Drop-in and mount it on your Angular template
    const checkout = await this.createAdyenCheckout();
    await checkout.create('dropin').mount('#adyen-checkout');
  }

  async createAdyenCheckout() {
    const configuration = {
      clientKey: this.sessionData?.clientKey,
      environment: 'live', // Use 'live' when in production
      paymentMethodsResponse: this.sessionData?.paymentMethodsResponse, // The result of the /paymentMethods call
      locale: 'en-US', // Optional. Specifies the language to show in the UI
      showPayButton: true,
      analytics: {
        enabled: true, // Set to false to not send analytics data to Adyen.
      },
      amount: {
        currency: this.sessionData.currency, // Use the relevant currency
        value: this.sessionData.amount, // Amount in minor units (e.g., 1000 means 10.00 EUR)
      },
      onSubmit: (state, dropin) => {
        // Your logic to handle the payment submission
        this.makePayment(state, dropin);
      },
      onError: (error) => {
        // console.error('Error occurred during the payment:', error);
      },
    };

    return await AdyenCheckout(configuration);
  }

  async finalizeCheckout() {
    try {
      // Create AdyenCheckout re-using existing Session
      const checkout = await this.createAdyenCheckout();

      // Submit the extracted redirectResult (to trigger onPaymentCompleted() handler)
      checkout.submitDetails({ details: '' });
    } catch (error) {
      // console.error(error);
    }
  }

  makePayment(state, dropin) {
    const queryObj = {
      nonce: {
        channel: 'Web',
        currency: this.orderDetail?.currency,
        returnUrl: 'https://goyolla-47a47.web.app/payment-return',
        merchantReference: this.orderDetail?.sequenceNumber,
        shopperReference: this.sessionData?.shopperReference,
        paymentComponentData: JSON.stringify({
          paymentMethod: state?.data?.paymentMethod,
          storePaymentMethod: false,
          supportNativeRedirect: true,
        }),
        minorUnitsAmount: this.sessionData?.amount,
        userAgent: state?.data?.browserInfo?.userAgent,
      },
      type:
        state?.data?.paymentMethod?.type === 'paypal' ? PaymentTypes.PAYPAL : PaymentTypes.SCHEME,
    };
    const queryJsonString = JSON.stringify(queryObj);
    const base64Encoded = btoa(queryJsonString);
    const params = {
      query: base64Encoded,
    };
    this.checkoutService
      .paymentSession(environment?.eddressAppConfig?.paymentGateway, params)
      .subscribe(
        (result) => {
          if (result.data?.paypalRedirectAction) {
            localStorage.setItem('orderUid', this.orderDetail.uid);
            dropin.handleAction(result.data?.paypalRedirectAction); // Handle the redirect
          } else {
            if (result?.data?.status === 'AUTHORIZED') {
              this.onSubmit.emit(result);
              this.close();
            } else {
              this.notification.errorNotification(result?.data.refusalReason);
              const backdrop = document.getElementById('adyen-backrop');
              backdrop.style.display = 'none';
              this.close();
            }
          }
        },
        (error) => {}
      );
  }
  closeAndCancelOrder() {
    // const params = {
    //   locale: 'en',
    //   os: 'Web',
    //   uid: this.user.uid,
    //   idUser: this.user.idUser,
    //   appName: environment.eddressAppConfig.appName,
    //   cancelationReason: '',
    //   orderUid: this.orderDetail?.uid,
    // };
    // this.orderDetailService.cancelOrder(params, this.orderDetail?.uid).subscribe((response) => {

    // });
    const backdrop = document.getElementById('adyen-backrop');
    backdrop.style.display = 'none';
    this.close();
  }
  close() {
    this.closeDrawer.emit(false);
  }
}
