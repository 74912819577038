<div class="w-full self-stretch flex flex-col justify-start gap-[16px_0px]">

    <div class="flex relative leading-[20px] font-semibold cursor-pointer" (click)="showFilter()">
        <span class="self-center">{{label}}</span>
        <img [src]="isFilterOpen ? upArrow : downArrow" alt="Brand Image" class="self-center ml-5"
            style="width: 15px; height: 15px;" />
    </div>
    <div class="w-full self-stretch flex flex-col justify-start gap-[8px_0px]" *ngIf="isFilterOpen">
        <div class="self-stretch flex flex-col justify-start gap-[8px_0px]">
            <div class="self-stretch flex flex-col justify-start gap-[6px_0px]">
                <div class="relative leading-[20px] hidden">
                    Search
                </div>
                <div
                    class="self-stretch rounded-lg bg-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] overflow-hidden flex flex-row items-center justify-start py-2.5 px-3.5 text-base text-gray-500 border-[1px] border-solid border-gray-300">
                    <div class="flex-1 flex flex-row items-center justify-start gap-[0px_8px]">
                        <img class="w-5 relative h-5 overflow-hidden shrink-0" alt="search long"
                            src="assets/images/category/searchlg.svg" />

                        <input type="text"
                            class="flex-1 relative leading-[24px] overflow-hidden text-ellipsis whitespace-nowrap outline-none focus:ring-0 border-none p-0 m-0"
                            placeholder="Search" [(ngModel)]="searchValue">
                    </div>
                </div>
            </div>
        </div>
        <div class="self-stretch flex flex-col items-start justify-start py-0 pr-0 pl-2 gap-[12px_0px]">
            <div class="self-stretch flex flex-col items-start justify-start py-0 pt-2 pb-2 pl-2 mt-2 gap-[12px_0px]"
                style="max-height: 250px; overflow-y: auto;" *ngIf="searchList.length == 0">
                <div class="self-stretch flex flex-row items-start justify-start gap-[0px_8px]"
                    *ngFor="let item of batchList | search:'label': searchValue; let i = index">
                    <div class="flex flex-row items-center justify-center pt-0.5 px-0 pb-0">
                        <div class="flex items-center">
                            <input [(ngModel)]="item.selected" id="default-checkbox-{{i}}" type="checkbox" value=""
                                class="text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            <label for="default-checkbox-{{i}}"
                                class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">{{ item.label
                                }}</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex flex-row items-start justify-start text-brand-700" *ngIf="label !== 'Sub Categories'">
                <div class="overflow-hidden flex flex-row items-center justify-center gap-[0px_6px]">
                    <div class="relative leading-[20px] font-semibold cursor-pointer" (click)="showMore()"
                        *ngIf="batchList < mainItemsList && searchList.length === 0">
                        Show 10 More
                    </div>

                    <div class="relative leading-[20px] font-semibold cursor-pointer"
                        *ngIf="batchList.length === mainItemsList.length" (click)="showLess()">
                        Show Less
                    </div>

                    <!-- <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon"
                        src="assets/images/category/placeholder.svg" /> -->
                </div>
            </div>
        </div>
    </div>
</div>