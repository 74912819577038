import { AfterViewInit, Component, ElementRef, Input, OnInit, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Carousel } from 'flowbite';
import type { CarouselItem, CarouselOptions, CarouselInterface } from 'flowbite';
import type { InstanceOptions } from 'flowbite';
import { NgIf, NgFor } from '@angular/common';
@Component({
    selector: 'app-landing-slider',
    templateUrl: './landing-slider.component.html',
    styleUrl: './landing-slider.component.scss',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgIf, NgFor],
})
export class LandingSliderComponent implements OnInit, AfterViewInit {
  @ViewChildren('bannerSlide') bannerSlides!: QueryList<ElementRef>;
  @Input() banners = [];

  interval: any;
  intervalPause: any;

  activeSlideIndex = 0;

  carouselVar;
  intervalTimer;

  constructor(public router: Router) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.initialize();
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const target = entry.target as HTMLElement;
          const imageUrl = target.getAttribute('data-image');
          if (imageUrl) {
            target.style.backgroundImage = `url(${imageUrl})`;
          }
          observer.unobserve(target); // Stop observing once the image has been loaded
        }
      });
    });

    this.bannerSlides.forEach(slide => {
      observer.observe(slide.nativeElement);
    });
  }
  

  initialize(): void {
    const carouselElement: HTMLElement = document.getElementById('carousel-example');

    const items: CarouselItem[] = [];
    const indicatorItems = [];
    this.banners.forEach((banner, index) => {
      items.push({
        position: index,
        el: document.getElementById('carousel-item-' + (index + 1)),
      });
      indicatorItems.push({
        position: index,
        el: document.getElementById('carousel-indicator-' + (index + 1)),
      });
    });

    // object options with default values
    const options: CarouselOptions = {
      defaultPosition: 0,
      interval: 5000,

      indicators: {
        activeClasses: 'bg-brand-700 dark:red',
        inactiveClasses: 'bg-white/50 dark:bg-brand-700 hover:bg-brand-700 dark:hover:bg-brand-700',
        items: indicatorItems,
      },

      // callback functions
      onNext: () => {
        // console.log('next slider item is shown');
      },
      onPrev: () => {
        // console.log('previous slider item is shown');
      },
      onChange: () => {
        // console.log('new slider item has been shown');
      },
    };

    // instance options object
    const instanceOptions: InstanceOptions = {
      id: 'carousel-example',
      override: true,
    };

    const carousel: CarouselInterface = new Carousel(
      carouselElement,
      items,
      options,
      instanceOptions
    );

    this.carouselVar = carousel;

    this.startCarousel(carousel);

    //carousel.cycle();

    // set event listeners for prev and next buttons
    const $prevButton = document.getElementById('data-carousel-prev');
    const $nextButton = document.getElementById('data-carousel-next');

    $prevButton.addEventListener('click', () => {
      clearInterval(this.intervalTimer);
      this.startCarousel(carousel);
      if (this.activeSlideIndex > 0) {
        this.activeSlideIndex = (this.activeSlideIndex - 1) % this.banners.length;
        carousel.prev();
      } else {
        this.activeSlideIndex = (this.activeSlideIndex + 1) % this.banners.length;
        carousel.next();
      }
    });

    $nextButton.addEventListener('click', () => {
      clearInterval(this.intervalTimer);
      this.startCarousel(carousel);
      this.activeSlideIndex = (this.activeSlideIndex + 1) % this.banners.length;
      carousel.next();
    });
  }
  shopNow(url) {
    this.router.navigate([url]);
  }

  startCarousel(carousel: CarouselInterface) {
    this.intervalTimer = setInterval(() => {
      if (this.activeSlideIndex < this.banners.length - 1) {
        this.activeSlideIndex = this.activeSlideIndex + 1;
      } else {
        this.activeSlideIndex = 0;
      }
      carousel.next();
    }, 5000);
  }

  changeActiveIndicator() {
    if (this.activeSlideIndex > 0) {
      this.activeSlideIndex = (this.activeSlideIndex - 1) % this.banners.length;
      this.carouselVar.prev();
    } else if (this.activeSlideIndex === 0) {
      this.activeSlideIndex = (this.activeSlideIndex + 1) % this.banners.length;
      this.carouselVar.next();
    }
  }
  actionClick(banner) {
    if (banner?.actionUrl) {
      window.location.href = banner?.actionUrl;
    } else if (banner.collectionSlug) {
      //need to fix from backend api
      this.router.navigate(['/category', banner?.collectionSlug]);
    }
  }
}
