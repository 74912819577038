import { AuthService } from '@core/services/auth.service';
import { ValidatorService } from './../../../../core/services/validator.service';
import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl, ValidationErrors, ValidatorFn, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ValidationFn } from 'ngx-permissions';
import { FirebaseAuthErrorHandler } from '@app/core/error/firebaseError';
import { LocalStorageService } from '@app/core/services/localstorage.service';
import { LOGIN_EXPIRY } from '@app/shared/variables/loginVariables';
import { NotificationService } from '@app/core/services/notification.service';
import { LoaderService } from '@app/core/services/loader.service';
import { environment } from '@environments/environment';
import { NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'app-signup',
    templateUrl: './signup.component.html',
    styleUrl: './signup.component.scss',
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        NgIf,
        NgClass,
    ],
})
export class SignupComponent implements OnInit {
  @Output() isLoginOpen = new EventEmitter<boolean>();
  @Output() isSignupOpen = new EventEmitter<boolean>();

  isSubmitted = false;

  constructor(
    private validators: ValidatorService,
    private auth: AuthService,
    private notification: NotificationService,
    private loaderService: LoaderService,
    public authService: AuthService
  ) {}

  signUpForm = new FormGroup(
    {
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [this.validators.emailValidator, Validators.required]),
      password: new FormControl('', [
        ValidatorService.passwordValidator,
        ValidatorService.passwordValidatorSpecialCharacters,
        Validators.required,
        Validators.minLength(8),
      ]),
      confirmPassword: new FormControl('', [Validators.required]),
    },
    {
      validators: this.confirmedValidator('password', 'confirmPassword'),
    }
  );

  ngOnInit() {}

  closeSignup() {
    this.isSignupOpen.emit(false);
  }

  openLogin() {
    this.isLoginOpen.emit(true);
  }

  onSubmit() {
    this.isSubmitted = true;

    const name = this.signUpForm.get('name').value;
    const email = this.signUpForm.get('email').value;
    const password = this.signUpForm.get('password').value;
    if (this.signUpForm.valid) {
      let uid = '';
      this.loaderService.open();
      this.auth
        .signUp(email, password)
        .then((result) => {
          uid = result.user.uid;

          const credentials = {
            appName: environment.eddressAppConfig.appName, // from frontend
            authUid: uid,
            email: email,
            fullName: name,
            appIdentifier: '', // not needed on web
            os: 'web',
            locale: 'es', // from frontend localized
            authProvider: password,
          };

          this.auth.backendAuthenticate(credentials).subscribe((result) => {
            this.auth.setLoginWithExpiryForLocalStorage(result.jwt);
            this.loaderService.close();
            this.signIn();
          });
        })
        .catch((error) => {
          this.loaderService.close();
          const errorMessage = FirebaseAuthErrorHandler.convertMessage(error['code']);

          this.notification.errorNotification(errorMessage);
        });
    }
  }

  signIn() {
    this.isSubmitted = true;

    const email = this.signUpForm.get('email').value;
    const password = this.signUpForm.get('password').value;
    if (email && password) {
      let uid = '';
      this.loaderService.open();
      this.authService.attemptAuthByEmailAndPassword(email, password).subscribe(
        (result) => {
          uid = result.user.uid;

          const credentials = {
            appName: environment.eddressAppConfig.appName, // from frontend
            authUid: uid,
            email: email,
            fullName: '',
            appIdentifier: '', // not needed on web
            os: 'web',
            locale: 'es', // from frontend localized
            authProvider: password,
          };

          this.authService.backendAuthenticate(credentials).subscribe((data) => {
            this.loaderService.close();
            // this.notification.successNotification('Login Successfully');
            // this.authService.setLoginWithExpiryForLocalStorage(result.jwt);
            this.authService.setUserAuthentication(data);
            this.authService.setToken(data.jwtToken);
            this.closeSignup();
            window.location.reload();
          });
        },
        (error) => {
          this.loaderService.close();
          const errorMessage = FirebaseAuthErrorHandler.convertMessage(error['code']);
          this.notification.errorNotification(errorMessage);
        }
      );
    }
  }

  confirmedValidator(controlName: string, matchingControlName: string): ValidatorFn {
    return (formGroup: FormGroup): ValidationErrors | null => {
      const control = formGroup.get(controlName);
      const matchingControl = formGroup.get(matchingControlName);

      if (!control || !matchingControl) {
        return null;
      }

      if (matchingControl.errors && !matchingControl.errors['passwordMismatch']) {
        return null;
      }

      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ passwordMismatch: true });
        return { passwordMismatch: true };
      } else {
        matchingControl.setErrors(null);
        return null;
      }
    };
  }

  get signupForm() {
    return this.signUpForm.controls;
  }
}
