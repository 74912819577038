import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private toastrService: ToastrService) {}

  successNotification(message: string) {
    this.toastrService.success(message, '', {
      timeOut: 5000,
    });
  }
  errorNotification(message: string) {
    this.toastrService.error(message, '', {
      timeOut: 5000,
    });
  }
  infoNotification(message: string) {
    this.toastrService.info(message, '', {
      timeOut: 5000,
    });
  }

  warningNotification(message: string) {
    this.toastrService.warning(message, '', {
      timeOut: 5000,
    });
  }
}
