import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import { InstanceOptions, Modal, ModalInterface, ModalOptions } from 'flowbite';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrl: './modal.component.scss',
    standalone: true,
})
export class ModalComponent {
  @Input() isOpen: boolean;
  @Input() modalId;

  @Output() reset: EventEmitter<boolean> = new EventEmitter<boolean>();

  // options with default values
  modal: ModalInterface;
  modalOptions: ModalOptions = {
    // placement: 'bottom-right',
    backdrop: 'dynamic',
    backdropClasses:
      'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40 modal-backdrop-color',
    closable: true,
    onHide: () => {
      // console.log('modal is hidden');
    },
    onShow: () => {
      // console.log('modal is shown');
    },
    onToggle: () => {
      // console.log('modal has been toggled');
    },
  };

  // instance options object
  instanceOptions: InstanceOptions = {
    id: 'modalEl',
    override: true,
  };

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isOpen'].currentValue) {
      this.openModal();
    }

    if (!changes['isOpen'].currentValue) {
      this.onCloseModal();
    }
  }
  ngAfterViewInit() {
    const $targetEl: any = document.getElementById(this.modalId);
    this.modal = new Modal($targetEl, this.modalOptions, this.instanceOptions);
  }

  openModal() {
    this.modal?.show();
  }

  onCloseModal() {
    this.modal?.hide();
  }
}
