import { NewsletterComponent } from './../shared/components/newsletter/newsletter.component';
import { AfterContentInit, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import { EddressFacadeService } from '@app/core/services/eddressFacade.service';
import { GenericService } from '@app/core/services/generics.service';
import { LoaderService } from '@app/core/services/loader.service';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { AddressesService } from '@app/user-information/addresses/services/addresses.service';
import { NotificationService } from '@app/core/services/notification.service';
import { ForgotPasswordComponent } from '../layout/header/components/forgot-password/forgot-password.component';
import { SignupComponent } from '../layout/header/components/signup/signup.component';
import { LoginComponent } from '../layout/header/components/login/login.component';
import { AddressListComponent } from '../shared/components/address-list/address-list.component';
import { AddLocationComponent } from '../shared/components/add-location/add-location.component';
import { DrawerComponent } from '../shared/drawer/drawer.component';
import { LandingCategoriesComponent } from './components/landing-categories/landing-categories.component';
import { LandingBestSellersComponent } from './components/landing-best-sellers/landing-best-sellers.component';
import { LandingSliderComponent } from './components/landing-slider/landing-slider.component';
import { NgIf, NgFor, AsyncPipe, JsonPipe } from '@angular/common';
import { select, Store } from '@ngrx/store';
import { Banner } from '@app/core/models/banner.model';
import { addToBanner } from '@app/store/actions/banner.action';
import { AppState } from '@app/app.state';
import { BannerState } from '@app/store/states/bannerState';
import { selectBannersState, selectDataLoaded } from '@app/store/selectors/banner.selector';
import sortBy from 'lodash/sortBy';
import { selectPagesState } from '@app/store/selectors/pageTitles.selector';

@Component({
    selector: 'app-landing',
    templateUrl: './landing.component.html',
    styleUrl: './landing.component.scss',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        NgIf,
        LandingSliderComponent,
        NgFor,
        LandingBestSellersComponent,
        LandingCategoriesComponent,
        DrawerComponent,
        AddLocationComponent,
        AddressListComponent,
        LoginComponent,
        SignupComponent,
        ForgotPasswordComponent,
        AsyncPipe,
        JsonPipe,
        NewsletterComponent
    ],
})
export class LandingComponent implements OnInit, AfterContentInit {
  homePageBannerState$?:Observable<BannerState>;
  isLoaded$: Observable<boolean>;
  islanding: boolean = false;
  defaultLocations = [];
  selectedStore;
  stores = [];
  user;
  homeSections = [];
  homePageCategories = [];
  homePageProductSections = [];
  

  isDrawerOpen: boolean = false;
  isLocationOpen: boolean = false;
  isAddressesOpen: boolean = false;

  isLoginDrawerOpen: boolean = false;
  isSignupDrawerOpen: boolean = false;
  isForgotPasswordDrawerOpen: boolean = false;
  isFilterOpen: boolean = false;

  deliveryAddress;

  constructor(
    private route: ActivatedRoute,
    public eddress: EddressFacadeService,
    private authService: AuthService,
    private genericService: GenericService,
    private loaderService: LoaderService,
    private addressesService: AddressesService,
    private notification: NotificationService,
    private store: Store<AppState>
  ) {
    this.homePageBannerState$ = this.store.pipe(select(selectBannersState));
    this.isLoaded$ = this.store.pipe(select(selectDataLoaded));
    this.authService.currentUser.subscribe((data) => {
      if (Object.keys(data).length !== 0) {
        this.user = data;
      }
    });
    this.genericService.getDefaultLocations().subscribe((locs) => {
      const stores = this.genericService.getStoresData();
      if (stores?.length > 0) {
        this.selectedStore = stores[0];
      }
      this.defaultLocations = locs;

     

      // Subscribe and check if data is loaded
      this.isLoaded$.subscribe(isLoaded => {
        if (!isLoaded) {
          if (this.selectedStore && this.defaultLocations.length > 0) {
            if (this.user) {
              this.getHomepageBanners();
            } else {
              this.getPublicHomepageBanners();
            }
          }
        }
      });
      
    });
    // this.genericService.getStoresData().subscribe((result) => {
    //   if (result.length > 0) {
    //     this.selectedStore = result[0];
    //     this.getHomepageBanners();
    //   }
    // });

    this.authService.homePageData.subscribe((result) => {
      if (result) {
        this.homeSections = result?.homeSections;

        if (this.homeSections) {
          this.homeSections = this.homeSections?.filter((p) => !p.pageId);
          this.homeSections?.forEach((section) => {
            const collectonExsit = section.items.some((s) => s.type === 'COLLECTION');
            section.isCollectionSection = collectonExsit;
            // section.items.forEach((item) => {
            //   if (item.type === 'COLLECTION') {
            //     collections.push(item);
            //   }
            // });
            // if (section.type === 'PRODUCTS') {
            //   this.homePageProductSections.push(section);
            // }
          });
          this.homePageProductSections = sortBy(this.homePageProductSections, (o) => o.sortOrder);
        }
      }
    });
  }

  ngOnInit(): void {
    this.genericService.setPageTitleAndDescription('/', '');
  }
  getHomepageBanners() {
    const params = {
      locale: 'en',
      os: 'web',
      platform: 'WEB',
      locality: {
        coordinates: {
          lat: this.selectedStore.lat,
          lon: this.selectedStore.lon,
        },
      },
      uid: this.user.uid,
      idUser: this.user.idUser,
      operationUid: environment.eddressAppConfig.operationUid,
    };
    this.loaderService.open();
    this.genericService.getBanners(this.selectedStore?.id, params).subscribe(
      (result) => {
        this.loaderService.close();
        this.setBanners(result.banners)
      },
      (error) => {
        this.loaderService.close();
      }
    );
  }
  getPublicHomepageBanners() {
    const params = {
      locale: 'en',
      os: 'web',
      platform: 'WEB',
      locality: {
        coordinates: {
          lat: this.selectedStore.lat,
          lon: this.selectedStore.lon,
        },
      },
      appName: environment.eddressAppConfig.appName,
      operationUid: environment.eddressAppConfig.operationUid,
    };
    this.loaderService.open();
    this.genericService.getPublicBanners(this.selectedStore?.id, params).subscribe(
      (result) => {
        this.loaderService.close();
        this.setBanners(result.banners)
      },
      (error) => {
        this.loaderService.close();
      }
    );
  }

  setBanners(banners: Banner[]) {
    this.store.dispatch(addToBanner({banners: banners, isLoaded: true}))
  }
  openDrawer(type) {
    this.isDrawerOpen = true;

    this.isLocationOpen = false;
    this.isAddressesOpen = false;
    this.isLoginDrawerOpen = false;
    this.isFilterOpen = false;

    if (type === 'location') {
      this.isLocationOpen = true;
    } else if (type === 'addresses') {
      this.isAddressesOpen = true;
    } else if (type === 'login') {
      this.isForgotPasswordDrawerOpen = false;
      this.isSignupDrawerOpen = false;
      this.isLoginDrawerOpen = true;
    } else if (type === 'signup') {
      this.isLoginDrawerOpen = false;
      this.isForgotPasswordDrawerOpen = false;
      this.isSignupDrawerOpen = true;
    } else if (type === 'forgotPassword') {
      this.isLoginDrawerOpen = false;
      this.isForgotPasswordDrawerOpen = true;
    }
  }
  closeLocation($event) {
    this.isDrawerOpen = false;
    this.isLocationOpen = $event;
  }
  closeAddresses($event) {
    this.isDrawerOpen = false;
    this.isLocationOpen = $event;
  }

  closeLogin($event) {
    this.isDrawerOpen = false;
    this.isLoginDrawerOpen = $event;
  }

  closeSignup($event) {
    this.isDrawerOpen = false;
    this.isSignupDrawerOpen = $event;
  }

  closeForgotPassword($event) {
    this.isDrawerOpen = false;
    this.isForgotPasswordDrawerOpen = $event;
  }

  reset() {
    this.isDrawerOpen = false;
    this.isDrawerOpen = false;
    this.isLocationOpen = false;
  }
  getDeliveryAddress(deliveryAddress) {
    if (this.deliveryAddress !== deliveryAddress) {
      window.location.reload();
    }
    this.deliveryAddress = deliveryAddress;
    this.authService.setUserDeliveryAddress(this.deliveryAddress);
  }
  saveLocation(location) {
    const params = {
      address: {
        locality: location.locality,
        state: '',
        uid: this.user.uid,
        version: '3.1.9',
        street: location.street,
        building: location.building,
        locale: 'es',
        os: 'web',
        type: '',
        appName: environment.eddressAppConfig.appName,
        coordinates: location.coordinates,
        city: '',
        phoneCountryCode: '0',
        idUser: this.user.idUser,
        phoneCountryId: '0',
        notes: '',
        name: location.name,
        creationLat: location.coordinates.lat,
        creationLon: location.coordinates?.lon,
      },
    };
    this.loaderService.open();
    this.addressesService.saveUserAddress(params).subscribe(
      (result) => {
        this.loaderService.close();
        this.notification.successNotification('Address saved successfully');
        this.closeLocation(true);
      },
      (err) => {
        this.loaderService.close();
      }
    );
  }
  ngAfterContentInit() {}
}
