<div *ngIf="productList?.length > 0"
  class="self-stretch items-center justify-center pt-0 px-16 pb-12 gap-[32px_32px] cursor-pointer grid md:grid-cols-3 lg:grid-cols-5"
  id="frameContainerCategory2">
  <ng-container *ngFor="let item of productList">
    <app-product-item [item]="item" (openAddressDrawer)="openDrawer('addresses')"
      (openLoginDrawer)="openDrawer('login')" (onUpdateProduct)="onUpdateProduct($event)" />
  </ng-container>

</div>

<app-no-result *ngIf="isApiResponed && productList.length === 0" [searchString]="searchString"
  (onSearch)="onSearchBtnClick($event)"></app-no-result>

<app-drawer [drawerId]="'searchPageDrawer'" [isOpen]="isDrawerOpen" (reset)="reset()">
  <app-add-location *ngIf="isLocationOpen" (closeDrawer)="closeLocation($event)" (onSubmit)="saveLocation($event)" />
  <app-address-list *ngIf="isAddressesOpen" [eddressCode]="deliveryAddress?.eddressCode"
    (closeDrawer)="closeAddresses($event)" (newLocation)="openDrawer('location')"
    (confirmLocation)="getDeliveryAddress($event)"></app-address-list>

  <app-login *ngIf="isLoginDrawerOpen" (isLoginOpen)="closeLogin($event)" (isSignupOpen)="openDrawer('signup')"
    (isForgotPasswordOpen)="openDrawer('forgotPassword')" />

  <app-signup *ngIf="isSignupDrawerOpen" (isSignupOpen)="closeSignup($event)" (isLoginOpen)="openDrawer('login')" />
  <app-forgot-password *ngIf="isForgotPasswordDrawerOpen" (isForgotPasswordOpen)="closeLogin($event)"
    (isLoginOpen)="openDrawer('login')" />
</app-drawer>