export enum OrderStatusEnum {
  DRAFT = 'DRAFT',
  NEW = 'NEW',
  ASSIGNED = 'ASSIGNED',
  OUT_FOR_DELIVERY = 'OUT_FOR_DELIVERY',
  PICKED_UP = 'PICKED_UP',
  PICKING_UP = 'PICKING_UP',
  DELIVERING = 'DELIVERING',
  DELIVERED = 'DELIVERED',
  RETURNING = 'RETURNING',
  RETURNED = 'RETURNED',
  FAILED = 'FAILED',
  PICKUP_FAILED = 'PICKUP_FAILED',
  DELIVERY_FAILED = 'DELIVERY_FAILED',
  RETURN_FAILED = 'RETURN_FAILED',
  CONFIRMED = 'CONFIRMED',
  COMPLETE = 'COMPLETE',
  RETURNED_TO_CUSTOMER = 'RETURNED_TO_CUSTOMER',
  DONE = 'DONE',
  RETURN_CANCELED = 'RETURN_CANCELED',
  NEW_CANCELED = 'NEW_CANCELED',
  CANCELED = 'CANCELED',
  UNFULFILLED = 'UNFULFILLED',
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  READY = 'READY',
  DELAYED = 'DELAYED',
}
