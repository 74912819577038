import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/services/api.service';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScriptsService {

  constructor(private api: ApiService) { }

  getScripts(params): Observable<any> {
    return this.api
      .get('api/market/app/public/webScripts', params)
      .pipe(map((data) => data));
  }
}
