<div
    class="w-full relative bg-base-white flex flex-col items-center justify-start p-8 box-border gap-[32px_0px] text-left text-sm text-gray-900 font-display-xs-semibold">
    <div class="self-stretch flex flex-col items-center justify-start gap-[32px_0px] z-[0]">
        <div class="self-stretch flex flex-col items-center justify-start text-5xl">
            <div class="self-stretch flex flex-col items-start justify-start">
                <div class="relative leading-[32px] font-semibold">Confirm Your Details</div>
            </div>
        </div>
        <form [formGroup]="userProfileFormGroup"
            class="self-stretch rounded-xl flex flex-col items-center justify-start gap-[24px_0px] text-gray-700">
            <div class="self-stretch flex flex-col items-start justify-start gap-[20px_0px]">
                <div class="self-stretch flex flex-col items-start justify-start gap-[6px_0px]">
                    <div class="self-stretch flex flex-col items-start justify-start gap-[6px_0px]">
                        <div class="relative leading-[20px]">Name</div>
                        <input type="text" placeholder="Name"
                            class="self-stretch rounded-lg bg-base-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] flex flex-row items-center justify-start py-2.5 px-3.5 gap-[0px_8px] text-base text-gray-900 border-[1px] border-solid border-gray-300"
                            formControlName="fullName" />
                        <span *ngIf="
                userAccountForm['fullName'].invalid &&
                userAccountForm['fullName'].errors['required'] &&
                isSubmitted
              " class="text-error-500">Name is required</span>
                    </div>
                </div>
                <div class="self-stretch flex flex-col items-start justify-start gap-[6px_0px]">
                    <div class="self-stretch flex flex-col items-start justify-start gap-[6px_0px]">
                        <div class="relative leading-[20px]">Email</div>
                        <input type="text" placeholder="Email"
                            class="self-stretch rounded-lg bg-base-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] flex flex-row items-center justify-start py-2.5 px-3.5 gap-[0px_8px] text-base text-gray-900 border-[1px] border-solid border-gray-300"
                            formControlName="email" />
                        <span class="text-error-500" *ngIf="userAccountForm['email'].invalid && isSubmitted">
                            <span *ngIf="userAccountForm['email'].errors['required']">Email is required</span>
                            <span *ngIf="userAccountForm['email'].errors['email']">Email is invalid</span>
                        </span>
                    </div>
                </div>
                <div class="flex items-center justify-center">
                    <div class="relative" [ngClass]="{'open': open}">
                        <div class="flex">
                            <div class="relative">
                                <button (click)="toggleDropdown()" id="dropdown-phone-button"
                                    data-dropdown-toggle="dropdown-phone"
                                    class="flex-shrink-0 z-10 inline-flex items-center py-3 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-s-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600"
                                    type="button">

                                    <img class="h-6 w-6 me-2" [src]="selectedCountry.image" /> {{
                                    selectedCountry.dial_code
                                    }}<svg class="w-2.5 h-2.5 ms-2.5" aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                            stroke-width="2" d="m1 1 4 4 4-4" />
                                    </svg>
                                </button>
                                <div *ngIf="open"
                                    class="absolute z-10 mt-1 bg-white border border-gray-300 rounded-md shadow-lg w-56">
                                    <input [(ngModel)]="searchValue" [ngModelOptions]="{standalone: true}" type="text"
                                        placeholder="Search..."
                                        class="w-full px-3 py-2 border-b border-gray-300 focus:outline-none">
                                    <ul class="max-h-60 overflow-y-auto">
                                        <li *ngFor="let country of countries | search:'name': searchValue"
                                            (click)="selectCountry(country)"
                                            class="px-3 py-2 hover:bg-gray-100 cursor-pointer flex items-center">
                                            <img class="h-4 w-4 me-2" [src]="country.image" />
                                            <span>{{ country.name }}</span>
                                            <span class="ml-auto text-gray-500">{{ country.dial_code }}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <input formControlName="phoneNumber" type="tel"
                                class="px-3 py-2 border border-gray-300 rounded-r-md focus:outline-none"
                                placeholder="Phone number">
                        </div>
                        <span *ngIf="
                        userAccountForm['phoneNumber'].invalid &&
                        userAccountForm['phoneNumber'].errors['required'] &&
                        isSubmitted
                      " class="text-error-500">Mobile Number is required</span>
                    </div>
                </div>
            </div>
            <div (click)="onSubmitFn()"
                class="self-stretch flex flex-col items-start justify-start text-base text-base-white cursor-pointer">
                <div
                    class="self-stretch rounded-lg bg-brand-600 shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] overflow-hidden flex flex-row items-center justify-center py-2.5 px-4 gap-[0px_6px] border-[1px] border-solid border-brand-600">
                    <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />

                    <div class="flex flex-row items-center justify-center py-0 px-0.5">
                        <div class="relative leading-[24px] font-semibold">
                            Confirm
                        </div>
                    </div>
                    <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />
                </div>
            </div>
        </form>
    </div>
    <!-- <div (click)="close()"
        class="w-11 !m-[0] absolute top-[26px] right-[20px] rounded-lg h-11 overflow-hidden shrink-0 flex flex-row items-center justify-center p-2 box-border z-[1] cursor-pointer">
        <img class="w-6 relative h-6 overflow-hidden shrink-0" alt="s" src="assets/images/home/xclose.svg" />
    </div> -->
</div>