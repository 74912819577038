<div class="flex-1 flex flex-col items-center md:items-start justify-start gap-[12px_0px] text-lg text-gray-900">
  <h1 class="relative text-xl leading-[30px] font-semibold">Favorites</h1>
  <div *ngIf="productList?.length > 0" class="flex flex-row items-center pt-0 pb-12 gap-[0px_32px] cursor-pointer"
    id="frameContainer1">
    <div class="grid md:grid-cols-3 lg:grid-cols-4 gap-[32px_32px]">
      <div
        class="rounded-lg bg-base-white box-border shrink-0 flex flex-col items-center md:items-start justify-start pt-0 px-0 gap-[4px] z-[0] duration-700 ease-in-out overflow-hidden"
        *ngFor="let item of productList">
        <app-product-item [item]="item" (onUpdateFavorites)="getFavoriteProducts()"
          (onUpdateProduct)="onUpdateProduct($event)" />
      </div>
    </div>
  </div>
</div>
<div *ngIf="productList?.length === 0">
  <div class="flex-1 flex flex-col items-center">
    <div class="flex-grow text-center">
      <img class="w-24 h-24 ml-[100px] mb-5 relative overflow-hidden shrink-0" alt="rounder heart icon"
        src="assets/images/user/heartrounded.svg" id="shoppingCartIcon" />
      <h1 class="font-bold text-5xl">Favorites</h1>
      <span class="text-lg">Your favorite products will appear here.</span>
    </div>
  </div>
</div>