import { GenericService } from '@app/core/services/generics.service';
import { LayoutServiceService } from './../services/layout-service.service';
import { Component, Input, ViewEncapsulation, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { environment } from '@environments/environment';
import { NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrl: './footer.component.scss',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgFor, NgIf, RouterLink],
})
export class FooterComponent implements OnInit, OnChanges {
  @Input() collections;
  @Input() companyInfo;
  companyLogo = '';
  defaultLogo;
  companyDescription = '';
  copyrightInfo = '';
  iosAppUrl = '';
  androidAppUrl = '';
  facebookUrl = '';
  instagramUrl = '';
  twitterUrl = '';
  linkedInUrl = '';

  marketAppSettings;
  tenantUid;
  appName = environment.eddressAppConfig.appName;

  contentPages = [];

  companyInfoPages = [];
  legalPages = [];

  constructor(
    public router: Router,
    private layoutServices: LayoutServiceService,
    private genericService: GenericService
  ) {}

  ngOnInit() {
    this.getMarketPlaceAppData();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['companyInfo']) {
      this.companyInfo = changes['companyInfo']?.currentValue;
      this.getCompanyInfo();
    }
  }

  getMarketPlaceAppData() {
    const params = {
      appName: environment.eddressAppConfig.appName,
      appVersionCode: 1,
      operationUid: environment.eddressAppConfig.operationUid,
      os: 'web',
      locale: 'en',
    };
    this.genericService.getMarketPlaceAppData(params).subscribe((result) => {
      this.marketAppSettings = result?.data?.marketAppSettings;
      this.tenantUid = result.data.marketAppSettings.tenantUid;

      if (this.tenantUid) {
        this.getContentPages();
      }
    });
  }

  onClickMenu(menu) {
    this.router.navigate(['/category', menu?.collection?.slug]);
  }

  getContentPages() {
    const params = {
      tenantUid: this.tenantUid,
    };
    this.layoutServices.getAllContentPages(params, environment.eddressAppConfig.appName).subscribe(
      (result) => {
        this.contentPages = result;
        this.companyInfoPages = this.contentPages.filter(item => item.tag === 'COMPANY_INFO');
        this.legalPages = this.contentPages.filter(item => item.tag === 'LEGAL');
      },
      (err) => {}
    );
  }

  onClickContentPage(page) {
    this.router.navigate(['/contents', page?.slug]);
  }
  redirectToAppLink(type) {
    if (type === 'ios') {
      window.open(environment?.eddressAppConfig?.appUrls?.ios, '_blank');
    } else if (type === 'android') {
      window.open(environment?.eddressAppConfig?.appUrls?.android, '_blank');
    }
  }

  getCompanyInfo() {
    if(this.companyInfo) {
      this.companyDescription = this.companyInfo.companyInfo;
      this.companyLogo = this.companyInfo.logoUrl;
      this.copyrightInfo = this.companyInfo.copyrightInfo;
      this.iosAppUrl = this.companyInfo.iosAppUrl;
      this.androidAppUrl = this.companyInfo.androidAppUrl;
      this.facebookUrl = this.companyInfo.facebookUrl;
      this.instagramUrl = this.companyInfo.instagramUrl;
      this.twitterUrl = this.companyInfo.twitterUrl;
      this.linkedInUrl = this.companyInfo.linkedInUrl;
    }
  }
}
