import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/services/api.service';
import { Urls } from '@app/core/util/urls';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MyWalletService {
  constructor(private api: ApiService) {}

  getWalletHistory(): Observable<any> {
    return this.api.get(Urls.WALLET_HISTORY).pipe(map((data) => data));
  }
}
