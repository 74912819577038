import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/services/api.service';
import { Urls } from '@app/core/util/urls';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PaymentsService {
  constructor(private api: ApiService) {}
  getPaymentCards(params): Observable<any> {
    return this.api.post(Urls.GET_CREDIT_CARDS, params).pipe(map((data) => data));
  }
  addCreditCard(params): Observable<any> {
    return this.api.post(Urls.ADD_CREDIT_CARD, params).pipe(map((data) => data));
  }
  deleteCreditCard(uid): Observable<any> {
    return this.api.post(Urls.DELETE_CREDIT_CARD + "/" + uid,).pipe(map((data) => data));
  }
}
