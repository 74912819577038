import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GenericService } from '@app/core/services/generics.service';
import { GoogleMapsModule } from '@angular/google-maps';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-add-location',
    templateUrl: './add-location.component.html',
    styleUrl: './add-location.component.scss',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        FormsModule,
        NgIf,
        GoogleMapsModule,
        ReactiveFormsModule,
    ],
})
export class AddLocationComponent implements OnInit, OnChanges {
  @Input() location;
  @Output() closeDrawer = new EventEmitter<boolean>();
  @Output() onSubmit = new EventEmitter<boolean>();
  locationFormGroup: FormGroup;
  isSubmitted = false;
  isDraged = false;
  areaName;
  map: google.maps.Map;
  options: google.maps.MapOptions = {
    scrollwheel: true,
    streetViewControl: false,
    mapTypeControl: false,
  };
  center: google.maps.LatLngLiteral = { lat: 40, lng: -20 };
  zoom = 15;
  icon = {
    url: 'assets/images/home/app_pin.png', // url
    scaledSize: new google.maps.Size(30, 40), // scaled size
    // origin: new google.maps.Point(0, 0), // origin
    // anchor: new google.maps.Point(0, 0), // anchor
  };
  markerOptions: google.maps.MarkerOptions = {
    draggable: false,
    icon: this.icon,
  };
  markerPosition: google.maps.LatLngLiteral;
  locality;

  constructor(
    private formBuilder: FormBuilder,
    private genericService: GenericService
  ) {}

  ngOnInit(): void {
    this._initLocationForm();
    this.getDefaultLocation();
  }
  getDefaultLocation() {
    this.genericService.getDefaultLocations().subscribe((locs) => {
      if (this.location) {
        this.setMarkerPosition();
      } else {
        if (locs.length > 0) {
          this.center = {
            lat: locs[0].lat,
            lng: locs[0].lon,
          };
          this.markerPosition = {
            lat: locs[0].lat,
            lng: locs[0].lon,
          };
        }
      }

      this.getLocationNameByLatLng();
    });
  }
  mapInitialize(map) {
    this.map = map;
    this.loadGooglePlacesAutoComplete();
  }
  ngOnChanges(changes: SimpleChanges) {
    const simpleChanges: any = changes;
    if (!simpleChanges?.location?.firstChange) {
      this.location = simpleChanges?.location?.currentValue;
      this._initLocationForm();
      this.setMarkerPosition();
    }
  }

  setMarkerPosition() {
    this.center = {
      lat: this.location?.coordinates?.lat,
      lng: this.location?.coordinates?.lon,
    };
    this.markerPosition = {
      lat: this.location?.coordinates?.lat,
      lng: this.location?.coordinates?.lon,
    };
  }

  _initLocationForm() {
    this.locationFormGroup = this.formBuilder.group({
      name: [this.location?.name, [Validators.required]],
      building: [this.location?.building],
      street: [this.location?.street],
    });
  }

  addMarker(event: google.maps.MapMouseEvent) {
    this.markerPosition = event.latLng.toJSON();
    this.getLocationNameByLatLng();
  }
  getLocationNameByLatLng() {
    const geocoder = new google.maps.Geocoder();
    const latlng = new google.maps.LatLng(this.markerPosition.lat, this.markerPosition.lng);
    const request = {
      location: latlng,
    };
    geocoder.geocode(request, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        if (results[1]) {
          this.locality = results[1].formatted_address;
          this.areaName = this.locality
        }
      }
    });
  }
  onSubmitFn() {
    this.isSubmitted = true;
    const formValues = this.locationFormGroup.value;

    if (this.locationFormGroup.valid) {
      formValues.coordinates = {
        lat: this.markerPosition.lat,
        lon: this.markerPosition.lng,
      };
      formValues.locality = this.locality;
      this.onSubmit.emit(formValues);
      this.isSubmitted = false;
      this.locationFormGroup.reset();
    } else {
      this.isSubmitted = false;
      this.locationFormGroup.markAllAsTouched();
    }
  }

  loadGooglePlacesAutoComplete() {
    const input = document.getElementById('pac-input') as HTMLInputElement;
    const options = {
      // componentRestrictions: { country: this.user.countryIso || 'lb' },
      strictBounds: false,
    } as google.maps.places.AutocompleteOptions;
    const autocomplete = new google.maps.places.Autocomplete(input, options);

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      if (place.geometry.viewport) {
        this.map.fitBounds(place.geometry.viewport);
        this.map.setCenter(place.geometry.location);
        this.markerPosition = place.geometry.location.toJSON();
      } else {
        this.map.setCenter(place.geometry.location);
      }
      this.getLocationNameByLatLng();
    });
  }

  close() {
    this.closeDrawer.emit(false);
  }
  get locationForm() {
    return this.locationFormGroup.controls;
  }
}
