<div
  class="w-[300px] flex-1 bg-white overflow-hidden flex flex-col items-center justify-start gap-[24px_0px] border-l-[1px] border-solid border-gray-200">
  <div class="self-stretch flex flex-row items-start justify-start py-0 px-6 relative gap-[0px_8px]">
    <div class="flex-1 flex flex-row items-center justify-start pt-6 px-0 pb-0 gap-[0px_16px] z-[0]">
      <img class="w-6 relative rounded-lg h-6" alt="shoping bag icon" src="assets/images/home/shoppingbag01.svg" />

      <div class="flex-1 flex flex-col items-start justify-start gap-[4px_0px]">
        <div class="self-stretch relative leading-[30px] font-semibold">
          Order #{{ orderNumber }}
        </div>
        <div class="w-[376px] relative text-sm leading-[20px] text-gray-600 hidden">
          Lorem ipsum dolor sit amet.
        </div>
      </div>
    </div>
    <div (click)="close()"
      class="w-10 my-0 mx-[!important] absolute top-[12px] right-[16px] rounded-lg h-10 overflow-hidden shrink-0 flex flex-row items-center justify-center p-2 box-border z-[1]">
      <img class="w-6 relative h-6 overflow-hidden shrink-0 cursor-pointer" alt="close icon"
        src="assets/images/home/xclose.svg" />
    </div>
  </div>
  <div
    class="self-stretch flex flex-col items-start justify-start py-0 px-6 box-border gap-[24px_0px] text-xs text-gray-800">
    <div class="self-stretch flex flex-col items-start justify-start text-center text-sm text-gray-700">
      <div class="self-stretch flex flex-row items-center justify-between">
        <div
          class="rounded-md bg-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] flex flex-row items-center justify-start py-0.5 px-2 gap-[0px_6px] border-[1px] border-solid border-gray-300">
          <img class="w-2 relative h-2" alt="item icon" src="assets/images/home/-dot.svg" />

          <div class="relative leading-[20px]">{{ itemsCount }} items</div>
        </div>
        <div class="relative text-xl leading-[30px] font-semibold text-gray-900 text-left">
          {{ configuration?.tenantCurrencySymbol }}{{ grandTotal || 0 | number : "1.2-2" }}
        </div>
      </div>
    </div>
    <div class="self-stretch relative bg-gray-201 h-px"></div>

    <div *ngFor="let item of items"
      class="self-stretch rounded-lg bg-white flex flex-row items-center justify-start gap-[0px_4px] border-[1px] border-solid border-gray-201">
      <img class="w-[100px] rounded-3xs h-[100px] object-cover" alt="product image"
        [src]="item?.imageUrl ? item?.imageUrl : item?.image" />

      <div class="flex-1 flex flex-col items-start justify-start py-1 px-3 gap-[4px_0px]">
        <div class="self-stretch relative leading-[18px] font-semibold">
          {{ item?.label }}
        </div>
        <div class="self-stretch relative leading-[18px]">
          {{ item?.quantity }}
        </div>
        <div class="self-stretch flex flex-row items-center justify-between text-base text-brand-500">
          <div class="flex flex-row items-center justify-start">
            <b class="relative leading-[24px]">
              {{ configuration?.tenantCurrencySymbol }}{{ item?.totalPrice | number : "1.2-2" }}</b>
          </div>
        </div>
      </div>
    </div>

    <div class="self-stretch relative bg-gray-201 h-px"></div>
  </div>

  <!-- <div class="w-full px-6" *ngIf="!hideReoderbtn">
    <div
      class="self-stretch rounded-lg bg-brand-600 shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] overflow-hidden flex flex-row items-center justify-center py-2.5 px-6 gap-[6px] text-base-white border-[1px] border-solid border-brand-600"
    >
      <img
        class="w-5 relative h-5 overflow-hidden shrink-0 hidden"
        alt="s"
        src=""
      />
      <div class="flex flex-1 flex-row items-center justify-center py-0 px-0.5 w-full">
        <button
          class="login-button relative leading-[24px] font-semibold w-full"
          (click)="reOrder()"
        >
          Reorder
        </button>
      </div>
      <img
        class="w-5 relative h-5 overflow-hidden shrink-0 hidden"
        alt="s"
        src=""
      />
    </div>
  </div> -->
</div>