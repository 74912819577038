import { pageData } from "@app/core/models/pageData.model";

export interface PageTitlesState {
  pageData: pageData[];
    isLoaded: boolean
  }

export const initialPageTitleState: PageTitlesState = {
  pageData: [],
    isLoaded: false,
  };
  