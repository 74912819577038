<div
  class="w-full relative bg-base-white flex flex-col items-center justify-start pt-20 px-2 md:px-8 pb-12 box-border gap-[0px] text-left text-sm text-gray-900 font-display-sm-semibold">
  <div class="flex flex-col items-center justify-start py-0 px-8 box-border md:w-[420px] z-[0]">
    <div id="recaptcha-container"></div>
    <div *ngIf="!isLoginWithPhone" class="flex flex-col items-center justify-start gap-[32px] md:w-[420px]">
      <div class="self-stretch flex flex-col items-center justify-start gap-[48px] text-center text-11xl">
        <div class="self-stretch flex flex-col items-start justify-start gap-[12px]">
          <div class="self-stretch relative leading-[20px] font-semibold">
            Log in to your account
          </div>
          <div class="self-stretch relative text-base leading-[24px] text-gray-600">
            Welcome back! Please enter your details.
          </div>
        </div>
      </div>
      <div class="self-stretch rounded-xl flex flex-col items-center justify-start gap-[24px] text-gray-700">
        <div class="self-stretch flex-1 flex-col items-start justify-start gap-[20px]">
          <form *ngIf="loginOptions?.loginWithEmail" [formGroup]="loginFormGroup">
            <div class="self-stretch flex-1 flex-col items-start justify-start gap-[6px] mb-4">
              <div class="relative leading-[20px] mb-3">Email</div>

              <div class="flex flex-row items-center justify-start">
                <input type="email" placeholder="Enter your email"
                  class="flex-1 overflow-hidden text-ellipsis whitespace-nowrap rounded-lg border-gray-300 focus:outline-none px-2"
                  formControlName="email" />
              </div>

              <span *ngIf="loginForm['email'].invalid && isSubmitted" class="p-error">
                <span *ngIf="loginForm['email'].errors['required']">Email is required</span>
                <span *ngIf="loginForm['email'].errors['email']">Email is invalid</span>
              </span>
              <img class="w-4 relative h-4 hidden" alt="hidden icon" src="assets/images/home/help-icon.svg" />

              <div class="w-80 relative leading-[20px] text-gray-600 hidden">
                This is a hint text to help user.
              </div>
            </div>

            <div class="self-stretch flex-1 flex-col items-start justify-start gap-[6px] mb-6">
              <div class="relative leading-[20px] mb-3">Password</div>

              <div class="flex flex-row items-center justify-start">
                <input type="password" placeholder="Enter your password"
                  class="flex-1 overflow-hidden text-ellipsis whitespace-nowrap rounded-lg border-gray-300 focus:outline-none px-2"
                  formControlName="password" />
              </div>

              <span *ngIf="loginForm['password'].invalid && isSubmitted" class="p-error">
                <span *ngIf="loginForm['password'].errors['required']">Password is required</span>
              </span>

              <img class="w-4 relative h-4 hidden" alt="hidden icon" src="assets/images/home/help-icon.svg" />

              <div class="w-80 relative leading-[20px] text-gray-600 hidden">
                This is a hint text to help user.
              </div>
            </div>
          </form>
          <div *ngIf="loginOptions?.loginWithEmail" class="self-stretch flex flex-row items-center justify-start mb-6">
            <div class="flex-1 flex flex-row items-start justify-start gap-[8px]">
              <input id="default-checkbox" type="checkbox" value=""
                class="w-4 h-4 mt-1 text-brand-600 bg-gray-100 border-gray-300 rounded focus:ring-brand-500 dark:focus:ring-brand-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
              <div class="flex-1 flex flex-col items-start justify-start w-full">
                <div class="self-stretch relative leading-[20px]">Remember for 30 days</div>
                <div class="w-80 relative leading-[20px] text-gray-600 hidden">
                  Save my login details for next time.
                </div>
              </div>
            </div>
            <div
              class="self-stretch overflow-hidden flex flex-1 flex-row items-center justify-end gap-[6px] text-brand-700">
              <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />
              <div class="relative leading-[20px] font-semibold cursor-pointer" (click)="openForgotPassword()">
                Forgot password
              </div>
              <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />
            </div>
          </div>
          <div class="self-stretch flex flex-col items-start justify-start gap-[16px] text-base">
            <div *ngIf="loginOptions?.loginWithEmail" (click)="onSubmit()"
              class="self-stretch rounded-lg bg-brand-600 shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] overflow-hidden flex flex-row items-center justify-center py-2.5 px-4 gap-[6px] text-base-white border-[1px] border-solid border-brand-600">
              <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />
              <div class="flex flex-1 flex-row items-center justify-center py-0 px-0.5 w-full">
                <button class="login-button relative leading-[24px] font-semibold w-full">
                  Sign in
                </button>
              </div>
              <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />
            </div>
            <div class="self-stretch flex flex-col items-center justify-center gap-[12px]">
              <div *ngIf="loginOptions?.loginWithFacebook" (click)="loginByFacebook()"
                class="w-full rounded-lg bg-base-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] box-border overflow-hidden flex-row items-center justify-center py-2.5 px-4 gap-[12px] border-[1px] border-solid border-gray-300">
                <div class="flex justify-center items-center">
                  <img class="w-6 relative h-6 overflow-hidden shrink-0 inline-block mr-2" alt="facebook icon"
                    src="assets/images/home/social-facebook.svg" />
                  <div class="social-media relative leading-[24px] inline-block font-semibold">
                    Sign in with Facebook
                  </div>
                </div>
              </div>

              <div *ngIf="loginOptions?.loginWithGoogle" (click)="loginByGoogle()"
                class="w-full rounded-lg bg-base-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] box-border overflow-hidden flex-row items-center justify-center py-2.5 px-4 gap-[12px] border-[1px] border-solid border-gray-300">
                <div class="flex justify-center items-center">
                  <img class="w-6 relative h-6 overflow-hidden shrink-0 inline-block mr-2" alt="google icon"
                    src="assets/images/home/social-google.svg" />
                  <div class="social-media relative leading-[24px] inline-block font-semibold inline-block">
                    Sign in with Google
                  </div>
                </div>
              </div>
              <div *ngIf="loginOptions?.loginWithPhone" (click)="loginWithPhone()"
                class="w-full rounded-lg bg-base-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] box-border overflow-hidden flex-row items-center justify-center py-2.5 px-4 gap-[12px] border-[1px] border-solid border-gray-300">
                <div class="flex justify-center items-center">
                  <img class="w-6 relative h-6 overflow-hidden shrink-0 inline-block mr-2" alt="phone icon"
                    src="assets/images/phoneoutgoing02.svg" />
                  <div class="social-media relative leading-[24px] inline-block font-semibold inline-block">
                    Sign in with Phone Number
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="loginOptions?.loginWithEmail"
          class="self-stretch flex flex-row items-baseline justify-center gap-[4px] text-gray-600">
          <div class="relative leading-[20px]">Don’t have an account?</div>
          <div (click)="openSignup()"
            class="overflow-hidden flex flex-row items-center justify-center gap-[6px] text-brand-700">
            <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />
            <div class="signup-button relative leading-[20px] font-semibold">Sign up</div>
            <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="isLoginWithPhone" class="flex flex-col items-center justify-start gap-[32px] md:w-[420px]">
      <div class="self-stretch flex flex-col items-center justify-start gap-[48px] text-center text-11xl">
        <div class="self-stretch flex flex-col items-start justify-start gap-[12px]">
          <div class="self-stretch relative leading-[20px] font-semibold">
            Log in to your account
          </div>
          <div *ngIf="!showOtpField" class="self-stretch relative text-base leading-[24px] text-gray-600">
            Welcome back! Please enter your phone number to receive an activation code.
          </div>
        </div>
      </div>
      <div class="self-stretch rounded-xl flex flex-col items-center justify-start gap-[24px] text-gray-700">
        <div class="self-stretch flex-1 flex-col items-start justify-start gap-[20px]">
          <ng-container *ngIf="!showOtpField">
            <div class="flex items-center justify-center">
              <div class="relative" [ngClass]="{'open': open}">
                <div class="flex">
                  <div class="relative">

                    <button (click)="toggleDropdown()" id="dropdown-phone-button" data-dropdown-toggle="dropdown-phone"
                      class="flex-shrink-0 z-10 inline-flex items-center py-3 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-s-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600"
                      type="button">

                      <img class="h-6 w-6 me-2" [src]="selectedCountry.image" /> {{
                      selectedCountry.dial_code
                      }}<svg class="w-2.5 h-2.5 ms-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                        fill="none" viewBox="0 0 10 6">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="m1 1 4 4 4-4" />
                      </svg>
                    </button>
                    <div *ngIf="open"
                      class="absolute z-10 mt-1 bg-white border border-gray-300 rounded-md shadow-lg w-56">
                      <input [(ngModel)]="searchValue" [ngModelOptions]="{standalone: true}" type="text"
                        placeholder="Search..." class="w-full px-3 py-2 border-b border-gray-300 focus:outline-none">
                      <ul class="max-h-60 overflow-y-auto">
                        <li *ngFor="let country of countries | search:'name': searchValue"
                          (click)="selectCountry(country)"
                          class="px-3 py-2 hover:bg-gray-100 cursor-pointer flex items-center">
                          <img class="h-4 w-4 me-2" [src]="country.image" />
                          <span>{{ country.name }}</span>
                          <span class="ml-auto text-gray-500">{{ country.dial_code }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <input [(ngModel)]="phoneNumber" type="tel"
                    class="px-3 py-2 border border-gray-300 rounded-r-md focus:outline-none" placeholder="Phone number">
                </div>
                <!-- <span *ngIf="
                userAccountForm['phoneNumber'].invalid &&
                userAccountForm['phoneNumber'].errors['required'] &&
                isSubmitted
              " class="text-error-500">Mobile Number is required</span> -->
              </div>


            </div>
            <div class="self-stretch relative mt-10 text-base leading-[24px] text-gray-600">
              If you use phone sign-in, you might receive an SMS message for verification and standard rate apply.
            </div>
          </ng-container>
          <ng-container *ngIf="showOtpField">
            <div class="flex flex-col items-center justify-center">
              <div class="self-stretch relative  text-base leading-[24px] text-gray-600">
                Please enter the verification code that was sent to your mobile device.
              </div>
              <ng-otp-input class="mt-5" (onInputChange)="verifyCode($event)" [config]="{length:6}"></ng-otp-input>
            </div>
            <div class="self-stretch mt-10 flex flex-col items-start justify-start gap-[16px] text-base">
              <button [disabled]="timeLeft > 0" (click)="sendVerificationCode(); timeLeft = 30"
                class="self-stretch rounded-lg bg-brand-600 disabled:opacity-75 shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] overflow-hidden flex flex-row items-center justify-center py-2.5 px-4 gap-[6px] text-base-white border-[1px] border-solid border-brand-600">
                <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />
                <div class="flex flex-1 flex-row items-center justify-center py-0 px-0.5 w-full">
                  <span class="login-button relative leading-[24px] font-semibold w-full">
                    {{timeLeft === 0 ? 'Resend' : 'Resend in'}} <span *ngIf="timeLeft !== 0">{{timeLeft + '
                      seconds'}}</span>
                  </span>
                </div>
                <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />
              </button>
            </div>
          </ng-container>
          <div *ngIf="!showOtpField"
            class="self-stretch mt-10 flex flex-col items-start justify-start gap-[16px] text-base">
            <div (click)="sendVerificationCode()"
              class="self-stretch rounded-lg bg-brand-600 shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] overflow-hidden flex flex-row items-center justify-center py-2.5 px-4 gap-[6px] text-base-white border-[1px] border-solid border-brand-600">
              <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />
              <div class="flex flex-1 flex-row items-center justify-center py-0 px-0.5 w-full">
                <button class="login-button relative leading-[24px] font-semibold w-full">
                  Confirm
                </button>
              </div>
              <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

  <div (click)="closeLogin()"
    class="xclose-container w-11 my-0 mx-[!important] absolute top-[20px] right-[30px] rounded-lg h-11 overflow-hidden shrink-0 flex flex-row items-center justify-center p-2 box-border z-[1]">
    <img class="w-6 relative h-6 overflow-hidden shrink-0" alt="close icon" src="assets/images/home/xclose.svg" />
  </div>
</div>