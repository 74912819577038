var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
import instances from '../../dom/instances';
var Default = {
  triggerType: 'hover',
  onShow: function () {},
  onHide: function () {},
  onToggle: function () {}
};
var DefaultInstanceOptions = {
  id: null,
  override: true
};
var Dial = /** @class */function () {
  function Dial(parentEl, triggerEl, targetEl, options, instanceOptions) {
    if (parentEl === void 0) {
      parentEl = null;
    }
    if (triggerEl === void 0) {
      triggerEl = null;
    }
    if (targetEl === void 0) {
      targetEl = null;
    }
    if (options === void 0) {
      options = Default;
    }
    if (instanceOptions === void 0) {
      instanceOptions = DefaultInstanceOptions;
    }
    this._instanceId = instanceOptions.id ? instanceOptions.id : targetEl.id;
    this._parentEl = parentEl;
    this._triggerEl = triggerEl;
    this._targetEl = targetEl;
    this._options = __assign(__assign({}, Default), options);
    this._visible = false;
    this._initialized = false;
    this.init();
    instances.addInstance('Dial', this, this._instanceId, instanceOptions.override);
  }
  Dial.prototype.init = function () {
    var _this = this;
    if (this._triggerEl && this._targetEl && !this._initialized) {
      var triggerEventTypes = this._getTriggerEventTypes(this._options.triggerType);
      this._showEventHandler = function () {
        _this.show();
      };
      triggerEventTypes.showEvents.forEach(function (ev) {
        _this._triggerEl.addEventListener(ev, _this._showEventHandler);
        _this._targetEl.addEventListener(ev, _this._showEventHandler);
      });
      this._hideEventHandler = function () {
        if (!_this._parentEl.matches(':hover')) {
          _this.hide();
        }
      };
      triggerEventTypes.hideEvents.forEach(function (ev) {
        _this._parentEl.addEventListener(ev, _this._hideEventHandler);
      });
      this._initialized = true;
    }
  };
  Dial.prototype.destroy = function () {
    var _this = this;
    if (this._initialized) {
      var triggerEventTypes = this._getTriggerEventTypes(this._options.triggerType);
      triggerEventTypes.showEvents.forEach(function (ev) {
        _this._triggerEl.removeEventListener(ev, _this._showEventHandler);
        _this._targetEl.removeEventListener(ev, _this._showEventHandler);
      });
      triggerEventTypes.hideEvents.forEach(function (ev) {
        _this._parentEl.removeEventListener(ev, _this._hideEventHandler);
      });
      this._initialized = false;
    }
  };
  Dial.prototype.removeInstance = function () {
    instances.removeInstance('Dial', this._instanceId);
  };
  Dial.prototype.destroyAndRemoveInstance = function () {
    this.destroy();
    this.removeInstance();
  };
  Dial.prototype.hide = function () {
    this._targetEl.classList.add('hidden');
    if (this._triggerEl) {
      this._triggerEl.setAttribute('aria-expanded', 'false');
    }
    this._visible = false;
    // callback function
    this._options.onHide(this);
  };
  Dial.prototype.show = function () {
    this._targetEl.classList.remove('hidden');
    if (this._triggerEl) {
      this._triggerEl.setAttribute('aria-expanded', 'true');
    }
    this._visible = true;
    // callback function
    this._options.onShow(this);
  };
  Dial.prototype.toggle = function () {
    if (this._visible) {
      this.hide();
    } else {
      this.show();
    }
  };
  Dial.prototype.isHidden = function () {
    return !this._visible;
  };
  Dial.prototype.isVisible = function () {
    return this._visible;
  };
  Dial.prototype._getTriggerEventTypes = function (triggerType) {
    switch (triggerType) {
      case 'hover':
        return {
          showEvents: ['mouseenter', 'focus'],
          hideEvents: ['mouseleave', 'blur']
        };
      case 'click':
        return {
          showEvents: ['click', 'focus'],
          hideEvents: ['focusout', 'blur']
        };
      case 'none':
        return {
          showEvents: [],
          hideEvents: []
        };
      default:
        return {
          showEvents: ['mouseenter', 'focus'],
          hideEvents: ['mouseleave', 'blur']
        };
    }
  };
  return Dial;
}();
export function initDials() {
  document.querySelectorAll('[data-dial-init]').forEach(function ($parentEl) {
    var $triggerEl = $parentEl.querySelector('[data-dial-toggle]');
    if ($triggerEl) {
      var dialId = $triggerEl.getAttribute('data-dial-toggle');
      var $dialEl = document.getElementById(dialId);
      if ($dialEl) {
        var triggerType = $triggerEl.getAttribute('data-dial-trigger');
        new Dial($parentEl, $triggerEl, $dialEl, {
          triggerType: triggerType ? triggerType : Default.triggerType
        });
      } else {
        console.error("Dial with id ".concat(dialId, " does not exist. Are you sure that the data-dial-toggle attribute points to the correct modal id?"));
      }
    } else {
      console.error("Dial with id ".concat($parentEl.id, " does not have a trigger element. Are you sure that the data-dial-toggle attribute exists?"));
    }
  });
}
if (typeof window !== 'undefined') {
  window.Dial = Dial;
  window.initDials = initDials;
}
export default Dial;
